import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Add from "@material-ui/icons/PlaylistAdd";
import EditRounded from "@material-ui/icons/EditRounded";
import Computer from "@material-ui/icons/Computer";
import Check from "@material-ui/icons/Check";
import CheckBox from "@material-ui/icons/CheckBox";
import Cancel from "@material-ui/icons/Cancel";
import HighlightOff from "@material-ui/icons/HighlightOff";

//Style
import styles from "assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle";

// General components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

//Constants
import { ConnectionTypes } from "utils/Constants";

// authentication
import MsalAuth from "authentication/MsalAuth";

const useStyles = makeStyles(styles);

const fieldsByType = {
    s3: [
        { key: "accessKeyId", label: "Access Key Id", type: "password" },
        {
            key: "secretAccessKey",
            label: "Secret Access Key",
            type: "password",
        },
        { key: "bucketName", label: "Bucket Name", type: "text" },
        { key: "path", label: "Path", type: "text" },
        {
            key: "fileExtension",
            label: "File Extension",
            type: "select",
            values: [
                { label: "JSON", key: ".json" },
                { label: "CSV", key: ".csv" },
                { label: "TXT", key: ".txt" },
            ],
        },
        { key: "unzip", label: "Unzip", type: "boolean" },
    ],
    sharepoint: [
        { key: "tenantId", label: "Tenant Id", type: "sp" },
        { key: "siteUrl", label: "Site Url", type: "text" },
        { key: "path", label: "Path", type: "text" },
        {
            key: "fileExtension",
            label: "File Extension",
            type: "select",
            values: [
                { label: "JSON", key: ".json" },
                { label: "CSV", key: ".csv" },
                { label: "TXT", key: ".txt" },
            ],
        },
        { key: "unzip", label: "Unzip", type: "boolean" },
    ],
    qualtrics: [
        { key: "siteUrl", label: "Site Url", type: "text" },
        { key: "token", label: "Token", type: "password" },
        {
            key: "fileExtension",
            label: "File Extension",
            type: "select",
            values: [
                { label: "JSON", key: ".json" },
                { label: "CSV", key: ".csv" },
                { label: "TXT", key: ".txt" },
            ],
        },
        { key: "unzip", label: "Unzip", type: "boolean" },
    ],
    sapHana: [
        { key: "dbHost", label: "Host", type: "text" },
        { key: "dbPort", label: "Port", type: "text" },
        { key: "dbSchema", label: "Schema", type: "text" },
        { key: "dbUser", label: "User", type: "text" },
        { key: "dbPassword", label: "Password", type: "password" },
    ],
    default: [
        { key: "name", label: "Connection Name", type: "text" },
        { key: "connectionId", label: "Connection Id", type: "text" },
        { key: "type", label: "Type", type: "text" },
    ],
};

async function msAuthentication(props) {
    const msalAuth = new MsalAuth("insights");
    msalAuth.login(function(response) {
        props.onFieldChangeConnection("tenantId", response.tenantId);
    });
}

const ConnectionsTab = (props) => {
    const classes = useStyles();
    let remoteConnections = props.config.params
        ? { ...props.config.params.remoteConnections }
        : {};

    function showModal() {
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={props.showModal === true}
                keepMounted
                onClose={() => props.setShowModal(false)}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
            >
                <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <h4 className={classes.modalTitle}>
                        Error connection details
                    </h4>
                </DialogTitle>
                <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                >
                    {props.connectionMessage ? props.connectionMessage : null}
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button
                        onClick={() => props.setShowModal(false)}
                        color="danger"
                        simple
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function ConnectionsTable() {
        let rows = Object.keys(remoteConnections);
        return (
            <GridItem xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                    {rows.length > 0 ? (
                        <Table
                            className={classes.table}
                            aria-label="Connections Table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Connection Name</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row}>
                                        <TableCell>
                                            {remoteConnections[row]["name"]}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                ConnectionTypes[
                                                    remoteConnections[row][
                                                        "type"
                                                    ]
                                                ]
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="Edit Connection">
                                                <IconButton
                                                    onClick={() =>
                                                        props.onEditConnectionButton(
                                                            row,
                                                            remoteConnections[
                                                                row
                                                            ]
                                                        )
                                                    }
                                                >
                                                    <EditRounded
                                                        color="primary"
                                                        fontSize="small"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete Connection">
                                                <IconButton
                                                    onClick={() =>
                                                        props.onDeleteConnectionButton(
                                                            row
                                                        )
                                                    }
                                                >
                                                    <HighlightOff
                                                        color="primary"
                                                        fontSize="small"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    ) : (
                        <Table
                            className={classes.table}
                            aria-label="Connections Table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Add new connection...</TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    )}
                </TableContainer>
            </GridItem>
        );
    }

    function ConnectionForm() {
        return (
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                    <GridItem xs={12} sm={12}>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={4}>
                                    <CustomInput
                                        success={
                                            props.configConnectionState.name ===
                                            "success"
                                        }
                                        error={
                                            props.configConnectionState.name ===
                                            "error"
                                        }
                                        labelText={
                                            <span>
                                                Connection Name{" "}
                                                <small>
                                                    {props.configConnectionState
                                                        .name === "error"
                                                        ? "(required)"
                                                        : "*"}{" "}
                                                </small>
                                            </span>
                                        }
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            disabled:
                                                props.displayModeConnection ===
                                                "edit",
                                            onChange: (event) =>
                                                props.onFieldChangeConnection(
                                                    "name",
                                                    event.target.value
                                                ),
                                            value: props.configConnection.name,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={4}>
                                    <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                    >
                                        <InputLabel
                                            htmlFor="simple-select"
                                            className={classes.selectLabel}
                                        >
                                            Connection Type
                                        </InputLabel>
                                        <Select
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                                disabled: classes.disabled,
                                            }}
                                            value={props.configConnection.type}
                                            onChange={(e) =>
                                                props.onFieldChangeConnection(
                                                    "type",
                                                    e.target.value
                                                )
                                            }
                                            inputProps={{
                                                name: "type",
                                                id: "type",
                                            }}
                                        >
                                            
                                            <MenuItem
                                                classes={{
                                                    root:
                                                        classes.selectMenuItem,
                                                    selected:
                                                        classes.selectMenuItemSelected,
                                                }}
                                                style={{
                                                    overflowX: "auto",
                                                    textOverflow: "ellipsis",
                                                }}
                                                value="qualtrics"
                                            >
                                                Qualtrics XM Automations
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridItem>
                </GridContainer>
                <h5 className={classes.sectionTitle}>Parameters</h5>
                {TypeForm(props.configConnection.type)}

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Tooltip title="Check connection">
                            <Button
                                color={
                                    props.connectionConnected !== undefined
                                        ? props.connectionConnected
                                            ? "info"
                                            : "danger"
                                        : "facebook"
                                }
                                onClick={() =>
                                    props.onCheckConnection(fieldsByType)
                                }
                                round
                                className={classes.documentation}
                                disabled={
                                    props.connectionTesting ||
                                    props.formIsSaving
                                }
                            >
                                {props.connectionConnected !== undefined ? (
                                    props.connectionConnected ? (
                                        <div>
                                            <CheckBox
                                                className={classes.icons}
                                            />{" "}
                                            Connected
                                        </div>
                                    ) : (
                                        <div>
                                            <Cancel className={classes.icons} />{" "}
                                            Connection Error
                                        </div>
                                    )
                                ) : (
                                    <div>
                                        {props.connectionTesting
                                            ? "Loading..."
                                            : "Test Connectivity"}{" "}
                                    </div>
                                )}
                            </Button>
                        </Tooltip>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        {props.connectionMessage ? (
                            <Tooltip title="View connection error details">
                                <Button
                                    color="danger"
                                    simple
                                    round
                                    className={classes.documentation}
                                    onClick={() => props.setShowModal(true)}
                                >
                                    Error details
                                </Button>
                            </Tooltip>
                        ) : null}
                    </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                    <Button
                        disabled={
                            props.formIsSaving || !props.connectionConnected
                        }
                        variant="outlined"
                        color="primary"
                        onClick={() => props.onSaveConnection(fieldsByType)}
                    >
                        {!props.formIsSaving ? "Save" : "Loading..."}
                    </Button>
                    <Button
                        disabled={props.formIsSaving}
                        variant="outlined"
                        color="danger"
                        onClick={() => props.onCancelConnectionButton()}
                    >
                        Cancel
                    </Button>
                </GridContainer>
            </GridItem>
        );
    }

    function TypeForm(type) {
        let fields = [];
        if (type && type !== "") {
            fields = fieldsByType[type];
        }

        return <div>{fields.map((field) => inputByType(field))}</div>;
    }

    function inputByType(field) {
        switch (field.type) {
            case "boolean":
                return booleanInput(field);
            case "select":
                return selectInput(field);
            case "sp":
                return spInput(field);
            default:
                return textInput(field);
        }
    }

    function selectInput(input) {
        return (
            <GridContainer key={input.key}>
                <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                        {input.label}
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                        >
                            {input.label}
                        </InputLabel>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu,
                            }}
                            classes={{
                                select: classes.select,
                                disabled: classes.disabled,
                            }}
                            value={props.configConnection[input.key]}
                            onChange={(e) =>
                                props.onFieldChangeConnection(
                                    input.key,
                                    e.target.value
                                )
                            }
                            inputProps={{
                                name: input.key,
                                id: input.key,
                            }}
                        >
                            {input.values.map((value) => (
                                <MenuItem
                                    key={value.key}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                            classes.selectMenuItemSelected,
                                    }}
                                    style={{
                                        overflowX: "auto",
                                        textOverflow: "ellipsis",
                                    }}
                                    value={value.key}
                                >
                                    {value.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
        );
    }

    function booleanInput(input) {
        return (
            <GridContainer key={input.key}>
                <GridItem xs={12} sm={6}>
                    <FormControlLabel
                        classes={{
                            label: classes.label,
                            labelPlacementStart: classes.activeToggle,
                        }}
                        label={input.label}
                        labelPlacement="start"
                        control={
                            <Switch
                                checked={props.configConnection[input.key]}
                                onChange={(event) =>
                                    props.onFieldChangeConnection(
                                        input.key,
                                        event.target.checked
                                    )
                                }
                                value={props.configConnection[input.key]}
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.switchChecked,
                                    thumb: classes.switchIcon,
                                    track: classes.switchBar,
                                }}
                            />
                        }
                    />
                </GridItem>
            </GridContainer>
        );
    }

    function textInput(input) {
        return (
            <GridContainer key={input.key}>
                <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                        {input.label}
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <CustomInput
                        success={
                            props.configConnectionState[input.key] ===
                            "successs"
                        }
                        error={
                            props.configConnectionState[input.key] === "error"
                        }
                        labelText={
                            <span>
                                {input.label + " "}
                                <small>
                                    {props.configConnectionState[input.key] ===
                                    "error"
                                        ? "(required)"
                                        : "*"}{" "}
                                </small>
                            </span>
                        }
                        id={input.key}
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            onChange: (event) =>
                                props.onFieldChangeConnection(
                                    input.key,
                                    event.target.value
                                ),
                            value: props.configConnection[input.key],
                            type: input.type,
                        }}
                    />
                </GridItem>
            </GridContainer>
        );
    }

    function spInput(input) {
        return (
            <GridContainer key={input.key}>
                <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.contentLabelHorizontal}>
                        Connect <strong>Relish App Center</strong> with your
                        Microsoft account. If you acept this app will get access
                        to the specified resources in you organization.
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <Tooltip title="Connect Relish Apps with your Microsoft Account">
                        <Button
                            color="behance"
                            onClick={() => msAuthentication(props)}
                            round
                            simple
                            className={classes.documentation}
                        >
                            {props.configConnection[input.key] === "" ? (
                                <Computer className={classes.icons} />
                            ) : (
                                <Check className={classes.icons} />
                            )}
                            Connect with Microsoft
                        </Button>
                    </Tooltip>
                </GridItem>
            </GridContainer>
        );
    }

    return (
        <GridItem xs={12} sm={12} md={12}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <h5 className={classes.sectionTitle}>
                        Connections
                        {props.displayModeConnection === "list" ? (
                            <Tooltip title="Create new connection">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    color="primary"
                                    onClick={() =>
                                        props.onCreateConnectionButton()
                                    }
                                >
                                    <Add className={classes.icons} />
                                </Button>
                            </Tooltip>
                        ) : null}
                    </h5>
                </GridItem>
            </GridContainer>

            {props.displayModeConnection === "list" ? (
                <GridContainer>{ConnectionsTable()}</GridContainer>
            ) : (
                <GridContainer>{ConnectionForm()}</GridContainer>
            )}
            {showModal()}
        </GridItem>
    );
};

export default ConnectionsTab;
