import React, { useState } from 'react'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'

// @material-ui/core components
import Dialog from '@material-ui/core/Dialog'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'

// styles
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js'
import stylesButtons from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'
const useStyles = makeStyles(styles)
const useStylesButtons = makeStyles(stylesButtons)

export default function MonitorModal(props) {
  const [enabled, setEnabled] = useState(props.enabled)
  const [paramName, setParamName] = useState(props.paramName)
  const [type, setType] = useState(props.type)

  const classes = useStyles()
  const buttonClases = useStylesButtons()
  const types = [
    { value: 'string', label: 'String' },
    { value: 'bolean', label: 'Bolean' },
    { value: 'number', label: 'Number' }
  ]

  const returnValues = {
    enabled,
    paramName,
    type
  }

  return (
    <Dialog open={true}>
      <div style={{ alignItems: 'center' }}>
        <Grid container style={{ overflow: 'hidden' }}>
          <div style={{ textAlign: 'center', width: '100%' }}>
            <h3
              style={{
                fontSize: '1.25rem',
                fontWeight: '500',
                lineHeight: '1.6'
              }}
            >
              {props.title}
            </h3>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <GridContainer style={{ paddingBottom: '5%', width: '100%' }}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <CardHeader>Parameter Name</CardHeader>
                <CardBody>
                  <CustomInput
                    labelProps={{ style: { width: '100%' } }}
                    id="paramName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    required
                    inputProps={{
                      type: 'text',
                      value: paramName,
                      onChange: event => {
                        setParamName(event.target.value)
                      }
                    }}
                  />
                </CardBody>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CardHeader>Enabled</CardHeader>
                <CardBody>
                  <div>
                    <Switch
                      checked={enabled}
                      onChange={event => {
                        setEnabled(event.target.checked)
                      }}
                      value="Active"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        thumb: classes.switchIcon,
                        track: classes.switchBar
                      }}
                    />
                  </div>
                </CardBody>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CardHeader>Type</CardHeader>
                <CardBody>
                  <Select
                    MenuProps={{
                      style: { zIndex: 5555 }
                    }}
                    style={{
                      width: '100%'
                    }}
                    value={type}
                    onChange={e => {
                      setType(e.target.value)
                    }}
                    inputProps={{
                      name: 'type',
                      id: 'type'
                    }}
                  >
                    {types.map(value => {
                      return (
                        <MenuItem key={value.value} value={value.value}>
                          {value.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </CardBody>
              </GridItem>
            </GridContainer>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: '5%',
              paddingRight: '5%',
              paddingBottom: '2%',
              textAlign: 'right'
            }}
          >
            <Button
              className={buttonClases.colorPrimary}
              onClick={() => {
                props.onConfirm(returnValues)
              }}
              variant="outlined"
              disabled={paramName === ''}
              color="inherit"
            >
              Confirm
            </Button>

            <Button
              onClick={props.onCancel}
              style={{ marginLeft: '8px' }}
              color="secondary"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  )
}
