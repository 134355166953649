const main = {
    appName: {
        type: "string",
        required: true,
        displayName: "App Name",
        isAlphaNumeric: true,
    },
    type: {
        type: "string",
        required: true,
        displayName: "Environment Type",
    },
    enabled: {
        type: "boolean",
        required: true,
        displayName: "Enabled",
    },
};

const load = {
    name: {
        type: "string",
        required: true,
        displayName: "Name",
    },
    type: {
        type: "string",
        required: true,
        displayName: "Load Type",
    },
    preferredUsageType: {
        type: "string",
        required: false,
        displayName: "Load Type",
        default: 'sync'
    },
    updateFrequency: {
        type: "string",
        required: false,
        displayName: "Frequency",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled") {
                        isValid =
                            data.updateFrequency !== null &&
                            data.updateFrequency !== undefined &&
                            data.updateFrequency.trim() !== "";
                    }
                    return isValid;
                },
                message: "Update Frequency is a mandatory field",
            },
        ],
    },
    executionTime: {
        type: "string",
        required: false,
        displayName: "Execution Time",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled") {
                        isValid =
                            data.executionTime !== null &&
                            data.executionTime !== undefined &&
                            data.executionTime.trim() !== "";
                    }
                    return isValid;
                },
                message: "Execution Time is a mandatory field",
            },
        ],
    },
    executionDay: {
        type: "string",
        required: false,
        displayName: "Execution Day",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled" && (data.updateFrequency === "weekly" || data.updateFrequency === "monthly")) {
                        isValid =
                            data.executionDay !== null &&
                            data.executionDay !== undefined &&
                            data.executionDay.trim() !== "";
                    }
                    return isValid;
                },
                message: "Execution Day is a mandatory field",
            },
        ],
    },
    executionHour: {
        type: "string",
        required: false,
        displayName: "Execution Hour",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled" && (data.updateFrequency === "everyNHours")) {
                        isValid =
                            data.executionHour !== null &&
                            data.executionHour !== undefined &&
                            data.executionHour.trim() !== "";
                    }
                    return isValid;
                },
                message: "Execution Hour is a mandatory field",
            },
        ],
    },
    executionMinute: {
        type: "string",
        required: false,
        displayName: "Execution Minute",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled" && (data.updateFrequency === "everyNMinutes")) {
                        isValid =
                            data.executionMinute !== null &&
                            data.executionMinute !== undefined &&
                            data.executionMinute.trim() !== "";
                    }
                    return isValid;
                },
                message: "Execution Minute is a mandatory field",
            },
        ],
    },
    startDate: {
        type: "string",
        required: true,
        isDate: true,
        displayName: "Start Date",
    },
};

const destination = {
    remoteConnection: {
        type: "string",
        required: true,
        displayName: "Destination Type",
    },
    unzip: {
        type: "boolean",
        required: false,
        displayName: "Unzip file",
    },
    fileExtension: {
        type: "string",
        required: false,
        displayName: "File extension",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    const connection = data.remoteConnections[data.remoteConnection];
                    if (connection.type === "sharepoint" || connection.type === "s3" || connection.type === "qualtrics") {
                        isValid =
                            data.fileExtension !== null &&
                            data.fileExtension !== undefined &&
                            data.fileExtension.trim() !== "";
                    }
                    return isValid;
                },
                message: "fileExtension is a mandatory field",
            },
        ],
    },
    path: {
        type: "string",
        required: false,
        isPath: true,
        displayName: "Path",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    const connection = data.remoteConnections[data.remoteConnection];
                    if (connection.type === "sharepoint" || connection.type === "s3") {
                        isValid =
                            data.path !== null &&
                            data.path !== undefined &&
                            data.path.trim() !== "";
                    }
                    return isValid;
                },
                message: "Path is a mandatory field",
            },
        ],
    },
};

const connection = {
    name: {
        type: "string",
        required: true,
        displayName: "Connection Name",
    },
    type: {
        type: "string",
        required: true,
        displayName: "Connection Type",
    },
    path: {
        type: "string",
        required: false,
        isPath: true,
        displayName: "Path",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint" || data.type === "s3" || data.type === "sftp") {
                        isValid =
                            data.path !== null &&
                            data.path !== undefined &&
                            data.path.trim() !== "";
                    }
                    return isValid;
                },
                message: "Path is a mandatory field",
            },
        ],
    },
    unzip: {
        type: "boolean",
        required: false,
        displayName: "Unzip file",
    },
    fileExtension: {
        type: "string",
        required: false,
        displayName: "File extension",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint" || data.type === "s3" || data.type === "qualtrics") {
                        isValid =
                            data.fileExtension !== null &&
                            data.fileExtension !== undefined &&
                            data.fileExtension.trim() !== "";
                    }
                    return isValid;
                },
                message: "fileExtension is a mandatory field",
            },
        ],
    },
    accessKeyId: {
        type: "string",
        required: false,
        displayName: "Aws Access Key",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.accessKeyId !== null &&
                            data.accessKeyId !== undefined &&
                            data.accessKeyId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Aws Access Key is a mandatory field",
            },
        ],
    },
    secretAccessKey: {
        type: "string",
        required: false,
        displayName: "Aws Access Secret",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.secretAccessKey !== null &&
                            data.secretAccessKey !== undefined &&
                            data.secretAccessKey.trim() !== "";
                    }
                    return isValid;
                },
                message: "Aws Access Secret is a mandatory field",
            },
        ],
    },
    bucketName: {
        type: "string",
        required: false,
        displayName: "Bucket Name",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.bucketName !== null &&
                            data.bucketName !== undefined &&
                            data.bucketName.trim() !== "";
                    }
                    return isValid;
                },
                message: "Bucket Name is a mandatory field",
            },
        ],
    },
    tenantId: {
        type: "string",
        required: false,
        displayName: "Tenant Id",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint") {
                        isValid =
                            data.tenantId !== null &&
                            data.tenantId !== undefined &&
                            data.tenantId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Tenant Id is a mandatory field",
            },
        ],
    },
    siteUrl: {
        type: "string",
        required: false,
        isURL: true,
        displayName: "Site Url",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "qualtrics" || data.type === "sharepoint") {
                        isValid =
                            data.siteUrl !== null &&
                            data.siteUrl !== undefined &&
                            data.siteUrl.trim() !== "";
                    }
                    return isValid;
                },
                message: "Site Url is a mandatory field",
            },
        ],
    },
    token: {
        type: "string",
        required: false,
        displayName: "Token",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "qualtrics") {
                        isValid =
                            data.token !== null &&
                            data.token !== undefined &&
                            data.token.trim() !== "";
                    }
                    return isValid;
                },
                message: "Token is a mandatory field",
            },
        ],
    },
    dbHost: {
        type: "string",
        required: false,
        displayName: "dbHost",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana") {
                        isValid =
                            data.dbHost !== null &&
                            data.dbHost !== undefined &&
                            data.dbHost.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbHost is a mandatory field",
            },
        ],
    },
    dbPort: {
        type: "string",
        required: false,
        displayName: "dbPort",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana" || data.type === "msSql") {
                        isValid =
                            data.dbPort !== null &&
                            data.dbPort !== undefined &&
                            data.dbPort.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbPort is a mandatory field",
            },
        ],
    },
    dbSchema: {
        type: "string",
        required: false,
        displayName: "dbSchema",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana") {
                        isValid =
                            data.dbSchema !== null &&
                            data.dbSchema !== undefined &&
                            data.dbSchema.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbSchema is a mandatory field",
            },
        ],
    },
    dbUser: {
        type: "string",
        required: false,
        displayName: "dbUser",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana" || data.type === "msSql") {
                        isValid =
                            data.dbUser !== null &&
                            data.dbUser !== undefined &&
                            data.dbUser.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbUser is a mandatory field",
            },
        ],
    },
    dbPassword: {
        type: "string",
        required: false,
        displayName: "dbPassword",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sapHana" || data.type === "msSql") {
                        isValid =
                            data.dbPassword !== null &&
                            data.dbPassword !== undefined &&
                            data.dbPassword.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbPassword is a mandatory field",
            },
        ],
    },
    dbName: {
        type: "string",
        required: false,
        displayName: "dbName",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "msSql") {
                        isValid =
                            data.dbName !== null &&
                            data.dbName !== undefined &&
                            data.dbName.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbName is a mandatory field",
            },
        ],
    },
    dbServer: {
        type: "string",
        required: false,
        displayName: "dbServer",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "msSql") {
                        isValid =
                            data.dbServer !== null &&
                            data.dbServer !== undefined &&
                            data.dbServer.trim() !== "";
                    }
                    return isValid;
                },
                message: "dbServer is a mandatory field",
            },
        ],
    },
    host: {
        type: "string",
        required: false,
        displayName: "Host",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sftp") {
                        isValid =
                            data.host !== null &&
                            data.host !== undefined &&
                            data.host.trim() !== "";
                    }
                    return isValid;
                },
                message: "Host is a mandatory field",
            },
        ],
    },
    userName: {
        type: "string",
        required: false,
        displayName: "Username",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sftp") {
                        isValid =
                            data.userName !== null &&
                            data.userName !== undefined &&
                            data.userName.trim() !== "";
                    }
                    return isValid;
                },
                message: "Username is a mandatory field",
            },
        ],
    },
    port: {
        type: "string",
        required: false,
        displayName: "Port",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sftp") {
                        isValid =
                            data.port !== null &&
                            data.port !== undefined &&
                            data.port.trim() !== "";
                    }
                    return isValid;
                },
                message: "Port is a mandatory field",
            },
        ],
    },
    encryptionMethod: {
        type: "string",
        required: false,
        displayName: "Encryption Method",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sftp") {
                        isValid =
                            data.encryptionMethod !== null &&
                            data.encryptionMethod !== undefined &&
                            data.encryptionMethod.trim() !== "";
                    }
                    return isValid;
                },
                message: "encryptionMethod is a mandatory field",
            },
        ],
    },
    encryptionFile: {
        type: "string",
        required: false,
        displayName: "Encryption File",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sftp" && data.encryptionMethod === "pgp") {
                        isValid =
                            data.encryptionFile !== null &&
                            data.encryptionFile !== undefined &&
                            data.encryptionFile.trim() !== "";
                            if (!isValid && data.encryptionFileName && data.encryptionFileName !== '') isValid = true
                    }
                    return isValid;
                },
                message: "encryptionFile is a mandatory field",
            },
        ],
    },
    singleConnection: {
        type: "boolean",
        required: false,
        displayName: "Single Connection",
    },
};

const aribaConnection = {
    realm: {
        type: "string",
        required: true,
        displayName: "Realm",
    },
    clientId: {
        type: "string",
        required: false,
        displayName: "Client Id",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.clientId !== null &&
                            data.clientId !== undefined &&
                            data.clientId.trim() !== "";
                    }
                    return isValid;
                },
                message: "clientId is a mandatory field",
            },
        ],
    },
    apiKey: {
        type: "string",
        required: false,
        displayName: "Api Key",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.apiKey !== null &&
                            data.apiKey !== undefined &&
                            data.apiKey.trim() !== "";
                    }
                    return isValid;
                },
                message: "apiKey is a mandatory field",
            },
        ],
    },
    secret: {
        type: "string",
        required: false,
        displayName: "Secret",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.secret !== null &&
                            data.secret !== undefined &&
                            data.secret.trim() !== "";
                    }
                    return isValid;
                },
                message: "secret is a mandatory field",
            },
        ],
    },
    authenticationType: {
        type: "string",
        required: false,
        displayName: "Authentication Type",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.itk) {
                        isValid =
                            data.authenticationType !== null &&
                            data.authenticationType !== undefined &&
                            data.authenticationType.trim() !== "";
                    }
                    return isValid;
                },
                message: "AuthenticationType is a mandatory field",
            },
        ],
    },
    authenticationKey: {
        type: "string",
        required: false,
        displayName: "Authentication Key",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.itk && data.authenticationType === 'sharedSecret') {
                        isValid =
                            data.authenticationKey !== null &&
                            data.authenticationKey !== undefined &&
                            data.authenticationKey.trim() !== "";
                    }
                    return isValid;
                },
                message: "AuthenticationKey is a mandatory field",
            },
        ],
    },
    siteRegion: {
        type: "string",
        required: false,
        displayName: "SAP Ariba Site Region",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.itk) {
                        isValid =
                            data.siteRegion !== null &&
                            data.siteRegion !== undefined &&
                            data.siteRegion.trim() !== "";
                    }
                    return isValid;
                },
                message: "Site Region is a mandatory field",
            },
        ],
    },
    authUser: {
        type: "string",
        required: false,
        displayName: "Auth User",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.itk && data.authenticationType === 'spendVisibility') {
                        isValid =
                            data.authUser !== null &&
                            data.authUser !== undefined &&
                            data.authUser.trim() !== "";
                    }
                    return isValid;
                },
                message: "Auth Use is a mandatory field",
            },
        ],
    },
    password: {
        type: "string",
        required: false,
        displayName: "Password",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.itk && data.authenticationType === 'spendVisibility') {
                        isValid =
                            data.password !== null &&
                            data.password !== undefined &&
                            data.password.trim() !== "";
                    }
                    return isValid;
                },
                message: "Password is a mandatory field",
            },
        ],
    }
};

export { main, load, destination, connection, aribaConnection };
