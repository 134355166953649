const configuration = {
}

const parameters = {
    ConcatHouseID: {
        type: "boolean",
        required: true,
        displayName: "Concat House Id to the address?",
    },
    ConcatRoomID: {
        type: "boolean",
        required: true,
        displayName: "Concat Room Id to the address?",
    },
    MaxSuggestions: {
        type: "integer",
        required: true,
        displayName: "Maximum number of address suggestions",
        max: 10,
        min: 1,
    },
    PostalCodeLength: {
        type: "integer",
        required: true,
        max: 9,
        min: 5,
        displayName: "Postal code length",
    },
    ValidationLevel: {
        type: "string",
        required: true,
        displayName: "Validation level"
    }
}

const questionnaire = {
    name: {
        type: "string",
        required: true,
        displayName: "Questionnaire Name",
        length: {
            min: 1
        }
    }
}

const question = {
    addressType: {
        type: "string",
        displayName: "Address Type"
    },
    extendedAddress: {
        type: "string",
        displayName: "Extended Address",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if(data.addressType === "extendedAddress"){
                        isValid = (data.extendedAddress !== null && data.extendedAddress !== undefined && data.extendedAddress.trim() !== "")
                    }
                    return isValid
                },
                message: "Extended Address is a mandatory field"
            }
        ]
    },
    address: {
        type: "string",
        displayName: "Address",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if(data.addressType === "address"){
                        isValid = (data.address !== null && data.address !== undefined && data.address.trim() !== "")
                    }
                    return isValid
                },
                message: "Address is a mandatory field"
            }
        ]
    },//Fields for generic
    street: {
        type: "string",
        displayName: "Street",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if(data.addressType === "generic"){
                        isValid = (data.street !== null && data.street !== undefined && data.street.trim() !== "")
                    }
                    return isValid
                },
                message: "Street is a mandatory field"
            }
        ]
    },
    street2: {
        type: "string",
        displayName: "Street 2",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if(data.addressType === "generic"){
                        isValid = (data.street2 !== null && data.street2 !== undefined && data.street2.trim() !== "")
                    }
                    return isValid
                },
                message: "Street 2 is a mandatory field"
            }
        ]
    },
    city: {
        type: "string",
        displayName: "City",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if(data.addressType === "generic"){
                        isValid = (data.city !== null && data.city !== undefined && data.city.trim() !== "")
                    }
                    return isValid
                },
                message: "City is a mandatory field"
            }
        ]
    },
    region: {
        type: "string",
        displayName: "Region",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if(data.addressType === "generic"){
                        isValid = (data.region !== null && data.region !== undefined && data.region.trim() !== "")
                    }
                    return isValid
                },
                message: "Region is a mandatory field"
            }
        ]
    },
    postalCode: {
        type: "string",
        displayName: "Postal Code",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if(data.addressType === "generic"){
                        isValid = (data.postalCode !== null && data.postalCode !== undefined && data.postalCode.trim() !== "")
                    }
                    return isValid
                },
                message: "Postal Code is a mandatory field"
            }
        ]
    },
    country: {
        type: "string",
        displayName: "Country",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if(data.addressType === "generic"){
                        isValid = (data.country !== null && data.country !== undefined && data.country.trim() !== "")
                    }
                    return isValid
                },
                message: "Country is a mandatory field"
            }
        ]
    }
}

export {
    configuration,
    parameters,
    question,
    questionnaire
};
