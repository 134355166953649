import React from 'react'

import { Badge, Grid, Tooltip } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { errorTypes, getError } from './useHeader'
// Form Validation
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { Controller } from 'react-hook-form'

import { generateJsonValidationV2 } from 'utils/validator'

const SelectField = props => {
  const {
    control,
    index,
    readOnly,
    label,
    headerField,
    headerData,
    headerFieldsActions,

    requiredFields,
    getValues,
    children
  } = props

  const { getColors, classes, deleteErrorHeader } = headerFieldsActions

  const errorField = headerData.errors.find(x => x.field === headerField.field)

  const errorData = getError(errorField)

  const fieldRule = requiredFields.find(x => x.standardField === headerField.field)
  const rules = readOnly ? {} : generateJsonValidationV2(fieldRule, getValues)

  return (
    <Controller
      render={({ field, fieldState: { error, isDirty } }) => {
        const isError = Boolean(error) || errorData?.criticalityType === errorTypes.critical
        const isVisible = Boolean(error) || Boolean(errorData)

        const errorMessage = errorData ? errorData?.message : error?.message

        return (
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Tooltip
                classes={{
                  arrow: isError ? classes.errorColor : classes.warningColor,
                  tooltip: isError ? classes.tooltipError : classes.tooltipWarning
                }}
                title={isVisible ? errorMessage : ''}
                arrow
                placement="right"
              >
                <Badge
                  style={{ width: '95%' }}
                  classes={{
                    badge: isError ? classes.badgeError : classes.badgeWarning
                  }}
                  badgeContent={
                    isError ? '!' : <WarningIcon className={classes.warningColor}></WarningIcon>
                  }
                  invisible={isVisible ? false : true}
                >
                  <FormControl
                    variant="outlined"
                    error={isError}
                    style={{ width: '100%' }}
                    disabled={readOnly}
                    className={readOnly ? classes.readOnly : ''}
                  >
                    <InputLabel
                      id="demo-simple-select-outlined-label"
                      classes={{
                        root: !isError ? getColors(errorData, isDirty).color : '',
                        focused: !isError ? getColors(errorData, isDirty).color : ''
                      }}
                    >
                      {label}
                    </InputLabel>
                    <Select
                      {...field}
                      id={headerField.id}
                      style={{ width: '100%' }}
                      labelId="demo-simple-select-label"
                      label={label ?? ''}
                      onChange={e => {
                        field.onChange(e.target.value)
                        if (typeof props.onChange === 'function') props.onChange(e.target.value)
                        deleteErrorHeader({ field: headerField.field })
                      }}
                    >
                      {children}
                    </Select>
                  </FormControl>
                </Badge>
              </Tooltip>
            </Grid>
          </Grid>
        )
      }}
      name={`headerFields.${index}.value`}
      control={control}
      rules={rules}
    />
  )
}

export default SelectField
