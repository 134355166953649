import React, { useState } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
// @material-ui/icons

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Constants
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { Select, MenuItem } from '@material-ui/core'
import { InvoiceOriginOptions } from 'utils/Constants'
import EmailConfigurationForm from '../components/EmailConfigurationForm'
import ApiConfigurationForm from './ApiConfigurationForm'
const useStyles = makeStyles(styles)
const useStyles2 = makeStyles(styles2)

export default function InvoiceOriginConfigurationForm(props) {
  const classes = useStyles()
  const classes2 = useStyles2()
  const [originConfig, setOriginConfig] = useState(props?.data?.origin ?? '')
  const invoiceOriginOptions = Object.values(InvoiceOriginOptions)
  function typeForm() {
    let form = null
    console.log('typeForm', { originConfig })
    switch (originConfig) {
      case 'peppolNetwork':
      case 'api':
        form = (
          <ApiConfigurationForm
            onClose={props.onClose}
            appId={props.appId}
            id={props?.data?.id}
            action={props.action}
            apiType={originConfig}
            showValidationMessage={props.showValidationMessage}
          />
        )

        break
      case 'email':
        form = (
          <EmailConfigurationForm
            onClose={props.onClose}
            appId={props.appId}
            id={props?.data?.id}
            action={props.action}
            showValidationMessage={props.showValidationMessage}
          />
        )
        break
      default:
        break
    }
    return form
  }

  const handleChangeOrigin = event => {
    console.log('handleChangeOrigin', { event })
    setOriginConfig(event.target.value)
  }

  function returnFormsToDisplay() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer
          style={{ margin: 'auto' }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <GridItem xs={6}>
            <div className={classes2.verticalAlign}>
              <FormLabel id={'invoiceOrigin'} className={classes2.inputLabel}>
                Invoice Origin
              </FormLabel>
            </div>
          </GridItem>
          <GridItem xs={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}>Invoice Origin*</InputLabel>
              <Select
                value={originConfig}
                onChange={handleChangeOrigin}
                disabled={props?.data?.origin ? true : false}
                inputProps={{
                  name: `invoiceOrigin`,
                  id: `invoiceOrigin`
                }}
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Select the invoice origin
                </MenuItem>
                {invoiceOriginOptions.map(origin => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    key={origin.key}
                    value={origin.key}
                  >
                    {origin.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        {typeForm()}
      </GridItem>
    )
  }

  return <GridContainer>{returnFormsToDisplay()}</GridContainer>
}
