import React, { useState, useMemo, useEffect } from 'react'
import queryString from 'query-string'
import jwtDecode from 'jwt-decode'
import { useHistory } from 'react-router-dom'

// Form
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

// @material-ui/core components
import { Fade, makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import TextField from '@material-ui/core/TextField'
import AddAlert from '@material-ui/icons/AddAlert'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
// components
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
// import CardFooter from 'components/Card/CardFooter'
import Snackbar from 'components/Snackbar/Snackbar.js'

// images
import LoginImage from 'assets/img/LoginImage.png'

//services
import {
  validateAccessToken,
  getRelishMessage,
  savePiiAgreementTerms
} from '../../services/apiPII'

// utils
import { showLoading, hideLoading } from 'utils/functions'

// constants
import { piiValidationUpdateOptions } from 'utils/Constants'

// Style
import {
  hexToRgb,
  blackColor
} from 'assets/jss/material-dashboard-pro-react.js'
import loginStyles from 'assets/jss/material-dashboard-pro-react/views/loginViewStyle.js'

// hooks
import { useCreateAccessCode } from 'hooks/usePii'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  inputLabel: {
    paddingBottom: '10px ',
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.40)',
    fontSize: '14px'
  },
  formField: {
    width: '95%',
    paddingBottom: '15px'
  }
}))

const useLoginStyles = makeStyles(loginStyles)

const loginObject = {
  email: '',
  'Access Code': ''
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .min(1)
    .required(),
  'Access Code': yup
    .string()
    .min(1)
    .required()
})

export default function DataAccessLogin (props) {
  // using history to push to data form
  const history = useHistory()
  const params = queryString.parse(props.location.search)
  const classes = useStyles()
  const loginClasses = useLoginStyles()
  const [loginForm, setLoginForm] = useState(loginObject)
  const [showAccesscode, setShowAccessCode] = useState(false)
  const [tenantId, setTenantId] = useState('')
  const [env, setEnv] = useState('')
  const [email, setEmail] = useState('')
  const [isValidToken, setIsValidToken] = useState(false)
  const [maskedEmail, setMaskedEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [createError, setCreateError] = useState(false)
  const [urlToken, setUrlToken] = useState('')
  const { mutate: createAccessToken, isError, error } = useCreateAccessCode()
  const [isMessage, setIsMessage] = useState('')
  const [isUpdate, setIsFormUpdate] = useState(false)
  const [agreementTerm, setAgreementTerm] = useState('false')
  const [updateValue, setUpdateValue] = useState(piiValidationUpdateOptions.all)
  const [checked, setChecked] = useState([false, false])
  const {
    handleSubmit,
    control,
    formState: { errors },
    formState,
    getValues,
    reset
  } = useForm({
    mode: 'all',
    defaultValues: useMemo(() => loginForm, [loginForm]),
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  const [cardAnimaton, setCardAnimation] = useState('cardHidden')
  setTimeout(function () {
    setCardAnimation('')
  }, 700)

  function handleLoginButtonClick () {
    if (getValues('email').toUpperCase() !== email.toUpperCase()) {
      setCreateError(true)
      setErrorMessage('Email does not match')
      setTimeout(() => {
        setCreateError(false)
        setErrorMessage(undefined)
      }, 5000)
    } else {
      if (!showAccesscode) {
        setShowAccessCode(true)
        setIsMessage('A token Access will be sent to your Email.')
        createAccessToken({
          tenantId,
          env,
          email,
          url: history.location.pathname + history.location.search,
          urlToken,
          isUpdate
        })
      }
    }
  }

  function validateIsError (field) {
    if (errors[field]) {
      return true
    }
    return false
  }

  async function onSubmitHook (data) {
    console.log(data)
    showLoading()
    //pii form input
    const response = await validateAccessToken(
      data['Access Code'],
      tenantId,
      env,
      email,
      urlToken
    )
    if (response.success) {
      //set values
      props.setTenantId(tenantId)
      props.setValidationId(env)
      props.setEmail(email)
      props.setSupplierId(response.data.supplierId)
      props.setSupplierName(response.data.supplierName)
      props.setIsFormUpdate(isUpdate.toString())
      props.setUrlToken(urlToken)
      props.setFormUpdateValidation(updateValue)
      //local storage
      localStorage.setItem('pii.tenantId', tenantId)
      localStorage.setItem('pii.validationId', env)
      localStorage.setItem('pii.email', email)
      localStorage.setItem('pii.supplierId', response.data.supplierId)
      localStorage.setItem('pii.setSupplierName', response.data.supplierName)
      localStorage.setItem('pii.isFormUpdate', isUpdate)
      localStorage.setItem('pii.urlToken', urlToken)
      localStorage.setItem('pii.formUpdateValidation', updateValue)
      if (agreementTerm === 'true') {
        //agreement already filled up
        history.push('/public/dataAccess/form')
      } else {
        props.setAppId('slp-pii')
        props.setTypeId('consent')
        const relishBody = await getRelishMessage(
          'slp-pii',
          'consent',
          urlToken
        )
        props.setMessage(relishBody)
        props.setCallBackFunction({
          onclick: (hk, validationId, version, token) => {
            acceptAgreementCallFunction(hk, validationId, version, token)
          }
        })
        history.push('/public/dataAccess/relishMessage')
      }
    } else {
      //local storage
      localStorage.removeItem('pii.tenantId')
      localStorage.removeItem('pii.validationId')
      localStorage.removeItem('pii.email')
      localStorage.removeItem('pii.supplierId')
      localStorage.removeItem('pii.setSupplierName')
      localStorage.removeItem('pii.isFormUpdate')
      localStorage.removeItem('pii.urlToken')
      localStorage.removeItem('pii.formUpdateValidation')
      setCreateError(true)
      setErrorMessage(response.message)
    }
    hideLoading()
  }
  async function acceptAgreementCallFunction (
    hashKey,
    validationId,
    agreementVersion,
    token
  ) {
    console.log('agreement to be accepted', {
      hashKey,
      validationId,
      agreementVersion
    })
    const response = await savePiiAgreementTerms(
      hashKey,
      validationId,
      agreementVersion,
      token
    )
    if (response && response.data.success) {
      history.push('/public/dataAccess/form')
    }
  }
  const maskEmail = mail => {
    let mailUsername = mail.split('@')[0]
    mailUsername =
      mailUsername[0] + mailUsername.substring(1).replace(/./gi, '*')
    let mailDomain =
      mail
        .split('@')[1]
        .split('.')[0]
        .substring(0, 1) +
      mail
        .split('@')[1]
        .split('.')[0]
        .substring(1)
        .replace(/./gi, '*')
    let mailTld =
      mail
        .split('@')[1]
        .split('.')[1]
        .substring(0, 1) +
      mail
        .split('@')[1]
        .split('.')[1]
        .substring(1)
        .replace(/./gi, '*')

    return `${mailUsername}@${mailDomain}.${mailTld}`
  }

  /*const { error, isError } = useCreateAccessCode({
    tenantId,
    env,
    email,
    enableQuery: showAccesscode,
    url: history.location.pathname + history.location.search,
    urlToken
  })*/

  useEffect(() => {
    try {
      const tokenPayload = jwtDecode(params.t)
      setUrlToken(params.t)
      const current_time = new Date().getTime() / 1000
      if (current_time < tokenPayload.exp) {
        setIsValidToken(true)
      } else {
        setErrorMessage('Token already expired')
        setCreateError(true)
        return
      }
      setIsValidToken(true)
      let email = Buffer.from(params.e, 'base64').toString()
      let maskedEmail = maskEmail(email)
      setEmail(email)
      setTenantId(Buffer.from(params.h, 'base64').toString())
      setEnv(Buffer.from(params.s, 'base64').toString())
      setMaskedEmail(maskedEmail)
      let updateFlag = params.u
      console.log('is update', { context: updateFlag })
      if (updateFlag) {
        console.log('is update2', { context: updateFlag })
        setIsFormUpdate(true)
      }
      let agreementTermParam = params.tc
      console.log('agreement terms', { context: agreementTermParam })
      if (agreementTermParam) {
        setAgreementTerm(agreementTermParam)
      }
      //test
      // setEmail('rodolfo@relishiq.com')
      // setAgreementTerm('false')
      // setIsValidToken(true)
      // setIsFormUpdate(true)
    } catch (error) {
      setCreateError(true)
      setErrorMessage(error.message)
    }
  }, [params.t, params.e, params.h, params.s, params.u, params.tc])

  useEffect(() => {
    if (isError) {
      setCreateError(true)
    }
  }, [isError])

  const handleChange1 = event => {
    console.log('checked1', { con: event.target })
    setUpdateValue(piiValidationUpdateOptions.all)
    setChecked([event.target.checked, event.target.checked])
  }

  const handleChange2 = event => {
    console.log('checked2', { con: event.target })
    setUpdateValue(piiValidationUpdateOptions.bank)
    setChecked([event.target.checked, checked[1]])
  }

  const handleChange3 = event => {
    console.log('checked3', { con: event.target })
    setUpdateValue(piiValidationUpdateOptions.tin)
    setChecked([checked[0], event.target.checked])
  }
  const childrenOptions = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Bank Validation"
        control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
      />
      <FormControlLabel
        label="Tin Validation"
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </Box>
  )
  return (
    <Fade in={true} timeout={250}>
      <div
        className={loginClasses.container}
        style={{ paddingTop: '5%', paddingBottom: '5%' }}
      >
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={handleSubmit(onSubmitHook)}>
              <Card login className={loginClasses[cardAnimaton]}>
                <CardHeader
                  className={`${loginClasses.cardHeader} ${loginClasses.textCenter}`}
                  color="primary"
                >
                  <h2 className={loginClasses.cardTitle}>Relish</h2>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <img
                        width="100%"
                        src={LoginImage}
                        alt="logo"
                        className={loginClasses.logotypeImage}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <h5
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        {showAccesscode
                          ? 'You will recieve an email with an access code, please enter it below to verify you identity '
                          : `Please enter your e-mail: ${maskedEmail}`}
                      </h5>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            InputLabelProps={{
                              className: classes.inputLabel
                            }}
                            className={classes.formField}
                            label="Email*"
                            error={validateIsError('email')}
                            helperText={
                              errors.email ? errors.email.message : ''
                            }
                            disabled={showAccesscode || !isValidToken}
                            {...field}
                          />
                        )}
                        name="email"
                        control={control}
                      />
                    </GridItem>
                    {showAccesscode ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              InputLabelProps={{
                                className: classes.inputLabel
                              }}
                              className={classes.formField}
                              label="Access Code*"
                              type="string"
                              error={validateIsError('Access Code')}
                              helperText={
                                errors['Access Code']
                                  ? errors['Access Code'].message
                                  : ''
                              }
                              {...field}
                            />
                          )}
                          name="Access Code"
                          control={control}
                        />
                      </GridItem>
                    ) : (
                      ''
                    )}
                    {showAccesscode &&
                    formState.isValid &&
                    isValidToken &&
                    isUpdate !== undefined &&
                    isUpdate ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl component="fieldset">
                          <FormLabel className={classes.labelHorizontal}>
                            Section to update
                          </FormLabel>
                          <div>
                            <FormControlLabel
                              label="All"
                              control={
                                <Checkbox
                                  checked={checked[0] && checked[1]}
                                  indeterminate={checked[0] !== checked[1]}
                                  onChange={handleChange1}
                                />
                              }
                            />
                            {childrenOptions}
                          </div>
                        </FormControl>
                      </GridItem>
                    ) : (
                      ''
                    )}
                  </GridContainer>
                </CardBody>
                <GridItem
                  xs={12}
                  style={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                    paddingBottom: '2%',
                    textAlign: 'center'
                  }}
                >
                  <Button
                    color="primary"
                    simple
                    size="lg"
                    onClick={handleLoginButtonClick}
                    type={showAccesscode ? 'submit' : undefined}
                    disabled={
                      showAccesscode
                        ? !formState.isValid
                        : !isValidToken ||
                          getValues('email') === '' ||
                          errors.email
                    }
                  >
                    {showAccesscode ? 'Login' : 'Next'}
                  </Button>
                  {showAccesscode ? (
                    <Button
                      color="primary"
                      simple
                      size="lg"
                      onClick={() => {
                        setLoginForm(loginObject)
                        reset(loginForm)
                        setShowAccessCode(false)
                      }}
                    >
                      Go Back
                    </Button>
                  ) : (
                    ''
                  )}
                  {/* <Button
                    color="primary"
                    simple
                    size="lg"
                    onClick={() => {
                      handleLoginButtonClick2()
                    }}
                  >
                    Login2
                  </Button> */}
                </GridItem>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={
            isError && !errorMessage
              ? error.response?.data?.message ||
                'Something went wrong, try again later'
              : errorMessage
              ? errorMessage
              : ''
          }
          open={createError}
          closeNotification={() => {
            setCreateError(false)
            if (errorMessage) {
              setErrorMessage(undefined)
            }
          }}
          close
        />
        <Snackbar
          place="bl"
          color="success"
          icon={AddAlert}
          message={isMessage.length > 0 ? isMessage : ''}
          open={!isError && isMessage.length > 0}
          closeNotification={() => {
            setIsMessage('')
          }}
          close
        />
      </div>
    </Fade>
  )
}
