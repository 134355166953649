import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import {
    Select,
    MenuItem
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { CircularProgress } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp';
import AddAlert from "@material-ui/icons/AddAlert";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Info from "@material-ui/icons/Info";
import Map from "@material-ui/icons/Map";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/PlaylistAdd";
import ListAltIcon from '@material-ui/icons/ListAlt';

// components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";
import Button from "components/CustomButtons/Button.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";

// style
import styles from "assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js";

import { AddressValidaton } from "utils/Constants";

const useStyles = makeStyles(styles);

function getModalForm(classes, props, addressType) {
    let formBody
    switch (addressType) {
        case "address":
            formBody = (
                <CustomInput
                    success={props.modalFormState.address === "success"}
                    error={props.modalFormState.address === "error"}
                    labelText={"Address*"}
                    id="inputAddress"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => {
                            props.onFormChange("address", event.target.value)
                        },
                        type: "text",
                        value: props.modalForm.address,
                        endAdornment:
                            props.modalFormState["address"] === "error" ? (
                                <InputAdornment position="end">
                                    <Close className={classes.error} />
                                </InputAdornment>
                            ) : (
                                    undefined
                                )
                    }}
                />)
            break;
        case "extendedAddress":
            formBody = (
                <CustomInput
                    success={props.modalFormState.extendedAddress === "success"}
                    error={props.modalFormState.extendedAddress === "error"}
                    labelText={"Extended Address*"}
                    id="inputExtendedAddress"
                    formControlProps={{
                        fullWidth: true
                    }}
                    inputProps={{
                        onChange: event => {
                            props.onFormChange("extendedAddress", event.target.value)
                        },
                        type: "text",
                        value: props.modalForm.extendedAddress,
                        endAdornment:
                            props.modalFormState["extendedAddress"] === "error" ? (
                                <InputAdornment position="end">
                                    <Close className={classes.error} />
                                </InputAdornment>
                            ) : (
                                    undefined
                                )
                    }}
                />)
            break;
        case "generic":
            formBody = (
                <>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={6}>
                            <CustomInput
                                success={props.modalFormState.street === "success"}
                                error={props.modalFormState.street === "error"}
                                labelText={"Street*"}
                                id="inputStreet"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        props.onFormChange("street", event.target.value)
                                    },
                                    type: "text",
                                    value: props.modalForm.street,
                                    endAdornment:
                                        props.modalFormState["street"] === "error" ? (
                                            <InputAdornment position="end">
                                                <Close className={classes.error} />
                                            </InputAdornment>
                                        ) : (
                                                undefined
                                            )
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <CustomInput
                                success={props.modalFormState.street2 === "success"}
                                error={props.modalFormState.street2 === "error"}
                                labelText={"Street 2*"}
                                id="inputStreet2"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        props.onFormChange("street2", event.target.value)
                                    },
                                    type: "text",
                                    value: props.modalForm.street2,
                                    endAdornment:
                                        props.modalFormState["street2"] === "error" ? (
                                            <InputAdornment position="end">
                                                <Close className={classes.error} />
                                            </InputAdornment>
                                        ) : (
                                                undefined
                                            )
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <CustomInput
                                success={props.modalFormState.city === "success"}
                                error={props.modalFormState.city === "error"}
                                labelText={"City*"}
                                id="inputCity"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        props.onFormChange("city", event.target.value)
                                    },
                                    type: "text",
                                    value: props.modalForm.city,
                                    endAdornment:
                                        props.modalFormState["city"] === "error" ? (
                                            <InputAdornment position="end">
                                                <Close className={classes.error} />
                                            </InputAdornment>
                                        ) : (
                                                undefined
                                            )
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <CustomInput
                                success={props.modalFormState.region === "success"}
                                error={props.modalFormState.region === "error"}
                                labelText={"Region*"}
                                id="inputRegion"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        props.onFormChange("region", event.target.value)
                                    },
                                    type: "text",
                                    value: props.modalForm.region,
                                    endAdornment:
                                        props.modalFormState["region"] === "error" ? (
                                            <InputAdornment position="end">
                                                <Close className={classes.error} />
                                            </InputAdornment>
                                        ) : (
                                                undefined
                                            )
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <CustomInput
                                success={props.modalFormState.postalCode === "success"}
                                error={props.modalFormState.postalCode === "error"}
                                labelText={"Postal Code*"}
                                id="inputPostalCode"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        props.onFormChange("postalCode", event.target.value)
                                    },
                                    type: "text",
                                    value: props.modalForm.postalCode,
                                    endAdornment:
                                        props.modalFormState["postalCode"] === "error" ? (
                                            <InputAdornment position="end">
                                                <Close className={classes.error} />
                                            </InputAdornment>
                                        ) : (
                                                undefined
                                            )
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6}>
                            <CustomInput
                                success={props.modalFormState.country === "success"}
                                error={props.modalFormState.country === "error"}
                                labelText={"Country*"}
                                id="inputCountry"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    onChange: event => {
                                        props.onFormChange("country", event.target.value)
                                    },
                                    type: "text",
                                    value: props.modalForm.country,
                                    endAdornment:
                                        props.modalFormState["country"] === "error" ? (
                                            <InputAdornment position="end">
                                                <Close className={classes.error} />
                                            </InputAdornment>
                                        ) : (
                                                undefined
                                            )
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </>
            )
            break;

        default:
            break;
    }

    return (formBody)
}

function modal(classes, props) {
    let alertMessage = (null)
    if (props.modal.visible) {
        switch (props.modal.type) {
            case "delete":
                alertMessage = (
                    <SweetAlert
                        warning
                        style={{ display: "block" }}
                        title="Are you sure you want to delete the question?"
                        onConfirm={() => props.onQuestionDelete()}
                        onCancel={() => props.onHideModal()}
                        confirmBtnCssClass={classes.button + " " + classes.success}
                        cancelBtnCssClass={classes.button + " " + classes.danger}
                        confirmBtnText="Yes, I want to delete it"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        {props.modal.name}
                    </SweetAlert>
                )
                break;
            case "edit":
                alertMessage = (
                    <SweetAlert
                        style={{ display: "block", overflow: "auto", maxHeight: "600px" }}
                        title="Edit question"
                        onConfirm={() => props.onQuestionEdit()}
                        onCancel={() => props.onHideModal()}
                        confirmBtnCssClass={classes.button + " " + classes.success}
                        cancelBtnCssClass={classes.button + " " + classes.danger}
                        confirmBtnText="Confirm"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                    >
                                        Address Type
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            style: { zIndex: 5555 }
                                        }}
                                        classes={{
                                            select: classes.select,
                                            disabled: classes.disabled
                                        }}
                                        value={props.modalForm.addressType}
                                        onChange={e => props.onFormChange("addressType", e.target.value)}
                                        inputProps={{
                                            name: "addressType",
                                            id: "addressType"
                                        }}
                                    >
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                            value={"address"}
                                        >
                                            Address
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                            value={"extendedAddress"}
                                        >
                                            Extended Address
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                            value={"generic"}
                                        >
                                            Generic
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {getModalForm(classes, props, props.modalForm.addressType)}
                            </GridItem>
                        </GridContainer>
                    </SweetAlert >
                )
                break;
            case "create":
                alertMessage = (
                    <SweetAlert
                        style={{ display: "block", overflow: "auto", maxHeight: "600px" }}
                        title="Add new question"
                        onConfirm={() => props.onQuestionCreate()}
                        onCancel={() => props.onHideModal()}
                        confirmBtnCssClass={classes.button + " " + classes.success}
                        cancelBtnCssClass={classes.button + " " + classes.danger}
                        confirmBtnText="Confirm"
                        cancelBtnText="Cancel"
                        showCancel
                    >
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <FormControl
                                    fullWidth
                                    className={classes.selectFormControl}
                                >
                                    <InputLabel
                                        htmlFor="simple-select"
                                        className={classes.selectLabel}
                                    >
                                        Address Type
                                    </InputLabel>
                                    <Select
                                        MenuProps={{
                                            className: classes.selectMenu,
                                            style: { zIndex: 5555 }
                                        }}
                                        classes={{
                                            select: classes.select,
                                            disabled: classes.disabled
                                        }}
                                        value={props.modalForm.addressType}
                                        onChange={e => props.onFormChange("addressType", e.target.value)}
                                        inputProps={{
                                            name: "addressType",
                                            id: "addressType"
                                        }}
                                    >
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                            value={"address"}
                                        >
                                            Address
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                            value={"extendedAddress"}
                                        >
                                            Extended Address
                                        </MenuItem>
                                        <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected
                                            }}
                                            style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                                            value={"generic"}
                                        >
                                            Generic
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {getModalForm(classes, props, props.modalForm.addressType)}
                            </GridItem>
                        </GridContainer>
                    </SweetAlert >
                )
                break;

            default:
                break;
        }
    }

    return (alertMessage)
}

export default function NitorConnect(props) {
    const classes = useStyles()

    let dataTableInfo = props.questionList.map((question) => {
        return {
            id: question.id,
            type: AddressValidaton.AddressType[question.addressType],
            ki: (question.addressType === "address" ? question.address : (question.addressType === "extendedAddress" ? question.extendedAddress : "")),
            actions: (
                // we've added some custom button actions
                <div className="actions-right">
                    <Tooltip title={"Edit question"}>
                        <Button
                            color={"warning"}
                            simple
                            justIcon
                            className={classes.actionButton}
                            onClick={() => { props.onShowModal("edit", "question", question.id) }}
                        >
                            <Edit className={classes.icon} />
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Delete question"}>
                        <Button
                            color={"danger"}
                            simple
                            justIcon
                            className={classes.actionButton}
                            onClick={() => { props.onShowModal("delete", "question", question.id) }}
                        >
                            <Close className={classes.icon} />
                        </Button>
                    </Tooltip>
                </div>
            )
        }
    });

    function returnTabsToDisplay() {
        return (
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardHeader>
                        <h3 className={classes.formHeader}>
                            Nitor Data Assure - US Address Validation - Questionnaire Configuration
                        </h3>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button color="behance" simple className={classes.documentation}
                                href="/admin/dataAssure/documentation" target="_blank">
                                <Info className={classes.icons} />
                                    Download Documentation
                            </Button>
                        </GridItem>
                    </CardHeader>
                    <CardBody>
                        <GridItem xs={12} sm={12}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12}>
                                            <h5 className={classes.sectionTitle}>Details</h5>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <GridContainer className={classes.row}>
                                        <GridItem xs={12} sm={12}>
                                            <FormLabel className={classes.contentLabelHorizontal}>
                                                <strong>Questionnaire:</strong> {props.selectedForm ? props.selectedForm.uniqueName : ""}
                                            </FormLabel>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <GridItem xs={12}>
                                        <GridContainer className={classes.row}>
                                            <GridItem xs={12}>
                                                <Card>
                                                    <CardHeader icon>
                                                        <CardIcon color="info">
                                                            <Map />
                                                        </CardIcon>
                                                        <h4 className={classes.cardIconTitle}>Question</h4>
                                                        <Tooltip title="Add new question">
                                                            <Button color="primary" className={classes.addButton} onClick={() => { props.onShowModal("create", "question", null) }}>
                                                                <Add className={classes.icons} />
                                                            </Button>
                                                        </Tooltip>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <ReactTable
                                                            data={dataTableInfo}
                                                            filterable
                                                            columns={[
                                                                {
                                                                    Header: "Question Type",
                                                                    accessor: "type"
                                                                },
                                                                {
                                                                    Header: "KI",
                                                                    accessor: "ki"
                                                                },
                                                                {
                                                                    Header: "Actions",
                                                                    accessor: "actions",
                                                                    sortable: false,
                                                                    filterable: false
                                                                }
                                                            ]}
                                                            defaultPageSize={10}
                                                            showPaginationTop
                                                            minRows={0}
                                                            showPaginationBottom={false}
                                                            className="-striped -highlight"
                                                        />

                                                    </CardBody>
                                                </Card>
                                            </GridItem>
                                        </GridContainer>
                                        <div className={classes.center}>
                                            {props.isLoadingSave ?
                                                <CircularProgress color="inherit" /> :
                                                <Button color="primary" onClick={props.onConfigSave}>
                                                    Save
                                    </Button>
                                            }
                                        </div>
                                    </GridItem>
                                </GridItem>
                            </GridContainer>
                        </GridItem>

                    </CardBody>
                </Card>
            </GridItem>
        )
    }

    let breadcrumbViews = [
        {
            name: "Data Assure",
            url: "/admin/dataAssure/index",
            icon: AssignmentTurnedInIcon
        },
        {
            name: "Address Validation",
            url: `/admin/dataAssure/addressValidation/${props.selectedAppId}`,
            icon: LocationCityIcon
        },
        {
            name: "Questionnaire Configuration",
            url: `/admin/dataAssure/addressquestionnaire/${props.selectedAppId}/${props.selectedAppId}`,
            icon: ListAltIcon
        }
    ]
    return (
        <GridContainer>
            {modal(classes, props)}
            <GridItem xs={12} sm={12} md={12} lg={3} >
                <Card className={classes.appBar}>
                    <CardHeader>
                        Application List
                    </CardHeader>
                    <CardBody>
                        <List>
                            {props.appList.map((prop, key) => {
                                return (
                                    <ListItem
                                        className={classes.listItem}
                                        button
                                        selected={props.selectedAppId === prop.env}
                                        key={key}
                                        onClick={() => props.onAppChanged(prop.env)}
                                    >
                                        <ListItemAvatar>
                                            {
                                                props.selectedAppId === prop.env ?
                                                    <Avatar >
                                                        <TouchApp />
                                                    </Avatar>
                                                    :
                                                    <Avatar className={classes.clearAvatar}>
                                                    </Avatar>
                                            }

                                        </ListItemAvatar>
                                        <ListItemText primary={prop.env} secondary={`Type: ${prop.type}`} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={9}>
                <Breadcrumbs views={breadcrumbViews} />
                {props.formIsLoading ?

                    <Card>
                        <GridContainer justify="center">
                            <div className={classes.circularProgress}>
                                <CircularProgress color="inherit" />
                            </div>
                        </GridContainer>
                    </Card>

                    :
                    returnTabsToDisplay()
                }
            </GridItem>
            <Snackbar
                place="br"
                color="success"
                icon={AddAlert}
                message={props.validationMessage}
                open={props.submitSuccess}
                closeNotification={() => props.setSubmitSuccess(false)}
                close
            />
            <Snackbar
                place="bl"
                color="danger"
                icon={AddAlert}
                message={props.validationMessage}
                open={props.createError}
                closeNotification={() => props.setCreateError(false)}
                close
            />
        </GridContainer>
    );
}
