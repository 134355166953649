import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";

// @material-ui/icons
import TouchApp from "@material-ui/icons/TouchApp";
import AddAlert from "@material-ui/icons/AddAlert";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/PlaylistAdd";
import EditRounded from "@material-ui/icons/EditRounded";

// components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Wizard from "components/WizardBasic/Wizard.js";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs.js";

// style
import styles from "assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js";

// Views
import ConnectionView from "./WizardSteps/ConnectionView.js";
import ConfigurationView from "./WizardSteps/ConfigurationView.js";
import ViewsView from "./WizardSteps/ViewsViews.js";
import AribaConnectionsView from "./WizardSteps/AribaConnectionsView.js";

const useStyles = makeStyles(styles);

function jsUcfirst(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export default function QualtricsLoad (props) {
    const classes = useStyles();

    function returnWizardToDisplay() {
        return (
            <Wizard
                color="primary"
                validate
                steps={[
                    {
                        stepName: "Configuration",
                        stepComponent: ConfigurationView,
                        stepId: "config",
                        initialState: props.config,
                    },
                    {
                        stepName: "Journeys",
                        stepComponent: ViewsView,
                        stepId: "journeys",
                        initialState: props.journeys,
                    },
                    {
                        stepName: "Ariba Connections",
                        stepComponent: AribaConnectionsView,
                        stepId: "aribaConnections",
                        initialState: props.aribaConnections,
                    },
                    {
                        stepName: "Connection",
                        stepComponent: ConnectionView,
                        stepId: "connection",
                        initialState: props.connection,
                    },
                ]}
                title="Load settings"
                subtitle=""
                finishButtonClick={(e) => props.onAppSaved(e)}
            />
        );
    }

    let breadcrumbViews = [
        {
            name: "Relish Qualtrics",
            url: "/admin/qualtrics/index",
            icon: AssessmentIcon,
        },
        {
            name: jsUcfirst(props.typeApp) + " Connection",
            url: `/admin/qualtrics/${props.typeApp}/${props.selectedAppId}`,
            icon: CheckIcon,
        },
        {
            name: "Load",
            url: `/admin/qualtrics/load/${props.typeApp}/${props.selectedAppId}`,
            icon: props.displayMode === "edit" ? EditRounded : AddIcon,
        },
    ];

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={3}>
                <Card className={classes.appBar}>
                    <CardHeader>Application List</CardHeader>
                    <CardBody>
                        <List>
                            {props.appList.map((prop, key) => {
                                return (
                                    <ListItem
                                        className={classes.listItem}
                                        button
                                        selected={
                                            props.selectedAppId === prop.env
                                        }
                                        key={key}
                                        onClick={() =>
                                            props.onAppChanged(prop.env)
                                        }
                                    >
                                        <ListItemAvatar>
                                            {props.selectedAppId ===
                                            prop.env ? (
                                                <Avatar>
                                                    <TouchApp />
                                                </Avatar>
                                            ) : (
                                                <Avatar
                                                    className={
                                                        classes.clearAvatar
                                                    }
                                                ></Avatar>
                                            )}
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={prop.appName}
                                            secondary={`Type: ${prop.type}`}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={9}>
                <Breadcrumbs views={breadcrumbViews} />
                {props.formIsLoading ? (
                    <Card>
                        <GridContainer justify="center">
                            <div className={classes.circularProgress}>
                                <CircularProgress color="inherit" />
                            </div>
                        </GridContainer>
                    </Card>
                ) : (
                    returnWizardToDisplay()
                )}
            </GridItem>
            <Snackbar
                place="br"
                color="success"
                icon={AddAlert}
                message={props.validationMessage}
                open={props.submitSuccess}
                closeNotification={() => props.setSubmitSuccess(false)}
                close
            />
            <Snackbar
                place="bl"
                color="danger"
                icon={AddAlert}
                message={props.validationMessage}
                open={props.createError}
                closeNotification={() => props.setCreateError(false)}
                close
            />
        </GridContainer>
    );
}
