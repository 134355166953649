import { ApiDnbConnector } from '../utils/Constants'
import { createHeaders } from 'utils/functions'
import { signOut } from 'store/AuthState'
import axios from 'axios'
import store from 'store'

/**********************************************************
 *                  DATA ASSURE ENDPOINTS
 **********************************************************/

export const dnbDaGetSupplier = async (supplierId, appId) => {
  try {
    const url = `${ApiDnbConnector.EndPoint}/supplier/${supplierId}/app/${appId}`

    const response = await axios({
      method: 'get',
      url: url,
      headers: createHeaders()
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })
    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const dnbDaSearchCompany = async (appId, searchCriteria) => {
  try {
    const url = `${ApiDnbConnector.EndPoint}/company/search`

    const response = await axios({
      method: 'post',
      url: url,
      headers: createHeaders(),
      data: {
        appId,
        searchCriteria
      }
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })
    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const dnbDaMatchSupplier = async (appId, supplierId, duns) => {
  try {
    const url = `${ApiDnbConnector.EndPoint}/supplier/match`

    const response = await axios({
      method: 'put',
      url: url,
      headers: createHeaders(),
      data: {
        appId,
        supplierId,
        duns
      }
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })

    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

/**********************************************************
 *                  IFRAME ENDPOINTS
 **********************************************************/

export const getDnBDashboard = async (supplierId, appId, dashboardId) => {
  try {
    let url = `${ApiDnbConnector.EndPoint}/supplier/${supplierId}/app/${appId}/dashboard/${dashboardId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })

    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

export const getDnBDashboardV2 = async (supplierId, appId, dashboardId) => {
  try {
    let url = `${ApiDnbConnector.EndPoint}/V2/supplier/${supplierId}/app/${appId}/dashboard/${dashboardId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })

    // const response = {
    //   supplierName: 'Billy supplier',
    //   supplierId: 123456789,
    //   duns: '11112222',
    //   cards: {
    //     'company information': { pos01: 'hola mundo' },
    //     'records and others': { records: ['100', '200', '300'] },
    //     people: { role: { a: 10, b: 20 } },
    //     'Risk Analysis': {
    //       NordicRiskScore: [
    //         { name: 'top1000', description: 40 },
    //         { name: 'Financial Risk', description: 'AA' }
    //       ],
    //       NordicRiskScore2: [{ name: 'top1000', description: 40 }]
    //     }
    //   }
    // }

    // return {
    //   success: true,
    //   data: response
    // }

    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

/*
Get information for suppliers that has been match
*/
export const getDnBSupplierInformation = async supplierId => {
  try {
    let url = `${ApiDnbConnector.EndPoint}/supplier/${supplierId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })

    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

/*
Get information for suppliers that has been match
*/
export const getDnBMatch = async (matchCriteria, supplierId, supplierName) => {
  try {
    let url = `${ApiDnbConnector.EndPoint}/match/companies`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: {
        ...matchCriteria,
        supplierId: supplierId,
        supplierName: supplierName
      }
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })

    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

/*
Get information for suppliers that has been match
*/
export const matchCompany = async (duns, supplierId, supplierName) => {
  try {
    let url = `${ApiDnbConnector.EndPoint}/match/supplier`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: {
        duns: duns,
        supplierId: supplierId,
        supplierName: supplierName
      }
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })

    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

/*
Get information for suppliers that has been match
*/
export const unmatchCompany = async (supplierId, supplierName) => {
  try {
    let url = `${ApiDnbConnector.EndPoint}/unmatch/supplier`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: {
        supplierId: supplierId,
        supplierName: supplierName
      }
    })
      .then(response => ({
        success: true,
        data: response.data
      }))
      .catch(error => {
        return handleAxiosError(error)
      })

    return response
  } catch (error) {
    console.error(error)
    return handleError(500, 'Something went wrong, try again later')
  }
}

const handleAxiosError = error => {
  return handleError(
    error.response?.status || 500,
    error.response?.data?.message || error.response?.data || error.response || error
  )
}

const handleError = (status, error) => {
  if (status === 401) {
    store.dispatch(signOut())
  } else {
    return {
      success: false,
      status,
      error
    }
  }
}
