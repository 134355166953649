import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  getAppConfigurationsByApp,
  getTransactionsByAppId,
  retryTransactionById
} from '../../services/apiApps'
import DataAssureRetryView from './DataAssureRetryView'

import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions'

import { updateHeightFunction } from 'store/AppState'

let _isMounted = false
let appId

async function getTransactions (props, environmentId, status = 'failed') {
  const response = await getTransactionsByAppId('slp', environmentId, status)
  if (response && response.success) {
    const rows = response.data.transactions
    props.setRows([...rows])
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function getAppListError (app, status = 'failed') {
  const response = await getTransactionsByAppId('slp', app.env, status)
  if (response && response.success) {
    return response.data.transactions.length
  }
}

async function updateAppListErrors (props) {
  const errorsLength = await props.appList.map(async item => {
    let length = await getAppListError(item)
    item.errors = length
    return { ...item }
  })
  const data = await Promise.all(errorsLength)
  props.setAppList(data)
}

async function retryTransaction (props, id) {
  const response = await retryTransactionById('slp', props.selectedAppId, id)
  if (response && response.success) {
    await getTransactions(props, props.selectedAppId)
    await updateAppListErrors(props)
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function getAppList (
  props,
  callback = () => {},
  selectedApp = undefined
) {
  let response = await getAppConfigurationsByApp('slp')

  if (response && response.success) {
    if (_isMounted) {
      if (response.data && response.data.length > 0) {
        const errorsLength = await response.data.map(async item => {
          if (selectedApp && selectedApp === item.env) props.setService(item.service)
          let length = await getAppListError(item)
          item.errors = length
          return { ...item }
        })
        const data = await Promise.all(errorsLength)
        props.setAppList(data)
        if (data.length !== 0) {
          if (!selectedApp) {
            selectedApp = data[0].env
            props.setService(data[0].service)
          }
          props.setTransactionIsLoading(true)
          await getTransactions(props, selectedApp)
          props.setSelectedAppId(selectedApp)
          props.setTransactionIsLoading(false)
        }
        props.setPageIsLoading(false)
      }
    }
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
  callback(props.setPageIsLoading)
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      updateHeight: state.app.updateHeight
    }),
    { updateHeightFunction }
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('rows', 'setRows', []),
  withState('transactionIsLoading', 'setTransactionIsLoading', false),
  withState('showModal', 'setShowModal', false),
  withState('modalInfo', 'setModalInfo', []),
  withState('service', 'setService', ''),
  withState('status', 'setStatus', 'failed'),
  withHandlers({
    onAppChanged: props => async id => {
      props.history.push(`/admin/dataAssure/${id}/transactions`)
      props.setSelectedAppId(id);
      props.setTransactionIsLoading(true)
      await getTransactions(props, id)
      await updateAppListErrors(props)
      props.setTransactionIsLoading(false)
    },
    onFieldChange: props => (field, value) => {
      props.setStatus(value)
    },
    onSearchValues: props => async app => {
      props.setTransactionIsLoading(true)
      await getTransactions(props, props.selectedAppId, props.status)
      await updateAppListErrors(props)
      props.setTransactionIsLoading(false)
    },
    onRetryTransaction: props => async id => {
      props.setTransactionIsLoading(true)
      await retryTransaction(props, id)
      props.setTransactionIsLoading(false)
    },
    onErrors: props => async id => {
      props.setTransactionIsLoading(true)
      props.setStatus('failed')
      await getTransactions(props, id)
      await updateAppListErrors(props)
      props.setTransactionIsLoading(false)
      props.history.push(`/admin/dataAssure/${id}/transactions`)
    }
  }),
  lifecycle({
    componentDidMount () {
      showLoading(this.props.setPageIsLoading)
      _isMounted = true
      this.props.setPageIsLoading(true)
      appId = this.props.match.params.appId
      getAppList(this.props, hideLoading, appId)
    }
  })
)(DataAssureRetryView)
