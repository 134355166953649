import {
  ApiAdmin,
  ApiNitorConnect,
  ApiAdminInvoice,
  AuthService,
  ApiAdminMonitor,
  ApiRouter,
  ApiDataAssureLogs,
  ApiAdminPricebook
} from 'utils/Constants'
import { createHeaders, getUserTenant } from 'utils/functions'
import axios from 'axios'
import store from 'store'
import { signOut } from 'store/AuthState'
import { putTenantConfigInvoicesEndPoint } from 'hooks/useTenantConfig'

/*
Get the list of configuration of the apps of teams
*/
async function getAppConfigurationsByApp(app) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `getAppConfigurationsByApp returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Create a new app with the specified configuration
*/
async function createAppConfiguration(app, appBody, addClientToBody = false) {
  try {
    let tenant = getUserTenant()
    if (addClientToBody) appBody.client = tenant
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`createAppConfiguration returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      /* console.info(`statusCode: ${response.status} for createAppConfiguration`)
       */
      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data }
      } else if (response.status === 403) {
        return response.data
      } else {
        return { success: false, message: response.data.message }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Create a new app with the specified configuration
*/
async function saveTeamsInit(body, callback) {
  try {
    let tenant = getUserTenant()
    let url
    if (body.app !== 'Invoices-AI') {
      url = `${ApiAdmin.EndPoint}/tenants/${tenant}/env/${body.appId}/teams/init`

      let requestHeaders = createHeaders()

      let response = await axios({
        method: 'post',
        url: url,
        headers: requestHeaders,
        data: body
      }).catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            store.dispatch(signOut())
          } else if (error.response.data) {
            return error.response
          }
        }
      })
      if (response) {
        // Check response status code
        /* console.info(`statusCode: ${response.status} for createAppConfiguration`)
        
        // If it's a success response
        const message =  response.data.message
        let success = false 
        */
        if (response.status === 200) {
          callback('Success', true)
        } else {
          callback('Something went wrong, try again later', false)
        }
      } else {
        callback('Something went wrong, try again later', false)
      }
    } else {
      callback('Success', JSON.stringify(body))
    }
  } catch (error) {
    callback('Something went wrong, try again later', false)
  }
}

/*
Get the App configuration by app and identifier
*/
async function getAppConfigurationByAppAndId(app, env) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getAppConfigurationByAppAndId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to update the configuration of a app by its identifier
*/
async function editAppConfigurationByAppAndId(app, env, appBody) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editAppConfigurationByAppAndId returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /*  console.info(
        `statusCode: ${response.status} for editAppConfigurationByAppAndId`
      )*/

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to update the connections configuration of a app by its identifier and type
*/
async function editAppConfigurationByAppAndType(app, env, appBody, type) {
  try {
    let tenant = getUserTenant()
    type = type === 'upstream' ? 'itk_upstream' : type
    type = type === 'downstream' ? 'itk_downstream' : type
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/${type}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editAppConfigurationByAppAndType returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /*  console.info(
        `statusCode: ${response.status} for editAppConfigurationByAppAndType`
      )
*/
      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to update the remote connections configuration of a app by its identifier
*/
async function editAppConnectionConfigurationByApp(app, env, appBody) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/connection`
    let requestHeaders = createHeaders()

    if (appBody.encryptionFileObj && appBody.encryptionFileObj !== '') {
      requestHeaders['Content-Type'] = 'multipart/form-data'
      const formData = new FormData()
      formData.append('encryptionFileObj', appBody.encryptionFileObj)
      delete appBody.encryptionFileObj
      formData.append('appBody', JSON.stringify(appBody))
      appBody = formData
    }

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editAppConfigurationByAppAndType returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /* console.info(
        `statusCode: ${response.status} for editAppConfigurationByAppAndType`
      )*/

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to delete the remote connections configuration of a app by its identifier
*/
async function deleteAppConnectionConfigurationByApp(app, env, id) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/connection`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'delete',
      url: url,
      headers: requestHeaders,
      data: { connectionId: id }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else if (error.response.status === 409) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `deleteAppConnectionConfigurationByApp returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /*  console.info(
        `statusCode: ${response.status} for deleteAppConnectionConfigurationByApp`
      )*/

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else if (response.status === 409) {
        return {
          success: false,
          message: "The connection is in use, it can't be deleted."
        }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the App loads by app
*/
async function getAppLoadsByAppId(app, env, type) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: { type: `${type}` }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getAppLoadsByAppId returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the App load by app and identifier
*/
async function getAppLoadByAppAndId(app, env, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getAppLoadByAppAndId returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Create the App load by app and identifier
*/
async function createAppLoad(app, env, appBody, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`createAppLoad returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      // console.info(`statusCode: ${response.status} for createAppLoad`)

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.message }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to update the load of a app by its identifier
*/
async function editAppLoadByAppAndId(app, env, appBody, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`editAppLoadByAppAndId returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      //  console.info(`statusCode: ${response.status} for editAppLoadByAppAndId`)

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Validate App Connection
*/
async function validateAppConnectionByType(app, env, appParams, type, apptype = 'insights') {
  try {
    let tenant = getUserTenant()
    type = type === 'upstream' ? 'itk_upstream' : type
    type = type === 'downstream' ? 'itk_downstream' : type
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/${type}/validate-connection/ariba`
    if (apptype !== 'insights') {
      url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/${type}/validate-connectionqt/ariba`
    }
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appParams
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `validateAppConnectionByType returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Validate App Connection
*/
async function validateAppConnection(
  app,
  appParams,
  env = null,
  type = null,
  addTenant = false,
  invoiceEndPoint = false
) {
  try {
    let tenant = getUserTenant()
    let url = ''
    if (addTenant && env) {
      url = `${ApiAdmin.EndPoint}/tenants/${tenant}/validate-connection/${app}/env/${env}`
      if (invoiceEndPoint)
        url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/validate-connection/${app}/env/${env}`
    } else url = `${ApiAdmin.EndPoint}/validate-connection/${app}`
    if (env && type) {
      url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/validate-connection/${type}`
      if (invoiceEndPoint)
        url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/validate-connection/${type}`
    }
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appParams
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`validateAppConnection returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Validate App Connection
*/
async function validateCustomAppConnection(
  app,
  appParams,
  env = null,
  addTenant = false,
  invoiceUrl = false,
  pricebookUrl = false
) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/validate-custom-connection/${app}/env/${env}`
    if (invoiceUrl) {
      url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${env}/transactions/connection`
    }
    if (pricebookUrl) {
      url = `${ApiAdminPricebook.EndPoint}tenants/${tenant}/apps/${env}/transactions/connection`
    }

    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appParams
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`validateAppConnection returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Associate a team to a document
*/
async function associateDocumentToTeam(documentData) {
  try {
    let tenant = getUserTenant()
    documentData.nitorTenantId = tenant

    let url = `${ApiNitorConnect.EndPoint}/tabs/ariba`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: documentData
    }).catch(error => {
      console.debug(error.response.status)
      console.debug(error.response)
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`associateDocumentToTeam returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      //  console.info(`statusCode: ${response.status} for associateDocumentToTeam`)
      //  console.debug(response)

      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, data: response.data.errors }
      }
    } else {
      return {
        success: false,
        data: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(`Error: ${error}`)
    if (error.response.data) {
      return { success: false, data: error.response.data.errors }
    } else {
      return {
        success: false,
        data: 'Something went wrong, try again later'
      }
    }
  }
}

/*
Start an app load
*/
async function setStartLoad(app, appBody, env, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'patch',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`setStartLoad returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      //console.info(`statusCode: ${response.status} for setStartLoad`)

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Stop an app load
*/
async function setStopLoad(app, appBody, env, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'patch',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`setStopLoad returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      //console.info(`statusCode: ${response.status} for setStopLoad`)

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Delete an app load
*/
async function setDeleteLoad(app, env, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'delete',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`setDeleteLoad returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      // console.info(`statusCode: ${response.status} for setDeleteLoad`)

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the app Constants
*/
async function getAppConstantsByApp(app, typeApp) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/typeApp/${typeApp}/constants`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getAppConstantsByApp returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the Load Logs by app and identifier
*/
async function getLoadLogsById(app, env, idLoad) {
  // idLoad = '89752a42-4592-42cd-abb7-602d37f26306'
  // env = 'dev'
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/transaction/${idLoad}/logs`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getLoadLogsById returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
async function saveConfiguration(app, env, appBody, service, process) {
  try {
    //  '/tenants/:tenantId/apps/slp/env/:env',
    appBody.process = process
    appBody.service = service
    let company = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${company}/apps/${app}/env/${env}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editAppConfigurationByAppAndId returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code

      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getMappingsByApp(app, service) {
  try {
    //constants/apps/slp/mappings/:service
    let company = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${company}/constants/apps/${app}/mappings/${service}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getMappingsByApp returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      // console.info(`statusCode: ${response.status} for getMappingsByApp`)

      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}
async function getTypesByService(app, service) {
  try {
    //constants/apps/slp/mappings/:service
    let company = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${company}/constants/apps/${app}/types/${service}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getTypesByService returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      //console.info(`statusCode: ${response.status} for getTypesByService`)

      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Get the transactions by app id
*/
async function getTransactionsByAppId(app, env, status = null) {
  try {
    let params = status ? { status: `${status}` } : ''
    let tenant = getUserTenant()
    let url = `${ApiDataAssureLogs.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/transactions`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: params
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTransactionsByAppId returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Post retry the transaction
*/
async function retryTransactionById(app, env, transactionId) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiDataAssureLogs.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/transactions/${transactionId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`retryTransactionById returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the transactions count by app id
*/
async function getTransactionsCountByAppId(app, body) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiDataAssureLogs.EndPoint}/tenants/${tenant}/apps/${app}/transactions/count`
    let requestHeaders = createHeaders()
    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: { ...body }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getTransactionsCountByAppId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the transactions count details by app id
*/
async function getTransactionsFullDetailsByAppId(app, type, query, offset, limit) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiDataAssureLogs.EndPoint}/tenants/${tenant}/apps/${app}/validations/${type}/fullTransactions`
    let requestHeaders = createHeaders()
    requestHeaders.offset = offset
    requestHeaders.limit = limit

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: { ...query }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getTransactionsCountDetailsByAppId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function searchDnbSupplier(app, data) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${app}/dnb/dunsNumberList`

    let requestHeaders = createHeaders()
    if (data.country && data.country.value) data.country = data.country.value
    let response = await axios({
      method: 'POST',
      url: url,
      data: data,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`searchDnbSupplier returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function updateDnbSupplier(app, data) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${app}/dnb/updateSupplierDunsNumber`

    let requestHeaders = createHeaders()
    let response = await axios({
      method: 'POST',
      url: url,
      data: data,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`searchDnbSupplier returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getDnBPendingSuppliers(app) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${app}/dnb/pendingSuppliers`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getDnBPendingSuppliers returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the transactions count details by app id
*/
async function getTransactionsCountDetailsByAppId(app, type, query) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiDataAssureLogs.EndPoint}/tenants/${tenant}/apps/${app}/validations/${type}/transactions`
    let requestHeaders = createHeaders()
    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: { ...query }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getTransactionsCountDetailsByAppId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the list of requisitions details
*/
async function getRequisitionsDetails(app, uniqueName) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/${uniqueName}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getRequisitionsDetails returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      //console.info(`statusCode: ${response.status} for getRequisitionsDetails`)

      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Get the list of requisitions details of changes
*/
async function getRequisitionsDetailsChanges(app, uniqueName) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/${uniqueName}/changes`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `getRequisitionsDetailsChanges returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /* console.info(
        `statusCode: ${response.status} for getRequisitionsDetailsChanges`
      )*/

      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Get the invoices by app
*/
async function getInvoicesByAppId(app, status, country) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/invoices`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: { status: `${status}`, country: `${country}` }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getInvoicesByAppId returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getInvoiceByInvoiceId(app, id) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/invoices/${id}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getInvoiceByInvoiceId returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the child loads by Load Id
*/
async function getChildLoadsByLoadId(app, env, type, id) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/childloads/${id}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: { type: `${type}` }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getChildLoadsByLoadId returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the tenants for connect
*/
async function getTenatsConnect(app) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/MSTeamsTenants`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTenatsConnect returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the tenant apps for connect
*/
async function getTenatAppsConnect(app, MSTenantId) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/MSTeamsTenantId/${MSTenantId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTenatAppsConnect returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Save the tenant app for connect
*/
async function saveTenatAppsConnect(app, appBody) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/DefaultConfiguration`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`saveTenatAppsConnect returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Import invoices
*/
async function importInvoices(app, appBody) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/invoices/import`
    let requestHeaders = createHeaders()
    requestHeaders['Content-Type'] = 'multipart/form-data'

    const formData = new FormData()
    formData.append('file', appBody)
    formData.append('fileName', appBody.name)
    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: formData
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`importInvoices returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the invoices import history
*/
async function getInvoiceImportHistory(app) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/invoices/history`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getInvoiceImportHistory returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
/*
EndPoint to get app List
*/
async function getInvoiceConfigByAppQuery(app) {
  let tenant = getUserTenant()

  let url
  switch (app) {
    case 'invoiceAI':
      url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/invoiceAI`
      break
    case 'insights':
      url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}`
      break
    default:
      throw new Error('App not implemented yet')
  }

  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Get the list of configurations of the apps of invoice
*/
async function getInvoiceConfigurationsByApp() {
  try {
    let response = await getInvoiceConfigByAppQuery('invoiceAI').catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `getInvoiceConfigurationsByApp returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

async function getInvoiceAppConfigurationByAppAndIdQuery(env) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/invoiceAI/env/${env}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Get the Invoice App configuration by app and identifier
*/
async function getInvoiceAppConfigurationByAppAndId(env) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/invoiceAI/env/${env}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getInvoiceAppConfigurationByAppAndId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Create a new invoiceapp with the specified configuration
*/
async function createInvoiceAppConfiguration(app, appBody, addClientToBody = false) {
  try {
    let tenant = getUserTenant()
    if (addClientToBody) appBody.client = tenant
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createInvoiceAppConfiguration returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      if (response.status === 201) {
        return { success: true, message: response.data }
      } else if (response.status === 403) {
        return response.data
      } else {
        return { success: false, message: response.data.message }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to update the invoice connections configuration of a app by its identifier and type
*/
async function editInvoiceAppConfigurationByAppAndType(app, env, appBody, type) {
  try {
    let tenant = getUserTenant()
    type = type === 'upstream' ? 'itk_upstream' : type
    type = type === 'downstream' ? 'itk_downstream' : type
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/${type}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editInvoiceAppConfigurationByAppAndType returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to update the remote connections configuration of a app by its identifier
*/
async function editInvoiceAppConnectionConfigurationByApp(app, env, appBody) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/connection`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editInvoiceAppConnectionConfigurationByApp returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Validate App Connection
*/
async function validateInvoiceAppConnectionByType(app, env, appParams, type) {
  try {
    let tenant = getUserTenant()
    type = type === 'upstream' ? 'itk_upstream' : type
    type = type === 'downstream' ? 'itk_downstream' : type
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/${type}/validate-connection/ariba`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appParams
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `validateInvoiceAppConnectionByType returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get the App loads by app
*/
async function getInvoiceAppLoadsByAppId(app, env, type) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: { type: `${type}` }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getInvoiceAppLoadsByAppId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Start an app load
*/
async function setStartInvoiceLoad(app, appBody, env, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'patch',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`setStartInvoiceLoad returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Stop an app load
*/
async function setStopInvoiceLoad(app, appBody, env, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'patch',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`setStopInvoiceLoad returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Delete an app load
*/
async function setDeleteInvoiceLoad(app, env, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'delete',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`setDeleteInvoiceLoad returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/**
Save invoice app
 */
async function saveInvoiceConfiguration(app, env, appBody, service, process) {
  try {
    appBody.process = process
    appBody.service = service
    let company = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${company}/apps/${app}/env/${env}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `saveInvoiceConfiguration returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code

      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Create the Invoice App load by app and identifier
*/
async function createInvoiceAppLoad(app, env, appBody, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`createInvoiceAppLoad returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      if (response.status === 201) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.message }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getInvoiceAppLoadByAppAndIdQuery({ app, env, idLoad }) {
  let tenant = getUserTenant()
  let url
  switch (app) {
    case 'invoiceAI':
      url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
      break
    case 'insights':
      url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
      break
    default:
      throw new Error('App not implemented yet')
  }
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Get the Invoice App load by app and identifier
*/
async function getInvoiceAppLoadByAppAndId(app, env, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getInvoiceAppLoadByAppAndId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to update the load of a invoice app by its identifier
*/
async function editInvoiceAppLoadByAppAndId(app, env, appBody, idLoad) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${idLoad}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editInvoiceAppLoadByAppAndId returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getInvoiceChildLoadsByLoadIdQuery({
  app,
  env,
  type,
  id,
  rowsPage,
  lastEvaluatedKey
}) {
  let tenant = getUserTenant()
  let url
  switch (app) {
    case 'invoiceAI':
      url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/childloads/${id}`
      break
    case 'insights':
      url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/childloads/${id}`
      break
    default:
      throw new Error('App not implemented yet')
  }

  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: { limit: rowsPage, key: lastEvaluatedKey }
  })
}
/*
Get the invoice child loads by Load Id
*/
async function getInvoiceChildLoadsByLoadId(app, env, type, id) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/childloads/${id}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders,
      params: { type: `${type}` }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getInvoiceChildLoadsByLoadId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Used to delete the remote connections configuration of an Invoice app by its identifier
*/
async function deleteInvoiceAppConnectionConfigurationByApp(app, env, id) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/connection`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'delete',
      url: url,
      headers: requestHeaders,
      data: { connectionId: id }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else if (error.response.status === 409) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `deleteInvoiceAppConnectionConfigurationByApp returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /*  console.info(
        `statusCode: ${response.status} for deleteInvoiceAppConnectionConfigurationByApp`
      )*/

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else if (response.status === 409) {
        return {
          success: false,
          message: "The connection is in use, it can't be deleted."
        }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Create a new tenant configuration for invoices with the specified attributes
*/

async function createTenantConfigurationForInvoices(company, tenantConfig) {
  try {
    tenantConfig.tenantId = company
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${company}/configurations`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: tenantConfig
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createTenantConfigurationForInvoices returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for createTenantConfigurationForInvoices`)

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.errors[0].msg }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

async function getTenantConfigInvoicesEndPoint(tenantId) {
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenantId}/configurations`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

async function getTenantConfigurationForInvoices(tenantId) {
  try {
    let response = await getTenantConfigInvoicesEndPoint(tenantId).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTenantConfigurations returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

async function editTenantConfigurationForInvoices(tenant, newTenantConfig) {
  try {
    let response = await putTenantConfigInvoicesEndPoint(tenant, newTenantConfig).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editTenantConfigurationForInvoices returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for editTenantConfigurationForInvoices`)

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.errors[0].msg }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

async function loginInAuthService(userInfo) {
  try {
    let url = `${AuthService.Endpoint}login`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: userInfo
    })
    return { success: true, data: response.data }
  } catch (error) {
    if (error?.response?.status === 401) {
      console.error(error.response.data.message)
      return {
        sucess: false,
        data: error.response.data.message || 'Something went wrong'
      }
    } else {
      console.error(error.message)
      // Throw error if it's not a success response
      throw new Error('Something went wrong')
    }
  }
}

async function getAribaCertificate() {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/certificate`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          throw new Error(`getAribaCertificate returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getRouterConfigurations() {
  try {
    let tenant = getUserTenant()
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getRouterConfigurations returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

async function getRouterConfigurationDetails(appId) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations/${appId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `getRouterConfigurationDetails returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

async function createRouterConfiguration(appBody) {
  try {
    let tenant = getUserTenant()
    appBody.tenantId = tenant
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `createRouterConfiguration returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /* console.info(`statusCode: ${response.status} for createAppConfiguration`)
       */
      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data }
      } else if (response.status === 403) {
        return response.data
      } else {
        return { success: false, message: response.data.message }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function editRouterConfiguration(appBody, appId) {
  try {
    let tenant = getUserTenant()
    appBody.tenantId = tenant
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations/${appId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `updateRouterConfiguration returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /* console.info(`statusCode: ${response.status} for createAppConfiguration`)
       */
      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else if (response.status === 403) {
        return response.data
      } else {
        return { success: false, message: response.data.message }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function validateRouterAppConnection(app, appParams) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations/${app}/validate-connection`

    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appParams
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `validateRouterAppConnection returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function editRouterConfigurationConnectionByApp(app, appBody) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations/${app}/connection`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editRouterConfigurationConnectionByApp returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /* console.info(
        `statusCode: ${response.status} for editAppConfigurationByAppAndType`
      )*/

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function deleteRouterConfigurationConnectionByApp(app, connectionId) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations/${app}/connection/${connectionId}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'delete',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `deleteRouterConfigurationConnectionByApp returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /* console.info(
        `statusCode: ${response.status} for editAppConfigurationByAppAndType`
      )*/

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function validateRouterAribaAppConnection(app, connectionType, appParams) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations/${app}/validate-ariba-connection/${connectionType}`

    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: appParams
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `validateRouterAppConnection returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function editRouterAribaConfigurationConnectionByApp(app, appBody) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiRouter.Endpoint}/tenants/${tenant}/configurations/${app}/ariba-connection`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(
            `editRouterAribaConfigurationConnectionByApp returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // Check response status code
      /* console.info(
        `statusCode: ${response.status} for editAppConfigurationByAppAndType`
      )*/

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getMonitorConstants() {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/monitorConsole/constants`
    let requestHeaders = createHeaders()
    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    })

    return { success: true, data: response.data }
  } catch (error) {
    console.error(error.message)
    return { success: false }
  }
}

async function getMonitorConfigurationByAppAndId(app, env, type) {
  try {
    let tenant = getUserTenant()
    let params = typeof type !== 'undefined' ? `?type=${type}` : ''
    let url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/monitorConsole${params}`
    let requestHeaders = createHeaders()
    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    })
    return { success: true, data: response.data }
  } catch (error) {
    console.error(error.message)
    return { success: false, data: error.data }
  }
}

async function saveMonitorConfiguration(app, env, appBody, service, process, isNew = false) {
  try {
    appBody.process = process
    appBody.service = service
    let company = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${company}/apps/${app}/env/${env}/monitorConsole`
    let requestHeaders = createHeaders()
    let response = await axios({
      method: isNew ? 'post' : 'put',
      url: url,
      headers: requestHeaders,
      data: appBody
    })

    if (response) {
      // Check response status code

      // If it's a success response
      if (response.status === 201 || response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again'
      }
    }
  } catch (error) {
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getDataDiagram(transactionId, transactionDate) {
  try {
    let tenantId = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenantId}/monitorConsole/${transactionId}/details/${transactionDate}/diagram`
    let headers = createHeaders()
    let response = await axios({
      method: 'get',
      url,
      headers
    })

    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
  } catch (error) {
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

async function getAppsMonitors(appList, app) {
  try {
    let company = getUserTenant()
    let url = `${ApiAdmin.EndPoint}/tenants/${company}/monitorConsole/${app}`
    let headers = createHeaders()
    let response = await axios({
      method: 'get',
      url,
      headers,
      params: { apps: JSON.stringify(appList) }
    })

    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
  } catch (error) {
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

async function getMonitorsTransactionsData(tenantId, env, monitorId, status, appId) {
  const url = `${ApiAdminMonitor.EndPoint}/tenants/${tenantId}/env/${env}/app/${appId}/monitors/${monitorId}`
  const requestHeaders = createHeaders()
  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: { status: `${status}` }
  })
}

async function getTransactionsDataById(tenantId, processId, env, appId, monitorId) {
  const url = `${ApiAdminMonitor.EndPoint}/tenants/${tenantId}/env/${env}/app/${appId}/monitors/${monitorId}/processes/${processId}`
  const headers = createHeaders()

  return axios({
    method: 'get',
    url,
    headers
  })
}

async function getMonitorsDescriptions(tenantId) {
  const url = `${ApiAdmin.EndPoint}/tenants/${tenantId}/monitors`
  const headers = createHeaders()

  return axios({
    method: 'get',
    url,
    headers
  })
}

async function getProcessDiagramData(tenantId, processId, env, appId, monitorId) {
  const url = `${ApiAdminMonitor.EndPoint}/tenants/${tenantId}/env/${env}/app/${appId}/monitors/${monitorId}/processes/${processId}/diagram`
  const headers = createHeaders()

  return axios({
    method: 'get',
    url,
    headers
  })
}

async function deleteStepHistory(transactionId) {
  const url = `${ApiAdminMonitor.EndPoint}/monitorConsole/transactions/${transactionId}`
  const headers = createHeaders()
  return axios({
    method: 'delete',
    url,
    headers
  })
}

async function getResposeDetails(hashKey, rangeKey, transactionDate) {
  try {
    const url = `${ApiDataAssureLogs.EndPoint}/key/${hashKey}/range/${rangeKey}/date/${transactionDate}/details`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getTransactionsCountDetailsByAppId returned a status code ${error.response.status}`
          )
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getResposeParsedDetails(hashKey, rangeKey, transactionDate) {
  try {
    const url = `${ApiDataAssureLogs.EndPoint}/key/${hashKey}/range/${rangeKey}/date/${transactionDate}/parsedDetails`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `getTransactionsCountDetailsByAppId returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function getDnBBlockConfiguration(appId, blockIdWithLevelAndVersion) {
  try {
    let tenant = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${appId}/dataBlockId/${blockIdWithLevelAndVersion}/dnb/getDataBlocks`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getDnBMapping returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: response.data.message
            ? response.data.message
            : 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: response.data.message
          ? response.data.message
          : 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)

    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
async function getDnBMapping(appId) {
  try {
    let tenant = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${appId}/dnb/getMappingConfiguration`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getDnBMapping returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
async function saveDataBlocksMappings(appId, mappings) {
  try {
    let tenant = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${appId}/dnb/updateBlockConfiguration`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: { dnbMappings: mappings }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getDnBMapping returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
// DA Files
async function getSlpBatchesByClient(appId) {
  try {
    let tenant = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${appId}/pocSedgwick/getBatches`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getSlpBatchesByClient returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
async function getSlpBatchesDetail(appId, batchId) {
  try {
    let tenant = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${appId}/pocSedgwick/getBatches/${batchId}`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getSlpBatchesDetail returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
async function uploadDataAssureBatch(props, suppliers, callback) {
  let upload = {}
  try {
    let tenant = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/slp/env/${props.selectedAppId}/uploadBatch/postJsonBatch`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: { suppliers: suppliers }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`uploadDataAssureBatch returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        upload = {
          success: true,
          data: response.data
        }
      } else {
        upload = {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      upload = {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }

  if (upload && upload.success) {
    props.setValidationMessage('Suppliers File Uploaded')
    props.setSubmitSuccess(true)
  } else {
    let message = upload.message ? upload.message : 'Something went wrong, try again'
    props.setValidationMessage(message)
    props.setSubmitError(true)
  }
  props.setSelectedView('UploadFileMainView')
  callback(props.setPageIsLoading)
}

// DA - POC - Coupa iFrame App
async function pocCoupaIFrameAppGetValidations(appId, supplierId) {
  try {
    let tenant = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${appId}/pocIframeApp/getValidations/${supplierId}`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `pocCoupaIFrameAppGetValidations returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

async function pocCoupaIFrameAppRunValidations(appId, supplierId) {
  try {
    let tenant = getUserTenant()
    const url = `${ApiAdmin.EndPoint}/tenants/${tenant}/apps/${appId}/pocIframeApp/runValidations/${supplierId}`
    const requestHeaders = createHeaders()
    const response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(
            `pocCoupaIFrameAppRunValidations returned a status code ${error.response.status}`
          )
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return {
          success: true,
          data: response.data
        }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

export {
  associateDocumentToTeam,
  createAppConfiguration,
  createAppLoad,
  createInvoiceAppConfiguration,
  createInvoiceAppLoad,
  createRouterConfiguration,
  createTenantConfigurationForInvoices,
  deleteAppConnectionConfigurationByApp,
  deleteInvoiceAppConnectionConfigurationByApp,
  deleteRouterConfigurationConnectionByApp,
  deleteStepHistory,
  editAppConfigurationByAppAndId,
  editAppConfigurationByAppAndType,
  editAppConnectionConfigurationByApp,
  editAppLoadByAppAndId,
  editInvoiceAppConfigurationByAppAndType,
  editInvoiceAppConnectionConfigurationByApp,
  editInvoiceAppLoadByAppAndId,
  editRouterAribaConfigurationConnectionByApp,
  editRouterConfiguration,
  editRouterConfigurationConnectionByApp,
  editTenantConfigurationForInvoices,
  getAppConfigurationByAppAndId,
  getAppConfigurationsByApp,
  getAppConstantsByApp,
  getAppLoadByAppAndId,
  getAppLoadsByAppId,
  getAppsMonitors,
  getAribaCertificate,
  getChildLoadsByLoadId,
  getDataDiagram,
  getDnBBlockConfiguration,
  getDnBMapping,
  getDnBPendingSuppliers,
  getInvoiceAppConfigurationByAppAndId,
  getInvoiceAppConfigurationByAppAndIdQuery,
  getInvoiceAppLoadByAppAndId,
  getInvoiceAppLoadByAppAndIdQuery,
  getInvoiceAppLoadsByAppId,
  getInvoiceByInvoiceId,
  getInvoiceChildLoadsByLoadId,
  getInvoiceChildLoadsByLoadIdQuery,
  getInvoiceConfigByAppQuery,
  getInvoiceConfigurationsByApp,
  getInvoiceImportHistory,
  getInvoicesByAppId,
  getLoadLogsById,
  getMappingsByApp,
  getMonitorConfigurationByAppAndId,
  getMonitorConstants,
  getMonitorsDescriptions,
  getMonitorsTransactionsData,
  getProcessDiagramData,
  getRequisitionsDetails,
  getRequisitionsDetailsChanges,
  getResposeDetails,
  getResposeParsedDetails,
  getRouterConfigurationDetails,
  getRouterConfigurations,
  getSlpBatchesByClient,
  getSlpBatchesDetail,
  getTenantConfigInvoicesEndPoint,
  getTenantConfigurationForInvoices,
  getTenatAppsConnect,
  getTenatsConnect,
  getTransactionsByAppId,
  getTransactionsCountByAppId,
  getTransactionsCountDetailsByAppId,
  getTransactionsDataById,
  getTransactionsFullDetailsByAppId,
  getTypesByService,
  importInvoices,
  loginInAuthService,
  pocCoupaIFrameAppGetValidations,
  pocCoupaIFrameAppRunValidations,
  retryTransactionById,
  saveConfiguration,
  saveDataBlocksMappings,
  saveInvoiceConfiguration,
  saveMonitorConfiguration,
  saveTeamsInit,
  saveTenatAppsConnect,
  searchDnbSupplier,
  setDeleteInvoiceLoad,
  setDeleteLoad,
  setStartInvoiceLoad,
  setStartLoad,
  setStopInvoiceLoad,
  setStopLoad,
  updateDnbSupplier,
  uploadDataAssureBatch,
  validateAppConnection,
  validateAppConnectionByType,
  validateCustomAppConnection,
  validateInvoiceAppConnectionByType,
  validateRouterAppConnection,
  validateRouterAribaAppConnection
}
