import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";

// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// models
import { load as loadSchema } from "models/qualtricsModel";
import { validateProperty, validateObject } from "utils/validator";
import { dayOfMonth, dayOfWeek } from "utils/Constants";

// style
import style from "assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle.js";

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.initialState;
    }
    sendState() {
        return this.state;
    }

    onFieldChange(field, value) {
        if (field === 'updateFrequency') {
            this.setState({ executionDay: '' });
        }
        if (field === "startDate") {
            if (typeof value === "object" && value !== null) {
                value = value._d.toLocaleDateString(window.navigator.userLanguage || window.navigator.language, {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
            }
        }
        if (field === "executionTime") {
            if (typeof value === "object" && value !== null) {
                value = value._d.toLocaleTimeString(window.navigator.userLanguage || window.navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                });
            }
        }
        this.setState({ [field]: value });
        let stateCopy = this.state;
        stateCopy[field] = value;

        let isValid = validateProperty(loadSchema, stateCopy, field).isValid;
        if (isValid) {
            this.setState({ [field + "State"]: "success" });
        } else {
            this.setState({ [field + "State"]: "error" });
        }
    }

    isValidated() {
        let validation = validateObject(loadSchema, this.state);

        if (!validation.isValid) {
            let field;
            for (field in this.state) {
                if (validation.errors[field]) {
                    this.setState({ [field + "State"]: "error" });
                }
            }
        }

        return validation.isValid;
    }

    executionDayOptions(updateFrequency) {
        const { classes } = this.props;
        let options = []
        if (updateFrequency === 'weekly') {
            options = dayOfWeek
        } else {
            options = dayOfMonth
        }
       return options.map(option => (
             <MenuItem classes={{
                root:
                    classes.selectMenuItem,
                selected:
                    classes.selectMenuItemSelected,
            }}
            style={{
                overflowX:
                    "auto",
                textOverflow:
                    "ellipsis",
            }}
            key={option.key} 
            value={option.key} >
                {option.label}
            </MenuItem>
        ))
    }

    render() {
        const { classes } = this.props;

        return (
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <h4 className={classes.infoText}>Load configuration</h4>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        <h5 className={classes.sectionTitle}>Settings</h5>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                Name of load
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <CustomInput
                                                success={
                                                    this.state.nameState ===
                                                    "success"
                                                }
                                                error={
                                                    this.state.nameState ===
                                                    "error"
                                                }
                                                labelText={
                                                    <span>
                                                        Name of load{" "}
                                                        <small>
                                                            {this.state
                                                                .nameState ===
                                                            "error"
                                                                ? "(required)"
                                                                : "*"}{" "}
                                                        </small>
                                                    </span>
                                                }
                                                id="name"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    onChange: (event) =>
                                                        this.onFieldChange(
                                                            "name",
                                                            event.target.value
                                                        ),
                                                    value: this.state.name,
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                Type
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <FormControl
                                                fullWidth
                                                className={
                                                    classes.selectFormControl
                                                }
                                            >
                                                <InputLabel
                                                    htmlFor="simple-select"
                                                    className={
                                                        classes.selectLabel
                                                    }
                                                >
                                                    Type of load
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        className:
                                                            classes.selectMenu,
                                                    }}
                                                    classes={{
                                                        select: classes.select,
                                                    }}
                                                    value={this.state.type}
                                                    onChange={(e) =>
                                                        this.onFieldChange(
                                                            "type",
                                                            e.target.value
                                                        )
                                                    }
                                                    inputProps={{
                                                        name: "type",
                                                        id: "type",
                                                    }}
                                                >
                                                    <MenuItem
                                                        classes={{
                                                            root:
                                                                classes.selectMenuItem,
                                                            selected:
                                                                classes.selectMenuItemSelected,
                                                        }}
                                                        style={{
                                                            overflowX: "auto",
                                                            textOverflow:
                                                                "ellipsis",
                                                        }}
                                                        value={"historical"}
                                                    >
                                                        Historical
                                                    </MenuItem>
                                                    <MenuItem
                                                        classes={{
                                                            root:
                                                                classes.selectMenuItem,
                                                            selected:
                                                                classes.selectMenuItemSelected,
                                                        }}
                                                        style={{
                                                            overflowX: "auto",
                                                            textOverflow:
                                                                "ellipsis",
                                                        }}
                                                        value={"scheduled"}
                                                    >
                                                        Scheduled Job
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                {this.state.type === "scheduled" ? (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6}>
                                                <FormLabel
                                                    className={
                                                        classes.inputLabelHorizontal
                                                    }
                                                >
                                                    Update Frequency
                                                </FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <FormControl
                                                    fullWidth
                                                    className={
                                                        classes.selectFormControl
                                                    }
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={
                                                            classes.selectLabel
                                                        }
                                                    >
                                                        Update Frequency
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className:
                                                                classes.selectMenu,
                                                        }}
                                                        classes={{
                                                            select:
                                                                classes.select,
                                                        }}
                                                        value={
                                                            this.state
                                                                .updateFrequency
                                                        }
                                                        onChange={(e) =>
                                                            this.onFieldChange(
                                                                "updateFrequency",
                                                                e.target.value
                                                            )
                                                        }
                                                        inputProps={{
                                                            name:
                                                                "updateFrequency",
                                                            id:
                                                                "updateFrequency",
                                                        }}
                                                    >
                                                        <MenuItem
                                                            classes={{
                                                                root:
                                                                    classes.selectMenuItem,
                                                                selected:
                                                                    classes.selectMenuItemSelected,
                                                            }}
                                                            style={{
                                                                overflowX:
                                                                    "auto",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                            value={"daily"}
                                                        >
                                                            Daily
                                                        </MenuItem>
                                                        <MenuItem
                                                            classes={{
                                                                root:
                                                                    classes.selectMenuItem,
                                                                selected:
                                                                    classes.selectMenuItemSelected,
                                                            }}
                                                            style={{
                                                                overflowX:
                                                                    "auto",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                            value={"weekly"}
                                                        >
                                                            Weekly
                                                        </MenuItem>
                                                        <MenuItem
                                                            classes={{
                                                                root:
                                                                    classes.selectMenuItem,
                                                                selected:
                                                                    classes.selectMenuItemSelected,
                                                            }}
                                                            style={{
                                                                overflowX:
                                                                    "auto",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                            value={"monthly"}
                                                        >
                                                            Monthly
                                                        </MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                ) : null}
                                {this.state.type === "scheduled" ? (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6}>
                                                <FormLabel
                                                    className={
                                                        classes.inputLabelHorizontal
                                                    }
                                                >
                                                    Execution Date
                                                </FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={3}>
                                                <InputLabel style={{fontSize: '10.5px', color: '#999', marginTop: '17px', marginBottom: '-9px'}}>
                                                    Execution Time
                                                </InputLabel>
                                                <Datetime
                                                    dateFormat={false}
                                                    closeOnSelect={true}
                                                    value={this.state.executionTime}
                                                    onChange={(event) =>
                                                        this.onFieldChange(
                                                            "executionTime",
                                                            event
                                                        )
                                                    }
                                                    inputProps={{
                                                        style: { fontSize: '14px' },
                                                        placeholder:
                                                            "Execute Time",
                                                        value: this.state.executionTime
                                                    }}
                                                />
                                            </GridItem>
                                            { this.state.updateFrequency === 'weekly'  || this.state.updateFrequency === 'monthly' ? (
                                            <GridItem xs={12} sm={3}>
                                                <FormControl
                                                    fullWidth
                                                    className={
                                                        classes.selectFormControl
                                                    }
                                                >
                                                    <InputLabel
                                                        htmlFor="simple-select"
                                                        className={this.state
                                                            .executionDayState ===
                                                            "error" ?
                                                            classes.selectLabelError :
                                                            classes.selectLabel
                                                        }
                                                    >
                                                        Execution Day 
                                                        <small>
                                                            {this.state
                                                                .executionDayState ===
                                                            "error"
                                                                ? "(required)"
                                                                : " *"}
                                                        </small>
                                                    </InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            className:
                                                                classes.selectMenu,
                                                        }}
                                                        classes={{
                                                            select:
                                                                classes.select,
                                                        }}
                                                        value={
                                                            this.state
                                                                .executionDay
                                                        }
                                                        onChange={(e) =>
                                                            this.onFieldChange(
                                                                "executionDay",
                                                                e.target.value
                                                            )
                                                        }
                                                        inputProps={{
                                                            name:
                                                                "executionDay",
                                                            id:
                                                                "executionDay",
                                                        }}
                                                    >
                                                       {this.executionDayOptions(this.state.updateFrequency)}
                                                    </Select>
                                                </FormControl>
                                            </GridItem>
                                            ) : null }
                                        </GridContainer>
                                    </GridItem>
                                ) : null }
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                Start Date
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <InputLabel style={{fontSize: '10.5px', color: '#999', marginTop: '17px', marginBottom: '-9px'}}>
                                                Start Date
                                            </InputLabel>
                                            <Datetime
                                                closeOnSelect={true}
                                                dateFormat="MM/DD/YYYY"
                                                value={this.state.startDate}
                                                timeFormat={false}
                                                onChange={(event) =>
                                                    this.onFieldChange(
                                                        "startDate",
                                                        event
                                                    )
                                                }
                                                inputProps={{
                                                    style: { fontSize: '14px'},
                                                    placeholder:
                                                        "Start Date",
                                                    value: this.state
                                                        .startDate
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridItem>
                </GridContainer>
            </GridItem>
        );
    }
}

Step1.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(style)(Step1);
