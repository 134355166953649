import React, { useEffect, useState } from 'react'

import logoAribaYellow from 'assets/img/aribaTransparent.png'
import { ReactComponent as CoupaLogo } from 'assets/img/invoices/CoupaLogo.svg'
import { ReactComponent as CoupaLogoBlackWhite } from 'assets/img/invoices/CoupaLogoBlackWhite.svg'
import logoAriba from 'assets/img/logoAriba.png'
import MaterialTable from 'material-table'
import { useQueryClient } from 'react-query'
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Fade from '@material-ui/core/Fade'
import { createTheme, makeStyles, ThemeProvider, withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

// @material-ui/icons
import { Tooltip } from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import AddIcon from '@material-ui/icons/Add'
import AddAlert from '@material-ui/icons/AddAlert'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import BlockIcon from '@material-ui/icons/Block'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'
import InboxIcon from '@material-ui/icons/Inbox'
import LinkIcon from '@material-ui/icons/Link'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import RestorePageIcon from '@material-ui/icons/RestorePage'
import SendIcon from '@material-ui/icons/Send'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import VisibilityIcon from '@material-ui/icons/Visibility'

// components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import CustomDownloadButton from 'components/Invoice/CustomDownloadButton'
import { ReapplyMappingsModal } from 'components/Invoice/ReapplyMappingsModal'
import { RejectInvoicesModal } from 'components/Invoice/RejectInvoicesModal'
import { SendToAnotherInboxModal } from 'components/Invoice/SendToAnotherInboxModal'
import Snackbar from 'components/Snackbar/Snackbar.js'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'

// Constants
import {
  AribaDocumentSubStatus,
  CfdiStatus,
  CoupaDocumentSubStatus,
  DocumentSubStatus,
  editInvoicesAI,
  InvoiceOrigins,
  InvoiceOriginsAriba,
  InvoiceProcess,
  invoiceStatus,
  InvoiceType,
  ServiceText
} from 'utils/Constants'

// functions
import { statusColor } from 'utils/functions'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

import SwitchNewUI from 'components/Invoice/SwitchNewUI.jsx'
import { useGetAppList } from 'hooks/useApp.js'
import { usePermissions } from 'hooks/useAuth.js'
import { useLocalStorageState } from 'hooks/useLocalStorage.js'
import { useGetDownloadDocument, usePostDownloadDocument } from 'hooks/useMasterData'
import {
  useGeInvoicesAiTenantConfig,
  useGetInvoiceProcessByTenant,
  useGetTenantConfig
} from 'hooks/useTenantConfig.js'
import { putProcessInvoicesBatch } from 'services/apiInvoices'
import { getInvoices } from 'services/apiInvoices.js'
import { invoiceProcessType } from 'utils/Constants.js'
import { formatDate } from 'utils/functions.js'
import { getProcessLookUp } from 'views/InvoiceException/utils.js'

const useStyles = makeStyles(styles)

const dictionaryColumns = {
  'High Priority': true,
  'Historical Invoice': true,
  'Invoice ID': false,
  Process: false,
  'Invoice Date': false,
  'Processing Date': false,
  ERPVendorID: false,
  'Supplier Name': false,
  'Purchase Order': false,
  'Invoice Origin': false,
  Status: false,
  Substatus: false,
  'CFDI Status': true,
  'Payment Receipts': true,
  UUID: true,
  'Relish Id': true,
  'Invoice Type': false,
  Application: false
}

const filters = [
  'new',
  'ignored',
  'processing',
  'rejected',
  'failed',
  'invoiceException',
  'completed',
  'movingInvoiceApp'
]

function buildParams({ query, status, highPriorityFlag, historicalInvoiceFlag, appId }) {
  const params = {
    filters: {},
    pageSize: query.pageSize,
    page: query.page,
    orderBy: { processingDate: 'desc' }
  }

  // eslint-disable-next-line
  for (const filter of query.filters) {
    params.filters[filter.column.field] = filter.value
  }

  if (query.orderBy) {
    params.orderBy = { [query.orderBy.field]: query.orderDirection }
  }

  if (query.search !== '') {
    params.filters['id'] = query.search
  }

  if (status) {
    params.filters['status'] = [...status]
  }

  if (highPriorityFlag) {
    params.filters['highPriorityFlag'] = true
  }

  if (historicalInvoiceFlag) {
    params.filters['historicalInvoiceFlag'] = true
  } else {
    params.filters['historicalInvoiceFlag'] = false
  }

  if (appId) {
    params.filters['appId'] = [appId]
  }

  return params
}

export default function OldInvoiceAI() {
  const queryClient = useQueryClient()
  const tableRef = React.createRef()
  const classes = useStyles()
  const [createError, setCreateError] = useState({ isOpen: false, message: '' })
  const [createInfo, setCreateInfo] = useState({
    isOpen: false,
    message: ''
  })
  const [highPriorityFlag, setHighPriorityFlag] = useState(false)
  const [historicalInvoiceFlag, setHistoricalInvoiceFlag] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [status, setStatus] = useLocalStorageState('invoice.status-filters', filters)
  const [download, setDownload] = useState(null)
  const [userFilters, setUserFilters] = useLocalStorageState('invoice.filters', {})
  const [pageSize, setPageSize] = useLocalStorageState('invoice.pageSize', 5)
  const [selectText, setSelectText] = useState('Deselect All')
  const [userColumns, setUserColumns] = useLocalStorageState('invoice.config', dictionaryColumns)
  const [selectedRows, setSelectedRows] = useState([])
  const [showReapplyModal, setShowReapplyModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showSendToInboxModal, setShowSendToInboxModal] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [selectAllText, setSelectAllText] = useState('Select All Rows')

  const { data: configTenant, isLoading: isLoadingTenantConfig } = useGetTenantConfig()

  const appList = useGetAppList('invoiceAI')
  const processList = useGetInvoiceProcessByTenant(configTenant?.tenantId ?? '')

  const postDownloadDocument = usePostDownloadDocument(configTenant, historicalInvoiceFlag)
  const { permissions } = usePermissions()
  const isEditEnabled = permissions.some(x => editInvoicesAI.includes(x))

  const {
    data: urlDocument,
    isLoading: isLoadingDocument,
    isError: isErrorDocument
  } = useGetDownloadDocument(configTenant, download)
  const {
    data: appConfig,
    isLoading: appConfigIsLoading,
    isError: appConfigIsError,
    error: appConfigError
  } = useGeInvoicesAiTenantConfig('invoiceAI', configTenant?.defaultApp)

  const isMultiAppEnabled = appConfig?.data?.params?.multiApp?.isEnabled

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#081c3e'
      }
    }
  })

  function selectAllChange() {
    if (selectAll) {
      setSelectAll(false)
      setSelectAllText('Select All Rows')
      selectRows([], null, false)
    } else {
      setSelectAll(true)
      setSelectAllText('Deselect All Rows')
      selectRows([], null, true)
    }
  }

  function selectRows(rows, rowData, selectAll = false) {
    let newSelectedRows = []
    if (rowData) {
      if (rowData?.tableData?.checked) {
        newSelectedRows = [...selectedRows, rowData]
      } else {
        newSelectedRows = selectedRows.filter(row => row.id !== rowData.id)
      }
      setSelectedRows(newSelectedRows)
    } else if (selectAll) {
      newSelectedRows = invoices.filter(row => row.status === 'invoiceException')
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows(newSelectedRows)
    }
  }

  async function onReapplyMappings() {
    await putProcessInvoicesBatch({
      appId: configTenant.defaultApp,
      body: {
        invoices: selectedRows.map(row => row.id),
        reason: 'Reapply Mappings'
      },
      type: 'reapply'
    }).then(() => {
      setSelectAll(false)
      setSelectedRows([])
      setSelectAllText('Select All Rows')
      if (tableRef.current) {
        tableRef.current.onQueryChange()
      }
      queryClient.invalidateQueries(['invoices'])
      // eslint-disable-next-line
      for (const selectedRow of selectedRows) {
        queryClient.invalidateQueries(['ocrInvoice', selectedRow.id])
      }
      setCreateInfo({
        isOpen: true,
        message: 'Reapplying mappings...'
      })
    })
  }

  async function onRejectInvoices(msj) {
    await putProcessInvoicesBatch({
      appId: configTenant.defaultApp,
      body: { invoices: selectedRows.map(row => row.id), reason: msj },
      type: 'reject'
    }).then(() => {
      setSelectAll(false)
      setSelectedRows([])
      setSelectAllText('Select All Rows')
      if (tableRef.current) {
        tableRef.current.onQueryChange()
      }

      // eslint-disable-next-line
      for (const selectedRow of selectedRows) {
        queryClient.invalidateQueries(['ocrInvoice', selectedRow.id])
      }
      setCreateInfo({
        isOpen: true,
        message: 'Rejecting invoices...'
      })
    })
  }

  async function onSendToAnotherInbox(emails) {
    await putProcessInvoicesBatch({
      appId: configTenant.defaultApp,
      body: { invoices: selectedRows.map(row => row.id), emails: emails },
      type: 'sendToAnotherInbox'
    }).then(() => {
      setSelectAll(false)
      setSelectedRows([])
      setSelectAllText('Select All Rows')
      if (tableRef.current) {
        tableRef.current.onQueryChange()
      }

      // eslint-disable-next-line
      for (const selectedRow of selectedRows) {
        queryClient.invalidateQueries(['ocrInvoice', selectedRow.id])
      }
      setCreateInfo({
        isOpen: true,
        message: 'Sending invoices...'
      })
    })
  }

  function onHighPriority(value) {
    setHighPriorityFlag(value)
    if (tableRef.current) {
      const filters = []
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(userFilters)) {
        filters.push({ column: { field: key }, value })
      }
      tableRef.current.onQueryChange({
        filters,
        page: 0,
        pageSize: pageSize,
        totalCount: 0
      })
    }
  }

  function onHistoricalInvoiceFilter(value) {
    setHistoricalInvoiceFlag(value)
    if (tableRef.current) {
      const filters = []
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(userFilters)) {
        filters.push({ column: { field: key }, value })
      }
      tableRef.current.onQueryChange({
        filters,
        page: 0,
        pageSize: pageSize,
        totalCount: 0
      })
    }
  }

  async function onSearchInvoices(newStatus) {
    let statusArray = [...status]
    if (newStatus === 'all') {
      if (statusArray.length === filters.length) {
        statusArray = []
        setSelectText('Select All')
      } else {
        statusArray = filters
        setSelectText('Deselect All')
      }
    } else {
      let selected = statusArray.findIndex(x => x === newStatus)
      if (selected !== -1) {
        statusArray.splice(selected, 1)
      } else {
        statusArray.push(newStatus)
        statusArray = [...new Set(statusArray)]
      }
      if (statusArray.length === filters.length) {
        setSelectText('Deselect All')
      } else {
        setSelectText('Select All')
      }
    }
    setStatus(statusArray)

    if (tableRef.current) {
      const filters = []
      // eslint-disable-next-line
      for (const [key, value] of Object.entries(userFilters)) {
        filters.push({ column: { field: key }, value })
      }
      tableRef.current.onQueryChange({
        filters,
        page: 0,
        pageSize: pageSize,
        totalCount: 0
      })
    }
    setSelectAll(false)
    setSelectedRows([])
    setSelectAllText('Select All Rows')
  }

  useEffect(() => {
    if (isErrorDocument) {
      setCreateError({
        isOpen: true,
        message: 'Something went wrong, try again later'
      })
      setDownload(null)
      return
    }

    if (urlDocument) {
      window.open(urlDocument, '_blank')
      setDownload(null)
    }
  }, [isLoadingDocument, isErrorDocument, urlDocument])

  useEffect(() => {
    if (!appConfigIsError) {
      return
    }
    setCreateError({
      isOpen: true,
      message: appConfigError.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [appConfigIsError, appConfigError])

  let breadcrumbViews = [
    {
      name: 'Invoices',
      url: `/admin/invoiceAI/index`,
      icon: InboxIcon
    }
  ]

  function getDisabledLogo() {
    switch (appConfig?.data?.service) {
      case 'ariba':
        return <img src={logoAriba} alt="Go to Ariba" style={{ width: '24px' }} />

      case 'coupa':
        return <CoupaLogoBlackWhite style={{ width: '2.4rem', height: '2rem' }} />

      case 'generic':
        return <LinkIcon />

      default:
        return <img src={logoAriba} alt="Go to Ariba" style={{ width: '24px' }} />
    }
  }

  function getActiveLogo() {
    switch (appConfig?.data?.service) {
      case 'ariba':
        return <img src={logoAribaYellow} alt="Go to Ariba" style={{ width: '24px' }} />

      case 'coupa':
        return <CoupaLogo style={{ width: '2.4rem', height: '2rem' }} />

      case 'generic':
        return <LinkIcon style={{ color: '#081c3e' }} />

      default:
        return <img src={logoAribaYellow} alt="Go to Ariba" style={{ width: '24px' }} />
    }
  }

  const disabledLogo = getDisabledLogo()

  const activeLogo = getActiveLogo()

  const actions = [
    {
      position: 'row',
      action: rowData => ({
        icon: () => (
          <NavLink
            to={{
              pathname: `/admin/invoiceAI/${rowData.id}/details`,
              state: { status }
            }}
          >
            <DescriptionIcon fontSize="small" style={{ color: '#081c3e' }} />
          </NavLink>
        ),
        tooltip: 'Details'
      })
    },
    {
      position: 'row',
      action: rowData => ({
        icon: () => (!rowData.linkAriba ? disabledLogo : activeLogo),
        tooltip: rowData.origin === 'email' ? '' : ServiceText[appConfig.data.service] ?? '',
        onClick: (event, rowData) => {
          window.open(rowData.linkAriba, '_blank')
        },
        disabled: !rowData.linkAriba
      })
    }
  ]

  if (isEditEnabled) {
    actions.push({
      position: 'row',
      action: rowData => ({
        icon: () => {
          if (rowData.isLocked) {
            return (
              <NavLink to={`/view/invoiceAI/${rowData.id}/exception-details`}>
                <LockOutlinedIcon
                  fontSize="small"
                  style={{
                    color:
                      /*(rowData.subProcess === invoiceProcessType.ocr ||
                        rowData.process === invoiceProcessType.ocr ||
                        rowData.subProcess === invoiceProcessType.eInvoice) &&*/
                      rowData.status === 'invoiceException' ? '#B52D15' : 'rgba(0, 0, 0, 0.26)'
                  }}
                />
              </NavLink>
            )
          }

          return (
            <NavLink to={`/view/invoiceAI/${rowData.id}/exception-details`}>
              <ArtTrackIcon
                fontSize="small"
                style={{
                  color:
                    /*(rowData.process === invoiceProcessType.ocr ||
                      rowData.subProcess === invoiceProcessType.ocr ||
                      rowData.subProcess === invoiceProcessType.eInvoice) &&*/
                    rowData.status === 'invoiceException' ? '#af8f1c' : 'rgba(0, 0, 0, 0.26)'
                }}
              />
            </NavLink>
          )
        },
        tooltip: 'Exceptions',
        disabled:
          /* (rowData.process === invoiceProcessType.ocr ||
            rowData.subProcess === invoiceProcessType.ocr ||
            rowData.subProcess === invoiceProcessType.eInvoice) &&*/
          rowData.status === 'invoiceException' ? false : true
      })
    })
  } else {
    actions.push({
      position: 'row',
      action: rowData => ({
        icon: () => (
          <NavLink to={`/view/invoiceAI/${rowData.id}/exception-read-only`}>
            <VisibilityIcon
              fontSize="small"
              style={{
                color:
                  (rowData.subProcess === invoiceProcessType.ocr ||
                    rowData.process === invoiceProcessType.ocr ||
                    rowData.subProcess === invoiceProcessType.eInvoice) &&
                  (rowData.status === 'failed' ||
                    rowData.status === 'completed' ||
                    rowData.status === 'rejected' ||
                    rowData.status === 'invoiceException')
                    ? '#081c3e'
                    : 'rgba(0, 0, 0, 0.26)'
              }}
            />
          </NavLink>
        ),
        tooltip: 'Exceptions',
        disabled: !(
          (rowData.subProcess === invoiceProcessType.ocr ||
            rowData.process === invoiceProcessType.ocr ||
            rowData.subProcess === invoiceProcessType.eInvoice) &&
          (rowData.status === 'failed' ||
            rowData.status === 'completed' ||
            rowData.status === 'rejected' ||
            rowData.status === 'invoiceException')
        )
      })
    })
  }

  let substatusFilters = { ...DocumentSubStatus }
  let invoiceOriginsFilters = { ...InvoiceOrigins }
  switch (appConfig?.data?.service) {
    case 'ariba':
      substatusFilters = {
        ...AribaDocumentSubStatus,
        ...DocumentSubStatus
      }
      invoiceOriginsFilters = {
        ...InvoiceOriginsAriba,
        ...InvoiceOrigins
      }
      break
    case 'coupa':
      substatusFilters = {
        ...CoupaDocumentSubStatus,
        ...DocumentSubStatus
      }
      invoiceOriginsFilters = { ...InvoiceOrigins }
      break

    default:
      break
  }
  const processLookUp = getProcessLookUp(processList.data)

  const columns = [
    {
      field: 'highPriority',
      title: 'High Priority',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['High Priority'],
      align: 'center',
      filtering: false,
      render: rowData =>
        rowData.highPriority ? (
          <div className={classes.dangerColor} style={{ fontSize: '1.7rem', fontWeight: '500' }}>
            !!
          </div>
        ) : (
          ''
        )
    },
    {
      field: 'historicalInvoice',
      title: 'Historical Invoice',
      hiddenByColumnsButton: appConfig?.data?.params?.invoices?.showHistoricalInvoiceOpts
        ? true
        : false,
      width: 100,
      hidden: appConfig?.data?.params?.invoices?.showHistoricalInvoiceOpts
        ? userColumns['Historical Invoice']
        : true,
      align: 'center',
      filtering: false,
      render: rowData =>
        rowData.historicalInvoice ? (
          <div className={classes.warningColor} style={{ fontSize: '1.7rem', fontWeight: '500' }}>
            <AccessTimeIcon />
          </div>
        ) : (
          ''
        )
    },
    {
      field: 'id',
      title: 'Relish Id',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Relish Id'],
      align: 'center',
      filtering: false
    },
    {
      field: 'processingDate',
      title: 'Processing Date',
      render: rowData =>
        formatDate({
          date: rowData.processingDate,
          options: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h23'
          }
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Processing Date'],
      align: 'center',
      filtering: false
    },
    {
      field: 'appId',
      title: 'Application',
      render: rowData => {
        const appData = appList.data?.find(x => x.env === rowData.appId)
        return appData?.appName ?? ''
      },

      width: 100,
      align: 'center',
      // eslint-disable-next-line
      lookup: appList.data?.reduce((acc, curr) => ((acc[curr.env] = curr.appName), acc), {}) ?? {},
      defaultFilter: userFilters['appId'],
      hiddenByColumnsButton: isMultiAppEnabled ?? false,
      hidden: isMultiAppEnabled ? userColumns['Application'] : true
    },
    {
      field: 'process',
      title: 'Process',
      render: rowData => {
        if (rowData.appId && processList.data) {
          const customProcess = processList.data.find(
            x => x.id === rowData.process && x.appId === rowData.appId
          )
          if (customProcess) {
            return <div>{customProcess.processName}</div>
          } else {
            return <div>{InvoiceProcess[rowData.process] ?? rowData.process}</div>
          }
        } else {
          return <div>{InvoiceProcess[rowData.process] ?? rowData.process}</div>
        }
      },
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Process'],
      align: 'center',
      lookup: processLookUp,
      defaultFilter: userFilters['process']
    },
    {
      field: 'invoiceId',
      title: 'Invoice ID',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Invoice ID'],
      align: 'center',
      defaultFilter: userFilters['invoiceId']
    },
    {
      field: 'relatedDocumentId',
      title: 'UUID',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['UUID'],
      align: 'center',
      defaultFilter: userFilters['relatedDocumentId']
    },
    {
      field: 'invoiceDate',
      title: 'Invoice Date',
      render: rowData =>
        rowData.invoiceDate ? rowData.invoiceDate.substring(0, 10) : 'Invalid Date',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Invoice Date'],
      align: 'center',
      filtering: false
      /* defaultFilter: userFilters['invoiceDate'],
      filterPlaceholder: 'yyyy-mm-dd' */
    },
    {
      field: 'supplierId',
      title: 'ERPVendorID',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['ERPVendorID'],
      align: 'center',
      defaultFilter: userFilters['supplierId']
    },
    {
      field: 'supplierName',
      title: 'Supplier Name',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Supplier Name'],
      align: 'center',
      defaultFilter: userFilters['supplierName']
    },
    {
      field: 'orderId',
      title: 'Purchase Order',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Purchase Order'],
      align: 'center',
      defaultFilter: userFilters['orderId']
    },
    {
      field: 'invoiceType',
      title: 'Invoice Type',
      render: rowData => (rowData.invoiceType ? InvoiceType[rowData.invoiceType] : ''),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Invoice Type'],
      align: 'center',
      lookup: InvoiceType,
      defaultFilter: userFilters['invoiceType']
    },
    {
      field: 'origin',
      title: 'Invoice Origin',
      render: rowData => invoiceOriginsFilters[rowData.origin],
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Invoice Origin'],
      align: 'center',
      lookup: invoiceOriginsFilters,
      defaultFilter: userFilters['origin']
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: statusColor(rowData.status) }}>{invoiceStatus[rowData.status]}</p>
      ),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Status'],
      align: 'center',
      filtering: false
    },
    {
      field: 'substatus',
      title: 'Substatus',
      render: rowData => (
        <p style={{ color: statusColor(rowData.substatus) }}>
          {invoiceStatus[rowData.substatus] ?? rowData.substatus}
        </p>
      ),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Substatus'],
      align: 'center',
      lookup: substatusFilters,
      defaultFilter: userFilters['substatus']
    },
    {
      field: 'cfdiStatus',
      title: 'CFDI Status',
      render: rowData => invoiceStatus[rowData.cfdiStatus],
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['CFDI Status'],
      align: 'center',
      lookup: CfdiStatus,
      defaultFilter: userFilters['cfdiStatus']
    },
    {
      field: 'invoicePayments',
      title: 'Payment Receipts',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Payment Receipts'],
      align: 'center',
      defaultFilter: userFilters['invoicePayments'],
      type: 'numeric'
    },

    {
      field: 'subProcess',
      title: 'subProcess',
      hiddenByColumnsButton: false,
      width: 100,
      hidden: true,
      align: 'center'
    }
  ]

  function hideColumns(column, hidden) {
    const configCopy = { ...userColumns }
    configCopy[column.title] = hidden
    setUserColumns(configCopy)
  }

  function filterColumns(columns) {
    const configCopy = {}
    let column
    for (column of columns) {
      configCopy[column.column.field] = column.value
    }
    setUserFilters(configCopy)
    setSelectAll(false)
    setSelectedRows([])
    setSelectAllText('Select All Rows')
  }

  function onSuccessDownloadPost(extension) {
    postDownloadDocument.mutate(
      { type: 'invoice', extension },
      {
        onSuccess: data => {
          setDownload(data.data.rk)
        },
        onError: () => {
          setCreateError({
            isOpen: true,
            message: 'Something went wrong, try again later'
          })
        }
      }
    )
  }

  const ColorSwitch = withStyles({
    switchBase: {
      color: '#fafafa',
      '&$checked': {
        color: '#ff9800'
      },
      '&$checked + $track': {
        backgroundColor: '#ff9800'
      }
    },
    checked: {},
    track: {}
  })(Switch)

  function returnInvoicesTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12} style={{ marginBottom: '20px' }}>
            <div
              style={{
                width: '100%',
                margin: '0 auto 10px'
              }}
            >
              <div className={classes.highPriorityButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onHighPriority(highPriorityFlag ? false : true)
                    }}
                    className={highPriorityFlag ? classes.dangerColorFilled : classes.dangerColor}
                  >
                    <div style={{ fontSize: '1.5rem' }}>!!</div>
                  </Fab>
                  <span className={classes.statusText}>High Priority</span>
                </div>
              </div>

              {appConfig?.data?.params?.invoices?.showHistoricalInvoiceOpts ? (
                <div className={classes.historicalInvoiceButton} style={{ width: '145px' }}>
                  <div>
                    <AccessTimeIcon
                      className={classes.warningColor}
                      style={{ verticalAlign: 'middle' }}
                    />
                    <span className={classes.statusText}>Historical</span>
                    <ColorSwitch
                      size="small"
                      onClick={() => {
                        onHistoricalInvoiceFilter(historicalInvoiceFlag ? false : true)
                      }}
                      checked={historicalInvoiceFlag}
                    />
                  </div>
                </div>
              ) : null}

              <Tooltip title="Refresh">
                <Button
                  style={{
                    maxWidth: '40px',
                    maxHeight: '30px',
                    minWidth: '40px',
                    minHeight: '30px'
                  }}
                  size="small"
                  className={
                    classes.floatRight + ' ' + classes.clearAvatar + ' ' + classes.colorPrimary
                  }
                  onClick={() => tableRef.current && tableRef.current.onQueryChange()}
                >
                  <RefreshRoundedIcon />
                </Button>
              </Tooltip>

              <CustomDownloadButton
                handleConfirm={onSuccessDownloadPost}
                className={
                  classes.floatRight + ' ' + classes.clearAvatar + ' ' + classes.colorPrimary
                }
                style={{
                  maxWidth: '40px',
                  maxHeight: '30px',
                  minWidth: '40px',
                  minHeight: '30px'
                }}
                disabled={Boolean(download) || !isEditEnabled}
                exportTypes={[{ value: '.csv', label: 'CSV' }, { value: '.json', label: 'JSON' }]}
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={{
                width: '100%',
                margin: '0 auto 10px'
              }}
            >
              <div className={classes.statusButton} style={{ width: '100px' }}>
                <div>
                  <Fab
                    size="small"
                    className={
                      status.find(x => x === 'new') ? classes.newColorFilled : classes.newColor
                    }
                    onClick={() => {
                      onSearchInvoices('new')
                    }}
                  >
                    <AddIcon />
                  </Fab>
                  <span className={classes.statusText}>New</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('processing')
                    }}
                    className={
                      status.find(x => x === 'processing')
                        ? classes.primaryColorFilled
                        : classes.primaryColor
                    }
                  >
                    <TimelapseIcon />
                  </Fab>
                  <span className={classes.statusText}>Processing</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '115px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('failed')
                    }}
                    className={
                      status.find(x => x === 'failed') !== undefined
                        ? classes.warningColorFilled
                        : classes.warningColor
                    }
                  >
                    <PriorityHighIcon />
                  </Fab>
                  <span className={classes.statusText}>Failed</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('invoiceException')
                    }}
                    className={
                      status.find(x => x === 'invoiceException') !== undefined
                        ? classes.exceptionColorFilled
                        : classes.exceptionColor
                    }
                  >
                    <PriorityHighIcon />
                  </Fab>
                  <span className={classes.statusText}>Exception</span>
                </div>
              </div>
              {isMultiAppEnabled ? (
                <div className={classes.statusButton} style={{ width: '145px' }}>
                  <div>
                    <Fab
                      size="small"
                      onClick={() => {
                        onSearchInvoices('movingInvoiceApp')
                      }}
                      className={
                        status.find(x => x === 'movingInvoiceApp') !== undefined
                          ? classes.exceptionColorFilled
                          : classes.exceptionColor
                      }
                    >
                      <PriorityHighIcon />
                    </Fab>
                    <span className={classes.statusText}>Changing</span>
                  </div>
                </div>
              ) : null}
              <div className={classes.statusButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('completed')
                    }}
                    className={
                      status.find(x => x === 'completed')
                        ? classes.successColorFilled
                        : classes.successColor
                    }
                  >
                    <CheckIcon />
                  </Fab>
                  <span className={classes.statusText}>Completed</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '130px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('rejected')
                    }}
                    className={
                      status.find(x => x === 'rejected')
                        ? classes.dangerColorFilled
                        : classes.dangerColor
                    }
                  >
                    <CloseIcon />
                  </Fab>
                  <span className={classes.statusText}>Rejected</span>
                </div>
              </div>
              <div className={classes.statusButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('ignored')
                    }}
                    className={
                      status.find(x => x === 'ignored')
                        ? classes.defaultColorFilled
                        : classes.defaultColor
                    }
                  >
                    <BlockIcon />
                  </Fab>
                  <span className={classes.statusText}>Ignored</span>
                </div>
              </div>
              {/* <div className={classes.statusButton} style={{ width: '145px' }}>
                <div>
                  <Fab
                    size="small"
                    onClick={() => {
                      onSearchInvoices('highPriority')
                    }}
                    className={
                      status.find(x => x === 'highPriority')
                        ? classes.dangerColorFilled
                        : classes.dangerColor
                    }
                  >
                    <div style={{ fontSize: '1.5rem' }}>!!</div>
                  </Fab>
                  <span className={classes.statusText}>High Priority</span>
                </div>
              </div> */}
              <div className={classes.statusButton} style={{ width: '115px', border: 'none' }}>
                <div>
                  <Button
                    size="small"
                    onClick={() => {
                      onSearchInvoices('all')
                    }}
                    style={{ color: '#999' }}
                  >
                    {selectText}
                  </Button>
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer justifyContent="center" style={{ marginTop: '5px' }}>
              <SyncLoader showLoader={Boolean(download)} textLoader={'Downloading File'} />
              {/* <SyncLoader showLoader={isFetching && !isLoading} /> */}
              {isLoadingTenantConfig || appConfigIsLoading || appList.isLoading ? (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
              ) : (
                <GridItem xs={12} sm={12} md={12}>
                  <Button
                    onClick={() => selectAllChange()}
                    style={{
                      marginBottom: '10px',
                      color: '#081c3e'
                    }}
                    disabled={!isEditEnabled}
                  >
                    {selectAllText}
                  </Button>
                  {selectedRows.length > 0 ? (
                    <div
                      style={{
                        color: ' #081c3e',
                        background: 'rgb(225, 227, 231)',
                        minHeight: '64px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: '24px',
                          width: '100%'
                        }}
                      >
                        <h6 style={{ fontSize: '1.25rem' }}>
                          {selectedRows.length} Row(s) selected
                        </h6>
                      </div>
                      <div
                        style={{
                          paddingRight: '8px',
                          width: '100%'
                        }}
                      >
                        <Tooltip title="Forward selected invoices">
                          <Button
                            style={{
                              maxWidth: '40px',
                              maxHeight: '30px',
                              minWidth: '40px',
                              minHeight: '30px'
                            }}
                            size="small"
                            className={
                              classes.floatRight +
                              ' ' +
                              classes.clearAvatar +
                              ' ' +
                              classes.colorPrimary
                            }
                            onClick={() => setShowSendToInboxModal(true)}
                          >
                            <SendIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Reject Invoices">
                          <Button
                            style={{
                              maxWidth: '40px',
                              maxHeight: '30px',
                              minWidth: '40px',
                              minHeight: '30px'
                            }}
                            size="small"
                            className={
                              classes.floatRight +
                              ' ' +
                              classes.clearAvatar +
                              ' ' +
                              classes.colorPrimary
                            }
                            onClick={() => setShowRejectModal(true)}
                          >
                            <BlockIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Reapply Mappings">
                          <Button
                            style={{
                              maxWidth: '40px',
                              maxHeight: '30px',
                              minWidth: '40px',
                              minHeight: '30px'
                            }}
                            size="small"
                            className={
                              classes.floatRight +
                              ' ' +
                              classes.clearAvatar +
                              ' ' +
                              classes.colorPrimary
                            }
                            onClick={() => setShowReapplyModal(true)}
                          >
                            <RestorePageIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  ) : null}
                  <ThemeProvider theme={theme}>
                    <MaterialTable
                      tableRef={tableRef}
                      columns={columns}
                      options={{
                        columnsButton: true,
                        actionsColumnIndex: -1,
                        filtering: true,
                        sorting: true,
                        showTitle: false,
                        emptyRowsWhenPaging: false,
                        pageSize: pageSize,
                        debounceInterval: 500,
                        showTextRowsSelected: false,
                        showSelectAllCheckbox: false,
                        selection: isEditEnabled,
                        selectionProps: rowData => {
                          const selection = {
                            color: 'primary'
                          }
                          if (selectedRows.find(row => row.id === rowData.id)) {
                            selection.checked = true
                          } else {
                            selection.checked = false
                          }
                          selection.disabled = !['invoiceException'].includes(rowData.status)
                          return selection
                        }
                      }}
                      data={query => {
                        const params = buildParams({
                          query,
                          status,
                          highPriorityFlag,
                          historicalInvoiceFlag
                        })
                        filterColumns(query.filters)
                        return getInvoices(params, setInvoices, setCreateError)
                      }}
                      actions={actions}
                      onChangeColumnHidden={hideColumns}
                      onChangeRowsPerPage={pageSize => setPageSize(pageSize)}
                      onSelectionChange={selectRows}
                    />
                  </ThemeProvider>
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <SwitchNewUI />
          <Breadcrumbs views={breadcrumbViews} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Invoice AI</h3>
            </CardHeader>
            <CardBody>{returnInvoicesTab()}</CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />
        <Snackbar
          place="bl"
          color="primary"
          icon={AddAlert}
          message={createInfo.message}
          open={createInfo.isOpen}
          closeNotification={() => setCreateInfo({ ...createInfo, isOpen: false })}
          close
        />
        <ReapplyMappingsModal
          open={showReapplyModal}
          onClose={() => setShowReapplyModal(false)}
          reapplyMappings={onReapplyMappings}
        />
        <RejectInvoicesModal
          open={showRejectModal}
          onClose={() => setShowRejectModal(false)}
          rejectInvoices={onRejectInvoices}
        />
        <SendToAnotherInboxModal
          open={showSendToInboxModal}
          onClose={() => setShowSendToInboxModal(false)}
          sendToAnotherInbox={onSendToAnotherInbox}
          email={appConfig?.data?.params?.ocr?.forwardInvoicesEmail}
        />
      </GridContainer>
    </Fade>
  )
}
