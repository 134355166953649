import React from 'react'
import MaterialTable from 'material-table'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Select, MenuItem } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Fade from '@material-ui/core/Fade'
import ReplayIcon from '@material-ui/icons/Replay'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import SearchIcon from '@material-ui/icons/Search'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import NavPills from 'components/NavPills/NavPills.js'
import Button from 'components/CustomButtons/Button.js'

// constants
import { loadStatus } from 'utils/Constants'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

const useStyles = makeStyles(styles)

export default function DataAssureRetry (props) {
  const classes = useStyles()

  let breadcrumbViews = [
    {
      name: 'Data Assure',
      url: '/admin/dataAssure/index',
      icon: AssessmentIcon
    },
    {
      name: 'Validations',
      url: `/admin/dataAssure/${props.selectedAppId}/transactions`,
      icon: CheckIcon
    }
  ]

  const columns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'validationId',
      title: 'Validation ID'
    },
    {
      field: 'supplierId',
      title: 'Supplier ID',
      render: rowData => (
        <a
          href={rowData.supplierLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {rowData.supplierId}
        </a>
      )
    },
    {
      field: 'date',
      title: 'Date',
      render: rowData => new Date(rowData.date).toLocaleString('en-US')
    },
    {
      field: 'tinValidation',
      title: 'Tin Validations',
      render: rowData =>
        rowData.tinValidation === 'failed' ? (
          <Button style={{ color: 'red', padding: '0' }} simple>
            <CancelIcon fontSize="small" />
            Failed
          </Button>
        ) : (
          <Button style={{ color: 'green', padding: '0' }} simple>
            <CheckCircleIcon fontSize="small" />
            Success
          </Button>
        )
    },
    {
      field: 'banksValidation',
      title: 'Bank Routing Number Validation',
      render: rowData =>
        rowData.banksValidation === 'failed' ? (
          <Button style={{ color: 'red', padding: '0' }} simple>
            <CancelIcon fontSize="small" />
            Failed
          </Button>
        ) : (
          <Button style={{ color: 'green', padding: '0' }} simple>
            <CheckCircleIcon fontSize="small" />
            Success
          </Button>
        )
    },
    {
      field: 'addressValidation',
      title: 'Address Validation',
      render: rowData =>
        rowData.addressValidation === 'failed' ? (
          <Button style={{ color: 'red', padding: '0' }} simple>
            <CancelIcon fontSize="small" />
            Failed
          </Button>
        ) : (
          <Button style={{ color: 'green', padding: '0' }} simple>
            <CheckCircleIcon fontSize="small" />
            Success
          </Button>
        )
    },
    {
      field: 'validationResponse',
      title: 'Questionnaire Response',
      render: rowData =>
        rowData.validationResponse === 'failed' ? (
          <Button style={{ color: 'red', padding: '0' }} simple>
            <CancelIcon fontSize="small" />
            Failed
          </Button>
        ) : (
          <Button style={{ color: 'green', padding: '0' }} simple>
            <CheckCircleIcon fontSize="small" />
            Success
          </Button>
        )
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => loadStatus[rowData.status]
    },
    {
      field: 'details',
      title: 'Details',
      render: rowData => (
        <Tooltip title={'Details'}>
          <IconButton
            aria-label="Details"
            onClick={() =>
              props.setShowModal(true) / props.setModalInfo(rowData.errorLog)
            }
          >
            <MoreHorizIcon fontSize="small" style={{ color: '#081c3e' }} />
          </IconButton>
        </Tooltip>
      )
    }
  ]

  function modal () {
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot
          //paper: classes.modal
        }}
        open={props.showModal === true}
        keepMounted
        //fullWidth={true}
        //maxWidth={'md'}
        onClose={() => props.setShowModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {props.service === 'coupa' ? (
                  <TableCell>Form Id</TableCell>
                ) : (
                  <TableCell>Document Id</TableCell>
                )}
                <TableCell>Validation</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.modalInfo.map((item, i) => (
                <TableRow key={i}>
                  {props.service === 'coupa' ? (
                    <TableCell>
                      <a
                        href={item.formLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.formId}
                      </a>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <a
                        href={item.documentLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.documentId}
                      </a>
                    </TableCell>
                  )}
                  <TableCell>{item.validation}</TableCell>
                  <TableCell>
                    {new Date(item.date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    })}
                  </TableCell>
                  <TableCell>{JSON.stringify(item.description)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => props.setShowModal(false)}
            color="danger"
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function returnValidationsTab () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        {props.selectedAppId ? (
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}></GridItem>
            <GridItem xs={12} sm={6} md={5}>
              <InputLabel className={classes.label}>Status</InputLabel>
              <FormControl fullWidth className={classes.selectFormControl}>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={props.status}
                  onChange={e => props.onFieldChange('status', e.target.value)}
                  inputProps={{
                    name: props.status,
                    id: props.status
                  }}
                >
                  <MenuItem
                    key="failed"
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    style={{
                      overflowX: 'auto',
                      textOverflow: 'ellipsis'
                    }}
                    value="failed"
                  >
                    Failed
                  </MenuItem>
                  <MenuItem
                    key="inProgress"
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    style={{
                      overflowX: 'auto',
                      textOverflow: 'ellipsis'
                    }}
                    value="inProgress"
                  >
                    In Progress
                  </MenuItem>
                  <MenuItem
                    key="success"
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    style={{
                      overflowX: 'auto',
                      textOverflow: 'ellipsis'
                    }}
                    value="success"
                  >
                    Success
                  </MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem md={1}>
              <Fab
                size="small"
                color="primary"
                className={classes.floatRight + ' ' + classes.bgColorPrimary}
                onClick={() => props.onSearchValues()}
              >
                <SearchIcon fontSize="small" />
              </Fab>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={8}>
              <h5 className={classes.sectionTitle}>
                {props.rows ? (
                  <Fab
                    size='small'
                    color='primary'
                    className={
                      classes.floatRight + ' ' + classes.bgColorPrimary
                    }
                    onClick={() => props.onRefreshTransactions()}
                  >
                    <RefreshRoundedIcon fontSize='small' />
                  </Fab>
                ) : null}
              </h5>
            </GridItem> */}
            <GridItem xs={12} sm={12}>
              {props.rows ? (
                <GridContainer justify="center" style={{ marginTop: '5px' }}>
                  {props.transactionIsLoading ? (
                    <div className={classes.reloadSize}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <GridItem xs={12} sm={12} md={12}>
                      <MaterialTable
                        columns={columns}
                        data={props.rows}
                        actions={[
                          rowData => ({
                            icon: () => (
                              <ReplayIcon style={{ color: '#081c3e' }} />
                            ),
                            tooltip: 'Retry',
                            onClick: (event, rowData) => {
                              props.onRetryTransaction(rowData.validationId)
                            },
                            disabled: rowData.status === 'success'
                          })
                        ]}
                        options={{
                          actionsColumnIndex: -1,
                          sorting: true,
                          showTitle: false
                        }}
                      />
                    </GridItem>
                  )}
                  {modal()}
                </GridContainer>
              ) : null}
            </GridItem>
          </GridContainer>
        ) : null}
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={3} className={classes.padding10}>
          <Card className={classes.appBar}>
            <CardHeader>Application List</CardHeader>
            <CardBody style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <List>
                {props.appList.map((prop, key) => {
                  return (
                    <ListItem
                      style={{ paddingLeft: '5px', paddingRight: '5px' }}
                      className={classes.listItem}
                      button
                      selected={props.selectedAppId === prop.env}
                      key={key}
                      onClick={() => props.onAppChanged(prop.env)}
                    >
                      <ListItemAvatar>
                        {props.selectedAppId === prop.env ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={prop.env}
                        secondary={`Type: ${prop.type}`}
                      />
                      {prop.errors && prop.errors > 0 ? (
                        <ListItemText style={{ marginLeft: '4px' }}>
                          <Button
                            className={classes.textErrors}
                            simple
                            onClick={() => props.onErrors(prop.env)}
                          >
                            <ReportProblemIcon fontSize="small" />
                            {prop.errors}{' '}
                            {prop.errors === 1 ? 'Error' : 'Errors'}
                          </Button>
                        </ListItemText>
                      ) : null}
                    </ListItem>
                  )
                })}
              </List>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={9}>
          <Breadcrumbs views={breadcrumbViews} />
          {props.formIsLoading ? (
            <Card>
              <GridContainer justify="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : (
            <Card>
              <CardHeader>
                <h3 className={classes.formHeader}>
                  Data Assure - Validations
                </h3>
              </CardHeader>
              <CardBody>
                <NavPills
                  action={actions => {
                    if (actions) {
                      props.updateHeightFunction(actions.updateHeight)
                    }
                  }}
                  animateHeight={false}
                  color="primary"
                  tabs={[
                    {
                      tabButton: 'Validations',
                      tabContent: returnValidationsTab()
                    }
                  ]}
                />
              </CardBody>
            </Card>
          )}
        </GridItem>
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={props.validationMessage}
          open={props.submitSuccess}
          closeNotification={() => props.setSubmitSuccess(false)}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={props.validationMessage}
          open={props.createError}
          closeNotification={() => props.setCreateError(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
