import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'

//import DataTable from "react-data-table-component";
import MUIDataTable from 'mui-datatables'

import { updateApis } from 'store/QualtricsState'

//services
import { getAppConstantsByApp } from 'services/apiApps'

// style
import style from 'assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle.js'

const mapState = state => ({
  apis: state.qualtrics.apis
})

const columns = [
  {
    name: 'name',
    label: 'Journey',
    options: {
      filter: true,
      sort: true
    }
  }
]

let selectedViews = []
let selectedViewsApi = []
let selectedApis = {}
let journeysArray = []

class views extends React.Component {
  constructor (props) {
    super(props)
    this.state = props.initialState
  }

  sendState () {
    return this.state
  }

  isValidated () {
    if (this.state.views.length > 0) {
      return true
    }
    return false
  }

  async getConstants () {
    const constants = await getAppConstantsByApp('qualtrics', 'supplier')

    if (constants.success) {
      journeysArray = constants.data.journeys
        ? constants.data.journeys.map(journey => {
          let views = []
          let apis = {}
            let eventKeys = Object.keys(journey.event)
            eventKeys.forEach(key => {
              views = [...views, ...journey.event[key].views]
              apis = { ...apis, ...journey.event[key].apis }
            })
            return {
              id: journey.name,
              name: journey.name,
              description: journey.description,
              views: [...new Set(views)],
              apis: { ...apis }
            }
          })
        : []
    }
  }

  componentDidMount () {
    this.getConstants()
  }

  apisChanged(apis, changedApis) {
    const apisKeys = Object.keys(apis)
    const changedApisKeys = Object.keys(changedApis)
    if (apisKeys.length !== changedApisKeys.length) {
      return false;
    }
  
    let key
    for (key of apisKeys) {
      if (apisKeys[key] !== changedApisKeys[key]) {
        return false;
      }
    }
  
    return true;
  }

  render () {
    const { classes } = this.props
    const selectedRows = this.state.views
      ? [...new Set(this.state.views.map(view => {
          return journeysArray.findIndex(item => {
            let itemSelected = null
            for (let i = 0; i < item.views.length; i++) {
              if (item.views[i].view === view) {
                itemSelected = item
              }
            }
            return itemSelected
          })
        })) ]
      : []

    const options = {
      download: false,
      filter: false,
      print: false,
      expandableRows: true,
      expandableRowsOnClick: true,
      viewColumns: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRows,
      renderExpandableRow: (rowData, rowMeta) => {
        const colSpan = rowData.length + 1
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <p>{journeysArray[rowMeta.rowIndex].description}</p>
              <div>
                <b>Views:</b>
                <ol>
                  {journeysArray[rowMeta.rowIndex].views.map(view => (
                    <li key={view.view}>{view.view}</li>
                  ))}
                </ol>
              </div>
            </TableCell>
          </TableRow>
        )
      },
      onRowSelectionChange: (
        currentRowsSelected,
        allRowsSelected,
        rowsSelected
      ) => {
        selectedViews = []
        selectedViewsApi = []
        selectedApis = {}
        rowsSelected.forEach(row => {
          journeysArray[row].views.forEach(view => {
            selectedViews.push(view.view)
            selectedViewsApi.push({ ...view })
          })
          selectedApis = { ...selectedApis, ...journeysArray[row].apis }
        })
        this.props.updateApis(selectedApis)
        this.setState({ views: [...new Set(selectedViews)] })
        this.setState({ viewsApi: [...new Map(selectedViewsApi.map(item => [item.view, item])).values()] })
      },
      selectableRowsOnClick: true
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Journeys configuration</h4>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer className={classes.appRow}>
                  <GridItem xs={12} sm={12}>
                    <MUIDataTable
                      title={'Journeys'}
                      data={journeysArray}
                      columns={columns}
                      options={options}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }
}

views.propTypes = {
  classes: PropTypes.object
}

export default connect(mapState, { updateApis })(withStyles(style)(views))
