import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// models
import { destination as destinationSchema } from "models/nitorInsightsModel";
import { validateProperty, validateObject } from "utils/validator";

//Constants
import { ConnectionTypes } from "utils/Constants";

// style
import style from "assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js";

const fieldsByType = {
    s3: [
        {
            key: "bucketName",
            label: "Bucket Name",
            type: "text",
            disabled: true,
        },
        { key: "path", label: "Path", type: "text", disabled: false },
        {
            key: "fileExtension",
            label: "File Extension",
            type: "select",
            values: [
                { label: "JSON", key: ".json" },
                { label: "CSV", key: ".csv" },
                { label: "TXT", key: ".txt" },
                { label: "ZIP", key: ".zip" }
            ],
        },
        { key: "unzip", label: "Unzip", type: "boolean" },
    ],
    sharepoint: [
        { key: "siteUrl", label: "Site Url", type: "text", disabled: true },
        { key: "path", label: "Path", type: "text", disabled: false },
        {
            key: "fileExtension",
            label: "File Extension",
            type: "select",
            values: [
                { label: "JSON", key: ".json" },
                { label: "CSV", key: ".csv" },
                { label: "TXT", key: ".txt" },
                { label: "ZIP", key: ".zip" }
            ],
        },
        { key: "unzip", label: "Unzip", type: "boolean" },
    ],
    qualtrics: [
        { key: "siteUrl", label: "Site Url", type: "text", disabled: true },
        {
            key: "fileExtension",
            label: "File Extension",
            type: "select",
            values: [
                { label: "JSON", key: ".json" },
                { label: "CSV", key: ".csv" },
                { label: "TXT", key: ".txt" },
            ],
        },
        { key: "unzip", label: "Unzip", type: "boolean" },
    ],
    sapHana: [{ key: "dbHost", label: "Host", type: "text", disabled: true }],
    msSql: [{ key: "dbName", label: "Name", type: "text", disabled: true }],
    sftp: [
        { key: 'host', label: 'Host', type: 'text', disabled: true },
        { key: 'userName', label: 'Username', type: 'text', disabled: true },
        { key: 'port', label: 'Port', type: 'text', disabled: true },
        {
            key: 'encryptionMethod',
            label: 'Encryption Method',
            type: 'text',
            disabled: true
        },
        { key: 'path', label: 'Path', type: 'text', disabled: false },
        {
            key: "fileExtension",
            label: "File Extension",
            type: "select",
            values: [
                { label: "JSON", key: ".json" },
                { label: "CSV", key: ".csv" },
                { label: "TXT", key: ".txt" },
            ],
        },
        { key: "singleConnection", label: "Single Connection", type: "boolean", disabled: true },
        { key: "unzip", label: "Unzip", type: "boolean" },
    ]
};

class connectivity extends React.Component {
    constructor(props) {
        super(props);

        this.state = props.initialState;
    }
    sendState() {
        return this.state;
    }

    booleanInput(input) {
        const { classes } = this.props;
        return (
            <GridContainer key={input.key}>
                <GridItem xs={12} sm={6}>
                    {input.disabled ? (
                        <FormControlLabel
                            classes={{
                                label: classes.label,
                                labelPlacementStart: classes.activeToggle,
                            }}
                            label={input.label}
                            labelPlacement="start"
                            control={
                                <Switch
                                    disabled={true}
                                    checked={this.state.remoteConnections[this.state.remoteConnection][input.key]}
                                    value={this.state.remoteConnections[this.state.remoteConnection][input.key]}
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar,
                                    }}
                                />
                            }
                        />
                    ) : (
                        <FormControlLabel
                            classes={{
                                label: classes.label,
                                labelPlacementStart: classes.activeToggle,
                            }}
                            label={input.label}
                            labelPlacement="start"
                            control={
                                <Switch
                                    disabled={!this.state.enabled}
                                    checked={this.state[input.key]}
                                    onChange={(event) =>
                                        this.onFieldChange(
                                            input.key,
                                            event.target.checked
                                        )
                                    }
                                    value={this.state[input.key]}
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.switchChecked,
                                        thumb: classes.switchIcon,
                                        track: classes.switchBar,
                                    }}
                                />
                            }
                        />
                    )}
                </GridItem>
            </GridContainer>
        );
    }

    textInput(input) {
        const { classes } = this.props;
        return (
            <GridContainer key={input.key}>
                <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                        {input.label}
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    {input.disabled ? (
                        <CustomInput
                            disabled={true}
                            labelText={<span>{input.label}</span>}
                            id={input.key}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: this.state.remoteConnections[
                                    this.state.remoteConnection
                                ][input.key],
                            }}
                        />
                    ) : (
                        <CustomInput
                            success={
                                this.state[input.key + "State"] === "successs"
                            }
                            error={this.state[input.key + "State"] === "error"}
                            labelText={
                                <span>
                                    {input.label + " "}
                                    <small>
                                        {this.state[input.key + "State"] ===
                                        "error"
                                            ? "(required)"
                                            : "*"}{" "}
                                    </small>
                                </span>
                            }
                            id={input.key}
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                onChange: (event) =>
                                    this.onFieldChange(
                                        input.key,
                                        event.target.value
                                    ),
                                value: this.state[input.key],
                                disabled: !this.state.enabled
                            }}
                        />
                    )}
                </GridItem>
            </GridContainer>
        );
    }

    selectInput(input) {
        const { classes } = this.props;
        return (
            <GridContainer key={input.key}>
                <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                        {input.label}
                    </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                    >
                        <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                        >
                            {input.label}
                        </InputLabel>
                        <Select
                            MenuProps={{
                                className: classes.selectMenu,
                            }}
                            classes={{
                                select: classes.select,
                                disabled: classes.disabled,
                            }}
                            value={this.state[input.key]}
                            onChange={(e) =>
                                this.onFieldChange(input.key, e.target.value)
                            }
                            inputProps={{
                                name: input.key,
                                id: input.key,
                                disabled: !this.state.enabled
                            }}
                        >
                            {input.values.map((value) => (
                                <MenuItem
                                    key={value.key}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                            classes.selectMenuItemSelected,
                                    }}
                                    style={{
                                        overflowX: "auto",
                                        textOverflow: "ellipsis",
                                    }}
                                    value={value.key}
                                >
                                    {value.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
        );
    }

    TypeForm(connection) {
        let fields = [];
        if (connection.type) {
            fields = fieldsByType[connection.type];
        }

        return (
            <GridItem xs={12} sm={12} md={12}>
                {fields.map((field) => this.inputByType(field))}
            </GridItem>
        );
    }

    inputByType(field) {
        switch (field.type) {
            case "boolean":
                return this.booleanInput(field);
            case "select":
                return this.selectInput(field);
            default:
                return this.textInput(field);
        }
    }

    onFieldChange(field, value) {
        this.setState({ [field]: value });
        let stateCopy = this.state;
        stateCopy[field] = value;

        let isValid = validateProperty(destinationSchema, stateCopy, field)
            .isValid;
        if (isValid) {
            this.setState({ [field + "State"]: "success" });
        } else {
            this.setState({ [field + "State"]: "error" });
        }

        if (field === "remoteConnection") {
            const type = this.state.remoteConnections[value].type;
            fieldsByType[type].forEach((field) => {
                let key = field.key;
                this.setState({
                    [key]: this.state.remoteConnections[value][key],
                });
            });
        }
    }

    isValidated() {
        if (!this.state.enabled) {
            return false
        }
        let validation = validateObject(destinationSchema, this.state);

        if (!validation.isValid) {
            let field;
            for (field in this.state) {
                if (validation.errors[field]) {
                    this.setState({ [field + "State"]: "error" });
                }
            }
        }

        return validation.isValid;
    }

    render() {
        const { classes } = this.props;
        return (
            <GridItem xs={12} sm={12} md={12}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12}>
                        <h4 className={classes.infoText}>
                            Destination Connection
                        </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                        <h5 className={classes.sectionTitle}>Destination</h5>
                        <GridItem xs={12} sm={12} md={12}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={6}>
                                            <FormLabel
                                                className={
                                                    classes.inputLabelHorizontal
                                                }
                                            >
                                                Destination
                                            </FormLabel>
                                        </GridItem>
                                        <GridItem xs={12} sm={6}>
                                            <FormControl
                                                fullWidth
                                                className={
                                                    classes.selectFormControl
                                                }
                                            >
                                                <InputLabel
                                                    htmlFor="simple-select"
                                                    className={
                                                        classes.selectLabel
                                                    }
                                                >
                                                    Destination
                                                </InputLabel>
                                                <Select
                                                    MenuProps={{
                                                        className:
                                                            classes.selectMenu,
                                                    }}
                                                    classes={{
                                                        select: classes.select,
                                                    }}
                                                    value={
                                                        this.state
                                                            .remoteConnection
                                                    }
                                                    onChange={(e) =>
                                                        this.onFieldChange(
                                                            "remoteConnection",
                                                            e.target.value
                                                        )
                                                    }
                                                    inputProps={{
                                                        name:
                                                            "remoteConnection",
                                                        id: "remoteConnection",
                                                        disabled: !this.state.enabled
                                                    }}
                                                >
                                                    {Object.keys(
                                                        this.state
                                                            .remoteConnections
                                                    ).map((key) => (
                                                        <MenuItem
                                                            key={key}
                                                            classes={{
                                                                root:
                                                                    classes.selectMenuItem,
                                                                selected:
                                                                    classes.selectMenuItemSelected,
                                                            }}
                                                            style={{
                                                                overflowX:
                                                                    "auto",
                                                                textOverflow:
                                                                    "ellipsis",
                                                            }}
                                                            value={key}
                                                        >
                                                            {
                                                                this.state
                                                                    .remoteConnections[
                                                                    key
                                                                ].name
                                                            }
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                                {this.state.remoteConnections[
                                    this.state.remoteConnection
                                ] ? (
                                    <GridItem xs={12} sm={12} md={12}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={6}>
                                                <FormLabel
                                                    className={
                                                        classes.inputLabelHorizontal
                                                    }
                                                >
                                                    Type Destination
                                                </FormLabel>
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <CustomInput
                                                    disabled={true}
                                                    labelText={
                                                        <span>
                                                            Type Destination
                                                        </span>
                                                    }
                                                    id="typeDestination"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                        value:
                                                            ConnectionTypes[
                                                                this.state
                                                                    .remoteConnections[
                                                                    this.state
                                                                        .remoteConnection
                                                                ].type
                                                            ],
                                                        disabled: !this.state.enabled
                                                    }}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                ) : null}
                                {this.state.remoteConnections[
                                    this.state.remoteConnection
                                ]
                                    ? this.TypeForm(
                                          this.state.remoteConnections[
                                              this.state.remoteConnection
                                          ]
                                      )
                                    : null}
                            </GridContainer>
                        </GridItem>
                    </GridItem>
                </GridContainer>
            </GridItem>
        );
    }
}

connectivity.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(style)(connectivity);
