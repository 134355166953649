import { useQuery, useMutation, useQueryClient } from 'react-query'
import {
  getMonitorsDescriptions,
  getMonitorsTransactionsData,
  getTransactionsDataById,
  getProcessDiagramData,
  deleteStepHistory
} from 'services/apiApps'
import { getUserTenant } from 'utils/functions'

export function useGetMonitorsDetails() {
  const tenantId = getUserTenant()
  return useQuery(
    ['monitors'],
    () =>
      getMonitorsDescriptions(tenantId).then(result => {
        const rows = result.data.map(row => {
          let newRow = { header: {} }
          newRow.header['monitorId'] = row.monitorId
          newRow.header['app'] = row.app
          newRow.header['environment'] = row.environment
          newRow.header['lastUpdate'] = row.lastUpdate
          newRow.header['description'] = row.description
          newRow.header['type'] = row.type
          return newRow.header
        })
        return { rows }
      }),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false
    }
  )
}

export function useGetTransactionData({ env, monitorId, status, appId }) {
  const tenantId = getUserTenant()
  return useQuery(
    [
      'transactions',
      // eslint-disable-next-line
      status.reduce((acc, curr) => ((acc[curr] = curr), acc), {}),
      tenantId,
      env,
      appId,
      monitorId
    ],
    () =>
      getMonitorsTransactionsData(tenantId, env, monitorId, status, appId).then(
        result => {
          const rows = result.data.map(row => {
            let newRow = { header: {} }
            newRow.header['monitorId'] = row.monitorId
            newRow.header['transactionDate'] = row.transactionDate
            newRow.header['processId'] = row.processId
            newRow.header['status'] = row.status
            newRow.header['lastUpdate'] = row.lastUpdate
            newRow.header['transactionId'] = row.transactionId
            newRow.header['description'] = row.description
            newRow.header['lastStepInfo'] = row.lastStepInfo
            return newRow.header
          })
          return { rows }
        }
      ),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false
    }
  )
}

export function useGetTransactionDataById({
  processId,
  env,
  appId,
  monitorId
}) {
  const tenantId = getUserTenant()
  return useQuery(
    ['processes', tenantId, env, appId, monitorId, processId],
    () =>
      getTransactionsDataById(tenantId, processId, env, appId, monitorId).then(
        result => {
          const rows = result.data.rows.map(row => {
            let newRow = { header: {} }
            newRow.header['lastUpdateDate'] = row.lastUpdateDate
            newRow.header['creationDate'] = row.creationDate
            newRow.header['stepKey'] = row.stepKey
            newRow.header['status'] = row.status
            newRow.header['history'] = row.history
            newRow.header['stepName'] = row.stepName
            newRow.header['stepOrder'] = row.stepOrder
            newRow.header['isStep'] = row.isStep
            newRow.header['monitorId'] = row.monitorId
            return newRow.header
          })

          return {
            rows
          }
        }
      ),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    }
  )
}

export function useGetDiagramData({ processId, env, appId, monitorId }) {
  const tenantId = getUserTenant()
  return useQuery(
    ['diagrams'],
    () =>
      getProcessDiagramData(tenantId, processId, env, appId, monitorId).then(
        result => {
          let newDiagramColors = {
            successColor: result.data.successColor,
            warningColor: result.data.warningColor,
            failureColor: result.data.failureColor
          }

          return { data: result.data.data, colors: newDiagramColors }
        }
      ),
    {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    }
  )
}

export function useDeleteStepHistory() {
  const queryClient = useQueryClient()
  return useMutation(
    values => {
      return deleteStepHistory(values.id)
    },
    {
      onMutate: async deletedInfo => {
        const { id, processId } = deletedInfo
        await queryClient.cancelQueries(['processes', processId])
        const previousValue = queryClient.getQueryData(['processes', processId])
        const newValue = { ...previousValue.rows[0] }
        const newHistory = newValue.history.filter(item => {
          return item.id !== id
        })
        newValue.history = newHistory
        const value = {}
        value.rows = []
        value.rows.push(newValue)
        // Optimistically update to the new value
        queryClient.setQueryData(['processes', processId], value)

        // Return a rollback function
        return () => {
          queryClient.setQueryData(['processes', processId], previousValue)
        }
      }
    }
  )
}
