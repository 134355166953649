import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import CheckIcon from '@material-ui/icons/Check'
import AssessmentIcon from '@material-ui/icons/Assessment'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import NavPills from 'components/NavPills/NavPills.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import CustomLogComponent from 'components/CustomLogComponent/CustomLogComponent.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'

const useStyles = makeStyles(styles)

function jsUcfirst (string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export default function QualtricsLogs (props) {
  const classes = useStyles()

  let breadcrumbViews = [
    {
        name: "Relish Qualtrics",
        url: "/admin/qualtrics/index",
        icon: AssessmentIcon,
    },
    {
        name: jsUcfirst(props.typeApp) + " Connection",
        url: `/admin/qualtrics/${props.typeApp}/${props.selectedAppId}`,
        icon: CheckIcon,
    },
    {
        name: "Load Logs",
        url: `/admin/qualtrics/logs/${props.typeApp}/${props.selectedAppId}`,
        icon: CheckIcon,
    },
];

  function returnLogsTab () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        {props.idLoad && props.selectedAppId ? (
          <CustomLogComponent
            transactionId={props.idLoad}
            environmentId={props.selectedAppId}
            app='insights'
            enabled={true}
          />
        ) : null}
      </GridItem>
    )
  }

  function returnTabsToDisplay () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>Relish Qualtrics - Logs</h3>
          </CardHeader>
          <CardBody>
            <NavPills
              action={actions => {
                if (actions) {
                  props.updateHeightFunction(actions.updateHeight)
                }
              }}
              animateHeight={false}
              color='primary'
              tabs={[
                {
                  tabButton: 'Logs',
                  tabContent: returnLogsTab()
                }
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    )
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {props.appList.map((prop, key) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    button
                    selected={props.selectedAppId === prop.env}
                    key={key}
                    onClick={() => props.onAppChanged(prop.env)}
                  >
                    <ListItemAvatar>
                      {props.selectedAppId === prop.env ? (
                        <Avatar>
                          <TouchApp />
                        </Avatar>
                      ) : (
                        <Avatar className={classes.clearAvatar}></Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={prop.appName}
                      secondary={`Type: ${prop.type}`}
                    />
                  </ListItem>
                )
              })}
            </List>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        {props.formIsLoading ? (
          <Card>
            <GridContainer justify='center'>
              <div className={classes.circularProgress}>
                <CircularProgress color='inherit' />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
      <Snackbar
        place='br'
        color='success'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.submitSuccess}
        closeNotification={() => props.setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place='bl'
        color='danger'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.createError}
        closeNotification={() => props.setCreateError(false)}
        close
      />
    </GridContainer>
  )
}
