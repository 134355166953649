import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { useQueryClient } from 'react-query'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { CircularProgress } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

// @material-ui/icons
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import AssessmentIcon from '@material-ui/icons/Assessment'
import Add from '@material-ui/icons/PlaylistAdd'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled'
// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Tooltip from '@material-ui/core/Tooltip'
import Button from 'components/CustomButtons/Button.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import InvoiceOriginConfigurationForm from './components/InvoiceOriginConfigurationForm'
import { ConfirmationModal } from '../../components/Invoice/ConfirmationModal'
// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Constants
import { useGetAppList } from 'hooks/useApp'

import {
  useGeAllOriginConfigurations,
  useDeleteOriginConfiguration,
  useUpdateEmailOriginConfiguration
} from 'hooks/useInvoiceOrigin'

import { InvoiceOriginOptions } from 'utils/Constants'

const useStyles = makeStyles(styles)

export default function InvoiceOriginConfiguration(props) {
  const appName = 'invoiceAI'
  const classes = useStyles()
  // eslint-disable-next-line
  const [emailIntegrationAction, setEmailIntegrationAction] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showConfigurationForm, setConfigurationForm] = useState(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [selectedAppId, setSelectedAppId] = useState(props.match.params.id)
  const [invoiceOriginConfig, setInvoiceOriginConfig] = useState(undefined)
  const [showEnableReadEmailsModal, setEnableReadEmailsModal] = useState(false)
  const queryClient = useQueryClient()
  const { data: appList, isLoading: isLoadingAppList } = useGetAppList(appName)
  const resultOriginConfigurations = useGeAllOriginConfigurations(selectedAppId ?? '')
  const enableReadEmails = useUpdateEmailOriginConfiguration()
  //const createEmailConfig = useCreateEmailConfiguration()
  const deleteOriginConfiguration = useDeleteOriginConfiguration()
  let breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAI/configurations',
      icon: AssessmentIcon
    },
    {
      name: 'Invoice Origin',

      url: `/admin/invoiceAI/additionalConfigurations/invoiceOrigin/${selectedAppId}`,
      icon: MailOutlineIcon
    }
  ]

  const onAppChanged = id => {
    setSelectedAppId(id)
    props.history.push(`/admin/invoiceAI/additionalConfigurations/invoiceOrigin/${selectedAppId}`)
  }

  useEffect(() => {
    if (resultOriginConfigurations.isError) {
      setValidationMessage(
        resultOriginConfigurations.error.response?.data?.message ||
          'Something went wrong, try again later'
      )
      setShowErrorAlert(true)
    }
  }, [resultOriginConfigurations.isError, resultOriginConfigurations.error])

  //#endregion

  const columns = [
    {
      title: 'No.',
      width: 10,
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'emailProvider',
      title: 'Identifier',
      render: rowData =>
        rowData.origin === 'email'
          ? rowData.identifier
          : InvoiceOriginOptions[rowData.identifier].label ?? rowData.identifier
    },
    {
      field: 'defaultProcess',
      title: 'Default process',
      render: rowData => rowData.defaultProcess
    }
  ]
  const onDeleteEmailConfig = data => {
    deleteOriginConfiguration.mutate(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['invoiceOrigin', 'all', selectedAppId])
        },
        onSuccess: response => {
          showValidationMessage('success', 'Configuration deleted')
          setShowDeleteConfirmationModal(false)
        },
        onError: error => {
          showValidationMessage(
            'error',
            error.response?.data?.message ?? 'Something went wrong, try again later'
          )
        }
      }
    )
  }
  // eslint-disable-next-line
  const onDeleteHandler = () => {
    setInvoiceOriginConfig(undefined)

    const body = {
      appId: selectedAppId ?? '',
      origin: InvoiceOriginOptions[invoiceOriginConfig.origin].origin
    }
    onDeleteEmailConfig({ id: invoiceOriginConfig.id, data: body })
  }

  const showValidationMessage = (type, message) => {
    if (type === 'success') {
      setShowSuccessAlert(true)
    } else {
      setShowErrorAlert(true)
    }
    setValidationMessage(message)
  }
  const onEnableReadEmails = () => {
    enableReadEmails.mutate(
      {
        data: {
          id: invoiceOriginConfig?.id,
          data: {
            appId: selectedAppId,
            id: invoiceOriginConfig?.id ?? '',
            skipEmailProcessing: invoiceOriginConfig?.skipEmailProcessing
          }
        }
      },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['invoiceOrigin', 'all', selectedAppId])
        },
        onSuccess: response => {
          setEnableReadEmailsModal(false)
          showValidationMessage('success', 'Configuration updated')
        },
        onError: error => {
          showValidationMessage(
            'error',
            error.response?.data?.message ?? 'Something went wrong, try again later'
          )
        }
      }
    )
  }
  const actionsOriginTable = () => {
    const actions = [
      rowData => ({
        icon: () => <EditIcon />,
        tooltip: 'Edit',
        onClick: (event, rowData) => {
          setConfigurationForm(true)
          setEmailIntegrationAction('edit')
          //const data = transformData(rowData)
          setInvoiceOriginConfig({
            id: rowData.id,
            origin:
              rowData.origin === 'api'
                ? InvoiceOriginOptions[rowData.identifier].key
                : rowData.origin
          })
        }
      }),
      rowData => ({
        icon: () => <DeleteIcon />,
        tooltip: 'Delete',
        onClick: (event, rowData) => {
          setShowDeleteConfirmationModal(true)
          setInvoiceOriginConfig({
            id: rowData.id,
            origin:
              rowData.origin === 'api'
                ? InvoiceOriginOptions[rowData.identifier].key
                : rowData.origin
          })
        }
      }),
      rowData => ({
        hidden: rowData.origin !== 'email',
        icon: () =>
          rowData.skipEmailProcessing ? <PlayCircleFilledIcon /> : <PauseCircleFilledIcon />,
        tooltip: rowData.skipEmailProcessing ? `Play` : 'Pause',
        onClick: (event, rowData) => {
          setEnableReadEmailsModal(true)
          setInvoiceOriginConfig({
            id: rowData.id,
            origin:
              rowData.origin === 'api'
                ? InvoiceOriginOptions[rowData.identifier].key
                : rowData.origin,
            skipEmailProcessing: rowData.skipEmailProcessing ? false : true
          })
        }
      })
    ]

    return actions
  }

  const contentEmailIntegration = (
    <Card>
      <CardHeader>
        <h3 className={classes.formHeader}>Invoice AI - Invoice Origin</h3>
      </CardHeader>
      <CardBody>
        <h5 className={classes.sectionTitle}>
          Connections
          {!showConfigurationForm ? (
            <>
              <Tooltip title="Create new connection">
                <Button
                  justIcon
                  round
                  simple
                  color="primary"
                  onClick={() => {
                    setConfigurationForm(true)
                    setEmailIntegrationAction('create')
                  }}
                >
                  <Add className={classes.icons} />
                </Button>
              </Tooltip>
            </>
          ) : null}
        </h5>
        {!showConfigurationForm ? (
          <GridItem xs={12} sm={12} md={12}>
            <MaterialTable
              columns={columns}
              data={resultOriginConfigurations.data?.originConfigurations || []}
              actions={actionsOriginTable()}
              isLoading={
                resultOriginConfigurations.isLoading ||
                resultOriginConfigurations.isFetching ||
                deleteOriginConfiguration.isLoading
              }
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false,
                emptyRowsWhenPaging: false
              }}
            />
          </GridItem>
        ) : null}
        {showConfigurationForm ? (
          <GridItem xs={12} sm={12} md={12}>
            <InvoiceOriginConfigurationForm
              onClose={() => {
                setConfigurationForm(false)
                setInvoiceOriginConfig(undefined)
              }}
              appId={selectedAppId}
              data={invoiceOriginConfig}
              action={emailIntegrationAction}
              showValidationMessage={showValidationMessage}
              //onCreateEmailConfig={onCreateEmailConfig}
              //onUpdateEmailConfig={onUpdateEmailConfig}
            />
          </GridItem>
        ) : null}
      </CardBody>
    </Card>
  )

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {appList
                ? appList.map((prop, key) => {
                    return (
                      <ListItem
                        className={classes.listItem}
                        button
                        selected={selectedAppId === prop.env}
                        key={key}
                        onClick={() => onAppChanged(prop.env)}
                      >
                        <ListItemAvatar>
                          {selectedAppId === prop.env ? (
                            <Avatar>
                              <TouchApp />
                            </Avatar>
                          ) : (
                            <Avatar className={classes.clearAvatar}></Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                      </ListItem>
                    )
                  })
                : null}
            </List>
          </CardBody>
        </Card>
      </GridItem>

      <GridItem xs={12} sm={12} md={12} lg={9}>
        {isLoadingAppList ? (
          <Card>
            <GridContainer justifyContent="center" alignItems="center">
              <div className={classes.circularProgress}>
                <CircularProgress color="inherit" />
              </div>
            </GridContainer>
          </Card>
        ) : (
          <>
            <Breadcrumbs views={breadcrumbViews} />
            {contentEmailIntegration}
          </>
        )}
      </GridItem>

      <Snackbar
        place="bl"
        color="success"
        icon={AddAlert}
        message={validationMessage}
        open={showSuccessAlert}
        closeNotification={() => setShowSuccessAlert(false)}
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={validationMessage}
        open={showErrorAlert}
        closeNotification={() => setShowErrorAlert(false)}
        close
      />

      <ConfirmationModal
        title={'Are you sure you want to delete the email configuration?'}
        showModal={showDeleteConfirmationModal}
        isLoading={deleteOriginConfiguration.isLoading}
        onClose={() => setShowDeleteConfirmationModal(false)}
        dialogActions={
          <>
            <Button
              onClick={() => {
                onDeleteHandler(invoiceOriginConfig)
              }}
              color="primary"
              variant="outlined"
            >
              Delete
            </Button>
            <Button onClick={() => setShowDeleteConfirmationModal(false)} color="secondary">
              Close
            </Button>
          </>
        }
      />
      <ConfirmationModal
        title={`Are you sure you want to ${
          invoiceOriginConfig?.skipEmailProcessing ? 'Pause' : 'play'
        } the email configuration?`}
        isLoading={enableReadEmails.isLoading}
        showModal={showEnableReadEmailsModal}
        onClose={() => setEnableReadEmailsModal(false)}
        dialogActions={
          <>
            <Button
              onClick={() => {
                onEnableReadEmails()
              }}
              color="primary"
              variant="outlined"
            >
              Accept
            </Button>
            <Button onClick={() => setEnableReadEmailsModal(false)} color="secondary">
              Close
            </Button>
          </>
        }
      />
    </GridContainer>
  )
}
