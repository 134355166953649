import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import { Select, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { CircularProgress } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CustomTreeTable from '../../components/CustomTreeTable/CustomTreeTable'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'

// react component for creating dynamic tables

import SweetAlert from 'react-bootstrap-sweetalert'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import Info from '@material-ui/icons/Info'
import Map from '@material-ui/icons/Map'
import Edit from '@material-ui/icons/Edit'
import Close from '@material-ui/icons/Close'
import Message from '@material-ui/icons/Message'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import NavPills from '../../components/NavPills/NavPills.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import InputAdornment from '@material-ui/core/InputAdornment'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'

import { MessagesTypes } from 'utils/Constants'

let swipeableActions
const useStyles = makeStyles(styles)

function getQuestionnaireModalForm (classes, props) {
  let formBody
  const commonBody = (
    <>
      <CustomInput
        success={props.modalFormState.uniqueName === 'success'}
        error={props.modalFormState.uniqueName === 'error'}
        labelText={'Unique Name*'}
        id='inputUniqueName'
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: event => {
            props.onFormChange('uniqueName', event.target.value)
          },
          type: 'text',
          value: props.modalForm.uniqueName,
          endAdornment:
            props.modalFormState['uniqueName'] === 'error' ? (
              <InputAdornment position='end'>
                <Close className={classes.error} />
              </InputAdornment>
            ) : (
              undefined
            )
        }}
      />
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
          Answer Type
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu,
            style: { zIndex: 5555 }
          }}
          classes={{
            select: classes.select,
            disabled: classes.disabled
          }}
          value={props.modalForm.answerType}
          onChange={e => props.onFormChange('answerType', e.target.value)}
          inputProps={{
            name: 'answerType',
            id: 'answerType'
          }}
        >
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
            value={'tax'}
          >
            Tax
          </MenuItem>
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
            value={'generic'}
          >
            Generic
          </MenuItem>
        </Select>
      </FormControl>

      <CustomInput
        success={props.modalFormState.lName === 'success'}
        error={props.modalFormState.lName === 'error'}
        labelText={'Supplier Name*'}
        id='inputLName'
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: event => {
            props.onFormChange('lName', event.target.value)
          },
          type: 'text',
          value: props.modalForm.lName,
          endAdornment:
            props.modalFormState['lName'] === 'error' ? (
              <InputAdornment position='end'>
                <Close className={classes.error} />
              </InputAdornment>
            ) : (
              undefined
            )
        }}
      />
      <CustomInput
        success={props.modalFormState.supplierType === 'success'}
        error={props.modalFormState.supplierType === 'error'}
        labelText={'Supplier Type*'}
        id='inputSupplierType'
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: event => {
            props.onFormChange('supplierType', event.target.value)
          },
          type: 'text',
          value: props.modalForm.supplierType,
          endAdornment:
            props.modalFormState['supplierType'] === 'error' ? (
              <InputAdornment position='end'>
                <Close className={classes.error} />
              </InputAdornment>
            ) : (
              undefined
            )
        }}
      />
      <CustomInput
        success={props.modalFormState.feinValue === 'success'}
        error={props.modalFormState.feinValue === 'error'}
        labelText={'FEIN Value*'}
        id='inputFeinValue'
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          onChange: event => {
            props.onFormChange('feinValue', event.target.value)
          },
          type: 'text',
          value: props.modalForm.feinValue,
          endAdornment:
            props.modalFormState['feinValue'] === 'error' ? (
              <InputAdornment position='end'>
                <Close className={classes.error} />
              </InputAdornment>
            ) : (
              undefined
            )
        }}
      />
    </>
  )
  switch (props.modalForm.answerType) {
    case 'tax':
      formBody = (
        <>
          {commonBody}
          <CustomInput
            success={props.modalFormState.tax === 'success'}
            error={props.modalFormState.tax === 'error'}
            labelText={'Tax*'}
            id='inputTax'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => {
                props.onFormChange('tax', event.target.value)
              },
              type: 'text',
              value: props.modalForm.tax,
              endAdornment:
                props.modalFormState['tax'] === 'error' ? (
                  <InputAdornment position='end'>
                    <Close className={classes.error} />
                  </InputAdornment>
                ) : (
                  undefined
                )
            }}
          />
        </>
      )
      break
    case 'generic':
      formBody = (
        <>
          {commonBody}
          <CustomInput
            success={props.modalFormState.country === 'success'}
            error={props.modalFormState.country === 'error'}
            labelText={'Country*'}
            id='inputCountry'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => {
                props.onFormChange('country', event.target.value)
              },
              type: 'text',
              value: props.modalForm.country,
              endAdornment:
                props.modalFormState['country'] === 'error' ? (
                  <InputAdornment position='end'>
                    <Close className={classes.error} />
                  </InputAdornment>
                ) : (
                  undefined
                )
            }}
          />
          <CustomInput
            success={props.modalFormState.ssn === 'success'}
            error={props.modalFormState.ssn === 'error'}
            labelText={'SSN*'}
            id='inputSSN'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => {
                props.onFormChange('ssn', event.target.value)
              },
              type: 'text',
              value: props.modalForm.ssn,
              endAdornment:
                props.modalFormState['ssn'] === 'error' ? (
                  <InputAdornment position='end'>
                    <Close className={classes.error} />
                  </InputAdornment>
                ) : (
                  undefined
                )
            }}
          />
          <CustomInput
            success={props.modalFormState.fein === 'success'}
            error={props.modalFormState.fein === 'error'}
            labelText={'FEIN*'}
            id='inputFEIN'
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => {
                props.onFormChange('fein', event.target.value)
              },
              type: 'text',
              value: props.modalForm.fein,
              endAdornment:
                props.modalFormState['fein'] === 'error' ? (
                  <InputAdornment position='end'>
                    <Close className={classes.error} />
                  </InputAdornment>
                ) : (
                  undefined
                )
            }}
          />
          <GridContainer justify='center'>
            <GridItem xs={12} sm={6}>
              <GridItem xs={12} sm={6}></GridItem>
              <GridItem xs={12} sm={6}></GridItem>
            </GridItem>
          </GridContainer>
        </>
      )
      break

    default:
      break
  }

  return formBody
}

function modal (classes, props) {
  let alertMessage = null
  if (props.modal.visible) {
    let title
    let onConfirm
    let body
    switch (props.modal.type) {
      case 'delete':
        alertMessage = (
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title='Are you sure you want to delete the questionnaire?'
            onConfirm={() =>
              props.onQuestionnaireDelete(swipeableActions.updateHeight)
            }
            onCancel={() => props.onHideModal()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText='Yes, I want to delete it'
            cancelBtnText='Cancel'
            showCancel
          >
            {props.modal.name}
          </SweetAlert>
        )
        break
      case 'edit':
        switch (props.modal.object) {
          case 'questionnaire':
            title = 'Edit questionnaire'
            onConfirm = () => props.onQuestionnaireEdit()
            body = getQuestionnaireModalForm(classes, props)
            break
          case 'message':
            title = 'Edit message'
            onConfirm = () => props.onMessageEdit()
            body = (
              <CustomInput
                success={props.modalFormState.message === 'success'}
                error={props.modalFormState.message === 'error'}
                labelText={'Display message'}
                id='inputUniqueName'
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => {
                    props.onFormChange('message', event.target.value)
                  },
                  type: 'text',
                  value: props.modalForm.message,
                  endAdornment:
                    props.modalFormState['message'] === 'error' ? (
                      <InputAdornment position='end'>
                        <Close className={classes.error} />
                      </InputAdornment>
                    ) : (
                      undefined
                    )
                }}
              />
            )
            break
          default:
            break
        }
        alertMessage = (
          <SweetAlert
            style={{ display: 'block', overflow: 'auto', maxHeight: '600px' }}
            title={title}
            onConfirm={onConfirm}
            onCancel={() => props.onHideModal()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText='Confirm'
            cancelBtnText='Cancel'
            showCancel
          >
            {props.modal.name}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {body}
              </GridItem>
            </GridContainer>
          </SweetAlert>
        )
        break
      case 'create':
        title = 'Create questionnaire'
        onConfirm = () =>
          props.onQuestionnaireCreate(swipeableActions.updateHeight)
        body = getQuestionnaireModalForm(classes, props)

        alertMessage = (
          <SweetAlert
            style={{ display: 'block', overflow: 'auto', maxHeight: '600px' }}
            title={title}
            onConfirm={onConfirm}
            onCancel={() => props.onHideModal()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText='Confirm'
            cancelBtnText='Cancel'
            showCancel
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                {body}
              </GridItem>
            </GridContainer>
          </SweetAlert>
        )
        break

      default:
        break
    }
  }

  return alertMessage
}

export default function TinValidationView (props) {
  const classes = useStyles()

  function returnConfigTab () {
    let dataTableInfo = Object.keys(props.configurationForm.mappings).map(
      (prop, key) => {
        const mapping = props.configurationForm.mappings[prop]
        return {
          id: key,
          name: mapping.uniqueName,
          sendResponseToQuestionnaire: (
            <div className='actions-center'>
              <Switch
                checked={mapping.SendResponseToQuestionnaire}
                onChange={event =>
                  props.onQuestionnaireChange(
                    'SendResponseToQuestionnaire',
                    event.target.checked,
                    prop
                  )
                }
                value='Active'
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
              />
            </div>
          ),
          actions: (
            // we've added some custom button actions
            <div className='actions-right'>
              <Tooltip title={'Edit questionnaire'}>
                <Button
                  color={'warning'}
                  simple
                  justIcon
                  className={classes.actionButton}
                  onClick={() => {
                    props.onShowModal(
                      'edit',
                      'questionnaire',
                      prop,
                      mapping.uniqueName
                    )
                  }}
                >
                  <Edit className={classes.icon} />
                </Button>
              </Tooltip>
              <Tooltip title={'Delete questionnaire'}>
                <Button
                  color={'danger'}
                  simple
                  justIcon
                  className={classes.actionButton}
                  onClick={() => {
                    props.onShowModal(
                      'delete',
                      'questionnaire',
                      prop,
                      mapping.uniqueName
                    )
                  }}
                >
                  <Close className={classes.icon} />
                </Button>
              </Tooltip>
            </div>
          )
        }
      }
    )

    return (
      <CustomTreeTable
        onSave={props.onConfigSave}
        transformCode={'sm'}
        onClickAdd={() => {
          props.onShowModal('create', 'questionnaire', null)
        }}
        formLabel={
          <>
            Add all Ariba SLP Questionnaires that require a
            <strong>Tin Validation</strong>.
          </>
        }
        transformedColumnName={'Questionnaire'}
        formTitle={'SLP Questionnaire'}
        isLoadingSave={props.isLoadingSave}
        addTooltipText={'Create new questionnaire'}
        tableTitle={'Questionnaires'}
        tableIcon={<Map />}
        data={dataTableInfo}
        mainAccessor={'name'}
        columns={[
          {
            Header: 'Questionnaire',
            accessor: 'name'
          },
          {
            Header: 'Send response to questionnaire?',
            accessor: 'sendResponseToQuestionnaire',
            sortable: false,
            filterable: false
          },
          {
            Header: 'Actions',
            accessor: 'actions',
            sortable: false,
            filterable: false
          }
        ]}
        defaultPageSize={10}
        showPaginationTop
        minRows={0}
        showPaginationBottom={false}
        className='-striped -highlight'
      />
    )
  }

  function returnParamsTab () {
    function returnMessagesTable () {
      let dataTableInfo = Object.keys(
        props.parametersForm.validationMessages.tinNameCode
      ).map((prop, key) => {
        let message = props.parametersForm.validationMessages.tinNameCode[prop]
        return {
          id: message.id,
          enabled: (
            <div className='actions-center'>
              <Switch
                checked={message.active}
                onChange={event => {
                  props.onQuestionChange(
                    'active',
                    event.target.checked,
                    prop,
                    'tinNameCode'
                  )
                }}
                value='Active'
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
              />
            </div>
          ),
          type: MessagesTypes[message.type],
          visible: (
            <div className='actions-center'>
              <Switch
                checked={message.visible}
                onChange={event =>
                  props.onQuestionChange(
                    'visible',
                    event.target.checked,
                    prop,
                    'tinNameCode'
                  )
                }
                value='Visible'
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
              />
            </div>
          ),
          description: message.description,
          message: message.message,
          actions: (
            // we've added some custom button actions
            <div className='actions-right'>
              <Tooltip title={'Edit message'}>
                <Button
                  color={'warning'}
                  simple
                  justIcon
                  className={classes.actionButton}
                  onClick={() => {
                    props.onShowModal(
                      'edit',
                      'message',
                      message.id,
                      message.description
                    )
                  }}
                >
                  <Edit className={classes.icon} />
                </Button>
              </Tooltip>
            </div>
          )
        }
      })

      return (
        <CustomTreeTable
          onSave={props.onParamsSave}
          isLoadingSave={props.isLoadingSave}
          transformWidth={800}
          formLabel={
            'The following parameters control the behavior of the Ariba Tin Validation API.'
          }
          transformedColumnName={'Questionnaire'}
          formTitle={'API Validation Parameters'}
          addTooltipText={'Create new questionnaire'}
          tableTitle={'Validation Messages'}
          tableIcon={<Message />}
          data={dataTableInfo}
          filterable
          columns={[
            {
              Header: 'Enabled',
              accessor: 'enabled',
              sortable: false,
              filterable: false
            },
            {
              Header: 'Type',
              accessor: 'type'
            },
            {
              Header: 'Visible',
              accessor: 'visible',
              sortable: false,
              filterable: false
            },
            {
              Header: 'Description',
              accessor: 'description'
            },
            {
              Header: 'Message',
              accessor: 'message'
            },
            {
              Header: 'Actions',
              accessor: 'actions',
              sortable: false,
              filterable: false
            }
          ]}
          mainAccessor={'description'}
          defaultPageSize={5}
          showPaginationTop
          minRows={0}
          showPaginationBottom={false}
          className='-striped -highlight'
        />
      )
    }

    return (
      <GridItem xs={12} sm={12}>
        {returnMessagesTable()}
      </GridItem>
    )
  }

  function returnTabsToDisplay () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <GridItem xs={6}>
            <Button
              onClick={() => {
                if (props.backButton) props.backButton()
              }}
            >
              <ArrowBackIcon />
              {props.backText}
            </Button>
          </GridItem>
          <CardHeader>
            <h3 className={classes.formHeader}>
              Nitor Data Assure - Tin Validation
            </h3>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color='behance'
                simple
                className={classes.documentation}
                href='/admin/dataAssure/documentation'
                target='_blank'
              >
                <Info className={classes.icons} />
                Download Documentation
              </Button>
            </GridItem>
          </CardHeader>
          <CardBody>
            <NavPills
              action={actions => {
                swipeableActions = actions
              }}
              animateHeight
              color='primary'
              tabs={[
                {
                  tabButton: 'Configuration',
                  tabContent: returnConfigTab()
                },
                {
                  tabButton: 'Parameters',
                  tabContent: returnParamsTab()
                }
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    )
  }

  /*let breadcrumbViews = [
    {
      name: 'Data Assure',
      url: '/admin/dataAssure/index',
      icon: AssignmentTurnedInIcon
    },
    {
      name: 'Tin Validation',
      url: `/admin/dataAssure/tinValidation/${props.selectedAppId}`,
      icon: AttachMoneyIcon
    }
  ]*/
  return (
    <GridContainer>
      {modal(classes, props)}
      {/* <GridItem xs={12} sm={12} md={12} lg={3} >
                <Card className={classes.appBar}>
                    <CardHeader>
                        Application List
                    </CardHeader>
                    <CardBody>
                        <List>
                            {props.appList.map((prop, key) => {
                                return (
                                    <ListItem
                                        className={classes.listItem}
                                        button
                                        selected={props.selectedAppId === prop.env}
                                        key={key}
                                        onClick={() => props.onAppChanged(prop.env)}
                                    >
                                        <ListItemAvatar>

                                            {
                                                props.selectedAppId === prop.env ?
                                                    <Avatar >
                                                        <TouchApp />
                                                    </Avatar>
                                                    :
                                                    <Avatar className={classes.clearAvatar}>
                                                    </Avatar>
                                            }

                                        </ListItemAvatar>
                                        <ListItemText primary={prop.env} secondary={`Type: ${prop.type}`} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </CardBody>
                </Card>
                       
            </GridItem> */}
      <GridItem xs={12} sm={12} md={12} lg={12}>
        {/* <Breadcrumbs views={breadcrumbViews} /> */}
        {props.formIsLoading ? (
          <Card>
            <GridContainer justify='center'>
              <div className={classes.circularProgress}>
                <CircularProgress color='inherit' />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
      <Snackbar
        place='br'
        color='success'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.submitSuccess}
        closeNotification={() => props.setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place='bl'
        color='danger'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.createError}
        closeNotification={() => props.setCreateError(false)}
        close
      />
    </GridContainer>
  )
}
