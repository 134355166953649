import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
// import dayjs from 'dayjs'
// Material UI
import { CardActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// Assets
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
// import poweredByRelish from 'assets/img/POC/powered-by-relish.png'
import dunsNBradstreetLogo from 'assets/img/POC/poweredByRelishNDnBV2.png'

// Components
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import { useQueryParams } from 'hooks/POC/CoupaIFrameApp/useQueryParams'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

// Services
import { getDnBDashboard } from 'services/apiDnbConnector'

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  },
  cardCategory: {
    color: '#999999',
    marginTop: '10px'
  },
  cardCategoryWhite: {
    margin: '0',
    color: 'rgba(255, 255, 255, 0.8)',
    fontSize: '.875rem'
  }
}

const useStyles = makeStyles(styles)

function CardApp({ title, type, values: attributes }) {
  const classes = useStyles()

  return (
    <GridItem xs={12} sm={6} md={4} style={{ marginTop: '10px' }}>
      <Card style={{ height: '100%' }}>
        <CardHeader>
          <CardText
            style={{
              backgroundColor: '#325a87',
              padding: '5px'
            }}
          >
            <h5 className={classes.cardTitleWhite}>{`${title}`}</h5>
          </CardText>
        </CardHeader>
        <CardBody>
          <table>
            {attributes.map(attribute => (
              <tr key={attribute.label}>
                <td style={{ color: '#3c78c8', verticalAlign: 'top' }}>{attribute.label}</td>
                <td style={{ color: 'primary', paddingLeft: '10px' }}>{attribute.data}</td>
              </tr>
            ))}
          </table>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default function DnbDaDashboardApp(props) {
  const classes = useStyles()
  const query = useQueryParams()
  const supplierId = query.get('object_id')
  const { dashboardId, appId } = useParams()

  const [state, setState] = useState({
    showLoader: false,
    supplierName: '',
    supplierId: null,
    companyInformation: [],
    snackBarMessage: null,
    snackBarShowSuccess: false,
    snackBarShowError: false,
    alert: null,
    error: null,
    duns: null
  })

  const {
    isLoading: loadingSupplierInformation,
    isFetching: fetchingSupplierInformation
  } = useQuery(
    ['SupplierInformation', 'dnbConnector-supplierInformation'],
    () => getDnBDashboard(supplierId, appId, dashboardId).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        if (data) {
          setState(old => ({
            ...old,
            ...data
          }))
        }
      },
      onError: result => {
        setState(old => ({
          ...old,
          error: `${result.status}: ${result.error}`
        }))
      }
    }
  )

  useEffect(() => {
    setState(old => ({
      ...old,
      showLoader: loadingSupplierInformation || fetchingSupplierInformation
    }))
  }, [loadingSupplierInformation, fetchingSupplierInformation])

  return (
    <GridContainer
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '30px',
        paddingBottom: '30px',
        width: '100%',
        margin: '0'
      }}
    >
      {state.alert}
      <GridItem xs={12}>
        {(state.showLoader && <SyncLoader showLoader={true} />) ||
          (state.error && <h1>{state.error}</h1>) || (
            <Card>
              <CardHeader style={{ paddingBottom: '0px' }}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <CardText style={{ backgroundColor: '#3C78C8' }}>
                      <h4 className={classes.cardTitleWhite}>{`${state.supplierName}`}</h4>
                    </CardText>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4
                          color="primary"
                          style={{ float: 'right', marginBottom: '0px', marginTop: '0px' }}
                        >{`D-U-N-S# ${state.duns}`}</h4>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <GridContainer>
                  {state.companyInformation.map(company => (
                    <CardApp key={company.title} {...company} />
                  ))}
                </GridContainer>
              </CardBody>
              <CardActions>
                <GridContainer>
                  <GridItem xs={6} style={{ textAlign: 'left' }}>
                    <a
                      href="https://relishiq.com/solutions/relish-data-assure/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: '#3c78c8' }}
                    >
                      <img
                        src={dunsNBradstreetLogo}
                        alt="Go to Relish"
                        style={{ maxWidth: '500px' }}
                      />
                    </a>
                  </GridItem>
                </GridContainer>
              </CardActions>
            </Card>
          )}
      </GridItem>
    </GridContainer>
  )
}
