import React, { useEffect, useState } from 'react'
import MaterialTable, { MTableToolbar } from 'material-table'
import PropTypes from 'prop-types'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Fade from '@material-ui/core/Fade'
import ApplicationListApp from 'components/ApplicationList/ApplicationListApp.jsx'

// @material-ui/icons
import Add from '@material-ui/icons/PlaylistAdd'
import AddAlert from '@material-ui/icons/AddAlert'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Button from 'components/CustomButtons/Button.js'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

//constants
import { GetApp } from '@material-ui/icons'
import { getDownloadS3Url, transformOpReadDetails } from 'services/apiDataAssure.js'
import { transformOpReadBatchesPag } from 'services/apiDataAssure.js'
import { transformOpReadTransactionsPag } from 'services/apiDataAssure.js'
import { useQuery } from 'react-query'
import DaBatchNew from './DaBatchNew.jsx'
import { DABulkTemplate } from 'utils/Constants.js'
import { Link } from '@material-ui/core'

const useStyles = makeStyles(styles)

function TabPanel(props) {
  const { children, tabPanelTable, currentTable, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={currentTable !== tabPanelTable}
      id={`simple-tabpanel-${tabPanelTable}`}
      aria-labelledby={`simple-tab-${tabPanelTable}`}
      {...other}
    >
      {currentTable === tabPanelTable && <>{children}</>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  tabPanelTable: PropTypes.number.isRequired,
  currentTable: PropTypes.number.isRequired
}

export default function DaBatchMainApp(props) {
  const classes = useStyles()

  const TABLES = {
    BATCHES: 0,
    TRANSACTIONS: 1,
    DETAILS: 2
  }

  /*  Pagination  */
  const [batchesPageSize, setBatchesPageSize] = useState(10)
  const [batchesCurrentPage, setBatchesCurrentPage] = useState(0)
  const [batchesTotal, setBatchesTotal] = useState(0)
  const [transactionsPageSize, setTransactionsPageSize] = useState(10)
  const [transactionsCurrentPage, setTransactionsCurrentPage] = useState(0)
  const [transactionsTotal, setTransactionsTotal] = useState(0)

  const [selectedView, setSelectedView] = useState('main')
  const [selectedAppId, setSelectedAppId] = useState('')
  const [selectedTable, setSelectedTable] = useState(TABLES.BATCHES)
  const [selectedBatch, setSelectedBatch] = useState(null)
  const [selectedTransaction, setSelectedTransaction] = useState(null)
  const [batches, setBatches] = useState([])
  const [transactions, setTransactions] = useState([])
  const [details, setDetails] = useState([])
  const [validationMessage, setValidationMessage] = useState('')
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false)
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false)
  const [isLoadingToolbar, setIsLoadingToolbar] = useState(false)

  const { isFetching: isFetchingBatches, refetch: refetchBatches } = useQuery(
    ['Batches', selectedAppId, batchesPageSize, batchesCurrentPage],
    () =>
      transformOpReadBatchesPag(
        selectedAppId,
        'spreadsheet',
        null,
        batchesPageSize,
        batchesCurrentPage
      ),
    {
      onSuccess: response => {
        if (response) {
          const responseData =
            response.data && response.data.items && response.data.items.length > 0
              ? response.data.items
              : []
          setBatches(responseData)
          setBatchesTotal(response.data.total)
        }
      },
      initialData: [],
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: Boolean(selectedAppId)
    }
  )

  const { isFetching: isFetchingTransactions, refetch: refetchTransactions } = useQuery(
    [
      'Transactions',
      selectedBatch?.spreadsheetBatchId,
      transactionsPageSize,
      transactionsCurrentPage
    ],
    () =>
      transformOpReadTransactionsPag(
        selectedAppId,
        'spreadsheet',
        selectedBatch.spreadsheetBatchId,
        transactionsPageSize,
        transactionsCurrentPage
      ),
    {
      onSuccess: response => {
        if (response) {
          setTransactions(response.data.items)
          setTransactionsTotal(response.data.total)
        }
      },
      initialData: [],
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: Boolean(selectedBatch)
    }
  )

  const { isFetching: isFetchingDetails } = useQuery(
    ['Details', selectedTransaction?.spreadsheetTransactionId],
    () => transformOpReadDetails(selectedAppId, selectedTransaction.transactionId),
    {
      onSuccess: response => {
        if (response) {
          const transaction = response.data
          const parsedDetails = transaction.validations
            .map(v =>
              [
                ...v.output.messages.errors.map(m => ({
                  type: 'error',
                  message: m,
                  externalId: v.output.externalId
                })),
                ...v.output.messages.warnings.map(m => ({
                  type: 'warning',
                  message: m,
                  externalId: v.output.externalId
                })),
                ...v.output.messages.info.map(m => ({
                  type: 'info',
                  message: m,
                  externalId: v.output.externalId
                })),
                ...v.output.messages.bypass.map(m => ({
                  type: 'bypass',
                  message: m,
                  externalId: v.output.externalId
                }))
              ].map(m => ({
                externalId: m.externalId,
                validationType: v.type,
                messageType: m.type,
                message: m.message
              }))
            )
            .flatMap(m => m)

          setDetails(parsedDetails)
        }
      },
      initialData: [],
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      enabled: Boolean(selectedTransaction)
    }
  )

  useEffect(() => {
    if (selectedView === 'main') {
      setSelectedTable(0)
    }
  }, [selectedView])

  const onAppChanged = appId => {
    setSelectedView('main')
    setBatches([])
    setSelectedAppId(appId)
  }

  const refreshTransactions = () => {
    setTransactions([])
    refetchTransactions()
  }

  const downloadBatch = async () => {
    setIsLoadingToolbar(true)
    if (selectedBatch.batchExportS3ObjectKey) {
      const downloadUrl = await getDownloadS3Url(
        'spreadsheet',
        selectedBatch.batchExportS3ObjectKey
      )
      console.log(`url: ${downloadUrl.data.url}`)
      window.open(downloadUrl.data.url, '_blank')
    } else {
      setValidationMessage('File is not ready yet, please try again later')
      setShowErrorSnackbar(true)
      setTimeout(() => {
        setValidationMessage('')
        setShowErrorSnackbar(false)
      }, 3000)
    }
    setIsLoadingToolbar(false)
  }

  const renderTransactionResult = result => {
    return (
      (result === 'FAILED' && (
        <Button style={{ color: 'red', padding: '0' }} simple>
          <CancelIcon fontSize="small" />
          Failed
        </Button>
      )) ||
      (result === 'PASSED WITH CAUTIONS' && (
        <Button style={{ color: '#9f9f00', padding: '0' }} simple>
          <CheckCircleIcon fontSize="small" />
          Passed with cautions
        </Button>
      )) ||
      (result === 'PASSED' && (
        <Button style={{ color: 'green', padding: '0' }} simple>
          <CheckCircleIcon fontSize="small" />
          Passed
        </Button>
      )) ||
      (result === 'BYPASS' && (
        <Button style={{ color: 'green', padding: '0' }} simple>
          <CheckCircleIcon fontSize="small" />
          Bypass
        </Button>
      )) ||
      ((result === 'NOT VALIDATED' || !result) && (
        <Button style={{ color: 'gray', padding: '0' }} simple>
          <CheckCircleIcon fontSize="small" />
          Not validated
        </Button>
      ))
    )
  }

  const batchColumns = [
    {
      title: 'Id',
      field: 'spreadsheetBatchId'
    },
    {
      title: 'Date',
      field: 'createdDate',
      render: rowData => new Date(rowData.createdDate).toLocaleString('en-US'),
      customSort: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
    },
    {
      title: 'File name',
      field: 'fileName',
      render: rowData => rowData.inputS3Files[0]?.fileName
    },
    {
      title: 'User',
      field: 'createdBy'
    },
    {
      title: 'Status',
      field: 'status'
    },
    {
      title: 'Result',
      field: 'result'
    },
    {
      title: 'Transactions',
      field: 'transactions',
      render: rowData => (
        <Tooltip title={'Transactions'}>
          <IconButton
            aria-label="Transactions"
            onClick={() => {
              console.log(`Selected BatchId: ${rowData.spreadsheetBatchId}`)
              setTransactions([])
              setSelectedTable(TABLES.TRANSACTIONS)
              setSelectedBatch(rowData)
            }}
          >
            <MoreHorizIcon fontSize="small" style={{ color: '#081c3e' }} />
          </IconButton>
        </Tooltip>
      )
    }
  ]

  const transactionColumns = [
    {
      title: 'Id',
      field: 'spreadsheetTransactionId',
      customSort: (a, b) => Number(a.spreadsheetTransactionId) - Number(b.spreadsheetTransactionId)
    },
    {
      title: 'Date',
      field: 'createdDate',
      render: rowData => new Date(rowData.createdDate).toLocaleString('en-US'),
      customSort: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
    },
    {
      title: 'Address result',
      field: 'validationSummary',
      render: rowData => {
        const result = rowData.validationSummary.find(v => v.type === 'address')?.result
        return renderTransactionResult(result)
      }
    },
    {
      title: 'Bank result',
      field: 'validationSummary',
      render: rowData => {
        const result = rowData.validationSummary.find(v => v.type === 'bank')?.result
        return renderTransactionResult(result)
      }
    },
    {
      title: 'Sanction list result',
      field: 'validationSummary',
      render: rowData => {
        const result = rowData.validationSummary.find(v => v.type === 'sanctionList')?.result
        return renderTransactionResult(result)
      }
    },
    {
      title: 'Tax result',
      field: 'validationSummary',
      render: rowData => {
        const result = rowData.validationSummary.find(v => v.type === 'tax')?.result
        return renderTransactionResult(result)
      }
    },
    {
      title: 'Details',
      field: 'details',
      render: rowData => {
        const isButtonEnabled = Boolean(rowData.transactionId)
        const buttonMessage =
          (isButtonEnabled && 'Details') || 'Transaction does not have detail yet.'
        return (
          <Tooltip title={buttonMessage}>
            <div>
              <IconButton
                aria-label={buttonMessage}
                onClick={() => {
                  console.log(`Selected TransactionId: ${rowData.transactionId}`)
                  setDetails([])
                  setSelectedTable(TABLES.DETAILS)
                  setSelectedTransaction(rowData)
                }}
                disabled={!isButtonEnabled}
                style={isButtonEnabled ? {} : { opacity: 0.4 }}
              >
                <MoreHorizIcon fontSize="small" style={{ color: '#081c3e' }} />
              </IconButton>
            </div>
          </Tooltip>
        )
      }
    }
  ]

  const detailColumns = [
    {
      title: 'Id',
      field: 'externalId'
    },
    {
      title: 'Validation type',
      field: 'validationType'
    },
    {
      title: 'Message type',
      field: 'messageType'
    },
    {
      title: 'Message',
      field: 'message'
    }
  ]

  const buttonStyle = {
    maxWidth: '40px',
    maxHeight: '30px',
    minWidth: '40px',
    minHeight: '30px'
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={3} className={classes.padding10}>
          <ApplicationListApp callbackAppChanged={onAppChanged}></ApplicationListApp>
        </GridItem>
        {selectedAppId && (
          <GridItem xs={12} sm={12} md={12} lg={9} className={classes.padding10}>
            {(selectedView === 'main' && (
              <>
                <TabPanel tabPanelTable={TABLES.BATCHES} currentTable={selectedTable}>
                  <MaterialTable
                    title="Batches"
                    columns={batchColumns}
                    data={batches}
                    options={{
                      actionsColumnIndex: -1,
                      pageSize: batchesPageSize,
                      pageSizeOptions: [5, 10, 20, 50, 100]
                    }}
                    page={batchesCurrentPage}
                    totalCount={batchesTotal}
                    onChangePage={page => setBatchesCurrentPage(page)}
                    onChangeRowsPerPage={pageSize => setBatchesPageSize(pageSize)}
                    isLoading={isFetchingBatches || isLoadingToolbar}
                    components={{
                      Toolbar: props => (
                        <div style={{ backgroundColor: '#e8eaf5' }}>
                          <MTableToolbar {...props} />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: '10px'
                            }}
                          >
                            <div>
                              <Tooltip title="Upload batch file">
                                <Button
                                  color="primary"
                                  onClick={e => setSelectedView('new')}
                                  style={buttonStyle}
                                >
                                  <Add />
                                </Button>
                              </Tooltip>
                            </div>
                            <div>
                              <Link href={DABulkTemplate.urlFile}>
                                {'Download Batch Upload Template'}
                              </Link>
                            </div>
                            <div>
                              <Tooltip title="Refresh">
                                <Button style={buttonStyle} onClick={refetchBatches}>
                                  <RefreshRoundedIcon />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                </TabPanel>
                <TabPanel tabPanelTable={TABLES.TRANSACTIONS} currentTable={selectedTable}>
                  <MaterialTable
                    title="Transactions"
                    columns={transactionColumns}
                    data={transactions}
                    options={{
                      actionsColumnIndex: -1,
                      pageSize: transactionsPageSize,
                      pageSizeOptions: [5, 10, 20, 50, 100]
                    }}
                    page={transactionsCurrentPage}
                    totalCount={transactionsTotal}
                    onChangePage={page => setTransactionsCurrentPage(page)}
                    onChangeRowsPerPage={pageSize => setTransactionsPageSize(pageSize)}
                    isLoading={isFetchingTransactions || isLoadingToolbar}
                    components={{
                      Toolbar: props => (
                        <div style={{ backgroundColor: '#e8eaf5' }}>
                          <MTableToolbar {...props} />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: '10px'
                            }}
                          >
                            <div>
                              <Tooltip title="Return to batches">
                                <IconButton
                                  onClick={e => {
                                    setSelectedBatch(null)
                                    setSelectedTable(TABLES.BATCHES)
                                  }}
                                  style={buttonStyle}
                                >
                                  <KeyboardBackspaceIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title="Download Batch Upload Results">
                                <Button
                                  color="primary"
                                  className={classes.addButton}
                                  onClick={downloadBatch}
                                  style={buttonStyle}
                                >
                                  <GetApp />
                                </Button>
                              </Tooltip>
                            </div>
                            <div>
                              <Tooltip title="Refresh">
                                <Button style={buttonStyle} onClick={refreshTransactions}>
                                  <RefreshRoundedIcon />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                </TabPanel>
                <TabPanel tabPanelTable={TABLES.DETAILS} currentTable={selectedTable}>
                  <MaterialTable
                    title="Details"
                    columns={detailColumns}
                    data={details}
                    options={{
                      actionsColumnIndex: -1,
                      pageSize: 10,
                      pageSizeOptions: [5, 10, 20, 50, 100]
                    }}
                    isLoading={isFetchingDetails || isLoadingToolbar}
                    components={{
                      Toolbar: props => (
                        <div style={{ backgroundColor: '#e8eaf5', display: 'grid' }}>
                          <MTableToolbar {...props} />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px'
                            }}
                          >
                            <div>
                              <Tooltip title="Return to transactions">
                                <IconButton
                                  onClick={e => {
                                    setSelectedTransaction(null)
                                    setSelectedTable(TABLES.TRANSACTIONS)
                                  }}
                                  style={buttonStyle}
                                >
                                  <KeyboardBackspaceIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <div style={{ flex: 'auto' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <div></div>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      )
                    }}
                  />
                </TabPanel>
              </>
            )) ||
              (selectedView === 'new' && (
                <DaBatchNew
                  setSelectedView={setSelectedView}
                  selectedAppId={selectedAppId}
                ></DaBatchNew>
              ))}
          </GridItem>
        )}
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={validationMessage}
          open={showSuccessSnackbar}
          closeNotification={() => setShowSuccessSnackbar(false)}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={validationMessage}
          open={showErrorSnackbar}
          closeNotification={() => setShowErrorSnackbar(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
