import axios from 'axios'
import store from 'store'
import { signOut } from 'store/AuthState'
import { ApiAdminInvoice } from 'utils/Constants'
import { createHeaders, getUserTenant } from 'utils/functions'

export async function getAppConfigurationByAppIdQuery(app, env) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Payments
*/

/*
Get the Payments by app
*/
export async function getPaymentsQuery({ status, appId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/payments`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: { status: `${status}` }
  })
}

/*
Get the Payment by id
*/
export async function getPaymentQuery({ paymentId, appId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/payment/${paymentId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getPresignedUrlPaymentFilesQuery({ appId, paymentId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/payments/${paymentId}/files/presignedurl`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Reprocess invoice
*/
export async function changePaymentStatusQuery({ app, id, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/payments/${id}/status`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
Textract invoice
*/
export async function getInvoiceTextract({ invoiceId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/ocr/invoices/${invoiceId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Textract invoice
*/
export async function putInvoiceTextract({ appId, invoiceId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/${invoiceId}/textract`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
Textract invoice
*/
export async function putResetInvoiceTextract({ appId, invoiceId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/${invoiceId}/textract/reset`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
Process in batch
*/
export async function putProcessInvoicesBatch({ appId, body, type }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/textract/batch/${type}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
Textract invoice
*/
export async function putValidationInvoice({ appId, invoiceId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/${invoiceId}/ocr/validation`
  let requestHeaders = createHeaders()

  body.submitPlatform = 'web-app'
  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
get text rekognition
*/
export async function postTextRekognition({ appId, invoiceId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/${invoiceId}/rekognition`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function getInvoicesEmailQuery(recordId, appId) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/email/${recordId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Get the invoices by app
*/
export async function getInvoicesByAppIdUseQueryV2({ params, appId }) {
  let tenant = getUserTenant()
  const filters = encodeURIComponent(JSON.stringify(params.filters))
  let url = `${ApiAdminInvoice.EndPoint}/v3/tenants/${tenant}/apps/${appId}/invoices?filters=${filters}&pageSize=${params.pageSize}&page=${params.page}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getInvoicesByAppIdUseQuery(status, appId) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/relational/v2/tenants/${tenant}/apps/${appId}/invoices`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: { status: `${status}` }
  })
}

/*
Get the invoices by app
*/
export async function getInvoicesRequest({ params }) {
  let tenant = getUserTenant()
  const filters = encodeURIComponent(JSON.stringify(params.filters))
  const orderBy = encodeURIComponent(JSON.stringify(params.orderBy))
  let url = `${ApiAdminInvoice.EndPoint}/relational/v1/tenants/${tenant}/invoices?filters=${filters}&pageSize=${params.pageSize}&page=${params.page}&orderBy=${orderBy}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Get the invoices by app
*/
export async function getInvoices(params, setInvoices, setCreateError) {
  return new Promise(async (resolve, reject) => {
    let retryCount = 0
    let doRetry = true
    let isError = false
    let result = { data: { invoices: [], total: 0 } }
    do {
      try {
        result = await getInvoicesRequest({
          params
        })

        isError = false
        doRetry = false
      } catch (error) {
        isError = true
        if (error.response?.status >= 500) {
          retryCount++
          if (retryCount > 5) {
            doRetry = false
          }
        } else {
          doRetry = false
        }
      }
    } while (doRetry !== false)

    if (isError) {
      setCreateError({
        isOpen: true,
        message: 'Unable to get invoices, try again later'
      })
      resolve({
        data: [],
        page: 0,
        totalCount: []
      })
    } else {
      const rows = result.data.invoices.map(row => {
        return {
          status: row.status,
          substatus: row.substatus,
          id: row.id,
          process: row.process,
          processingDate: row.processingDate,
          relatedDocumentId: row.relatedDocumentId,
          orderId: row.orderId,
          origin: row.origin,
          subProcess: row.subProcess,
          invoiceId: row.invoiceId,
          invoiceDate: row.invoiceDate,
          supplierId: row.supplierId,
          supplierName: row.supplierName,
          invoiceType: row.invoiceType,
          cfdiStatus: row.cfdiStatus,
          invoicePayments: row.invoicePayments,
          linkAriba: row.linkAriba,
          highPriority: row.highPriorityFlag,
          historicalInvoice: row.historicalInvoiceFlag,
          appId: row.appId,
          isLocked: row.isLocked
        }
      })
      setInvoices(rows)
      resolve({
        data: rows,
        page: params.page,
        totalCount: result.data.total
      })
    }
  })
}

/*
Get the invoice by invoice id
*/
export async function getInvoiceByInvoiceIdQuery({ invoiceId }) {
  let url = `${ApiAdminInvoice.EndPoint}/invoices/${invoiceId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getInvoiceImportHistoryQuery(appId) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/history`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getPresignedurlAttachmentsQuery({ appId, invoiceId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/${invoiceId}/files/presignedurl`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function postImportInvoicesQuery(appId, appBody) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/import`
  let requestHeaders = createHeaders()
  requestHeaders['Content-Type'] = 'multipart/form-data'

  const formData = new FormData()
  formData.append('file', appBody)
  formData.append('fileName', appBody.name)
  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: formData
  })
}

export async function changeInvoiceAiStatusQuery(app, id, params) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/invoices/${id}/status`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: params
  })
}

/*
Reprocess invoice
*/
export async function changeInvoiceAiStatus(app, id, params) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/invoices/${id}/status`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: params
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`changeInvoiceAiStatus returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 204) {
        return { success: true }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

export function newLoadRunQuery({ app, env, id }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${id}/run`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export function postDownloadDocumentsQuery({ app, type, extension, historicalInvoiceFlag }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/documents/download`
  let requestHeaders = createHeaders()
  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: {
      type,
      extension,
      historicalInvoiceFlag
    }
  })
}

export function getDownloadDocumentsQuery({ app, documentId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/documents/${documentId}/download`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Reprocess invoice
*/
export async function newLoadRun(app, env, id) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${app}/env/${env}/loads/${id}/run`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`changeInvoiceAiStatus returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 204) {
        return { success: true }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
/*
Download master data entity
*/
export async function downloadByEntityType(filters, defaultApp) {
  try {
    let tenant = getUserTenant()
    let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${defaultApp}/masterdata/downloads`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: filters
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`downloadByEntityType returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 201) {
        return { success: true }
      } else {
        return {
          success: false,
          message: response.message
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}

/*
Get downloads by app
*/
export async function getDownloadsUseQuery(defaultApp) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${defaultApp}/masterdata/downloads`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Get the emails by app
*/
export async function getEmailsQuery({ appId, rowsPage, lastEvaluatedKey, status }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/emails`
  let requestHeaders = createHeaders()

  const statusValues = Object.values(status)

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      status: `${statusValues}`,
      limit: rowsPage,
      key: lastEvaluatedKey
    }
  })
}

export async function getValidationRulesUseQuery(appId, type, lastEvaluatedKey, rowsPage, process) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/validationRules/${type}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      limit: rowsPage,
      key: lastEvaluatedKey,
      process: process.join(',')
    }
  })
}

/*
Get the emails by id
*/
export async function getEmailQuery({ appId, emailId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/emails/${emailId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Get invoices by Email
*/
export async function getInvoicesByEmailQuery({ appId, emailId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/emails/${emailId}/invoices`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getValidationRuleUseQuery(appId, type, id) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/validationRules/${type}/${id}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getValidationRuleFields(appId, process) {
  let url = `${ApiAdminInvoice.EndPoint}/apps/${appId}/validationRules/processes/${process}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Save validation rule by app and type
*/
export async function saveValidationRuleUseQuery(appId, type, id, body) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/validationRules/${type}/${id}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/*
Save validation rule by app and type
*/
export async function getInvoicesDocumentQuery(appId, invoiceId) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/${invoiceId}/comments`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/*
Save validation rule by app and type
*/
export async function saveInvoicesDocumentQuery({ appId, invoiceId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/${invoiceId}/comments`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/**
 * Copy invoice Query
 */
export async function createCopyInvoice({ appId, invoiceId, body }) {
  const tenant = getUserTenant()
  const url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/${invoiceId}/copy`
  const requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function getInvoicesOcrVersionsQuery({ transactionId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/invoices/ocr/transactions/${transactionId}/versions`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getInvoicesOcrDiff({ transactionId, latestVersion, previousVersion }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/invoices/ocr/transactions/${transactionId}/diff`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      latestVersion,
      previousVersion
    }
  })
}

export async function getInvoiceOcrTransaction({ transactionId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/invoices/ocr/transactions/${transactionId}`
  let requestHeaders = createHeaders()
  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

/**
 * Used to save notification params
 */
export async function saveInvoiceNotificationConfigOnAppConfiguration({ appId, body }) {
  const tenant = getUserTenant()
  const url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/notificationsConfig`
  const requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

/**
 * Get notification params
 */
export async function getInvoiceNotificationConfigOnAppConfiguration({ appId }) {
  const tenant = getUserTenant()
  const url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/notificationsConfig`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export function getImportInvoices({ appId, lastEvaluatedKey, rowsPage, status }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/imports`
  let requestHeaders = createHeaders()

  const statusValues = Object.values(status)

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      status: `${statusValues}`,
      limit: rowsPage,
      key: lastEvaluatedKey
    }
  })
}

export function getImportInvoice({ appId, importId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/imports/${importId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export function getImportInvoiceLogs({ appId, importId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/imports/${importId}/logs`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export function createImportInvoice({ appId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/imports`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export function startImportInvoice({ appId, importId, body = {} }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/invoices/imports/${importId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export function uploadImportInvoice({ presignedUploadUrl, file }) {
  return axios({
    method: 'put',
    url: presignedUploadUrl,
    headers: { 'Content-Type': file.type },
    data: file
  })
}

export async function getInvoicesByImport({ appId, importId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/imports/${importId}/invoices`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getConnectivityConfiguration(appId) {
  let url = `${ApiAdminInvoice.EndPoint}/apps/${appId}/configurations/accounts`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getIgnoreRulePreCreationInfo({ appId, documentId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/ignore-document/${documentId}/info-pre-create`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function ignoreDocument({ appId, documentId, body }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/ignore-document/${documentId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function processIgnoredDocument({ appId, documentId }) {
  let tenant = getUserTenant()
  let url = `${ApiAdminInvoice.EndPoint}/tenants/${tenant}/apps/${appId}/ignore-document/${documentId}/process-ignored`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: {}
  })
}

export async function getOcrFieldValues(field, params) {
  let url = `${ApiAdminInvoice.EndPoint}/ocr/fields/${field}/values`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params
  })
}

export async function updateInvoiceOcrData({ body, url }) {
  //let tenant = getUserTenant()
  let httpUrl = `${ApiAdminInvoice.EndPoint}${url}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: httpUrl,
    headers: requestHeaders,
    data: body
  })
}

export async function getPreviousAndNextInvoiceId(invoiceId, status, appId) {
  let url = `${ApiAdminInvoice.EndPoint}/relational/invoices/${invoiceId}/previousAndNext`
  let requestHeaders = createHeaders()

  let statusFixed = ''
  status.forEach((arrayItem, index) => {
    if (index === status.length - 1) {
      statusFixed += `'${arrayItem}'`
    } else {
      statusFixed += `'${arrayItem}',`
    }
  })

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: { status: statusFixed, appId }
  })
}

export async function getPreviousAndNextInvoiceIdV2({ invoiceId, filters }) {
  const filtersQuery = encodeURIComponent(JSON.stringify(filters))
  const url = `${ApiAdminInvoice.EndPoint}/relational/v2/invoices/${invoiceId}/previousAndNext?filters=${filtersQuery}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  }).then(result => result.data)
}

export async function postMoveInvoice(body) {
  let url = `${ApiAdminInvoice.EndPoint}/invoices/move`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function getStandardInvoice(invoiceId) {
  let url = `${ApiAdminInvoice.EndPoint}/invoices/${invoiceId}/standard`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getStandardInvoiceV2(invoiceId) {
  let url = `${ApiAdminInvoice.EndPoint}/management/invoices-ai/${invoiceId}/standard`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  }).then(result => result.data)
}

export async function getStandardInvoiceVersions(invoiceId) {
  let url = `${ApiAdminInvoice.EndPoint}/invoices/${invoiceId}/standard/versions`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getInvoiceStandardDiff({ invoiceId, latestVersion, previousVersion }) {
  let url = `${ApiAdminInvoice.EndPoint}/invoices/${invoiceId}/standard/diff`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders,
    params: {
      latestVersion,
      previousVersion
    }
  })
}

export async function saveStandardInvoice(invoiceId, body) {
  let url = `${ApiAdminInvoice.EndPoint}/management/v1/invoices-ai/${invoiceId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'put',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function validateStandardInvoice(invoiceId, body) {
  let url = `${ApiAdminInvoice.EndPoint}/management/v1/invoices-ai/${invoiceId}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: url,
    headers: requestHeaders,
    data: body
  })
}

export async function applyMappingsInvoiceStandard(url, body) {
  //let url = `${ApiAdminInvoice.EndPoint}/invoices/${invoiceId}/purchase-orders/mappings`

  let httpUrl = `${ApiAdminInvoice.EndPoint}${url}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: httpUrl,
    headers: requestHeaders,
    data: body
  })
}

export async function getSchemaRulesFields(appId, process) {
  let url = `${ApiAdminInvoice.EndPoint}/rules/schema/apps/${appId}/processes/${process}`
  let requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function getMasterDataConfigurations(appId) {
  const tenantId = getUserTenant()
  const url = `${ApiAdminInvoice.EndPoint}/tenants/${tenantId}/apps/${appId}/masterdata/configurations`
  const requestHeaders = createHeaders()

  return axios({
    method: 'get',
    url: url,
    headers: requestHeaders
  })
}

export async function updateInvoiceOcrDataBusinessRule(url, body) {
  const httpUrl = `${ApiAdminInvoice.EndPoint}${url}`
  const requestHeaders = createHeaders()

  return axios({
    method: 'post',
    url: httpUrl,
    headers: requestHeaders,
    data: body
  }).then(result => result.data.completedActions)
}

/* return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (value === 'test4') {
        resolve({ data: [{ value: '4', label: 'label 4' }] })
      } else {
        resolve({
          data: [
            { value: '1', label: 'label 1' },
            { value: '2', label: 'label 2' },
            { value: '3', label: 'label 3' }
          ]
        })
      }
    }, 1500)
  }) */
