const tenantCreate = {
    name: {
        type: "string",
        required: true,
        displayName: "Name"
    },
    address: {
        type: "string",
        required: true,
        displayName: "Address"
    },
    city: {
        type: "string",
        required: true,
        displayName: "City"
    },
    language: {
        type: "string",
        required: true,
        displayName: "Language"
    },
    email: {
        type: "string",
        required: true,
        isEmail: true,
        displayName: "Email"
    },
    webSiteUrl: {
        type: "string",
        required: true,
        isURL: true,
        displayName: "Web Site Url"
    },
    tenantId: {
        type: "string",
        required: true,
        length: {
            min: 1,
            max: 20
        },
        displayName: "Tenant",
        isId: true
    },
    enabled: {
        type: "boolean",
        required: true,
        displayName: "Enabled",
    },
    hasSubdomain: {
        type: "boolean",
        required: true,
        displayName: "Has Subdomain",
    },
    enabledApps: {
        type: "array",
        required: true,
        displayName: "Enabled Apps"
    },
    suscriptionType: {
        type: "string",
        required: false,
        displayName: "Suscription Type"
    },
    startDate: {
        type: "string",
        required: false,
        displayName: "Start Date"
    },
    endDate: {
        type: "string",
        required: false,
        displayName: "End Date"
    },
    active: {
        type: "boolean",
        required: false,
        displayName: "Active",
    },
    colorCode: {
        type: "string",
        required: true,
        displayName: "Color Code"
    },
    secondColorCode: {
        type: "string",
        required: true,
        displayName: "Secondary Color Code"
    },
    imageLogo: {
        type: "string",
        required: true,
        displayName: "Logo Url"
    },
}

const tenantUpdate = {
    name: {
        type: "string",
        required: true,
        displayName: "Name"
    },
    address: {
        type: "string",
        required: true,
        displayName: "Address"
    },
    city: {
        type: "string",
        required: true,
        displayName: "City"
    },
    language: {
        type: "string",
        required: true,
        displayName: "Language"
    },
    email: {
        type: "string",
        required: true,
        isEmail: true,
        displayName: "Email"
    },
    webSiteUrl: {
        type: "string",
        required: true,
        isURL: true,
        displayName: "Web Site Url"
    },
    enabled: {
        type: "boolean",
        required: true,
        displayName: "Enabled",
    },
    enabledApps: {
        type: "array",
        required: true,
        displayName: "Enabled Apps"
    },
    suscriptionType: {
        type: "string",
        required: false,
        displayName: "Suscription Type"
    },
    startDate: {
        type: "string",
        required: false,
        displayName: "Start Date"
    },
    endDate: {
        type: "string",
        required: false,
        displayName: "End Date"
    },
    active: {
        type: "boolean",
        required: false,
        displayName: "Active",
    },
    colorCode: {
        type: "string",
        required: true,
        displayName: "Color Code"
    },
    secondColorCode: {
        type: "string",
        required: true,
        displayName: "Secondary Color Code"
    },
    imageLogo: {
        type: "string",
        required: true,
        displayName: "Logo Url"
    },
}

export {
    tenantCreate,
    tenantUpdate
};
