const main = {
    appName: {
        type: "string",
        required: true,
        displayName: "App Name",
        isAlphaNumeric: true,
    },
    type: {
        type: "string",
        required: true,
        displayName: "Environment Type",
    },
    enabled: {
        type: "boolean",
        required: true,
        displayName: "Enabled",
    },
};

const load = {
    name: {
        type: "string",
        required: true,
        displayName: "Name",
    },
    type: {
        type: "string",
        required: true,
        displayName: "Load Type",
    },
    updateFrequency: {
        type: "string",
        required: false,
        displayName: "Frequency",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled") {
                        isValid =
                            data.updateFrequency !== null &&
                            data.updateFrequency !== undefined &&
                            data.updateFrequency.trim() !== "";
                    }
                    return isValid;
                },
                message: "Update Frequency is a mandatory field",
            },
        ],
    },
    executionTime: {
        type: "string",
        required: false,
        displayName: "Execution Time",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled") {
                        isValid =
                            data.executionTime !== null &&
                            data.executionTime !== undefined &&
                            data.executionTime.trim() !== "";
                    }
                    return isValid;
                },
                message: "Execution Time is a mandatory field",
            },
        ],
    },
    executionDay: {
        type: "string",
        required: false,
        displayName: "Execution Day",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "scheduled" && (data.updateFrequency === "weekly" || data.updateFrequency === "monthly")) {
                        isValid =
                            data.executionDay !== null &&
                            data.executionDay !== undefined &&
                            data.executionDay.trim() !== "";
                    }
                    return isValid;
                },
                message: "Execution Day is a mandatory field",
            },
        ],
    },
    startDate: {
        type: "string",
        required: true,
        isDate: true,
        displayName: "Start Date",
    },
};

const destination = {
    remoteConnection: {
        type: "string",
        required: true,
        displayName: "Destination Type",
    },
    unzip: {
        type: "boolean",
        required: false,
        displayName: "Unzip file",
    },
    fileExtension: {
        type: "string",
        required: false,
        displayName: "File extension",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    const connection = data.remoteConnections[data.remoteConnection];
                    if (connection.type === "sharepoint" || connection.type === "s3" || connection.type === "qualtrics") {
                        isValid =
                            data.fileExtension !== null &&
                            data.fileExtension !== undefined &&
                            data.fileExtension.trim() !== "";
                    }
                    return isValid;
                },
                message: "fileExtension is a mandatory field",
            },
        ],
    },
    path: {
        type: "string",
        required: false,
        isPath: true,
        displayName: "Path",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    const connection = data.remoteConnections[data.remoteConnection];
                    if (connection.type === "sharepoint" || connection.type === "s3") {
                        isValid =
                            data.path !== null &&
                            data.path !== undefined &&
                            data.path.trim() !== "";
                    }
                    return isValid;
                },
                message: "Path is a mandatory field",
            },
        ],
    },
};

const connection = {
    name: {
        type: "string",
        required: true,
        displayName: "Connection Name",
    },
    type: {
        type: "string",
        required: true,
        displayName: "Connection Type",
    },
    path: {
        type: "string",
        required: false,
        isPath: true,
        displayName: "Path",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint" || data.type === "s3") {
                        isValid =
                            data.path !== null &&
                            data.path !== undefined &&
                            data.path.trim() !== "";
                    }
                    return isValid;
                },
                message: "Path is a mandatory field",
            },
        ],
    },
    fileExtension: {
        type: "string",
        required: false,
        displayName: "File extension",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint" || data.type === "s3" || data.type === "qualtrics") {
                        isValid =
                            data.fileExtension !== null &&
                            data.fileExtension !== undefined &&
                            data.fileExtension.trim() !== "";
                    }
                    return isValid;
                },
                message: "fileExtension is a mandatory field",
            },
        ],
    },
    accessKeyId: {
        type: "string",
        required: false,
        displayName: "Aws Access Key",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.accessKeyId !== null &&
                            data.accessKeyId !== undefined &&
                            data.accessKeyId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Aws Access Key is a mandatory field",
            },
        ],
    },
    secretAccessKey: {
        type: "string",
        required: false,
        displayName: "Aws Access Secret",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.secretAccessKey !== null &&
                            data.secretAccessKey !== undefined &&
                            data.secretAccessKey.trim() !== "";
                    }
                    return isValid;
                },
                message: "Aws Access Secret is a mandatory field",
            },
        ],
    },
    bucketName: {
        type: "string",
        required: false,
        displayName: "Bucket Name",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "s3") {
                        isValid =
                            data.bucketName !== null &&
                            data.bucketName !== undefined &&
                            data.bucketName.trim() !== "";
                    }
                    return isValid;
                },
                message: "Bucket Name is a mandatory field",
            },
        ],
    },
    tenantId: {
        type: "string",
        required: false,
        displayName: "Tenant Id",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "sharepoint") {
                        isValid =
                            data.tenantId !== null &&
                            data.tenantId !== undefined &&
                            data.tenantId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Tenant Id is a mandatory field",
            },
        ],
    },
    host: {
        type: "string",
        required: true,
        displayName: "host",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "SFTP") {
                        isValid =
                            data.host !== null &&
                            data.host !== undefined &&
                            data.host.trim() !== "";
                    }
                    return isValid;
                },
                message: "Host is a mandatory field",
            },
        ],
    },
    port: {
        type: "string",
        required: false,
        displayName: "port",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "SFTP") {
                        isValid =
                            data.port !== null &&
                            data.port !== undefined &&
                            data.port.trim() !== "";
                    }
                    return isValid;
                },
                message: "port is a mandatory field",
            },
        ],
    },
    userName: {
        type: "string",
        required: false,
        displayName: "user",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "SFTP") {
                        isValid =
                            data.userName !== null &&
                            data.userName !== undefined &&
                            data.userName.trim() !== "";
                    }
                    return isValid;
                },
                message: "userName is a mandatory field",
            },
        ],
    },
    password: {
        type: "string",
        required: false,
        displayName: "password",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.type === "SFTP") {
                        isValid =
                            data.password !== null &&
                            data.password !== undefined &&
                            data.password.trim() !== "";
                    }
                    return isValid;
                },
                message: "Password is a mandatory field",
            },
        ],
    },
};

const aribaConnection = {
    ANID: {
        type: "string",
        required: true,
        displayName: "Ariba Network ID",
    },
    inboundClientId: {
        type: "string",
        required: false,
        displayName: "Inbound Client Id",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.inboundClientId !== null &&
                            data.inboundClientId !== undefined &&
                            data.inboundClientId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Inbound ClientId is a mandatory field",
            },
        ],
    },
    inboundSecret: {
        type: "string",
        required: false,
        displayName: "Inbound Secret",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.inboundSecret !== null &&
                            data.inboundSecret !== undefined &&
                            data.inboundSecret.trim() !== "";
                    }
                    return isValid;
                },
                message: "Inbound Secret is a mandatory field",
            },
        ],
    },
    inboundAuthenticationType: {
        type: "string",
        required: false,
        displayName: "Inbound Authentication Type",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.itk) {
                        isValid =
                            data.inboundAuthenticationType !== null &&
                            data.inboundAuthenticationType !== undefined &&
                            data.inboundAuthenticationType.trim() !== "";
                    }
                    return isValid;
                },
                message: "Inbound AuthenticationType is a mandatory field",
            },
        ],
    },
    outboundClientId: {
        type: "string",
        required: false,
        displayName: "Outbound Client Id",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.outboundClientId !== null &&
                            data.outboundClientId !== undefined &&
                            data.outboundClientId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Outbound ClientId is a mandatory field",
            },
        ],
    },
    outboundSecret: {
        type: "string",
        required: false,
        displayName: "Outbound Secret",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.outboundSecret !== null &&
                            data.outboundSecret !== undefined &&
                            data.outboundSecret.trim() !== "";
                    }
                    return isValid;
                },
                message: "Outbound Secret is a mandatory field",
            },
        ],
    },
    outboundAuthenticationType: {
        type: "string",
        required: false,
        displayName: "Outbound Authentication Type",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.itk) {
                        isValid =
                            data.outboundAuthenticationType !== null &&
                            data.outboundAuthenticationType !== undefined &&
                            data.outboundAuthenticationType.trim() !== "";
                    }
                    return isValid;
                },
                message: "Outbound AuthenticationType is a mandatory field",
            },
        ],
    },
    aribaNetworkClientId: {
        type: "string",
        required: false,
        displayName: "Ariba Network Client Id",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.aribaNetworkClientId !== null &&
                            data.aribaNetworkClientId !== undefined &&
                            data.aribaNetworkClientId.trim() !== "";
                    }
                    return isValid;
                },
                message: "Ariba Network ClientId is a mandatory field",
            },
        ],
    },
    aribaNetworkSecret: {
        type: "string",
        required: false,
        displayName: "Ariba Network Secret",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (!data.itk) {
                        isValid =
                            data.aribaNetworkSecret !== null &&
                            data.aribaNetworkSecret !== undefined &&
                            data.aribaNetworkSecret.trim() !== "";
                    }
                    return isValid;
                },
                message: "Ariba Network Secret is a mandatory field",
            },
        ],
    },
    aribaNetworkApiKey: {
        type: "string",
        required: false,
        displayName: "Ariba Network API Key",
        customRules: [
            {
                rule: function(data) {
                    let isValid = true;
                    if (data.itk) {
                        isValid =
                            data.aribaNetworkApiKey !== null &&
                            data.aribaNetworkApiKey !== undefined &&
                            data.aribaNetworkApiKey.trim() !== "";
                    }
                    return isValid;
                },
                message: "Ariba Network API Key is a mandatory field",
            },
        ],
    }
};

export { main, load, destination, connection, aribaConnection };
