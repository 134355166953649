import React, { useRef } from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/style.css'

// @material-ui/core components
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Select,
  MenuItem
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// @material-ui/icons
import ClearIcon from '@material-ui/icons/Clear'
import SettingsIcon from '@material-ui/icons/Settings'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'
import Close from '@material-ui/icons/Close'

// components
import GridItem from '../../components/Grid/GridItem.js'
import CardHeader from 'components/Card/CardHeader.js'
import CustomInput from '../../components/CustomInput/CustomInput.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomTreeTable from 'components/CustomTreeTable/CustomTreeTable'
import Button from '../../components/CustomButtons/Button.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js'

const useStyles = makeStyles(styles)

export default function MonitorConsoleSettings(props) {
  const classes = useStyles()
  const refreshTableFunction = useRef(() => {})
  const {
    stepKey,
    stepName,
    stepOrder,
    readAllParams,
    errorMessage,
    successMessage,
    rowsData,
    index,
    onMappingFieldChange,
    expanded,
    isStep,
    isFinishStep,
    enableTimeVerification,
    notificationsEmails,
    timeVerification,
    periodicityOptions,
    validationPeriodicity
  } = props

  function getTableData() {
    let data = []
    if (rowsData.value.length === 0) {
      return data
    }

    data = rowsData.value.map(data => {
      let row = {}
      row.enabled = (
        <div>
          <Switch
            disabled={true}
            checked={data.enabled}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              thumb: classes.switchIcon,
              track: classes.switchBar
            }}
          />
        </div>
      )
      row.paramName = <Typography>{data.paramName}</Typography>
      row.type = <Typography>{data.type}</Typography>
      row.action = (
        <GridContainer>
          <GridItem xs={6}>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  props.showMonitorModal(index, data.rowIndex)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </GridItem>
          <GridItem xs={6}>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  props.showDeleteConfirmDialog(index, data.rowIndex)
                }}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </GridItem>
        </GridContainer>
      )
      return row
    })

    return data
  }

  function getStepInformation() {
    if (isStep) {
      return (
        <GridContainer>
          <GridItem xs={6}>
            <CardHeader>Step Name</CardHeader>
            <CustomInput
              success={stepName.success}
              error={!stepName.success}
              labelText={stepName.success ? undefined : 'Step Name*'}
              labelProps={{ style: { width: '100%', margin: '1.25rem' } }}
              formControlProps={{
                fullWidth: true
              }}
              id={'stepName-' + stepKey}
              required
              inputProps={{
                style: { margin: '1.25rem' },
                type: 'text',
                value: stepName.value,
                onChange: event => {
                  onMappingFieldChange(index, 'stepName', event.target.value)
                },
                endAdornment: stepName.success ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        onMappingFieldChange(index, 'stepName', '')
                      }}
                    >
                      <Close className={classes.error} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  undefined
                )
              }}
            />
          </GridItem>
          <GridItem xs={6}>
            <CardHeader>Step Key</CardHeader>
            <CustomInput
              disabled
              labelProps={{ style: { width: '100%', margin: '1.25rem' } }}
              id={'stepKey-' + stepKey}
              formControlProps={{
                fullWidth: true
              }}
              required
              inputProps={{
                type: 'text',
                value: stepKey,
                style: { textAlign: 'center', margin: '1.25rem' }
              }}
            />
          </GridItem>
          <GridItem xs={6}>
            <CardHeader>Success Message</CardHeader>
            <CustomInput
              success={successMessage.success}
              error={!successMessage.success}
              labelText={
                successMessage.success ? undefined : 'Success Message*'
              }
              labelProps={{
                style: { width: '100%', float: 'left', margin: '1.25rem' }
              }}
              formControlProps={{
                fullWidth: true
              }}
              id={'successMessage-' + stepKey}
              required
              inputProps={{
                style: { margin: '1.25rem' },
                type: 'text',
                value: successMessage.value,
                onChange: event => {
                  onMappingFieldChange(
                    index,
                    'successMessage',
                    event.target.value
                  )
                },
                endAdornment: successMessage.success ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        onMappingFieldChange(index, 'successMessage', '')
                      }}
                    >
                      <Close className={classes.error} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  undefined
                )
              }}
            />
          </GridItem>
          <GridItem xs={6}>
            <CardHeader>Error Message</CardHeader>
            <CustomInput
              success={errorMessage.success}
              error={!errorMessage.success}
              labelText={errorMessage.success ? undefined : 'Error Message*'}
              labelProps={{
                style: { width: '100%', float: 'left', margin: '1.25rem' }
              }}
              formControlProps={{
                fullWidth: true
              }}
              id={'errorMessage-' + stepKey}
              required
              inputProps={{
                style: { margin: '1.25rem' },
                type: 'text',
                value: errorMessage.value,
                onChange: event => {
                  onMappingFieldChange(
                    index,
                    'errorMessage',
                    event.target.value
                  )
                },
                endAdornment: errorMessage.success ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        onMappingFieldChange(index, 'errorMessage', '')
                      }}
                    >
                      <Close className={classes.error} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  undefined
                )
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <CardHeader>Step order</CardHeader>
            <CustomInput
              success={stepOrder.success}
              error={!stepOrder.success}
              labelText={stepOrder.success ? undefined : 'Step Order*'}
              labelProps={{
                style: { width: '100%', float: 'left', margin: '1.25rem' }
              }}
              formControlProps={{
                fullWidth: true
              }}
              id={'stepOrder-' + stepKey}
              required
              inputInternalProps={{ min: 0 }}
              inputProps={{
                style: { margin: '1.25rem' },
                type: 'number',
                min: 0,
                value: stepOrder.value,
                onChange: event => {
                  onMappingFieldChange(index, 'stepOrder', event.target.value)
                }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <CardHeader>Is Finish Step</CardHeader>
            <FormControlLabel
              style={{ padding: '10px' }}
              control={
                <Switch
                  checked={isFinishStep.value}
                  onChange={event => {
                    onMappingFieldChange(
                      index,
                      'isFinishStep',
                      event.target.checked
                    )
                  }}
                  value="Active"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              }
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <CardHeader>Enable time Verification</CardHeader>
            <FormControlLabel
              style={{ padding: '10px' }}
              control={
                <Switch
                  checked={enableTimeVerification.value}
                  onChange={event => {
                    onMappingFieldChange(
                      index,
                      'enableTimeVerification',
                      event.target.checked
                    )
                  }}
                  value="Active"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              }
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={6}
            hidden={!enableTimeVerification.value}
          >
            <CardHeader>Time to Validate</CardHeader>
            <Datetime
              locale="en-US"
              closeOnSelect={true}
              value={timeVerification.value}
              timeFormat={'HH:mm'}
              dateFormat={false}
              className={classes.noBorder}
              onChange={event =>
                onMappingFieldChange(index, 'timeVerification', event)
              }
              inputProps={{
                readOnly: true,
                placeholder: 'Time to Validate',
                style: {
                  margin: '1.25rem',
                  fontFamily: 'sans-serif',
                  fontSize: '14px'
                },
                value: moment(new Date(timeVerification.value)).format('HH:mm')
              }}
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={6}
            hidden={!enableTimeVerification.value}
          >
            <CardHeader>Verification Periodicity</CardHeader>
            <Select
              MenuProps={{
                style: { zIndex: 5555 }
              }}
              style={{
                width: '100%',
                margin: '1.25rem',
                fontFamily: 'sans-serif',
                fontSize: '14px'
              }}
              value={validationPeriodicity.value}
              onChange={e => {
                onMappingFieldChange(
                  index,
                  'validationPeriodicity',
                  e.target.value
                )
              }}
              inputProps={{
                name: 'validationPeriodicity',
                id: 'validationPeriodicity' + stepKey
              }}
            >
              {periodicityOptions.map(value => {
                return (
                  <MenuItem key={value} value={value}>
                    {value.charAt(0).toUpperCase() + value.substring(1)}
                  </MenuItem>
                )
              })}
            </Select>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            hidden={!enableTimeVerification.value}
          >
            <CardHeader>Notification Email</CardHeader>
            <ReactMultiEmail
              placeholder="Add emails to send notifications"
              emails={notificationsEmails.value}
              onChange={_emails => {
                onMappingFieldChange(index, 'notificationsEmails', _emails)
              }}
              validateEmail={email => {
                return isEmail(email)
              }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                )
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <CardHeader>Read all Params</CardHeader>
            <FormControlLabel
              style={{ padding: '10px' }}
              control={
                <Switch
                  checked={readAllParams.value}
                  onChange={event => {
                    onMappingFieldChange(
                      index,
                      'readAllParams',
                      event.target.checked
                    )
                  }}
                  value="Active"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              }
            />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            hidden={readAllParams.value}
          >
            <CustomTreeTable
              tableMaxSize={10}
              hide
              hideSaveButton={true}
              hideAddButton={false}
              showTablePagination={false}
              formLabel={''}
              transformedColumnName={'Name'}
              formTitle={'Settings'}
              tableTitle={''}
              tableIcon={<SettingsIcon />}
              onClickAdd={() => {
                props.showMonitorModal(index, undefined)
              }}
              mainAccessor={'paramName'}
              noDataDiv={''}
              noDataText=""
              data={getTableData()}
              columns={[
                {
                  Header: 'Enabled',
                  accessor: 'enabled',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Param Name',
                  accessor: 'paramName',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Type',
                  accessor: 'type',
                  sortable: false,
                  filterable: false
                },
                {
                  Header: 'Actions',
                  accessor: 'action',
                  sortable: false,
                  filterable: false
                }
              ]}
              refreshData={refreshChild => {
                refreshTableFunction.current = () => {
                  refreshChild(getTableData())
                }
              }}
              transformCodes={['xs', 'sm']}
              defaultPageSize={10}
              showPaginationTop
              minRows={0}
              showPaginationBottom={false}
              className="-striped -highlight"
            ></CustomTreeTable>
          </GridItem>
        </GridContainer>
      )
    } else {
      return (
        <GridContainer>
          <GridItem xs={6}>
            <CardHeader>Monitor Name</CardHeader>
            <CustomInput
              disabled
              labelProps={{ style: { width: '100%' } }}
              formControlProps={{
                fullWidth: true
              }}
              id={'stepName-' + stepKey}
              inputProps={{
                style: { margin: '1.25rem' },
                type: 'text',
                value: stepName
              }}
            />
          </GridItem>
          <GridItem xs={6}>
            <CardHeader>Monitor Id</CardHeader>
            <CustomInput
              disabled
              labelProps={{ style: { width: '100%' } }}
              id={'stepKey-' + stepKey}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: 'text',
                value: stepKey,
                style: { textAlign: 'center', margin: '1.25rem' }
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <CardHeader>Step order</CardHeader>
            <CustomInput
              success={stepOrder.success}
              error={!stepOrder.success}
              labelText={stepOrder.success ? undefined : 'Step Order*'}
              labelProps={{ style: { width: '100%', float: 'left' } }}
              formControlProps={{
                fullWidth: true
              }}
              id={'stepOrder-' + stepKey}
              required
              inputInternalProps={{ min: 0 }}
              inputProps={{
                style: { margin: '1.25rem' },
                type: 'number',
                min: 0,
                value: stepOrder.value,
                onChange: event => {
                  onMappingFieldChange(index, 'stepOrder', event.target.value)
                }
              }}
            />
          </GridItem>
        </GridContainer>
      )
    }
  }

  return (
    <Accordion
      className={classes.accordion}
      style={{
        width: '100%'
      }}
      expanded={expanded ? expanded : false}
      onChange={() => {
        onMappingFieldChange(index, 'expanded', !expanded)
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Step Configuration</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <GridContainer style={{ paddingBottom: '5%', margin: 'auto' }}>
          <GridItem xs={12} style={{ textAlign: 'left' }}>
            <Button
              color={'danger'}
              simple
              className={classes.documentation}
              onClick={() => {
                props.onDeleteMonitorConsole(index)
              }}
            >
              <DeleteForeverOutlinedIcon className={classes.icon} />
              Delete Step
            </Button>
          </GridItem>
          {getStepInformation()}
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  )
}
