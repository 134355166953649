import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import SyncLoader from 'components/SyncLoader/SyncLoader'

// utils
import { formatDate } from 'utils/functions.js'
import { useGetMonitorsDetails } from 'hooks/useMonitorsConsole.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

const useStyles = makeStyles(styles)

export default function MonitorConsole(props) {
  const history = useHistory()
  const classes = useStyles()
  const [createError, setCreateError] = useState(false)
  const [userFilters, setUserFilters] = useState({})
  const breadcrumbViews = [
    {
      name: 'Monitor Console',
      url: `/admin/monitorConsole/index`,
      icon: DesktopWindowsIcon
    }
  ]

  const actions = [
    {
      icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
      tooltip: 'Details',
      onClick: (event, rowData) => {
        history.push(
          `/admin/monitorConsole/transactions/${rowData.environment}/${rowData.app}/${rowData.monitorId}/${rowData.description}`
        )
      }
    }
  ]

  const {
    data: transactions,
    isLoading,
    error,
    isError,
    refetch,
    isFetching
  } = useGetMonitorsDetails()

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError(true)
  }, [isError])

  let monitorData = {}
  let obj = {}
  let data = {}
  if (transactions && transactions.rows) {
    let transaction
    for (transaction of transactions.rows) {
      if (transaction.app) {
        obj = {}
        obj[transaction.app] = transaction.app
        data['app'] = { ...data['app'], ...obj }
      }

      if (transaction.lastUpdate) {
        obj = {}
        obj[transaction.lastUpdate] = formatDate({
          date: transaction.lastUpdate
        })
        data['lastUpdate'] = { ...data['lastUpdate'], ...obj }
      }

      if (transaction.environment) {
        obj = {}
        obj[transaction.environment] = transaction.environment
        data['environment'] = { ...data['environment'], ...obj }
      }

      if (transaction.monitorId) {
        obj = {}
        obj[transaction.monitorId] = transaction.monitorId
        data['monitorId'] = { ...data['monitorId'], ...obj }
      }

      if (transaction.description) {
        obj = {}
        obj[transaction.description] = transaction.description
        data['description'] = { ...data['description'], ...obj }
      }

      if (transaction.type) {
        obj = {}
        obj[transaction.type] = transaction.type
        data['type'] = { ...data['type'], ...obj }
      }
    }
    monitorData = data
  }

  const columns = [
    {
      field: 'monitorId',
      title: 'Monitor Id',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['monitorId'],
      defaultFilter: userFilters['monitorId']
    },
    {
      field: 'app',
      title: 'App',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['app'],
      defaultFilter: userFilters['app']
    },
    {
      field: 'environment',
      title: 'Environment',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['environment'],
      defaultFilter: userFilters['environment']
    },
    {
      field: 'type',
      title: 'Type',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['type'],
      defaultFilter: userFilters['type']
    },
    {
      field: 'description',
      title: 'Monitor Description',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['description'],
      defaultFilter: userFilters['description']
    },
    {
      field: 'lastUpdate',
      title: 'Last Update',
      render: rowData =>
        formatDate({
          date: rowData.lastUpdate
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['lastUpdate'],
      defaultFilter: userFilters['lastUpdate'],
      defaultSort: 'desc'
    }
  ]

  function filterColumns(columns) {
    const configCopy = { ...userFilters }
    let column
    for (column of columns) {
      configCopy[column.column.field] = column.value
    }
    setUserFilters(configCopy)
  }

  function returnMonitorTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem md={12}>
            <Button
              color="primary"
              size="small"
              className={classes.floatRight}
              onClick={() => refetch({ throwOnError: true })}
            >
              <RefreshRoundedIcon />
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer justify="center" style={{ marginTop: '5px' }}>
              <SyncLoader showLoader={isFetching && !isLoading} />
              {isLoading ? (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
              ) : (
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={columns}
                    data={transactions?.rows || []}
                    actions={actions}
                    // onChangeColumnHidden={hideColumns}
                    onFilterChange={filterColumns}
                    options={{
                      columnsButton: true,
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: true,
                      showTitle: false
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Monitor Console</h3>
            </CardHeader>
            <CardBody>{returnMonitorTab()}</CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={
            isError
              ? error.response.data.errors
                ? error.response.data.errors.map(error => error.msg).join(', ')
                : 'Something went wrong, try again later'
              : ''
          }
          open={createError}
          closeNotification={() => setCreateError(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
