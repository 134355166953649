import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

// Assets
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import dunsNBradstreetLogo from 'assets/img/POC/poweredByRelishNDnBV2.png'

// Components
import { CardActions, Grid, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useQueryParams } from 'hooks/POC/CoupaIFrameApp/useQueryParams'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardText from 'components/Card/CardText'
import DnbDaCardV2App from './cardV2App'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import CardBody from 'components/Card/CardBody'

// Services
import { getDnBDashboardV2 } from 'services/apiDnbConnector'

const useStyles = makeStyles({
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: '#FFFFFF',
    marginTop: '0'
  }
})

export default function DnbDaDashboardV2App(props) {
  const classes = useStyles()
  const query = useQueryParams()
  const supplierId = query.get('object_id')
  const { dashboardId, appId } = useParams()

  const [state, setState] = useState({
    alert: null,
    cards: [], //cards
    duns: null,
    error: null,
    showLoader: false,
    snackBarMessage: null,
    snackBarShowError: false,
    snackBarShowSuccess: false,
    supplierId: null,
    supplierName: ''
  })

  const {
    isLoading: loadingSupplierInformation,
    isFetching: fetchingSupplierInformation
  } = useQuery(
    ['SupplierInformation', 'dnbConnector-supplierInformation'],
    () => getDnBDashboardV2(supplierId, appId, dashboardId).then(result => result.data),
    {
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      onSuccess: data => {
        if (data) {
          setState(old => ({
            ...old,
            ...data
          }))
        }
      },
      onError: result => {
        setState(old => ({
          ...old,
          error: `${result.status}: ${result.error}`
        }))
      }
    }
  )

  useEffect(() => {
    setState(old => ({
      ...old,
      showLoader: loadingSupplierInformation || fetchingSupplierInformation
    }))
  }, [loadingSupplierInformation, fetchingSupplierInformation])

  if (state.showLoader) {
    return <SyncLoader showLoader={true} />
  }

  if (state.error) {
    return <h1>{state.error}</h1>
  }

  return (
    <>
      {state.alert}
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardHeader style={{ paddingBottom: '0px' }}>
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                  <CardText style={{ backgroundColor: '#3C78C8' }}>
                    <h4 className={classes.cardTitleWhite}>{`${state.supplierName}`}</h4>
                  </CardText>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                      <h4
                        color="primary"
                        style={{ float: 'right', marginBottom: '0px', marginTop: '0px' }}
                      >{`D-U-N-S# ${state.duns}`}</h4>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Grid container spacing={3}>
                {Object.entries(state.cards).map(([cardName, cardValue]) => (
                  <Grid item key={cardName} xs={12} sm={6}>
                    <DnbDaCardV2App cardName={cardName} cardValue={cardValue} />
                  </Grid>
                ))}
              </Grid>
            </CardBody>
            <CardActions>
              <Grid container>
                <Grid item xs={6} style={{ textAlign: 'left' }}>
                  <a
                    href="https://relishiq.com/solutions/relish-data-assure/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#3c78c8' }}
                  >
                    <img
                      src={dunsNBradstreetLogo}
                      alt="Go to Relish"
                      style={{ maxWidth: '500px' }}
                    />
                  </a>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
