import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import {
    getAppConfigurationsByApp,
    getAppConfigurationByAppAndId,
    editAppConfigurationByAppAndId
} from "services/apiApps";

import AddressValidationView from "./AddressValidationView"
// models
import { configuration as configurationSchema, questionnaire as questionnaireSchema, parameters as parametersSchema } from "models/addressValidationModel"
import { validateProperty, validateObject } from "utils/validator";

import { v4 as uuidv4 } from 'uuid';

let _isMounted = false
let appId

const newParametersForm = {
    env: "",
    ConcatHouseID: true,
    ConcatRoomID: true,
    MaxSuggestions: 5,
    PostalCodeLength: 5,
    ValidationMessages: {},
    ValidationLevel: "strict"
}

const newParametersState = {
    env: "",
    ConcatHouseID: "",
    ConcatRoomID: "",
    MaxSuggestions: "",
    PostalCodeLength: "",
    ValidationLevel: ""
}

const newAppConfigurations = {
    env: "",
    type: "Test",
    mappings: {}
}

const newModal = {
    visible: false,
    type: "edit",
    object: "questionnaire",
    name: ""
}

const formState = {
    name: ""
}

async function getAppList(props) {
    let response = await getAppConfigurationsByApp("slp")

    if (response.success) {
        if (_isMounted) {
            props.setAppList(response.data)
            props.setPageIsLoading(false)
        }
    }
}

async function getAppDetails(props, id) {
    props.setFormIsLoading(true)

    const appDetails = await getAppConfigurationByAppAndId("slp", id)

    if (appDetails.success) {
        const parameters = appDetails.data.addressValidation.parameters

        const parametersDetails = {
            env: appDetails.data.env,
            ConcatHouseID: parameters.ConcatHouseID,
            ConcatRoomID: parameters.ConcatRoomID,
            MaxSuggestions: parameters.MaxSuggestions,
            PostalCodeLength: parameters.PostalCodeLength,
            ValidationMessages: parameters.ValidationMessages,
            ValidationLevel: parameters.ValidationLevel
        }

        const configurationDetails = {
            env: appDetails.data.env,
            type: appDetails.data.type,
            mappings: appDetails.data.addressValidation.mappings
        }

        if (_isMounted) {
            props.setConfigurationForm(configurationDetails)
            props.setParametersForm(parametersDetails)

            props.setFormIsLoading(false)
        }
    } else {
        props.setFormIsLoading(false)
    }
}

async function onConfigSubmit(props) {
    let result = {}
    let successMessage

    let appDetails = await getAppConfigurationByAppAndId("slp", props.selectedAppId)
    let currentConfiguration = appDetails.data


    // Update with the form values
    let appConfiguration = createConfigRequestBody(props.configurationForm, currentConfiguration)
    let response

    response = await editAppConfigurationByAppAndId("slp", props.selectedAppId, appConfiguration)
    successMessage = "App updated successfully"

    if (response.success && _isMounted) {
        props.setValidationMessage(successMessage)
        props.setSubmitSuccess(true)

        setTimeout(function () {
            if (_isMounted) {
                props.setSubmitSuccess(false)
            }
        }, 5000);
    } else {
        // Show error message
        if (result) {
            let message = result.message
            if (!message) {
                message = "Something went wrong, please try again later."
            }
            props.setValidationMessage(message)
            props.setCreateError(true)
        }
    }
}

function createConfigRequestBody(configuration, requestBody) {
    let mappings = requestBody.addressValidation.mappings
    requestBody.addressValidation.configured = true
    let mapping
    for (mapping in mappings) {
        if (!configuration.mappings.hasOwnProperty(mapping)) {
            configuration.mappings[mapping] = mappings[mapping]
        }
    }

    requestBody.addressValidation.mappings = configuration.mappings
    return requestBody
}

async function onParamsSubmit(props) {
    let result = {}
    let successMessage

    let appDetails = await getAppConfigurationByAppAndId("slp", props.selectedAppId)
    let currentConfiguration = appDetails.data


    // Update with the form values
    let appConfiguration = createParamsRequestBody(props.parametersForm, currentConfiguration)
    let response

    response = await editAppConfigurationByAppAndId("slp", props.selectedAppId, appConfiguration)
    successMessage = "App updated successfully"

    if (response.success && _isMounted) {
        props.setValidationMessage(successMessage)
        props.setSubmitSuccess(true)

        setTimeout(function () {
            if (_isMounted) {
                props.setSubmitSuccess(false)
            }
        }, 5000);
    } else {
        // Show error message
        if (result) {
            let message = result.message
            if (!message) {
                message = "Something went wrong, please try again later."
            }
            props.setValidationMessage(message)
            props.setCreateError(true)
        }
    }
}

function createParamsRequestBody(parameters, requestBody) {
    requestBody.addressValidation.configured = true
    // Update parameters
    requestBody.addressValidation.parameters = parameters

    return requestBody
}

function hideModal(props) {
    props.modal.visible = false
    props.setModal(props.modal)
}

export default compose(
    connect(
        state => ({
            isAuthenticated: state.login.isAuthenticated,
            name: state.login.name
        }),
        {}
    ),
    withState("appList", "setAppList", []),
    withState("selectedAppId", "setSelectedAppId", null),
    withState("configurationForm", "setConfigurationForm", { ...newAppConfigurations }),
    withState("parametersForm", "setParametersForm", { ...newParametersForm }),
    withState("parametersFormState", "setParametersFormState", { ...newParametersState }),
    withState("modal", "setModal", { ...newModal }),
    withState("modalForm", "setModalForm", { ...formState }),
    withState("modalFormState", "setModalFormState", { ...formState }),
    withState("formIsLoading", "setFormIsLoading", false),
    withState("submitSuccess", "setSubmitSuccess", false),
    withState("createError", "setCreateError", false),
    withState("validationMessage", "setValidationMessage", ""),
    withState("pageIsLoading", "setPageIsLoading", true),
    withState("isLoadingSave", "setIsLoadingSave", false),
    withState("swipeableActions", "setSwipeableActions", null),
    withHandlers({
        onAppChanged: (props) => (id) => {
            props.setSelectedAppId(id)
            getAppDetails(props, id)
        },
        onShowModal: (props) => async (type, object, id, name) => {
            props.modal.type = type
            props.modal.object = object
            props.modal.id = id
            props.modal.name = (name ? name : "")
            props.modal.visible = true

            props.setModalFormState({ ...formState })
            props.modalForm.name = (name ? name : "")
            props.setModalForm(props.modalForm)
            props.setModal(props.modal)
        },
        onHideModal: (props) => async () => {
            hideModal(props)
        },
        onFormChange: (props) => async (field, value) => {
            props.modalForm[field] = value

            props.setModalForm(props.modalForm)

            let isValid = validateProperty(questionnaireSchema, props.modalForm, field).isValid
            if (isValid) {
                props.modalFormState[field] = "success"
            } else {
                props.modalFormState[field] = "error"
            }
            props.setModalFormState(props.modalFormState)
        },
        onConfigSave: (props) => async () => {
            props.setIsLoadingSave(true)

            let validation = validateObject(configurationSchema, props.configurationForm)
            if (validation.isValid) {
                await onConfigSubmit(props)
            }
            props.setIsLoadingSave(false)
        },
        onQuestionnaireEdit: (props) => async () => {
            const id = props.modal.id

            props.configurationForm.mappings[id].uniqueName = props.modalForm.name
            props.setConfigurationForm(props.configurationForm)

            hideModal(props)
        },
        onQuestionnaireCreate: (props) => async (callback) => {
            const name = props.modalForm.name

            // Create new questionnaire
            props.configurationForm.mappings[uuidv4()] = {
                uniqueName: name,
                SendResponseToQuestionnaire: true,
                questions: []
            }

            props.setConfigurationForm(props.configurationForm, function () {
                callback()
            })

            hideModal(props)
        },
        onQuestionnaireDelete: (props) => async (callback) => {
            const key = props.modal.id
            if (props.configurationForm.mappings.hasOwnProperty(key)) {
                // delete it
                delete props.configurationForm.mappings[key]
                props.setConfigurationForm(props.configurationForm, function () {
                    callback()
                })
            }
            hideModal(props)
        },
        onParamFieldChange: (props) => (field, value) => {
            props.parametersForm[field] = value
            props.setParametersForm(props.parametersForm)
            const isValid = validateProperty(parametersSchema, props.parametersForm, field).isValid
            if (isValid) {
                props.parametersFormState[field] = "success"
            } else {
                props.parametersFormState[field] = "error"
            }
            props.setParametersFormState(props.parametersFormState)
        },
        onQuestionChange: (props) => (field, value, id) => {
            props.parametersForm.ValidationMessages[id][field] = value
            props.setParametersForm(props.parametersForm)
        },
        onParamsSave: (props) => async () => {
            props.setIsLoadingSave(true)

            let validation = validateObject(parametersSchema, props.parametersForm)
            if (validation.isValid) {
                await onParamsSubmit(props)
            }
            props.setIsLoadingSave(false)
        },
        onMessageEdit: (props) => async () => {
            const id = props.modal.id

            props.parametersForm.ValidationMessages[id].message = props.modalForm.name
            props.setParametersForm(props.parametersForm)

            hideModal(props)
        },
    }),
    lifecycle({
        componentDidMount() {
            _isMounted = true
            appId = this.props.match.params.id
            this.props.setSelectedAppId(appId)

            getAppDetails(this.props, appId)

            this.props.setPageIsLoading(true)
            this.props.setModal({ ...newModal })
            getAppList(this.props)
        },
        componentWillUnmount() {
            _isMounted = false
        }
    })
)(AddressValidationView);
