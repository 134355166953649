import React, { useEffect, useState, useRef } from 'react'

// @material-ui/core components
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Button from 'components/CustomButtons/Button.js'
import AppMainMenu from '../CustomGenericViews/AppMainMenu.js'
import Wizard from '../../components/Wizard/Wizard.js'
import MonitorSettingsView from './MonitorSettingsView.js'

//utils
import { showLoading, hideLoading, cloneObject } from 'utils/functions'
//services
import {
  getAppConfigurationsByApp,
  getMonitorConfigurationByAppAndId,
  getTransactionsByAppId,
  getMonitorConstants,
  saveMonitorConfiguration,
  getRouterConfigurations
} from '../../services/apiApps'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'
const useStyles = makeStyles(styles)

export default function MonitorConsole(props) {
  const app = props.location.pathname.includes('dataAssure')
    ? 'slp'
    : props.location.pathname.includes('router')
    ? 'router'
    : ''
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [appList, setAppList] = useState([])
  const [selectedAppId, setSelectedAppId] = useState(undefined)
  const [selectedApp, setSelectedApp] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [createError, setCreateError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [showConfiguration, setShowConfiguration] = useState(false)
  const [validationForm, setValidationForm] = useState({})
  const [configurationDetailsLabel, setConfigurationDetailsLabel] = useState('')
  const [refreshView, setRefreshView] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [service, setService] = useState('')
  const [monitorConstants, setMonitorConstants] = useState(undefined)
  const divRef = useRef()

  const title = 'Monitor Console'

  function scrolltoTop() {
    divRef.current.scrollIntoView({ behavior: 'auto', block: 'start' })
  }
  function scrollTop() {
    divRef.current.scrollIntoView({ behavior: 'auto', block: 'start' })
  }

  async function getAppListError(app) {
    const response = await getTransactionsByAppId('slp', app.env, ['failed'])
    if (response && response.success) {
      return response.data.transactions.length
    }
  }
  async function appChanged(id) {
    setIsLoading(true)
    await getAppDetails(id)
    hideLoading(setIsLoading)
    setIsLoading(false)
  }

  function onAppChanged(id) {
    showLoading(setIsLoading)
    appChanged(id)
  }

  function showError(message) {
    setValidationMessage(message)
    setCreateError(true)
    setTimeout(() => {
      setCreateError(false)
    }, 5000)
  }

  async function getAppDetails(id, appType = undefined) {
    setSelectedAppId(id)
    let appDetails = await getMonitorConfigurationByAppAndId(app, id, appType)
    if (appDetails.success) {
      let validationDetails = appDetails.data.data
      setValidationForm(validationDetails)
    } else {
      if (appDetails.data) {
        setValidationForm(appDetails.data)
      }
    }
  }

  useEffect(() => {
    async function getAppList(callback = () => {}, selectedApp = undefined) {
      let monitorConstants = await getMonitorConstants()
      if (monitorConstants.success) {
        setMonitorConstants(monitorConstants.data)
        let response
        switch (app) {
          case 'slp':
            response = await getAppConfigurationsByApp('slp')
            break
          case 'router':
            response = await getRouterConfigurations()
            break
          default:
            response = { success: false }
            break
        }

        if (response.success) {
          if (response.data && response.data.length > 0) {
            switch (app) {
              case 'slp':
                const errorsLength = await response.data.map(async item => {
                  let length = await getAppListError(item)
                  item.errors = length
                  return { ...item }
                })

                const data = await Promise.all(errorsLength)
                setAppList(data)
                if (data.length !== 0) {
                  // get app details
                  if (!selectedApp) selectedApp = data[0].env
                  setSelectedAppId(selectedApp)
                  await getAppDetails(selectedApp)
                }
                break
              case 'router':
                setAppList(
                  response.data.map(app => {
                    return {
                      env: app.appId,
                      type: app.appType,
                      configurable: true,
                      service: 'router',
                      enabled: app.enabled,
                      appName: app.appId,
                      pk: app.pk,
                      sk: app.sk
                    }
                  })
                )
                if (!selectedApp) selectedApp = response.data[0].appId
                await getAppDetails(selectedApp, response.data[0].appType)
                break
              default:
                break
            }

            setIsLoading(false)
          }
        }
        callback()
      } else {
        hideLoading(setIsLoading)
        setIsLoading(false)
      }
    }

    showLoading(setIsLoading)
    getAppList(hideLoading)
    // eslint-disable-next-line
  }, [app])

  useEffect(() => {
    if (refresh) {
      setRefreshView(value => !value)
      setRefresh(false)
    }
  }, [validationForm, refresh])

  async function saveToDataBase(
    configuration,
    rollbackFunction,
    successMessage,
    generalSave = true,
    changeConfigured = false,
    refreshThisPage = false,
    errorMessage = 'Something went wrong, please try again later',
    process = selectedApp
  ) {
    let newConfiguration = cloneObject(validationForm)
    delete newConfiguration.displayMode
    delete newConfiguration.mapping

    if (process.includes('delete_')) {
      newConfiguration = cloneObject(configuration)
      delete newConfiguration.displayMode
      delete newConfiguration.mapping
      let keys = Object.keys(newConfiguration)
      keys.forEach(key => {
        if (typeof newConfiguration[key] === 'object') {
          if (newConfiguration[key].configurationOnClick)
            delete newConfiguration[key].configurationOnClick
          if (newConfiguration[key].parameters)
            delete newConfiguration[key].parameters
          if (newConfiguration[key].mappings)
            delete newConfiguration[key].mappings
        }
      })
    } else if (generalSave) {
      let keys = Object.keys(newConfiguration)
      keys.forEach(key => {
        if (typeof newConfiguration[key] === 'object') {
          if (newConfiguration[key].configurationOnClick)
            delete newConfiguration[key].configurationOnClick
          if (newConfiguration[key].parameters)
            delete newConfiguration[key].parameters
          if (newConfiguration[key].mappings)
            delete newConfiguration[key].mappings
        }
      })
      process = 'main'
    } else {
      if (!rollbackFunction) {
        let originalValidationsForm = cloneObject(validationForm)
        rollbackFunction = () => {
          setValidationForm(originalValidationsForm)
          setRefresh(true)
        }
      }

      if (changeConfigured) {
        newConfiguration[process].configured = true
      }

      newConfiguration[process].enableErrorNotification =
        configuration.enableErrorNotification
      newConfiguration[process].notificationsEmails =
        configuration.notificationsEmails

      newConfiguration[process].settings = configuration.settings
      delete newConfiguration.configurationOnClick
      delete newConfiguration.environment

      let keys = Object.keys(newConfiguration)
      keys.forEach(key => {
        if (typeof newConfiguration[key] === 'object' && key !== process) {
          delete newConfiguration[key]
        }
      })
    }

    showLoading()
    const response = await saveMonitorConfiguration(
      app,
      validationForm.env,
      newConfiguration,
      validationForm.service,
      process,
      validationForm.isNew
    )

    if (response.success) {
      setValidationMessage(successMessage)
      setSubmitSuccess(true)
      setTimeout(() => {
        setSubmitSuccess(false)
      }, 5000)

      if (response.data) {
        response.data.env = response.data.environment
        setValidationForm(response.data)
        if (refreshThisPage) {
          setRefresh(true)
        }
      }
    } else {
      setValidationMessage(errorMessage)
      setCreateError(true)
      setTimeout(() => {
        setCreateError(false)
      }, 5000)
      if (rollbackFunction) {
        rollbackFunction()
      }
    }
    scrollTop()
    hideLoading()
  }

  function deleteMonitorConsole(key) {
    let newValidationForm = cloneObject(validationForm)
    let originalValidationsForm = cloneObject(validationForm)
    let rollbackFunction = () => {
      setValidationForm(originalValidationsForm)
      setRefresh(true)
    }

    /* let keys = Object.keys(newValidationForm)
    keys = keys.filter(val => {
      return monitorConsoleRegEx.test(val)
    })

    keys = keys.sort((a, b) => {
      return a.localeCompare(b)
    })

    keys.forEach(value => {
      if (value > key) {
        let decrementKey = incrementAlphanumeric(value, false)
        let newItem = {
          ...newValidationForm[value],
          id: newValidationForm[decrementKey].id,
          title: newValidationForm[decrementKey].title
        }
        newValidationForm[decrementKey] = newItem
      }
    }) */

    delete newValidationForm[key]

    setValidationForm(newValidationForm)
    setRefresh(true)
    saveToDataBase(
      newValidationForm,
      rollbackFunction,
      'Monitor deleted successfully',
      false,
      false,
      false,
      'Something went wrong, please try again later',
      'delete_' + key
    )
  }

  function onAddMonitorConsole(newUniqueName) {
    let successMessage = 'Created Successfully'
    let originalValidationsForm = cloneObject(validationForm)
    let newValidationForms = validationForm

    let rollbackFunction = () => {
      setValidationForm(originalValidationsForm)
      setRefresh(true)
    }

    let newkey = 'monitorConsole' + (validationForm.monitorsId + 1)
    // let newName = 'Monitor Console ' + (validationForm.monitorsId + 1)
    /* if (keys.length > 0) {
      newkey = keys.reduce(function(p, v) {
        return p.localeCompare(v, undefined, {
          numeric: true,
          sensitivity: 'base'
        }) > 0
          ? p
          : v
      })
      if (!isNaN(Number(newkey))) {
        newkey = (Number(newkey) + 1).toString()
      } else {
        newkey = incrementAlphanumeric(newkey)
      }

      let keysNames = keys.map(key => {
        return newValidationForms[key].title
      })

      newName = keysNames.reduce(function(p, v) {
        return p.localeCompare(v, undefined, {
          numeric: true,
          sensitivity: 'base'
        }) > 0
          ? p
          : v
      })

      newName = incrementAlphanumeric(newName)
    } */
    newValidationForms[newkey] = {
      ...monitorConstants
    }

    newValidationForms.monitorsId += 1
    newValidationForms[newkey].description = newUniqueName
    newValidationForms[newkey].title = title
    newValidationForms[newkey].id = newkey
    newValidationForms[newkey].lastUpdate = new Date().getTime()

    setValidationForm(newValidationForms)
    setRefresh(true)
    saveToDataBase(
      newValidationForms[newkey],
      rollbackFunction,
      successMessage,
      false,
      false,
      true,
      'Something went wrong, please try again later',
      newkey
    )
  }

  function getAdminSteps() {
    let validationInitialState = validationForm
    let switchChildren = {}
    Object.keys(validationForm).forEach(key => {
      if (validationForm[key] && validationForm[key].configurable) {
        switchChildren[key] = validationForm[key]
        switchChildren[key].id = key
        switchChildren[key].configurationOnClick = (id, state) => {
          setShowConfiguration(true)
          setSelectedApp(id.toString())
          setConfigurationDetailsLabel(state.mapping.switchChildren[id].title)
          scrollTop()
        }
      }
    })

    switchChildren = Object.keys(switchChildren)
      .sort((a, b) => {
        return a.localeCompare(b)
      })
      // eslint-disable-next-line
      .reduce((r, k) => ((r[k] = switchChildren[k]), r), {})

    let mapping = {
      setService: setService,
      service: service,
      configureService: false,
      configureRealm: false,
      enabled: validationForm.enabled,
      mainSwitchText: 'Enable Monitor Console',
      mainSwitchOnChanged: (event, state) => {
        let newValidationFrom = validationForm
        validationForm.enabled = event.target.checked
        setValidationForm(newValidationFrom)
      },
      title: 'Monitor Console Settings',
      topChildren: '',
      bottomChildren: <div></div>,
      applicationTitle: 'Application',
      switchChildrenTitle: 'Configuration',
      switchChildren: switchChildren
    }

    validationInitialState.env = validationInitialState.environment
    validationInitialState.mapping = mapping
    validationInitialState.displayMode = 'edit'
    validationInitialState.addButtonText = 'Add Monitor Console'
    validationInitialState.modalTitle = 'Add New Monitor Console'
    validationInitialState.deleteModalMessage =
      'Are you sure you want to delete this monitor console?'
    validationInitialState.deleteButtonText = 'Delete monitor console'
    validationInitialState.setConfigurationDetails = () => {}
    validationInitialState.showAddButton = true
    validationInitialState.onAddButtonClick = onAddMonitorConsole
    validationInitialState.onDeleteConfirm = deleteMonitorConsole
    validationInitialState.setConfigurationDetails = setSelectedApp
    let steps = [
      {
        stepName: 'Configuration',
        stepComponent: AppMainMenu,
        stepId: 'configurations',
        initialState: validationInitialState
      }
    ]

    return steps
  }

  function returnWizardToDisplay() {
    return (
      <Wizard
        id="Wizard"
        color="primary"
        validate
        hideNavigation={props.displayMode === 'create'}
        title={configurationDetailsLabel + ' Configuration'}
        steps={getAdminSteps()}
        subtitle=""
        finishButtonClick={e => {
          let originalValidationsForm = cloneObject(validationForm)
          let rollbackFunction = () => {
            setValidationForm(originalValidationsForm)
            setRefresh(true)
          }
          saveToDataBase(
            validationForm,
            rollbackFunction,
            'Configurations Saved Succesfully'
          )
        }}
      />
    )
  }

  function returnConfigurationWizardToDisplay() {
    let renderSettings = cloneObject(validationForm[selectedApp].settings)
    renderSettings.forEach((setting, index) => {
      if (setting.isStep) {
        let keys = Object.keys(setting)
        keys.forEach(key => {
          if (key !== 'stepKey' && key !== 'isStep') {
            setting[key] = {}
            setting[key].value =
              validationForm[selectedApp].settings[index][key]
            setting[key].success = true
          }

          if (key === 'timeVerification') {
            setting[key].value = new Date(setting[key].value)
          }
        })
      } else {
        setting.stepOrder = {}
        setting.stepOrder.value =
          validationForm[selectedApp].settings[index].stepOrder
        setting.stepOrder.success = true
      }
    })

    return (
      <MonitorSettingsView
        backButtonOnClick={() => {
          setConfigurationDetailsLabel('')
          setShowConfiguration(false)
        }}
        title={configurationDetailsLabel + ' Configuration'}
        showError={showError}
        monitorSettings={renderSettings}
        configured={
          typeof validationForm[selectedApp].configured === 'undefined'
            ? false
            : validationForm[selectedApp].configured
        }
        validationForm={validationForm}
        app={app}
        monitorConstants={monitorConstants}
        saveToDataBase={saveToDataBase}
        selectedApp={selectedApp}
        environment={validationForm.environment}
        history={props.history}
      />
    )
  }

  function getView() {
    return (
      <div>
        {isLoading ? (
          ''
        ) : (
          <Fade in={true} timeout={250}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={3}
                className={classes.padding10}
              >
                <Card className={classes.appBar}>
                  <CardHeader>Application List</CardHeader>
                  <CardBody
                    style={{ paddingLeft: '15px', paddingRight: '15px' }}
                  >
                    <List>
                      {appList.map((prop, key) => {
                        return (
                          <ListItem
                            style={{ paddingLeft: '5px', paddingRight: '5px' }}
                            className={classes.listItem}
                            button
                            selected={selectedAppId === prop.env}
                            key={key}
                            onClick={() => {
                              setShowConfiguration(false)
                              setConfigurationDetailsLabel('')
                              onAppChanged(prop.env)
                              scrolltoTop()
                            }}
                          >
                            <ListItemAvatar>
                              {selectedAppId === prop.env ? (
                                <Avatar>
                                  <TouchApp />
                                </Avatar>
                              ) : (
                                <Avatar
                                  className={classes.clearAvatar}
                                ></Avatar>
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={prop.env}
                              secondary={`Type: ${prop.type}`}
                            />
                            {prop.errors && prop.errors > 0 ? (
                              <ListItemText style={{ marginLeft: '4px' }}>
                                <Button
                                  className={classes.textErrors}
                                  simple
                                  onClick={() => console.log('click')}
                                >
                                  <ReportProblemIcon fontSize="small" />
                                  {prop.errors}{' '}
                                  {prop.errors === 1 ? 'Error' : 'Errors'}
                                </Button>
                              </ListItemText>
                            ) : null}
                          </ListItem>
                        )
                      })}
                    </List>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={9}
                className={classes.padding10}
              >
                {!showConfiguration ? (
                  appList.length > 0 ? (
                    <Card>
                      <GridContainer spacing={4}>
                        <GridItem xs={12}>
                          {React.cloneElement(returnWizardToDisplay())}
                        </GridItem>
                      </GridContainer>
                    </Card>
                  ) : (
                    ''
                  )
                ) : (
                  <Card>
                    <GridContainer spacing={4}>
                      <GridItem xs={12}>
                        {React.cloneElement(
                          returnConfigurationWizardToDisplay()
                        )}
                      </GridItem>
                    </GridContainer>
                  </Card>
                )}
              </GridItem>
              <Snackbar
                place="br"
                color="success"
                icon={AddAlert}
                message={validationMessage}
                open={submitSuccess}
                closeNotification={() => setSubmitSuccess(false)}
                close
              />
              <Snackbar
                place="bl"
                color="danger"
                icon={AddAlert}
                message={validationMessage}
                open={createError}
                closeNotification={() => setCreateError(false)}
                close
              />
            </GridContainer>
          </Fade>
        )}
      </div>
    )
  }

  if (refreshView)
    return (
      <div key={'first'} ref={divRef}>
        {getView()}
      </div>
    )
  else
    return (
      <div key={'second'} ref={divRef}>
        {getView()}
      </div>
    )
}
