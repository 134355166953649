import React from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import { CircularProgress, Select, MenuItem, } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import CheckIcon from '@material-ui/icons/Check'
import AssessmentIcon from '@material-ui/icons/Assessment'
import CheckBox from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import NavPills from 'components/NavPills/NavPills.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Button from 'components/CustomButtons/Button.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import ExpiredDiv from 'components/ExpiredDiv/ExpiredDiv'
import NotificationAlert from 'components/NotificationAlert/NotificationAlert'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

const useStyles = makeStyles(styles)

function jsUcfirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export default function InsightsAriba(props) {
  const classes = useStyles()

  let breadcrumbViews = [
    {
      name: 'Relish Router',
      url: '/admin/router/index',
      icon: AssessmentIcon
    },
    {
      name: jsUcfirst(props.typeApp) + ' Connection',
      url: `/admin/router/${props.typeApp}/${props.selectedAppId}`,
      icon: CheckIcon
    }
  ]

  function showModal() {
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={props.showModal === true}
        keepMounted
        onClose={() => props.setShowModal(false)}
        aria-labelledby='classic-modal-slide-title'
        aria-describedby='classic-modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Error connection details</h4>
        </DialogTitle>
        <DialogContent
          id='classic-modal-slide-description'
          className={classes.modalBody}
        >
          {props.aribaConnectionMessage ? props.aribaConnectionMessage : null}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => props.setShowModal(false)}
            color='danger'
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function returnItkConnectionTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <h5 className={classes.sectionTitle}>SAP Ariba - Cloud Integration Gateway</h5>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <FormLabel className={classes.inputLabelHorizontal}>
                  Ariba Network ID (ANID)
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  success={props.configState.ANID === 'success'}
                  error={props.configState.ANID === 'error'}
                  labelText={
                    <span>
                      Ariba Network ID (ANID){' '}
                      <small>
                        {props.configState.ANID === 'error'
                          ? '(required)'
                          : '*'}{' '}
                      </small>
                    </span>
                  }
                  id='ANID'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: !props.expirationData.enabled,
                    onChange: event =>
                      props.onFieldChange('ANID', event.target.value),
                    value: props.config.ANID
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <Accordion expanded = {props.inboundAcc} onChange={ function() { return !props.setInboundAcc(!props.inboundAcc)}} key={'inbound'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            Inbound Requests (CIG to Relish)
          </AccordionSummary>
          <AccordionDetails>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      CIG Inbound Authentication Type
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                          style: { zIndex: 5555 }
                        }}
                        classes={{
                          select: classes.select,
                          disabled: classes.disabled
                        }}
                        value={props.config.inboundAuthenticationType}
                        onChange={e => props.onFieldChange("inboundAuthenticationType", e.target.value)}
                        inputProps={{
                          name: "inboundAuthenticationType",
                          id: "inboundAuthenticationType"
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                          value={"sharedSecret"}
                        >
                          Shared Secret
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      Ariba Inbound Client Id
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={props.configState.inboundClientId === 'success'}
                      error={props.configState.inboundClientId === 'error'}
                      labelText={
                        <span>
                          Ariba Inbound Client Id{' '}
                          <small>
                            {props.configState.inboundClientId === 'error'
                              ? '(required)'
                              : '*'}{' '}
                          </small>
                        </span>
                      }
                      id='inboundClientId'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !props.expirationData.enabled,
                        type: 'text',
                        value: props.config.inboundClientId,
                        onChange: event =>
                          props.onFieldChange('inboundClientId', event.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      Ariba Inbound Application Secret
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={props.configState.inboundSecret === 'success'}
                      error={props.configState.inboundSecret === 'error'}
                      labelText={
                        <span>
                          Ariba Inbound Secret{' '}
                          <small>
                            {props.configState.inboundSecret === 'error'
                              ? '(required)'
                              : '*'}{' '}
                          </small>
                        </span>
                      }
                      id='inboundSecret'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !props.expirationData.enabled,
                        type: 'password',
                        value: props.config.inboundSecret,
                        onChange: event =>
                          props.onFieldChange('inboundSecret', event.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color={
                    props.aribaConnectionConnected !== undefined
                      ? props.aribaConnectionConnected
                        ? 'info'
                        : 'danger'
                      : 'facebook'
                  }
                  onClick={() => props.onCheckAribaConnection('inbound')}
                  round
                  className={classes.documentation}
                  disabled={props.aribaConnectionTesting || !props.expirationData.enabled}
                >
                  {props.aribaConnectionConnected !== undefined ? (
                    props.aribaConnectionConnected ? (
                      <div>
                        <CheckBox className={classes.icons} /> Connected
                      </div>
                    ) : (
                      <div>
                        <Cancel className={classes.icons} /> Connection Error
                      </div>
                    )
                  ) : (
                    <div>
                      {props.aribaConnectionTesting
                        ? 'Loading...'
                        : 'Test Connectivity'}{' '}
                    </div>
                  )}
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {props.aribaConnectionMessage ? (
                  <Button
                    disabled={!props.expirationData.enabled}
                    color='danger'
                    simple
                    round
                    className={classes.documentation}
                    onClick={() => props.setShowModal(true)}
                  >
                    Error details
                  </Button>
                ) : null}
              </GridItem>
            </GridContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded = {props.outboundAcc} onChange={ function() { return !props.setOutboundAcc(!props.outboundAcc)}} key={'outbound'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            Outbound Requests (Relish To CIG)
          </AccordionSummary>
          <AccordionDetails>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      CIG Outbound Authentication Type
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <Select
                        MenuProps={{
                          className: classes.selectMenu,
                          style: { zIndex: 5555 }
                        }}
                        classes={{
                          select: classes.select,
                          disabled: classes.disabled
                        }}
                        value={props.config.outboundAuthenticationType}
                        onChange={e => props.onFieldChange("outboundAuthenticationType", e.target.value)}
                        inputProps={{
                          name: "outboundAuthenticationType",
                          id: "outboundAuthenticationType"
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          style={{ overflowX: "auto", textOverflow: "ellipsis" }}
                          value={"sharedSecret"}
                        >
                          Shared Secret
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      Ariba Outbound Client Id
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={props.configState.outboundClientId === 'success'}
                      error={props.configState.outboundClientId === 'error'}
                      labelText={
                        <span>
                          Ariba Outbound Client Id{' '}
                          <small>
                            {props.configState.outboundClientId === 'error'
                              ? '(required)'
                              : '*'}{' '}
                          </small>
                        </span>
                      }
                      id='outboundClientId'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !props.expirationData.enabled,
                        type: 'text',
                        value: props.config.outboundClientId,
                        onChange: event =>
                          props.onFieldChange('outboundClientId', event.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      Ariba Outbound Application Secret
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={props.configState.outboundSecret === 'success'}
                      error={props.configState.outboundSecret === 'error'}
                      labelText={
                        <span>
                          Ariba Outbound Secret{' '}
                          <small>
                            {props.configState.outboundSecret === 'error'
                              ? '(required)'
                              : '*'}{' '}
                          </small>
                        </span>
                      }
                      id='outboundSecret'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !props.expirationData.enabled,
                        type: 'password',
                        value: props.config.outboundSecret,
                        onChange: event =>
                          props.onFieldChange('outboundSecret', event.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color={
                    props.aribaOUTConnectionConnected !== undefined
                      ? props.aribaOUTConnectionConnected
                        ? 'info'
                        : 'danger'
                      : 'facebook'
                  }
                  onClick={() => props.onCheckAribaConnection('outbound')}
                  round
                  className={classes.documentation}
                  disabled={props.aribaOUTConnectionTesting || !props.expirationData.enabled}
                >
                  {props.aribaOUTConnectionConnected !== undefined ? (
                    props.aribaOUTConnectionConnected ? (
                      <div>
                        <CheckBox className={classes.icons} /> Connected
                      </div>
                    ) : (
                      <div>
                        <Cancel className={classes.icons} /> Connection Error
                      </div>
                    )
                  ) : (
                    <div>
                      {props.aribaOUTConnectionTesting
                        ? 'Loading...'
                        : 'Test Connectivity'}{' '}
                    </div>
                  )}
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {props.aribaOUTConnectionMessage ? (
                  <Button
                    disabled={!props.expirationData.enabled}
                    color='danger'
                    simple
                    round
                    className={classes.documentation}
                    onClick={() => props.setShowModal(true)}
                  >
                    Error details
                  </Button>
                ) : null}
              </GridItem>
            </GridContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded = {props.aribaAcc} onChange={ function() { return !props.setaribaAcc(!props.aribaAcc)}} key={'ariba'}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            Ariba Network API
          </AccordionSummary>
          <AccordionDetails>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      Ariba Network API Key
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={props.configState.aribaNetworkApiKey === 'success'}
                      error={props.configState.aribaNetworkApiKey === 'error'}
                      labelText={
                        <span>
                          Ariba Network API Key{' '}
                          <small>
                            {props.configState.aribaNetworkApiKey === 'error'
                              ? '(required)'
                              : '*'}{' '}
                          </small>
                        </span>
                      }
                      id='aribaNetworkApiKey'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !props.expirationData.enabled,
                        type: 'text',
                        value: props.config.aribaNetworkApiKey,
                        onChange: event =>
                          props.onFieldChange('aribaNetworkApiKey', event.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      Ariba Network Client Id
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={props.configState.aribaNetworkClientId === 'success'}
                      error={props.configState.aribaNetworkClientId === 'error'}
                      labelText={
                        <span>
                          Ariba Network Client Id{' '}
                          <small>
                            {props.configState.aribaNetworkClientId === 'error'
                              ? '(required)'
                              : '*'}{' '}
                          </small>
                        </span>
                      }
                      id='aribaNetworkClientId'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !props.expirationData.enabled,
                        type: 'text',
                        value: props.config.aribaNetworkClientId,
                        onChange: event =>
                          props.onFieldChange('aribaNetworkClientId', event.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <FormLabel className={classes.inputLabelHorizontal}>
                      Ariba Network Secret
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <CustomInput
                      success={props.configState.aribaNetworkSecret === 'success'}
                      error={props.configState.aribaNetworkSecret === 'error'}
                      labelText={
                        <span>
                          Ariba Network Secret{' '}
                          <small>
                            {props.configState.aribaNetworkSecret === 'error'
                              ? '(required)'
                              : '*'}{' '}
                          </small>
                        </span>
                      }
                      id='aribaNetworkSecret'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: !props.expirationData.enabled,
                        type: 'password',
                        value: props.config.aribaNetworkSecret,
                        onChange: event =>
                          props.onFieldChange('aribaNetworkSecret', event.target.value)
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color={
                    props.aribaNetworkConnectionConnected !== undefined
                      ? props.aribaNetworkConnectionConnected
                        ? 'info'
                        : 'danger'
                      : 'facebook'
                  }
                  onClick={() => props.onCheckAribaConnection('ariba')}
                  round
                  className={classes.documentation}
                  disabled={props.aribaNetworkConnectionTesting || !props.expirationData.enabled}
                >
                  {props.aribaNetworkConnectionConnected !== undefined ? (
                    props.aribaNetworkConnectionConnected ? (
                      <div>
                        <CheckBox className={classes.icons} /> Connected
                      </div>
                    ) : (
                      <div>
                        <Cancel className={classes.icons} /> Connection Error
                      </div>
                    )
                  ) : (
                    <div>
                      {props.aribaNetworkConnectionTesting
                        ? 'Loading...'
                        : 'Test Connectivity'}{' '}
                    </div>
                  )}
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {props.aribaNetworkConnectionMessage ? (
                  <Button
                    disabled={!props.expirationData.enabled}
                    color='danger'
                    simple
                    round
                    className={classes.documentation}
                    onClick={() => props.setShowModal(true)}
                  >
                    Error details
                  </Button>
                ) : null}
              </GridItem>
            </GridContainer>
          </AccordionDetails>
        </Accordion>
        <GridContainer justify='center'>
          <Button
            disabled={props.isLoadingSave || !props.expirationData.enabled || !props.aribaConnectionConnected || !props.aribaOUTConnectionConnected || !props.aribaNetworkConnectionConnected || !(props.config.ANID !== '')}
            variant='outlined'
            color='primary'
            onClick={() => props.onConfigSave()}
          >
            {!props.isLoadingSave ? 'Save' : 'Loading...'}
          </Button>
        </GridContainer>
        {showModal()}
      </GridItem>
    )
  }

  function returnTabsToDisplay() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <div style={{ position: "relative" }}>
          <NotificationAlert variant='filled' severity='warning' appInfo={props.expirationData} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>
                Relish Router - {jsUcfirst(props.typeApp)}
              </h3>
            </CardHeader>
            <CardBody>
              <NavPills
                action={actions => {
                  if (actions) {
                    props.updateHeightFunction(actions.updateHeight)
                  }
                }}
                animateHeight={false}
                color='primary'
                tabs={[
                  {
                    tabButton: 'Ariba Connection',
                    tabContent: returnItkConnectionTab()
                  }
                ]}
              />
            </CardBody>
          </Card>
          <ExpiredDiv appInfo={props.expirationData} />
        </div>
      </GridItem>
    )
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {props.appList.map((prop, key) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    button
                    selected={props.selectedAppId === prop.appId}
                    key={key}
                    onClick={() => props.onAppChanged(prop.appId)}
                  >
                    <ListItemAvatar>
                      {props.selectedAppId === prop.appId ? (
                        <Avatar>
                          <TouchApp />
                        </Avatar>
                      ) : (
                        <Avatar className={classes.clearAvatar}></Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={prop.appId}
                      secondary={`Type: ${prop.appType}`}
                    />
                  </ListItem>
                )
              })}
            </List>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        {props.formIsLoading ? (
          <Card>
            <GridContainer justify='center'>
              <div className={classes.circularProgress}>
                <CircularProgress color='inherit' />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
      <Snackbar
        place='br'
        color='success'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.submitSuccess}
        closeNotification={() => props.setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place='bl'
        color='danger'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.createError}
        closeNotification={() => props.setCreateError(false)}
        close
      />
    </GridContainer>
  )
}
