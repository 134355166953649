import React, { useState, useEffect } from 'react'

import MaterialTable from 'material-table'
import { useQueryClient } from 'react-query'

// Router
import { useHistory } from 'react-router'

// Material UI
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Fab from '@material-ui/core/Fab'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import TablePagination from '@material-ui/core/TablePagination'

// Icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import CheckIcon from '@material-ui/icons/Check'
import Add from '@material-ui/icons/PlaylistAdd'
import AssessmentIcon from '@material-ui/icons/Assessment'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

// Constants
import { insightsDeluxeTitles } from 'utils/Constants'

// Components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import { TablePaginationActions } from 'components/Invoice/TablePaginationActions'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Hooks
import { useGetTransactionList, useChangeStatusTransaction } from 'hooks/useInvoiceConf'

import { useGetAppList, useGetAppConfigurationById } from 'hooks/useApp'

//  Views
import TabPanel from './TabPanel'
import InvoiceGroupForm from './InvoiceGroupForm'
import InvoiceRaasForm from 'views/InvoiceWorkday/Group/raas/InvoiceRaasForm'

const useStyles = makeStyles(styles)

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const appType = 'invoiceAI'

/*
  columns
  group,
  appid
  path
  InvoiceGroupForm
 */

export default function InvoiceGroup(params) {
  const {
    columns,
    group,
    appId,
    path,
    isLoading,
    connectionObj,
    schema,
    fields,
    modifyData,
    connected
  } = params

  const classes = useStyles()
  const history = useHistory()
  const [tab, setTab] = useState(0)

  const queryClient = useQueryClient()
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPage: 10,
    lastPage: -1,
    lastEvaluatedKeys: {}
  })
  const [submitSuccess, setSubmitSuccess] = useState({
    isOpen: false,
    message: ''
  })
  const [createError, setCreateError] = useState({
    message: '',
    isOpen: false,
    color: 'danger'
  })

  let breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAI/configurations',
      icon: AssessmentIcon
    },
    {
      name: insightsDeluxeTitles[group] + ' Connection',
      url: path.main,
      icon: CheckIcon
    }
  ]

  const appList = useGetAppList(appType)

  const app = useGetAppConfigurationById(appId)
  useEffect(() => {
    if (!appList.isLoading && appList.isError) {
      setCreateError({
        message: appList.error.response?.data?.message ?? 'Something went wrong, try again later',
        isOpen: true,
        color: 'danger'
      })
    }
  }, [appList.isLoading, appList.isError, appList.error])

  const {
    data: transactionList,
    isLoading: isLoadingTransactionList,
    error: errorTransactionList,
    isError: isErrorTransactionList,
    refetch: refetchTransactionList,
    isFetching: isRefetchingTransactionList
  } = useGetTransactionList({
    appId: app.data?.data.insightsAppId,
    group,
    pagination,
    options: { refetchOnWindowFocus: false }
  })

  useEffect(() => {
    if (!isErrorTransactionList) {
      return
    }
    setCreateError({
      isOpen: true,
      message:
        errorTransactionList.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [isErrorTransactionList, errorTransactionList])

  useEffect(() => {
    if (!transactionList) {
      return
    }

    if (!transactionList?.lastEvaluatedKey) {
      setPagination(old => {
        return {
          ...old,
          lastPage: old.page
        }
      })
      return
    }

    setPagination(old => {
      return {
        ...old,
        lastEvaluatedKeys: {
          ...old.lastEvaluatedKeys,
          [old.page]: transactionList?.lastEvaluatedKey
        }
      }
    })
  }, [transactionList])

  const {
    mutate: changeStatusTransaction,
    isLoading: isLoadingChangeStatus
  } = useChangeStatusTransaction({
    appId: app.data?.data.insightsAppId
  })

  function onStartTransaction(transactionId) {
    changeStatusTransaction(
      { data: { changeStatus: { status: 'new' }, transactionId: transactionId } },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Transactions', 'appId', appId])
        },
        onSuccess: response => {
          if (response.status === 200) {
            setSubmitSuccess({ message: 'Starting...', isOpen: true })
            refetchTransactionList({ throwOnError: false })
          }
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  function onStopTransaction(transactionId) {
    changeStatusTransaction(
      { data: { changeStatus: { status: 'stopped' }, transactionId: transactionId } },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Transactions', 'appId', appId])
        },
        onSuccess: response => {
          if (response.status === 200) {
            setSubmitSuccess({ message: 'Stopping...', isOpen: true })
            refetchTransactionList({ throwOnError: false })
          }
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  function onDeleteTransaction(transactionId) {
    changeStatusTransaction(
      { data: { changeStatus: { status: 'deleted' }, transactionId: transactionId } },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Transactions', 'appId', appId])
        },
        onSuccess: response => {
          if (response.status === 200) {
            setSubmitSuccess({ message: 'Deleting...', isOpen: true })
            refetchTransactionList({ throwOnError: false })
          }
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  const handleChangePage = (event, newPage) => {
    setPagination(old => {
      return {
        ...old,
        page: newPage
      }
    })
  }

  const handleChange = (event, newValue) => {
    setTab(newValue)
  }

  function addTransaction() {
    let validConnection = false
    if (app.data?.data?.params[app.data?.data?.service]) {
      validConnection = app.data?.data?.params[app.data?.data?.service][group] ? true : false
    }

    if (!app.data?.data?.params.remoteConnections || !validConnection) {
      setCreateError({
        isOpen: true,
        message: 'You have to create a valid connection.'
      })
    } else {
      history.push(path.addTransaction)
    }
    // history.push(path.addTransaction)
  }

  function invoiceGroupForm() {
    let form

    switch (group) {
      case 'raasApi':
        form = (
          <InvoiceRaasForm
            app={app}
            setCreateError={setCreateError}
            setSubmitSuccess={setSubmitSuccess}
            setTab={setTab}
            schema={schema}
            connectionObj={connectionObj}
            connected={connected}
            fields={fields}
            modifyData={modifyData}
          />
        )
        break

      default:
        form = (
          <InvoiceGroupForm
            app={app}
            setCreateError={setCreateError}
            setSubmitSuccess={setSubmitSuccess}
            setTab={setTab}
            schema={schema}
            connectionObj={connectionObj}
            connected={connected}
            fields={fields}
            modifyData={modifyData}
          />
        )
        break
    }
    return form
  }

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={12} sm={3} md={3} lg={3}>
        <Card style={{ height: '100%' }}>
          <CardContent>Application List</CardContent>
          <CardContent>
            {appList.isLoading ? (
              <Grid container justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </Grid>
            ) : (
              <List>
                {appList.data?.map((prop, key) => {
                  return (
                    <ListItem
                      className={classes.listItem}
                      button
                      selected={appId === prop.env}
                      key={key}
                      onClick={() => {
                        history.push('/admin/invoiceAI/configurations')
                      }}
                    >
                      <ListItemAvatar>
                        {appId === prop.env ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText primary={prop.appName} secondary={`Type: ${prop.type}`} />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={9} md={9} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        <Card>
          <CardContent>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Configurations" {...a11yProps(0)} />
                  <Tab label="Connection" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={tab} index={0}>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={12}>
                      <h5 className={classes.sectionTitle}>
                        {insightsDeluxeTitles[group]} Transactions
                        <Tooltip title="Add Transaction">
                          <IconButton
                            color="primary"
                            component="span"
                            size="small"
                            disabled={isLoading}
                            className={classes.marginLeft}
                            onClick={() => addTransaction()}
                          >
                            <Add fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                        {!isLoadingTransactionList ? (
                          <Fab
                            size="small"
                            color="primary"
                            className={classes.floatRight + ' ' + classes.bgColorPrimary}
                            onClick={() => refetchTransactionList({ throwOnError: false })}
                          >
                            <RefreshRoundedIcon fontSize="small" />
                          </Fab>
                        ) : null}
                        <br />
                      </h5>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {isLoadingTransactionList || isRefetchingTransactionList ? (
                        <Grid container justifyContent="center">
                          <div className={classes.circularProgress}>
                            <CircularProgress color="inherit" />
                          </div>
                        </Grid>
                      ) : (
                        <Grid container justifyContent="center">
                          <Grid item xs={12} sm={12} md={12}>
                            <MaterialTable
                              columns={columns}
                              data={transactionList?.rows ?? []}
                              actions={[
                                {
                                  icon: 'edit',
                                  tooltip: 'Edit Transaction',
                                  onClick: (event, rowData) =>
                                    history.push(`${path.edit}/${rowData.id}`)
                                },
                                rowData => ({
                                  icon: 'chevron_right',
                                  tooltip: 'Start Transaction',
                                  onClick: (event, rowData) => onStartTransaction(rowData.id),
                                  disabled:
                                    (rowData.status !== 'new' && rowData.status !== 'stopped') ||
                                    isLoadingChangeStatus
                                }),
                                rowData => ({
                                  icon: () => <NotInterestedIcon />,
                                  tooltip: 'Stop Transaction',
                                  onClick: (event, rowData) => onStopTransaction(rowData.id),
                                  disabled:
                                    rowData.status === 'new' ||
                                    rowData.status === 'stopped' ||
                                    rowData.status === 'completed' ||
                                    isLoadingChangeStatus
                                }),
                                {
                                  icon: 'delete',
                                  tooltip: 'Delete Transaction',
                                  onClick: (event, rowData) => onDeleteTransaction(rowData.id)
                                },
                                {
                                  icon: 'view_column',
                                  tooltip: 'Transaction Details',
                                  onClick: (event, rowData) =>
                                    history.push(`${path.details}/${rowData.id}`)
                                }
                              ]}
                              options={{
                                actionsColumnIndex: -1,
                                sorting: true,
                                showTitle: false,
                                emptyRowsWhenPaging: false,
                                pageSize: pagination.rowsPage,
                                pageSizeOptions: [10, 20, 30, 40, 50]
                              }}
                              onChangeRowsPerPage={pageSize => {
                                setPagination({
                                  page: 0,
                                  rowsPage: pageSize,
                                  lastEvaluatedKeys: []
                                })
                              }}
                              components={{
                                Pagination: props => {
                                  return (
                                    <TablePagination
                                      {...props}
                                      onChangePage={handleChangePage}
                                      ActionsComponent={subProps => (
                                        <TablePaginationActions
                                          {...subProps}
                                          pagination={pagination}
                                        />
                                      )}
                                    />
                                  )
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  {isLoading ? (
                    <Grid container justifyContent="center">
                      <div className={classes.circularProgress}>
                        <CircularProgress color="inherit" />
                      </div>
                    </Grid>
                  ) : (
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <h5 className={classes.sectionTitle}>
                          {insightsDeluxeTitles[group]} Connection
                        </h5>
                      </Grid>
                      <Grid item xs={12}>
                        {invoiceGroupForm()}
                      </Grid>
                    </Grid>
                  )}
                </TabPanel>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Snackbar
        place="br"
        color="success"
        icon={AddAlert}
        message={submitSuccess.message}
        open={submitSuccess.isOpen}
        closeNotification={() =>
          setSubmitSuccess({
            isOpen: false,
            message: ''
          })
        }
        close
      />
      <Snackbar
        place="bl"
        color="danger"
        icon={AddAlert}
        message={createError.message}
        open={createError.isOpen}
        closeNotification={() =>
          setCreateError({
            isOpen: false,
            message: ''
          })
        }
        close
      />
    </Grid>
  )
}
