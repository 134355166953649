import ESigners from 'views/ESigners/ESigners.js'
import Dashboard from 'views/MainDashboard/Dashboard.js'

// Tenant
import CreateTenants from 'views/Tenants/Create'
import EditTenants from 'views/Tenants/Edit'
import IndexTenants from 'views/Tenants/Index'

// Users
import CreateUsers from 'views/Users/Create/CreateView'
import EditUsers from 'views/Users/Edit'
import IndexUsers from 'views/Users/Index'

// Apps
import NitorConnect from 'views/Connect'
import NitorConnectLoginStart from 'views/Connect/Login/LoginStart'

import AddressQuestionnaire from 'views/AddressQuestionnaire'
import AddressValidation from 'views/AddressValidation'
import DataAssure from 'views/DataAssure'
import DataAssureCount from 'views/DataAssureCount'
import DataAssureCountType from 'views/DataAssureCountType'
import DataAssureRetry from 'views/DataAssureRetry'
import NitorConnectDocumentation from 'views/Documentation/NitorConnectDocumentation'
import EmailView from 'views/Emails/EmailContainer'
import EmailsView from 'views/Emails/EmailListContainer'
import InsightsDetails from 'views/Insights/Details/InsightsDetails'
import InsightsGroup from 'views/Insights/Group/InsightsGroup'
import InsightsViews from 'views/Insights/Principal/InsightsPrincipal'
import InsightsTransaction from 'views/Insights/Transaction/InsightsTransaction'
import InsightsAriba from 'views/InsightsAriba'
import InsightsLoad from 'views/InsightsLoad'
import InsightsLogs from 'views/InsightsLogs'
import Intelligence from 'views/Intelligence'
import IntelligenceAriba from 'views/IntelligenceAriba'
import IntelligenceLoad from 'views/IntelligenceLoad'
import IntelligenceLogs from 'views/IntelligenceLogs'
import InvoiceAIView from 'views/InvoiceAI/InvoiceAIView.jsx'
import InvoiceAIDiff from 'views/InvoiceException/Difference'
import MasterDataDownloads from 'views/MasterDataDownload/MasterDataDownloadView'
import MasterDataPO from 'views/MasterDataPO/MasterDataPO'
import MasterDataPOView from 'views/MasterDataPO/MasterDataPOView'
import NitorInsights from 'views/NitorInsights'
import { PaymentDetails } from 'views/Payments/Details/PaymentDetails'
import { PaymentList } from 'views/Payments/List/PaymentList'
import Qualtrics from 'views/Qualtrics'
import QualtricsAriba from 'views/QualtricsAriba'
import QualtricsLoad from 'views/QualtricsLoad'
import QualtricsLogs from 'views/QualtricsLogs'
import TinValidation from 'views/TinValidation'
import Tmobile from 'views/Tmobile'
import TmobileDetails from 'views/TmobileDetails'
import InvoiceEditMasterDataRules from 'views/ValidationRules/MasterData/EditMasterDataRulesView'
import InvoiceEditOcrRules from 'views/ValidationRules/Ocr/EditOcrRulesView'
import InvoiceValidationRules from 'views/ValidationRules/ValidationRulesView'

import InvoiceDetailsView from 'views/InvoiceDetails/InvoiceDetails'

import ConnectDefault from 'views/ConnectDefault'

import Invoice from 'views/Invoice'
import InvoiceAriba from 'views/InvoiceAriba'
import InvoiceDetails from 'views/InvoiceCoupa/Details/InsightsDetails'
import InvoiceGroup from 'views/InvoiceCoupa/Group/InvoiceGroup'
import InvoiceTransaction from 'views/InvoiceCoupa/Transaction/InvoiceTransaction'

import InvoiceDetailsS4Hana from 'views/InvoiceS4Hana/Details/InsightsDetails'
import InvoiceGroupS4Hana from 'views/InvoiceS4Hana/Group/InvoiceGroup'
import InvoiceTransactionS4Hana from 'views/InvoiceS4Hana/Transaction/InvoiceTransaction'

import InvoiceLoad from 'views/InvoiceLoad'
import InvoiceLogs from 'views/InvoiceLogs'
import InvoiceUserImport from 'views/UserImport/UserImportContainer'

import MonitorConsoleConfigurations from 'views/MonitorConsoleConfigurations/MonitorConsoleConfigurations'
import MonitorTransactionDetailsView from 'views/MonitorConsoleDetails/MonitorTransactionDetailsView'
import MonitorDiagramView from 'views/MonitorDiagramView/MonitorDiagramView'
import PIIEmailsView from 'views/PIIEmails/PIIEmailsView'
import DaBatchMainApp from 'views/UploadFileValidations/DaBatchMain'

//workday
import InvoiceGroupWorkday from 'views/InvoiceWorkday/Group/InvoiceGroupWorkday'
import AddWorkdayTransaction from 'views/InvoiceWorkday/Transaction/AddWorkdayTransaction'
import WorkdayTransactionDetail from 'views/InvoiceWorkday/Details/WorkdayTransactionDetail'
// PriceVerify
import DownloadHistory from 'views/PriceVerify/DownloadHistory'

// Pricebook
import PriceBook from 'views/PriceBook'

// support tickets
import CreateSupportTicket from 'views/SupportTickets/Create'
import IndexSupportTickets from 'views/SupportTickets/Index'
import SupportTicketDetails from 'views/SupportTicketsDetails'

// router
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle'
import Router from 'views/Router'
import RouterAriba from 'views/RouterAriba'

// @material-ui/icons

import AddCircleIcon from '@material-ui/icons/AddCircle'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import Business from '@material-ui/icons/Business'
import CloudQueue from '@material-ui/icons/CloudCircle'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import { default as LibraryBooksIcon } from '@material-ui/icons/LibraryBooks'
import PieChartIcon from '@material-ui/icons/PieChart'
import ReceiptIcon from '@material-ui/icons/Receipt'
import SettingsIcon from '@material-ui/icons/Settings'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import Web from '@material-ui/icons/Web'
//Documentation
import AribaPDF from 'components/DocumentPDF/showAribaPDF'
import CoupaPDF from 'components/DocumentPDF/showCoupaPDF'

import TeamsRedirect from 'views/Connect/TeamsRedirect'
import DataAssureDnBMissing from 'views/DataAssure/DnB/DnBMissing'
import Imports from 'views/InvoiceAI/Imports'
import InvoiceAINotifications from 'views/InvoiceAIAdditionalConfiguration/InvoiceAINotificationsConfiguration'
import InvoiceImportDetails from 'views/InvoiceImport/InvoiceImportDetails'
import InvoiceImportLoad from 'views/InvoiceImport/InvoiceImportLoad'
import MonitorConsoleView from 'views/MonitorConsole/MonitorConsoleView'
import MonitorDetailsView from 'views/MonitorConsoleDetails/MonitorConsoleDetailsView'
import { ViewsPermissions } from '../utils/Constants'
import InvoiceOriginConfiguration from 'views/InvoiceAIAdditionalConfiguration/InvoiceOriginConfiguration'

const ViewsConfigurations = {
  TENANTS: {
    INDEX: {
      path: '/tenants/index',
      lookup: '/tenants',
      name: 'Tenants',
      mini: 'C',
      icon: Business,
      component: IndexTenants,
      layout: '/admin'
    },
    CREATE: {
      path: '/tenants/create',
      name: 'Create Tenant',
      redirect: true,
      component: CreateTenants,
      layout: '/admin'
    },
    EDIT: {
      path: '/tenants/edit/:id',
      name: 'Edit Tenant',
      redirect: true,
      component: EditTenants,
      layout: '/admin'
    }
  },
  USERS: {
    INDEX: {
      path: '/users/index',
      lookup: '/users',
      name: 'Users',
      mini: 'U',
      icon: SupervisorAccount,
      component: IndexUsers,
      layout: '/admin'
    },
    CREATE: {
      path: '/users/create',
      name: 'Create User',
      redirect: true,
      component: CreateUsers,
      layout: '/admin'
    },
    EDIT: {
      path: '/users/edit/:idTenant/:id',
      name: 'Edit User',
      redirect: true,
      component: EditUsers,
      layout: '/admin'
    }
  },
  INVOICEAI: {
    INDEX: {
      path: '/invoiceAI/index',
      name: 'Invoices',
      mini: 'IN',
      component: InvoiceAIView,
      layout: '/admin',
      lookup: '/invoiceAI/index'
    },
    PAYMENTS: {
      path: '/invoiceAI/payments',
      name: 'Payment Receipts',
      mini: 'PY',
      component: PaymentList,
      layout: '/admin',
      lookup: '/invoiceAI/payments'
    },
    EMAILS: {
      path: '/invoiceAI/emails',
      name: 'Emails',
      mini: 'EM',
      component: EmailsView,
      layout: '/admin',
      lookup: '/invoiceAI/emails'
    },
    COMPARE: {
      path: '/invoiceAI/:invoiceId/compare',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceAIDiff,
      layout: '/admin',
      lookup: '/invoiceAI/'
    },
    EMAIL_DETAILS: {
      path: '/invoiceAI/email/:emailId/details',
      name: 'Emails',
      redirect: true,
      component: EmailView,
      layout: '/admin',
      lookup: '/invoiceAI/email'
    },
    // EXCEPTIONS: {
    //     path: '/invoiceAI/exceptions',
    //     name: 'Exceptions',
    //     mini: '.',
    //     component: InvoiceExceptions,
    //     layout: '/admin',
    //     lookup: '/invoiceAI/exceptions'
    // },
    IMPORT: {
      path: '/invoiceAI/fileImport',
      name: 'Imported Files',
      mini: 'IMP',
      component: Imports,
      layout: '/admin',
      lookup: '/invoiceAI/fileImport'
    },
    IMPORT_UPLOAD: {
      path: '/invoiceAI/upload/import',
      name: 'Import New File',
      redirect: true,
      component: InvoiceImportLoad,
      layout: '/admin',
      lookup: '/invoiceAI/import'
    },
    IMPORT_DETAILS: {
      path: '/invoiceAI/import/:importId/details',
      name: 'File Details',
      redirect: true,
      component: InvoiceImportDetails,
      layout: '/admin',
      lookup: '/invoiceAI/import'
    },
    CONFIGURATIONS: {
      path: '/invoiceAI/configurations',
      name: 'Configurations',
      mini: 'CF',
      component: Invoice,
      layout: '/admin',
      lookup: '/invoiceAI/configurations'
    },
    MASTERDATA: {
      path: '/invoiceAI/dataexport',
      name: 'Data Export',
      mini: 'DE',
      component: MasterDataDownloads,
      layout: '/admin',
      lookup: '/invoiceAI/dataexport'
    },
    /* RULE_ENGINE: {
      path: '/platform/invoiceAI/rules/',
      layout: window?.top?.location.origin,
      name: 'Custom Rules',
      mini: 'CR',
      external: true,
      target: '_blank',
      to: '/platform/invoiceAI/rules/'
    }, */
    MASTERDATA_PO: {
      redirect: true,
      path: '/invoiceAI/masterData/purchaseOrder/:poId?',
      name: 'Purchase Order',
      mini: 'PO',
      component: MasterDataPO,
      layout: '/admin',
      lookup: '/invoiceAI/masterData/purchaseOrder/index'
    },
    MASTERDATA_PO_INDEX: {
      path: '/invoiceAI/masterData/purchaseOrder/index',
      name: 'Purchase Orders',
      mini: 'PO',
      component: MasterDataPOView,
      layout: '/admin',
      lookup: '/invoiceAI/masterData/purchaseOrder/index'
    },
    // CONNECTIONS: {
    //   path: '/invoiceAI/connections/:id',
    //   lookup: '/invoiceAI/',
    //   name: 'invoice AI',
    //   redirect: true,
    //   component: InvoiceConnections,
    //   layout: '/admin'
    // },
    LOGS: {
      path: '/invoiceAI/details/:id/:type/:idLoad',
      lookup: '/invoiceAI/',
      name: 'invoice AI',
      redirect: true,
      component: InvoiceLogs,
      layout: '/admin'
    },
    LOADS: {
      path: '/invoiceAI/load/:id/:type/:idLoad?',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceLoad,
      layout: '/admin'
    },
    DETAILS: {
      path: '/invoiceAI/:invoiceId/details',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceDetailsView,
      layout: '/admin'
    },
    PAYMENT_DETAILS: {
      path: '/invoiceAI/payment/:paymentId/details',
      lookup: '/invoiceAI/payments',
      name: 'Payment Receipts Details',
      redirect: true,
      component: PaymentDetails,
      layout: '/admin'
    },
    ADDITIONAL_CONFIGURATIONS: {
      path: '/invoiceAI/additionalConfigurations/Notifications/:id',
      lookup: '/invoiceAI/additionalConfigurations/Notifications/',
      name: 'Invoice AI Notifications',
      redirect: true,
      component: InvoiceAINotifications,
      layout: '/admin'
    },
    INVOICE_ORIGIN_CONFIGURATION: {
      path: '/invoiceAI/additionalConfigurations/invoiceOrigin/:id',
      lookup: '/invoiceAI/additionalConfigurations/invoiceOrigin/',
      name: 'Invoice Origin Configuration',
      redirect: true,
      component: InvoiceOriginConfiguration,
      layout: '/admin'
    },
    INVOICE_ARIBA: {
      path: '/invoiceAI/:type/:id',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceAriba,
      layout: '/admin'
    },
    INVOICE_TRANSACTION: {
      path: '/invoiceAI/coupa/transaction/:group/:appId/:transactionId?',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceTransaction,
      layout: '/admin'
    },
    INVOICE_DETAILS: {
      path: '/invoiceAI/coupa/detail/:group/:appId/:transactionId',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceDetails,
      layout: '/admin'
    },
    INVOICE_GROUP: {
      path: '/invoiceAI/coupa/:group/:appId',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceGroup,
      layout: '/admin'
    },
    USER_IMPORT: {
      path: '/invoiceAI/userImport',
      name: 'User Integration',
      mini: 'UI',
      component: InvoiceUserImport,
      layout: '/admin',
      lookup: '/invoiceAI/userImport'
    },
    MD_VALIDATION_RULES_EDIT: {
      path: '/invoiceAI/:appId/masterDataValidationRules/:ruleId/edit',
      lookup: '/invoiceAI/',
      name: 'Edit Master Data Rules',
      redirect: true,
      component: InvoiceEditMasterDataRules,
      layout: '/admin'
    },
    OCR_VALIDATION_RULES_EDIT: {
      path: '/invoiceAI/:appId/ocrValidationRules/:ruleId/edit',
      lookup: '/invoiceAI/',
      name: 'Edit Ocr Rules',
      redirect: true,
      component: InvoiceEditOcrRules,
      layout: '/admin'
    },
    VALIDATION_RULES: {
      path: '/invoiceAI/validationRules',
      name: 'Validation Rules',
      mini: 'VR',
      component: InvoiceValidationRules,
      layout: '/admin',
      lookup: '/invoiceAI/validationRules'
    },
    INVOICE_TRANSACTION_S4HANA: {
      path: '/invoiceAI/s4Hana/transaction/:group/:appId/:transactionId?',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceTransactionS4Hana,
      layout: '/admin'
    },
    INVOICE_DETAILS_S4HANA: {
      path: '/invoiceAI/s4Hana/detail/:group/:appId/:transactionId',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceDetailsS4Hana,
      layout: '/admin'
    },
    INVOICE_GROUP_S4HANA: {
      path: '/invoiceAI/s4Hana/:group/:appId',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceGroupS4Hana,
      layout: '/admin'
    },
    INVOICE_TRANSACTION_WORKDAY: {
      path: '/invoiceAI/workday/transaction/:group/:appId/:transactionId?',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: AddWorkdayTransaction,
      layout: '/admin'
    },
    INVOICE_DETAILS_WORKDAY: {
      path: '/invoiceAI/workday/detail/:group/:appId/:transactionId',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: WorkdayTransactionDetail,
      layout: '/admin'
    },
    INVOICE_GROUP_WORKDAY: {
      path: '/invoiceAI/workday/:group/:appId',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      redirect: true,
      component: InvoiceGroupWorkday,
      layout: '/admin'
    }
  }
}

function mapMonitorConsole(permissions) {
  let monitorMenu = []

  if (checkViewPermissions(permissions, 'MONITOR_CONSOLE', 'INDEX')) {
    monitorMenu = [
      {
        collapse: true,
        name: 'Monitor Console',
        icon: DesktopWindowsIcon,
        state: 'monitorCollapse',
        views: []
      }
    ]

    monitorMenu[0].views.push({
      path: '/monitorConsole/index',
      name: 'Monitor Console',
      mini: 'MC',
      component: MonitorConsoleView,
      layout: '/admin',
      lookup: '/monitorConsole/index'
    })

    monitorMenu.push({
      path: '/monitorConsole/transactions/:env/:appId/:monitorId/:description',
      lookup: '/monitorConsole/transactions/',
      name: 'Monitor Details',
      redirect: true,
      component: MonitorDetailsView,
      layout: '/admin'
    })

    monitorMenu.push({
      path: '/monitorConsole/details/:env/:appId/:monitorId/:description/:processId',
      lookup: '/monitorConsole/details/',
      name: 'Monitor Details',
      redirect: true,
      component: MonitorTransactionDetailsView,
      layout: '/admin'
    })

    monitorMenu.push({
      path: '/monitorConsole/diagram/:env/:appId/:monitorId/:description/:processId',
      lookup: '/monitorDiagrams/',
      name: 'Diagram',
      redirect: true,
      component: MonitorDiagramView,
      layout: '/admin'
    })
  }

  return monitorMenu
}

function mapInvoiceAi(apps, permissions) {
  let invoiceMenu = [
    {
      collapse: true,
      path: '/invoiceAI/index',
      state: 'invoiceCollapse',
      lookup: '/invoiceAI/',
      name: 'Invoice AI',
      icon: ReceiptIcon,
      component: InvoiceAIView,
      layout: '/admin',
      views: []
    }
  ]

  if (apps.includes('invoiceAI')) {
    if (checkViewPermissions(permissions, 'INVOICEAI', 'INVOICES')) {
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'INDEX'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'DETAILS'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'COMPARE'))
    }

    if (checkViewPermissions(permissions, 'CUSTOM', 'PAYMENT_RECEIPTS')) {
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'PAYMENTS'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'PAYMENT_DETAILS'))
    }

    if (checkViewPermissions(permissions, 'CUSTOM', 'IMPORT_INVOICES')) {
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'IMPORT'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'IMPORT_UPLOAD'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'IMPORT_DETAILS'))
    }

    if (checkViewPermissions(permissions, 'INVOICEAI', 'EMAILS')) {
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'EMAILS'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'EMAIL_DETAILS'))
    }

    if (checkViewPermissions(permissions, 'CUSTOM', 'PO_EDITOR')) {
      const masterDataMenu = [
        {
          collapse: true,
          name: 'Custom Forms',
          mini: 'CF',
          component: MasterDataPOView,
          state: 'componentsCollapse',
          views: []
        }
      ]
      masterDataMenu[0].views.push(getViewItem('INVOICEAI', 'MASTERDATA_PO_INDEX'))
      masterDataMenu[0].views.push(getViewItem('INVOICEAI', 'MASTERDATA_PO'))
      if (masterDataMenu[0].views.length > 0) invoiceMenu[0].views.push(...masterDataMenu)
    }
  }

  return invoiceMenu[0].views.length > 0 ? invoiceMenu : []
}

function mapDataAssure(apps, permissions) {
  const dataAssureMenu = []
  const dataAssureMenuViews = []
  if (apps.includes('slp')) {
    if (checkViewPermissions(permissions, 'PII', 'INDEX')) {
      dataAssureMenuViews.push({
        path: '/pii/index',
        state: 'piiCollapse',
        lookup: '/pii/',
        name: 'PII Suppliers Information',
        mini: 'PII',
        layout: '/admin',
        component: PIIEmailsView
      })
    }
    if (checkViewPermissions(permissions, 'CUSTOM', 'DATA_ASSURE_UPLOAD')) {
      dataAssureMenuViews.push({
        path: '/dataAssure/uploadFileValidations',
        name: 'Batch upload',
        mini: 'UF',
        component: DaBatchMainApp,
        layout: '/admin',
        lookup: '/dataAssure/uploadFileValidations'
      })
    }
    if (dataAssureMenuViews.length > 0) {
      dataAssureMenu.push({
        collapse: true,
        state: 'piiCollapse',
        name: 'Data Assure',
        icon: AssignmentTurnedInIcon,
        layout: '/admin',
        views: [...dataAssureMenuViews]
      })
    }
  }
  return dataAssureMenu
}
function mapDataAssureDnB(apps, permissions) {
  const menu = []

  if (apps.includes('slp')) {
    if (checkViewPermissions(permissions, 'DNB', 'MATCH')) {
      menu.push({
        collapse: true,
        state: 'dnbCollapse',
        name: 'DnB Match',
        icon: AddCircleIcon,

        layout: '/admin',
        views: [
          {
            path: '/dataAssure/dnbmatch',
            state: 'dnbCollapse',
            lookup: '/dataAssure/dnbmatch',
            name: 'DnB Match',
            mini: 'DnB',
            layout: '/admin',
            component: DataAssureDnBMissing
          }
        ]
      })
    }
  }
  return menu
}

function mapDefaultRoutes() {
  const defaultRoutes = [
    {
      path: '/home',
      lookup: 'home',
      name: 'Home',
      redirect: true,
      icon: DashboardIcon,
      component: Dashboard,
      layout: '/admin'
    },
    {
      path: '/contract/esigners',
      lookup: '/contract/esigners',
      name: 'ESigners',
      redirect: true,
      component: ESigners,
      layout: '/admin'
    },
    {
      path: '/nitorConnect/documentation',
      lookup: '/nitorConnect',
      name: 'Connect',
      redirect: true,
      component: NitorConnectDocumentation,
      layout: '/admin'
    },
    {
      path: '/nitorConnect/login-start',
      lookup: '/nitorConnect',
      name: 'Connect',
      redirect: true,
      component: NitorConnectLoginStart,
      layout: '/admin'
    },
    {
      path: '/nitorConnect/login-end',
      lookup: '/nitorConnect',
      name: 'Connect',
      redirect: true,
      component: TeamsRedirect,
      layout: '/admin'
    }
  ]

  return defaultRoutes
}

function mapAdminRoutes(permissions) {
  const adminMenu = [
    {
      collapse: true,
      name: 'Manage',
      icon: Web,
      state: 'pageCollapse',
      views: []
    }
  ]

  if (checkViewPermissions(permissions, 'TENANTS', 'CONFIGURATIONS')) {
    adminMenu[0].views.push(getViewItem('TENANTS', 'INDEX'))
    adminMenu.push(getViewItem('TENANTS', 'CREATE'))
    adminMenu.push(getViewItem('TENANTS', 'EDIT'))
  }
  if (checkViewPermissions(permissions, 'USERS', 'CONFIGURATIONS')) {
    adminMenu[0].views.push(getViewItem('USERS', 'INDEX'))
    adminMenu.push(getViewItem('USERS', 'CREATE'))
    adminMenu.push(getViewItem('USERS', 'EDIT'))
  }
  return adminMenu[0].views.length > 0 ? adminMenu : null
}

function mapSupportRoutes() {
  // Adding support Tickets
  let supportTicketsMenu = [
    {
      path: '/support/index',
      lookup: 'support',
      name: 'Support',
      redirect: true,
      icon: ContactSupportIcon,
      component: IndexSupportTickets,
      layout: '/admin'
    },
    {
      path: '/support/ticket/create',
      name: 'Create Support Ticket',
      redirect: true,
      component: CreateSupportTicket,
      layout: '/admin'
    },
    {
      path: '/support/ticket/:ticketId/tenant/:tenantId',
      name: 'Support Ticket Details',
      redirect: true,
      component: SupportTicketDetails,
      layout: '/admin'
    }
  ]
  return supportTicketsMenu
}

function mapAppsRoutes(apps, permissions) {
  let appsMenu = [
    {
      collapse: true,
      name: 'Settings',
      icon: SettingsIcon,
      state: 'componentsCollapse',
      views: []
    }
  ]

  if (apps.includes('teams')) {
    const teamsMenu = [
      {
        collapse: true,
        path: '/nitorConnect',
        state: 'connectCollapse',
        lookup: '/nitorConnect',
        name: 'Connect',
        mini: 'NC',
        icon: CloudQueue,
        component: NitorConnect,
        layout: '/admin',
        views: []
      }
    ]

    if (checkViewPermissions(permissions, 'CONNECT', 'CONFIGURATIONS')) {
      teamsMenu[0].views.push({
        path: '/nitorConnect/index',
        name: 'Configurations',
        mini: '.',
        component: NitorConnect,
        layout: '/admin',
        lookup: '/nitorConnect/index'
      })
    }

    if (checkViewPermissions(permissions, 'CONNECT', 'DEFAULT_CONNECTION')) {
      teamsMenu[0].views.push({
        path: '/nitorConnect/connections',
        name: 'Default Connection',
        mini: '.',
        component: ConnectDefault,
        layout: '/admin',
        lookup: '/nitorConnect/connections'
      })
    }
    if (teamsMenu[0].views.length > 0) appsMenu[0].views.push(...teamsMenu)
  }
  if (apps.includes('slp')) {
    const slpMenu = [
      {
        collapse: true,
        path: '/dataAssure/index',
        state: 'dataCollapse',
        lookup: '/dataAssure/',
        name: 'Data Assure',
        mini: 'AV',
        icon: AssignmentTurnedInIcon,
        component: DataAssure,
        layout: '/admin',
        views: []
      }
    ]
    if (checkViewPermissions(permissions, 'DATA_ASSURE', 'TRANSACTIONS')) {
      slpMenu[0].views.push({
        path: '/dataAssure/transactions/retry',
        name: 'Transactions',
        mini: 'T',
        component: DataAssureRetry,
        layout: '/admin',
        lookup: '/dataAssure/transactions/retry'
      })
      slpMenu.push(
        {
          path: '/dataAssure/:typeValidation/transactions/count',
          lookup: '/dataAssure/',
          name: 'Details',
          redirect: true,
          component: DataAssureCountType,
          layout: '/admin'
        },
        {
          path: '/dataAssure/:appId/transactions',
          lookup: '/dataAssure/',
          name: 'Transactions',
          redirect: true,
          component: DataAssureRetry,
          layout: '/admin'
        }
      )
    }

    if (checkViewPermissions(permissions, 'DATA_ASSURE', 'CONFIGURATIONS')) {
      slpMenu[0].views.push({
        path: '/dataAssure/index',
        name: 'Configurations',
        mini: 'CF',
        component: DataAssure,
        layout: '/admin',
        lookup: '/dataAssure/index'
      })

      slpMenu.push(
        {
          path: '/dataAssure/addressValidation/:id',
          lookup: '/dataAssure/',
          name: 'Address Validation',
          redirect: true,
          component: AddressValidation,
          layout: '/admin'
        },
        {
          path: '/dataAssure/addressquestionnaire/:id/:form',
          lookup: '/dataAssure/',
          name: 'Questionnaire',
          redirect: true,
          component: AddressQuestionnaire,
          layout: '/admin'
        },
        {
          path: '/dataAssure/tinValidation/:id',
          lookup: '/dataAssure/',
          name: 'Tin Validation',
          redirect: true,
          component: TinValidation,
          layout: '/admin'
        },
        {
          path: '/dataAssure/documentation/ariba',
          lookup: '/dataAssure/',
          name: 'Documentation',
          redirect: true,
          component: AribaPDF,
          layout: '/admin'
        },
        {
          path: '/dataAssure/documentation/coupa',
          lookup: '/dataAssure/',
          name: 'Documentation',
          redirect: true,
          component: CoupaPDF,
          layout: '/admin'
        }
      )
    }
    if (
      checkViewPermissions(permissions, 'DATA_ASSURE', 'MONITOR_CONSOLE') &&
      checkViewPermissions(permissions, 'MONITOR_CONSOLE', 'CONFIGURE_MONITORS')
    ) {
      slpMenu[0].views.push({
        path: '/dataAssure/monitorConfigurations',
        name: 'Monitor Configurations',
        mini: 'MC',
        component: MonitorConsoleConfigurations,
        layout: '/admin',
        lookup: '/dataAssure/monitorConfigurations'
      })
    }

    if (checkViewPermissions(permissions, 'DATA_ASSURE', 'LOGS')) {
      slpMenu[0].views.push({
        path: '/dataAssure/transactions/count',
        name: 'Logs',
        mini: 'LG',
        component: DataAssureCount,
        layout: '/admin',
        lookup: '/dataAssure/transactions/count'
      })
    }

    if (slpMenu[0].views.length > 0) appsMenu[0].views.push(...slpMenu)
  }
  if (apps.includes('insights')) {
    if (checkViewPermissions(permissions, 'CUSTOM', 'INSIGHTS')) {
      appsMenu[0].views.push(
        {
          path: '/nitorInsights/index',
          lookup: '/nitorInsights/',
          name: 'Relish Insights',
          mini: 'NI',
          icon: AssessmentIcon,
          component: NitorInsights,
          layout: '/admin'
        },
        // {
        //   path: '/insights/index',
        //   lookup: '/insights/',
        //   name: 'Relish Insights2',
        //   mini: 'NI',
        //   icon: AssessmentIcon,
        //   component: InsightsViews,
        //   layout: '/admin'
        // },
        {
          path: '/nitorInsights/load/:id/:type/:idLoad?',
          lookup: '/nitorInsights/',
          name: 'Relish Insights',
          redirect: true,
          component: InsightsLoad,
          layout: '/admin'
        },
        {
          path: '/nitorInsights/details/:id/:type/:idLoad',
          lookup: '/nitorInsights/',
          name: 'Relish Insights',
          redirect: true,
          component: InsightsLogs,
          layout: '/admin'
        },
        {
          path: '/nitorInsights/:type/:id',
          lookup: '/nitorInsights/',
          name: 'Relish Insights',
          redirect: true,
          component: InsightsAriba,
          layout: '/admin'
        },
        // {
        //   path: '/insights/transaction/:appId/:group/:transactionId?',
        //   lookup: '/insights/',
        //   name: 'Relish Insights',
        //   redirect: true,
        //   component: InsightsTransaction,
        //   layout: '/admin'
        // },
        // {
        //   path: '/insights/details/:appId/:group/:transactionId',
        //   lookup: '/insights/',
        //   name: 'Relish Insights',
        //   redirect: true,
        //   component: InsightsDetails,
        //   layout: '/admin'
        // },
        // {
        //   path: '/insights/:appId/:group',
        //   lookup: '/insights/',
        //   name: 'Relish Insights',
        //   redirect: true,
        //   component: InsightsGroup,
        //   layout: '/admin'
        // },
        {
          path: '/tmobile/:id',
          lookup: '/',
          name: 'T Mobile Details',
          redirect: true,
          component: TmobileDetails,
          layout: '/admin'
        },
        {
          path: '/tmobile',
          lookup: '/',
          name: 'T Mobile',
          redirect: true,
          component: Tmobile,
          layout: '/admin'
        }
      )
    }

    if (checkViewPermissions(permissions, 'INSIGHTS', 'CONFIGURATIONS')) {
      appsMenu[0].views.push(
        {
          path: '/insights/index',
          lookup: '/insights/',
          name: 'Insights',
          mini: 'NI',
          icon: AssessmentIcon,
          component: InsightsViews,
          layout: '/admin'
        },
        {
          path: '/insights/:appId/groups/:group/transactions/:transactionId?',
          lookup: '/insights/',
          name: 'Relish Insights',
          redirect: true,
          component: InsightsTransaction,
          layout: '/admin'
        },
        {
          path: '/insights/:appId/groups/:group/details/:transactionId',
          lookup: '/insights/',
          name: 'Relish Insights',
          redirect: true,
          component: InsightsDetails,
          layout: '/admin'
        },
        {
          path: '/insights/:appId/groups/:group',
          lookup: '/insights/',
          name: 'Relish Insights',
          redirect: true,
          component: InsightsGroup,
          layout: '/admin'
        }
      )
    }
  }
  if (apps.includes('qualtrics')) {
    if (checkViewPermissions(permissions, 'QUALTRICS', 'CONFIGURATIONS')) {
      appsMenu[0].views.push(
        {
          path: '/qualtrics/index',
          lookup: '/qualtrics/',
          name: 'Qualtrics',
          mini: 'QA',
          icon: PieChartIcon,
          component: Qualtrics,
          layout: '/admin'
        },
        {
          path: '/qualtrics/load/:id/:type/:idLoad?',
          lookup: '/qualtrics/',
          name: 'Qualtrics',
          redirect: true,
          component: QualtricsLoad,
          layout: '/admin'
        },
        {
          path: '/qualtrics/logs/:id/:type/:idLoad',
          lookup: '/qualtrics/',
          name: 'Qualtrics',
          redirect: true,
          component: QualtricsLogs,
          layout: '/admin'
        },
        {
          path: '/qualtrics/:type/:id',
          lookup: '/qualtrics/',
          name: 'Qualtrics',
          redirect: true,
          component: QualtricsAriba,
          layout: '/admin'
        }
      )
    }
  }
  if (apps.includes('intelligence')) {
    if (checkViewPermissions(permissions, 'INTELLIGENCE', 'CONFIGURATIONS')) {
      appsMenu[0].views.push(
        {
          path: '/intelligence/index',
          lookup: '/intelligence/',
          name: 'Intelligence',
          icon: ShowChartIcon,
          component: Intelligence,
          layout: '/admin'
        },
        {
          path: '/intelligence/load/:id/:type/:idLoad?',
          lookup: '/intelligence/',
          name: 'Intelligence',
          redirect: true,
          component: IntelligenceLoad,
          layout: '/admin'
        },
        {
          path: '/intelligence/logs/:id/:type/:idLoad',
          lookup: '/intelligence/',
          name: 'Intelligence',
          redirect: true,
          component: IntelligenceLogs,
          layout: '/admin'
        },
        {
          path: '/intelligence/:type/:id',
          lookup: '/intelligence/',
          name: 'Intelligence',
          redirect: true,
          component: IntelligenceAriba,
          layout: '/admin'
        }
      )
    }
  }
  if (apps.includes('invoiceAI')) {
    const invoiceMenu = [
      {
        collapse: true,
        path: '/invoiceAI/index',
        state: 'confCollapse',
        lookup: '/invoiceAI/',
        name: 'Invoice AI',
        mini: 'INS',
        // icon: ReceiptIcon,
        component: InvoiceAIView,
        layout: '/admin',
        views: []
      }
    ]

    if (permissions.includes('*')) {
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'VALIDATION_RULES'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'OCR_VALIDATION_RULES_EDIT'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'MD_VALIDATION_RULES_EDIT'))
    }
    if (checkViewPermissions(permissions, 'INVOICEAI', 'CONFIGURATIONS')) {
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'USER_IMPORT'))
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'CONFIGURATIONS'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_TRANSACTION'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_DETAILS'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_GROUP'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_TRANSACTION_S4HANA'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_DETAILS_S4HANA'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_GROUP_S4HANA'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_TRANSACTION_WORKDAY'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_DETAILS_WORKDAY'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_GROUP_WORKDAY'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'LOGS'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'LOADS'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'ADDITIONAL_CONFIGURATIONS'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_ORIGIN_CONFIGURATION'))
      invoiceMenu.push(getViewItem('INVOICEAI', 'INVOICE_ARIBA'))
    }

    if (checkViewPermissions(permissions, 'INVOICEAI', 'MASTERDATA')) {
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'MASTERDATA'))
    }

    /* if (checkViewPermissions(permissions, 'INVOICEAI', 'RULE_ENGINE')) {
      invoiceMenu[0].views.push(getViewItem('INVOICEAI', 'RULE_ENGINE'))
    } */

    if (invoiceMenu[0].views.length > 0) appsMenu[0].views.push(...invoiceMenu)
  }

  if (apps.includes('pricebook')) {
    if (checkViewPermissions(permissions, 'PRICEBOOK', 'CONFIGURATIONS')) {
      appsMenu[0].views.push({
        path: '/pricebook/configurations',
        lookup: '/pricebook/',
        name: 'PriceBook',
        mini: 'PB',
        icon: LibraryBooksIcon,
        component: PriceBook,
        layout: '/admin'
      })
    }
  }

  if (apps.includes('priceverify')) {
    const priceVerifyMenu = [
      {
        collapse: true,
        state: 'priceVerifyCollapse',
        name: 'Price Verify',
        mini: 'PV',
        icon: LibraryBooksIcon,
        views: []
      }
    ]

    if (checkViewPermissions(permissions, 'PRICE_VERIFY', 'DOWNLOAD_HISTORY')) {
      priceVerifyMenu[0].views.push({
        path: '/platform/priceVerify/downloadHistory',
        name: 'Download History',
        mini: 'DH',
        component: DownloadHistory,
        layout: '/admin',
        lookup: '/platform/priceVerify/downloadHistory'
      })
    }

    if (priceVerifyMenu[0].views.length > 0) appsMenu[0].views.push(...priceVerifyMenu)
  }

  if (apps.includes('router')) {
    if (checkViewPermissions(permissions, 'ROUTER', 'CONFIGURATIONS')) {
      const routerMenu = [
        {
          collapse: true,
          state: 'routerCollapse',
          name: 'Relish Router',
          mini: 'AV',
          icon: SwapHorizontalCircleIcon,
          views: []
        }
      ]
      routerMenu[0].views.push(
        {
          path: '/router/index',
          lookup: '/router/index',
          name: 'Relish Router',
          mini: 'RR',
          component: Router,
          layout: '/admin'
        },
        {
          path: '/router/:type/:id',
          lookup: '/router/',
          name: 'Relish Router',
          redirect: true,
          component: RouterAriba,
          layout: '/admin'
        },
        {
          path: '/router/monitorConfigurations',
          name: 'Monitor Configurations',
          mini: 'MC',
          component: MonitorConsoleConfigurations,
          layout: '/admin',
          lookup: '/router/monitorConfigurations'
        }
      )

      if (routerMenu[0].views.length > 0) appsMenu[0].views.push(...routerMenu)
    }
  }

  // Clean empty menu
  if (!appsMenu[0].views.length) {
    appsMenu = []
  }
  console.log('mapInvoiceAi', { apps, appsMenu })
  return appsMenu
}

function checkViewPermissions(userPermissions, app, group) {
  // Check Manage Permissions
  let generalPermissions = [...ViewsPermissions.GENERAL]
  if (ViewsPermissions[app]) {
    generalPermissions.push(...ViewsPermissions[app].GENERALS)
    generalPermissions.push(...ViewsPermissions[app][group])
  }

  return userPermissions.some(permission => generalPermissions.includes(permission))
}

function getViewItem(app, viewName) {
  return ViewsConfigurations[app][viewName]
}

export {
  ViewsConfigurations,
  mapAdminRoutes,
  mapAppsRoutes,
  mapDataAssure,
  mapDataAssureDnB,
  mapDefaultRoutes,
  mapInvoiceAi,
  mapMonitorConsole,
  mapSupportRoutes
}
