import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Fade } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Fab from '@material-ui/core/Fab'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import EmailsModal from './PIIEmailsModal.js'
import CustomConfirmAlert from 'components/CustomConfirmAlert/CustomConfirmAlert.js'
import { PIIRejectDialog } from 'components/PIIEmails/PIIRejectModal.js'
import { PIIDetailsModal } from 'components/PIIEmails/PIIDetailsModal.js'

// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import AddAlert from '@material-ui/icons/AddAlert'
import NotInterested from '@material-ui/icons/NotInterested'
import SendIcon from '@material-ui/icons/Send'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import HowToRegIcon from '@material-ui/icons/HowToReg'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

// utils
import { formatDate, piiStatusColor } from 'utils/functions.js'
import {
  useGetSuppliersData,
  useModifySupplier,
  useRejectSupplier,
  useResendInvitation
} from 'hooks/usePii'
import { useLocalStorageState } from 'hooks/useLocalStorage.js'
// constants
import { PIIStatus } from 'utils/Constants'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/piiStyle'

const useStyles = makeStyles(styles)
const piiDictionaryColums = {
  'Last Update': false,
  'Creation Date': false,
  Environment: false,
  'Task Id': false,
  'Supplier Id': false,
  'Supplier Email': false,
  Status: false,
  'Environment Type': false
}

const filters = [
  'Not invited',
  'Invited',
  'In Progress',
  'Token expired',
  'Submitted',
  'Rejected',
  'Integrated',
  'Blocked',
  'Obsolete',
  'Completed With Errors',
  'Token has exceded max attempts'
]

export default function PIIEmailsView () {
  const classes = useStyles()
  const [status, setStatus] = useState(filters)
  const [selectText, setSelectText] = useState('Deselect All')
  const [userFilters, setUserFilters] = useState({})
  const [supplierEmail, setSupplierEmail] = useState('')
  const [hashKey, setHashKey] = useState('')
  const [validationId, setValidationId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [createError, setCreateError] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')
  const [dialogAction, setDialogAction] = useState(undefined)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [details, setDetails] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState('')
  const [userColumns, setUserColumns] = useLocalStorageState(
    'userPII.config',
    piiDictionaryColums
  )
  const [env, setEnv] = useState('')
  const {
    data: suppliers,
    isLoading,
    error,
    isError,
    refetch,
    isFetching
  } = useGetSuppliersData(status)

  const {
    mutate: updateSupplier,
    error: errorModifySupplier,
    isError: isErrorModifySupplier
  } = useModifySupplier()
  const {
    mutate: rejectPIISupplier,
    error: errorRejectSupplier,
    isError: isErrorRejectSupplier
  } = useRejectSupplier()
  const {
    mutate: resendSupplierInvitation,
    error: errorResendInvitation,
    isError: isErrorResendInvitation
  } = useResendInvitation()

  useEffect(() => {
    if (
      !isError &&
      !isErrorModifySupplier &&
      !isErrorRejectSupplier &&
      !isErrorResendInvitation
    ) {
      return
    }
    setCreateError(true)

    if (isError)
      setErrorMessage(
        e =>
          e + '\n' + error.response?.data?.message ||
          'Something went wrong, try again later'
      )
    if (isErrorModifySupplier)
      setErrorMessage(
        e =>
          e +
            '\nError Modifying Supplier: ' +
            errorModifySupplier.response?.data?.message ||
          'Something went wrong, try again later'
      )
    if (isErrorRejectSupplier)
      setErrorMessage(
        e =>
          e +
            '\nError Rejecting Supplier: ' +
            errorRejectSupplier.response?.data?.message ||
          'Something went wrong, try again later'
      )
    if (isErrorResendInvitation)
      setErrorMessage(
        e =>
          e +
            '\nError Sending Invitation to Supplier: ' +
            errorResendInvitation.response?.data?.message ||
          'Something went wrong, try again later'
      )

    setTimeout(() => {
      setCreateError(false)
      setErrorMessage('')
    }, 5000)
  }, [
    isError,
    isErrorModifySupplier,
    isErrorRejectSupplier,
    isErrorResendInvitation,
    error,
    errorModifySupplier,
    errorRejectSupplier,
    errorResendInvitation
  ])

  let suppliersData = {}
  let obj = {}
  let data = {}
  if (suppliers && suppliers.rows) {
    let supplier
    for (supplier of suppliers.rows) {
      if (supplier.environment) {
        obj = {}
        obj[supplier.environment] = supplier.environment
        data['environment'] = { ...data['environment'], ...obj }
      }

      if (supplier.taskId) {
        obj = {}
        obj[supplier.taskId] = supplier.taskId
        data['taskId'] = { ...data['taskId'], ...obj }
      }

      if (supplier.supplierId) {
        obj = {}
        obj[supplier.supplierId] = supplier.supplierId
        data['supplierId'] = { ...data['supplierId'], ...obj }
      }

      if (supplier.email) {
        obj = {}
        obj[supplier.email] = supplier.email
        data['email'] = { ...data['email'], ...obj }
      }

      if (supplier.status) {
        obj = {}
        obj[supplier.status] =
          supplier.status.charAt(0).toUpperCase() + supplier.status.substring(1)
        data['status'] = { ...data['status'], ...obj }
      }

      if (supplier.lastUpdated) {
        obj = {}
        obj[supplier.lastUpdated] = supplier.lastUpdated
        data['lastUpdated'] = { ...data['lastUpdated'], ...obj }
      }

      if (supplier.creationDate) {
        obj = {}
        obj[supplier.creationDate] = supplier.creationDate
        data['creationDate'] = { ...data['creationDate'], ...obj }
      }

      if (supplier.type) {
        obj = {}
        obj[supplier.type] = supplier.type
        data['type'] = { ...data['type'], ...obj }
      }
    }
    suppliersData = data
  }

  const columns = [
    {
      field: 'lastUpdated',
      title: 'Last Update',
      render: rowData =>
        formatDate({
          date: rowData.lastUpdated
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Last Update'],
      align: 'center',
      lookup: suppliersData['lastUpdated'],
      defaultFilter: userFilters['lastUpdated']
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      render: rowData =>
        rowData.creationDate
          ? formatDate({
              date: rowData.creationDate
            })
          : 'Invalid Date',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Creation Date'],
      align: 'center',
      lookup: suppliersData['creationDate'],
      defaultFilter: userFilters['creationDate']
    },
    {
      field: 'environment',
      title: 'Environment',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Environment'],
      align: 'center',
      lookup: suppliersData['environment'],
      defaultFilter: userFilters['environment']
    },
    {
      field: 'type',
      title: 'Environment Type',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['type'],
      align: 'center',
      lookup: suppliersData['type'],
      defaultFilter: userFilters['type']
    },
    {
      field: 'taskId',
      title: 'Task Id',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Task Id'],
      align: 'center',
      lookup: suppliersData['taskId'],
      defaultFilter: userFilters['taskId'],
      render: rowData => (
        <Link
          component="button"
          variant="body2"
          style={{ color: '#144CAC' }}
          underline="none"
          onClick={() => {
            window.open(rowData.taskAribaLink, '_blank')
          }}
        >
          {rowData.taskId}
        </Link>
      )
    },
    {
      field: 'supplierId',
      title: 'Supplier Id',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Supplier Id'],
      align: 'center',
      lookup: suppliersData['supplierId'],
      defaultFilter: userFilters['supplierId'],
      render: rowData => (
        <Link
          component="button"
          variant="body2"
          style={{ color: '#144CAC' }}
          underline="none"
          onClick={() => {
            window.open(rowData.supplierAribaLink, '_blank')
          }}
        >
          {rowData.supplierId}
        </Link>
      )
    },
    {
      field: 'email',
      title: 'Supplier Email',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Supplier Email'],
      align: 'center',
      lookup: suppliersData['email'],
      defaultFilter: userFilters['email']
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => (
        <p style={{ color: piiStatusColor(rowData.status) }}>
          {rowData.status.charAt(0).toUpperCase() + rowData.status.substring(1)}
        </p>
      ),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Status'],
      align: 'center',
      lookup: suppliersData['status'],
      defaultFilter: userFilters['status']
    }
  ]

  const actions = [
    rowData => ({
      icon: () => (
        <EditIcon
          style={{
            color:
              rowData.status === PIIStatus.SUBMITTED ||
              rowData.status === PIIStatus.INTEGRATED ||
              rowData.status === PIIStatus.OBSOLETE ||
              rowData.status === PIIStatus.REJECTED
                ? 'rgba(0, 0, 0, 0.26)'
                : '#081c3e'
          }}
        />
      ),
      tooltip: 'Edit Email',
      onClick: (event, rowData) => {
        setSupplierEmail(rowData.email)
        setHashKey(rowData.hashKey)
        setEnv(rowData.environment)
        setValidationId(rowData.validationId)
        setShowModal(true)
      },
      disabled:
        rowData.status === PIIStatus.SUBMITTED ||
        rowData.status === PIIStatus.INTEGRATED ||
        rowData.status === PIIStatus.OBSOLETE ||
        rowData.status === PIIStatus.REJECTED
    }),
    rowData => ({
      icon: () => (
        <NotInterested
          style={{
            color:
              rowData.status === PIIStatus.SUBMITTED ||
              rowData.status === PIIStatus.INTEGRATED ||
              rowData.status === PIIStatus.OBSOLETE ||
              rowData.status === PIIStatus.REJECTED
                ? 'rgba(0, 0, 0, 0.26)'
                : '#081c3e'
          }}
        />
      ),
      tooltip: 'Reject Supplier',
      disabled:
        rowData.status === PIIStatus.SUBMITTED ||
        rowData.status === PIIStatus.INTEGRATED ||
        rowData.status === PIIStatus.OBSOLETE ||
        rowData.status === PIIStatus.REJECTED,
      onClick: (event, rowData) => {
        setHashKey(rowData.hashKey)
        setEnv(rowData.environment)
        setValidationId(rowData.validationId)
        setShowRejectDialog(true)
      }
    }),
    rowData => ({
      icon: () => (
        <SendIcon
          style={{
            color:
              rowData.status === PIIStatus.SUBMITTED ||
              rowData.status === PIIStatus.INTEGRATED ||
              rowData.status === PIIStatus.OBSOLETE ||
              rowData.status === PIIStatus.REJECTED
                ? 'rgba(0, 0, 0, 0.26)'
                : '#081c3e'
          }}
        />
      ),
      tooltip: 'Re-send invitation',
      disabled:
        rowData.status === PIIStatus.SUBMITTED ||
        rowData.status === PIIStatus.REJECTED ||
        rowData.status === PIIStatus.INTEGRATED ||
        rowData.status === PIIStatus.OBSOLETE, 
      onClick: (event, rowData) => {
        //console.log('on Resending', { context: rowData })
        setHashKey(rowData.hashKey)
        setEnv(rowData.environment)
        setValidationId(rowData.validationId)
        setShowConfirmDialog(true)
        setConfirmMessage('Are you sure, you want resend the invitation?')
        setDialogAction(() => () =>
          resendInvitation(rowData.hashKey, rowData.validationId)
        )
      }
    }),
    {
      icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
      tooltip: 'Details',
      onClick: (event, rowData) => {
        setShowDetails(true)
        setDetails(rowData.piiHistory)
      }
    }
  ]

  function filterColumns (columns) {
    const configCopy = { ...userFilters }
    let column
    for (column of columns) {
      configCopy[column.column.field] = column.value
    }
    setUserFilters(configCopy)
  }

  function hideColumns (column, hidden) {
    const configCopy = { ...userColumns }
    configCopy[column.title] = hidden
    setUserColumns(configCopy)
  }

  async function onSearchSuppliers (newStatus) {
    let statusArray = [...status]
    if (newStatus === 'all') {
      if (statusArray.length === filters.length) {
        statusArray = []
        setSelectText('Select All')
      } else {
        statusArray = filters
        setSelectText('Deselect All')
      }
    } else {
      let selected = statusArray.findIndex(x => x === newStatus)
      if (selected !== -1) {
        statusArray.splice(selected, 1)
      } else {
        statusArray.push(newStatus)
        statusArray = [...new Set(statusArray)]
      }
      if (statusArray.length === filters.length) {
        setSelectText('Deselect All')
      } else {
        setSelectText('Select All')
      }
    }
    setStatus(statusArray)
  }

  function getEmailModal () {
    let modal = (
      <EmailsModal
        title={'Edit Supplier Email'}
        supplierEmail={supplierEmail}
        onCancel={() => setShowModal(false)}
        onConfirm={returnValues => {
          updateSupplier({
            hashKey,
            validationId,
            email: returnValues.supplierEmail,
            environment: env,
            // eslint-disable-next-line
            status: status.reduce((acc, curr) => ((acc[curr] = curr), acc), {})
          })

          setSubmitSuccess(true)
          setTimeout(() => {
            setSubmitSuccess(false)
          }, 5000)
          setShowModal(false)
        }}
      ></EmailsModal>
    )

    return modal
  }

  function rejectSupplier (rejectedMessage) {
    rejectPIISupplier({
      hashKey,
      validationId,
      // eslint-disable-next-line
      status: status.reduce((acc, curr) => ((acc[curr] = curr), acc), {}),
      rejectedMessage
    })
    setValidationMessage('Supplier Rejected')
  }

  function resendInvitation (hashKey, validationId) {
    setValidationMessage('Invitation resended')
    resendSupplierInvitation({
      hashKey,
      validationId,
      // eslint-disable-next-line
      status: status.reduce((acc, curr) => ((acc[curr] = curr), acc), {})
    })
  }

  function getConfirmDialog () {
    return (
      <CustomConfirmAlert
        message={confirmMessage}
        title={'Resend Invitation'}
        onCancel={() => setShowConfirmDialog(false)}
        onConfirm={() => {
          dialogAction()
          setSubmitSuccess(true)
          setTimeout(() => {
            setSubmitSuccess(false)
          }, 5000)
          setShowConfirmDialog(false)
        }}
      ></CustomConfirmAlert>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>PII Suppliers Information</h3>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                <GridItem md={12}>
                  <Button
                    color="primary"
                    size="small"
                    className={classes.floatRight}
                    onClick={() => refetch({ throwOnError: true })}
                  >
                    <RefreshRoundedIcon />
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      width: '100%',
                      margin: '0 auto 10px'
                    }}
                  >
                    <div
                      className={classes.statusButton}
                      style={{ width: '130px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          className={
                            status.find(x => x === 'Not invited') !== undefined
                              ? classes.notInvitedColorFilled
                              : classes.notInvitedColor
                          }
                          onClick={() => {
                            onSearchSuppliers('Not invited')
                          }}
                        >
                          <MailOutlineIcon />
                        </Fab>
                        <span className={classes.statusText}>Not Invited</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '115px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          onClick={() => {
                            onSearchSuppliers('Invited')
                          }}
                          className={
                            status.find(x => x === 'Invited') !== undefined
                              ? classes.primaryColorFilled
                              : classes.primaryColor
                          }
                        >
                          <PersonAddIcon />
                        </Fab>
                        <span className={classes.statusText}>Invited</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '135px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          className={
                            status.find(x => x === 'In Progress') !== undefined
                              ? classes.inProgressColorFilled
                              : classes.inProgressColor
                          }
                          onClick={() => {
                            onSearchSuppliers('In Progress')
                          }}
                        >
                          <VisibilityIcon />
                        </Fab>
                        <span className={classes.statusText}>In Progress</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '155px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          className={
                            status.find(x => x === 'Token expired') !==
                            undefined
                              ? classes.tokenExpiredColorFilled
                              : classes.tokenExpiredColor
                          }
                          onClick={() => {
                            onSearchSuppliers('Token expired')
                          }}
                        >
                          <ReportProblemIcon />
                        </Fab>
                        <span className={classes.statusText}>
                          Token Expired
                        </span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '145px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          onClick={() => {
                            onSearchSuppliers('Integrated')
                          }}
                          className={
                            status.find(x => x === 'Integrated') !== undefined
                              ? classes.integratedColorFilled
                              : classes.integratedColor
                          }
                        >
                          <HowToRegIcon />
                        </Fab>
                        <span className={classes.statusText}>Integrated</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '115px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          onClick={() => {
                            onSearchSuppliers('Obsolete')
                          }}
                          className={
                            status.find(x => x === 'Obsolete') !== undefined
                              ? classes.warningColorFilled
                              : classes.warningColor
                          }
                        >
                          <PriorityHighIcon />
                        </Fab>
                        <span className={classes.statusText}>Obsolete</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '150px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          className={
                            status.find(
                              x => x === 'Token has exceded max attempts'
                            ) !== undefined
                              ? classes.dangerColorFilled
                              : classes.dangerColor
                          }
                          onClick={() => {
                            onSearchSuppliers('Token has exceded max attempts')
                          }}
                        >
                          <NotInterestedIcon />
                        </Fab>
                        <span className={classes.statusText}>Max Attempts</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '115px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          onClick={() => {
                            onSearchSuppliers('Blocked')
                          }}
                          className={
                            status.find(x => x === 'Blocked') !== undefined
                              ? classes.exceptionColorFilled
                              : classes.exceptionColor
                          }
                        >
                          <NotInterestedIcon />
                        </Fab>
                        <span className={classes.statusText}>Blocked</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '210px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          onClick={() => {
                            onSearchSuppliers('Completed With Errors')
                          }}
                          className={
                            status.find(x => x === 'Completed With Errors') !== undefined
                              ? classes.warningColorFilled
                              : classes.warningColor
                          }
                        >
                          <PriorityHighIcon />
                        </Fab>
                        <span className={classes.statusText}>Completed With Errors</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '145px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          onClick={() => {
                            onSearchSuppliers('Submitted')
                          }}
                          className={
                            status.find(x => x === 'Submitted')
                              ? classes.successColorFilled
                              : classes.successColor
                          }
                        >
                          <CheckIcon />
                        </Fab>
                        <span className={classes.statusText}>Submitted</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '130px' }}
                    >
                      <div>
                        <Fab
                          size="small"
                          onClick={() => {
                            onSearchSuppliers('Rejected')
                          }}
                          className={
                            status.find(x => x === 'Rejected')
                              ? classes.dangerColorFilled
                              : classes.dangerColor
                          }
                        >
                          <CloseIcon />
                        </Fab>
                        <span className={classes.statusText}>Rejected</span>
                      </div>
                    </div>
                    <div
                      className={classes.statusButton}
                      style={{ width: '115px', border: 'none' }}
                    >
                      <div>
                        <Button
                          size="small"
                          onClick={() => {
                            onSearchSuppliers('all')
                          }}
                          style={{ color: '#999' }}
                        >
                          {selectText}
                        </Button>
                      </div>
                    </div>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <GridContainer justify="center" style={{ marginTop: '5px' }}>
                    <SyncLoader showLoader={isFetching && !isLoading} />
                    {isLoading ? (
                      <div className={classes.reloadSize}>
                        <CircularProgress />
                      </div>
                    ) : (
                      <GridItem xs={12} sm={12} md={12}>
                        <MaterialTable
                          columns={columns}
                          data={suppliers?.rows || []}
                          actions={actions}
                          onChangeColumnHidden={hideColumns}
                          onFilterChange={filterColumns}
                          options={{
                            columnsButton: true,
                            actionsColumnIndex: -1,
                            filtering: true,
                            sorting: true,
                            showTitle: false
                          }}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                </GridItem>
                {showModal ? getEmailModal() : ''}
                {showConfirmDialog ? getConfirmDialog() : ''}
                <PIIRejectDialog
                  open={showRejectDialog}
                  onClose={() => setShowRejectDialog(false)}
                  rejectPII={rejectSupplier}
                />
                <PIIDetailsModal
                  showModal={showDetails}
                  onClose={() => setShowDetails(false)}
                  modalInfo={details}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={validationMessage}
          open={submitSuccess}
          closeNotification={() => setSubmitSuccess(false)}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={errorMessage}
          open={createError}
          closeNotification={() => {
            setCreateError(false)
            setErrorMessage('')
          }}
          close
        />
      </GridContainer>
    </Fade>
  )
}
