import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'

//Services
import {
  editRouterAribaConfigurationConnectionByApp,
  getRouterConfigurations,
  getRouterConfigurationDetails,
  validateRouterAribaAppConnection
} from 'services/apiApps'
import { checkExpiration } from "utils/functions"

//Constants
import { InputDefault } from 'utils/Constants'

//Views
import InsightsProcurementView from './RouterAribaView'

//Models
import { aribaConnection } from 'models/routerModel'
import { validateProperty, validateObject } from 'utils/validator'

import { updateHeightFunction } from 'store/AppState'

let _isMounted = false
let appId
let typeApp

const newConfig = {
  appId: '',
  ANID: '',
  enabled: false,
  inboundAuthenticationType: 'sharedSecret',
  inboundClientId: '',
  inboundSecret: '',
  outboundClientId: '',
  outboundSecret: '',
  outboundAuthenticationType: 'sharedSecret',
  aribaNetworkClientId: '',
  aribaNetworkSecret: '',
  aribaNetworkApiKey: ''
}

const newConfigState = {
  appId: '',
  ANID: '',
  enabled: '',
  inboundAuthenticationType: '',
  inboundClientId: '',
  inboundSecret: '',
  outboundClientId: '',
  outboundSecret: '',
  outboundAuthenticationType: '',
  aribaNetworkClientId: '',
  aribaNetworkSecret: '',
  aribaNetworkApiKey: ''
}

async function getAppList (props) {
  let response = await getRouterConfigurations()
  const data = checkExpiration(props.enabledApps['router'])
  props.setExpirationData(data)
  
  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data)
      props.setPageIsLoading(false)
    }
  }
}

async function getAppDetails (props, id, typeApp) {
  props.setFormIsLoading(true)
  props.setSelectedAppId(id)

  const appDetails = await getRouterConfigurationDetails(id)
  if (appDetails.success) {
    const params = appDetails.data.params ? appDetails.data.params.ariba : undefined
    const configurations = {
      appId: appDetails.data.appId,
      ANID: appDetails.data.ANID ? appDetails.data.ANID : '',
      enabled: appDetails.data.enabled,
      inboundAuthenticationType: 'sharedSecret',
      outboundAuthenticationType: 'sharedSecret',
      inboundClientId: appDetails.data.params.ariba.CIGToRelish?.User,
      inboundSecret: appDetails.data.params.ariba.CIGToRelish?.Secret,
      outboundClientId: appDetails.data.params.ariba.RelishToCIG?.User,
      outboundSecret: appDetails.data.params.ariba.RelishToCIG?.User,
      aribaNetworkClientId: appDetails.data.params.ariba.aribaNetwork?.purchaseOrderSupplierAPI?.clientId,
      aribaNetworkSecret: appDetails.data.params.ariba.aribaNetwork?.purchaseOrderSupplierAPI?.sharedSecret,
      aribaNetworkApiKey: appDetails.data.params.ariba.aribaNetwork?.purchaseOrderSupplierAPI?.apiKey,
    }
    if (typeApp === 'upstream' || typeApp === 'downstream') {
      configurations.itk = true
    }
    props.setAribaConnectionConnected(undefined)
    props.setAribaOUTConnectionConnected(undefined)
    props.setAribaNetworkConnectionConnected(undefined)
    if (params.CIGToRelish) {
      props.setAribaConnectionConnected(true)
    }
    if (params.RelishToCIG) {
      props.setAribaOUTConnectionConnected(true)
    }
    if (params.aribaNetwork?.purchaseOrderSupplierAPI) {
      props.setAribaNetworkConnectionConnected(true)
    }

    if (_isMounted) {
      props.setConfig(configurations)
    }
  }
  props.setFormIsLoading(false)
}

async function onConfigSubmit (props) {
  props.setFormIsLoading(true)
  let successMessage

  //Update with the form values
  let appConfiguration = createConfigRequestBody(props, props.config)
  let response

  response = await editRouterAribaConfigurationConnectionByApp(
    props.selectedAppId,
    appConfiguration
  )
  successMessage = 'App updated successfully'

  if (response && response.success && _isMounted) {
    await getAppDetails(props, props.selectedAppId, props.typeApp)
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
        props.setFormIsLoading(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
    props.setFormIsLoading(false)
  }
}

function createConfigRequestBody (props, configuration) {
  let requestBody = { 
    ANID: configuration.ANID
  }

  if (props.aribaConnectionConnected) {
    requestBody.inbound = {
      inboundAuthenticationType: configuration.inboundAuthenticationType,
      inboundClientId: configuration.inboundClientId,
      inboundSecret: configuration.inboundSecret
    }
  }

  if (props.aribaOUTConnectionConnected) {
    requestBody.outbound = {
      outboundAuthenticationType: configuration.outboundAuthenticationType,
      outboundClientId: configuration.outboundClientId,
      outboundSecret: configuration.outboundSecret
    }
  }

  if (props.aribaNetworkConnectionConnected) {
    requestBody.ariba = {
      aribaNetworkApiKey: configuration.aribaNetworkApiKey,
      aribaNetworkClientId: configuration.aribaNetworkClientId,
      aribaNetworkSecret: configuration.aribaNetworkSecret
    }
  }

  return requestBody
}

async function checkAribaConnection (props, type) {

  let connectionData = {}
  let checkProps = []

  if (type === 'inbound') {
    props.setAribaConnectionConnected(undefined)
    props.setAribaConnectionTesting(true)
    props.setAribaConnectionMessage(undefined)

    connectionData = {
      secret: props.config.inboundSecret,
      clientId: props.config.inboundClientId,
      authenticationType: props.config.inboundAuthenticationType
    }
  } else if (type === 'ariba') {
    props.setAribaNetworkConnectionConnected(undefined)
    props.setAribaNetworkConnectionTesting(true)
    props.setAribaNetworkConnectionMessage(undefined)

    connectionData = {
      secret: props.config.aribaNetworkSecret,
      clientId: props.config.aribaNetworkClientId,
      apiKey: props.config.aribaNetworkApiKey
    }
  } else {
    props.setAribaOUTConnectionConnected(undefined)
    props.setAribaOUTConnectionTesting(true)
    props.setAribaOUTConnectionMessage(undefined)

    connectionData = {
      secret: props.config.outboundSecret,
      clientId: props.config.outboundClientId,
      authenticationType: props.config.outboundAuthenticationType
    }
  }

  let bodyField
  for (bodyField of checkProps) {
    if (connectionData[bodyField] === InputDefault) {
      delete connectionData[bodyField]
    }
  }

  let connection = await validateRouterAribaAppConnection(
    props.config.appId,
    type,
    connectionData
  )
  let defaultError = {
    error: 'invalid_request',
    error_description: 'Connection Error'
  }
  defaultError = JSON.stringify(defaultError, null, '\t')

  if (connection.success) {

    switch (type) {
      case 'inbound': 
        props.setAribaConnectionConnected(connection.data.valid)
        if (connection.data.valid) {
          props.setAribaConnectionMessage(undefined)
        } else {
          props.setAribaConnectionMessage(
            JSON.stringify(connection.data.data) || defaultError)
        }
      break
      case 'outbound':
        props.setAribaOUTConnectionConnected(connection.data.valid)
        if (connection.data.valid) {
          props.setAribaOUTConnectionMessage(undefined)
        } else {
          props.setAribaOUTConnectionMessage(
            JSON.stringify(connection.data.data) || defaultError
          )
        }
      break
      case 'ariba':
        props.setAribaNetworkConnectionConnected(connection.data.valid)
        if (connection.data.valid) {
          props.setAribaNetworkConnectionMessage(undefined)
        } else {
          props.setAribNetworkConnectionMessage(
            JSON.stringify(connection.data.data) || defaultError
          )
        }
      break
      default:
        break
    }
  } else {
    switch (type) {
      case 'inbound':
          props.setAribaConnectionConnected(false)
          props.setAribaConnectionMessage(defaultError)
        break
      case 'outbound':
          props.setAribaOUTConnectionConnected(false)
          props.setAribaOUTConnectionMessage(defaultError)
        break
      case 'ariba':
          props.setAribaNetworkConnectionConnected(false)
          props.setAribaNetworkConnectionMessage(defaultError)
        break
      default:
        break
    }
  }

  switch (type) {
    case 'inbound':
        props.setAribaConnectionTesting(false)
      break
    case 'outbound':
      props.setAribaOUTConnectionTesting(false)
      break
    case 'ariba':
        props.setAribaNetworkConnectionTesting(false)
      break
    default:
      break
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      updateHeight: state.app.updateHeight,
      enabledApps: state.app.enabledApps
    }),
    { updateHeightFunction }
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('typeApp', 'setTypeApp', null),
  withState('config', 'setConfig', { ...newConfig }),
  withState('configState', 'setConfigState', { ...newConfigState }),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('loadIsLoading', 'setLoadIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('isLoadingSave', 'setIsLoadingSave', false),
  withState('inboundAcc', 'setInboundAcc', true),
  withState('outboundAcc', 'setOutboundAcc', true),
  withState('aribaAcc', 'setaribaAcc', true),
  withState(
    'aribaConnectionConnected',
    'setAribaConnectionConnected',
    undefined
  ),
  withState('aribaConnectionTesting', 'setAribaConnectionTesting', false),
  withState('aribaConnectionMessage', 'setAribaConnectionMessage', undefined),
  withState(
    'aribaOUTConnectionConnected',
    'setAribaOUTConnectionConnected',
    undefined
  ),
  withState('aribaOUTConnectionTesting', 'setAribaOUTConnectionTesting', false),
  withState('aribaOUTConnectionMessage', 'setAribaOUTConnectionMessage', undefined),
  withState(
    'aribaNetworkConnectionConnected',
    'setAribaNetworkConnectionConnected',
    undefined
  ),
  withState('aribaNetworkConnectionTesting', 'setAribaNetworkConnectionTesting', false),
  withState('aribaNetworkConnectionMessage', 'setAribaNetworkConnectionMessage', undefined),
  withState('showModal', 'setShowModal', false),
  withState('rows', 'setRows', null),
  withState("expirationData", "setExpirationData", {}),
  withHandlers({
    onAppChanged: props => id => {
      props.setSelectedAppId(id)
      getAppDetails(props, id, props.typeApp)
      props.history.push(
        `/admin/router/${props.typeApp}/${id}`
      )
    },
    onConfigSave: props => async () => {
      let validation = validateObject(aribaConnection, props.config)
      if (!validation.isValid) {
        let field
        for (field in props.config) {
          if (validation.errors[field]) {
            props.configState[field] = 'error'
          }
        }
        props.setConfigState(props.configState)
        props.setAribaConnectionTesting(false)
        return
      }

      props.setIsLoadingSave(true)
      await onConfigSubmit(props)
      props.setConfigState({ ...newConfigState })
      props.setAribaConnectionMessage(undefined)
      props.setAribaConnectionTesting(false)
      props.setIsLoadingSave(false)
    },
    onFieldChange: props => (field, value) => {
      props.config[field] = value
      props.setConfig(props.config)
      const isValid = validateProperty(aribaConnection, props.config, field)
        .isValid
      if (isValid) {
        props.configState[field] = 'success'
      } else {
        props.configState[field] = 'error'
      }

      props.setConfigState(props.configState)
      if (field.includes('inbound')) {
        props.setAribaConnectionConnected(undefined)
        props.setAribaConnectionMessage(undefined)
      } else if (field.includes('outbound')) {
        props.setAribaOUTConnectionConnected(undefined)
        props.setAribaOUTConnectionMessage(undefined)
      } else if (field.includes('ariba')) {
        props.setAribaNetworkConnectionConnected(undefined)
        props.setAribaNetworkConnectionMessage(undefined)
      }
      
    },
    onCheckAribaConnection: props => (type) => {
      checkAribaConnection(props, type)
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      console.debug(this.props)
      this.props.setPageIsLoading(true)
      appId = this.props.match.params.id
      typeApp = this.props.match.params.type
      this.props.setSelectedAppId(appId)
      this.props.setTypeApp(typeApp)
      getAppDetails(this.props, appId, typeApp)
      getAppList(this.props)
    },
    componentWillUnmount () {
      _isMounted = false
    }
  })
)(InsightsProcurementView)
