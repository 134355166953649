import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import {
    getAppConfigurationsByApp,
    getAppConfigurationByAppAndId,
    editAppConfigurationByAppAndId
} from "services/apiApps";

import AddressQuestionnaireView from "./AddressQuestionnaireView"
// models
import { configuration as configurationSchema, question as questionSchema } from "models/addressValidationModel"
import { validateProperty, validateObject } from "utils/validator";

import { v4 as uuidv4 } from 'uuid';

let _isMounted = false
let appId
let formId

const newModal = {
    visible: false,
    type: "edit",
    object: "question"
}

const modalForm = {
    addressType: "address",
    extendedAddress: "",
    address: "",
    street: "",
    street2: "",
    city: "",
    region: "",
    postalCode: "",
    country: ""
}

const modalFormState = {
    addressType: "",
    extendedAddress: "",
    address: "",
    street: "",
    street2: "",
    city: "",
    region: "",
    postalCode: "",
    country: ""
}

async function getAppList(props) {
    let response = await getAppConfigurationsByApp("slp")

    if (response.success) {
        if (_isMounted) {
            props.setAppList(response.data)
            props.setPageIsLoading(false)
        }
    }
}

async function getAppDetails(props, id, formId) {
    props.setFormIsLoading(true)

    const appDetails = await getAppConfigurationByAppAndId("slp", id)

    if (appDetails.success) {
        const mappings = appDetails.data.addressValidation.mappings

        // If the form exists
        if (mappings[formId]) {
            if (_isMounted) {
                props.setSelectedForm(mappings[formId], function () {
                    props.setQuestionList(mappings[formId].questions)
                    props.setFormIsLoading(false)
                })
            }
        } else {
        }
    } else {
        props.setFormIsLoading(false)
    }
}

async function onConfigSubmit(props) {
    let result = {}
    let successMessage

    let appDetails = await getAppConfigurationByAppAndId("slp", props.selectedAppId)
    let currentConfiguration = appDetails.data

    // Update with the form values
    let appConfiguration = createConfigRequestBody(props, currentConfiguration)
    let response

    response = await editAppConfigurationByAppAndId("slp", props.selectedAppId, appConfiguration)
    successMessage = "App updated successfully"

    if (response.success && _isMounted) {
        props.setValidationMessage(successMessage)
        props.setSubmitSuccess(true)

        setTimeout(function () {
            if (_isMounted) {
                props.setSubmitSuccess(false)
            }
        }, 5000);
    } else {
        // Show error message
        if (result) {
            let message = result.message
            if (!message) {
                message = "Something went wrong, please try again later."
            }
            props.setValidationMessage(message)
            props.setCreateError(true)
        }
    }
}

function createConfigRequestBody(props, requestBody) {
    if (requestBody.addressValidation.mappings[props.selectedFormId]) {
        requestBody.addressValidation.mappings[props.selectedFormId].questions = props.questionList
    }

    return requestBody
}

function hideModal(props) {
    props.modal.visible = false
    props.setModal(props.modal)
}

export default compose(
    connect(
        state => ({
            isAuthenticated: state.login.isAuthenticated,
            name: state.login.name
        }),
        {}
    ),
    withState("appList", "setAppList", []),
    withState("selectedAppId", "setSelectedAppId", null),
    withState("selectedFormId", "setSelectedFormId", null),
    withState("selectedForm", "setSelectedForm", null),
    withState("questionList", "setQuestionList", []),
    withState("modal", "setModal", { ...newModal }),
    withState("modalForm", "setModalForm", { ...modalForm }),
    withState("modalFormState", "setModalFormState", { ...modalFormState }),
    withState("formIsLoading", "setFormIsLoading", false),
    withState("submitSuccess", "setSubmitSuccess", false),
    withState("createError", "setCreateError", false),
    withState("validationMessage", "setValidationMessage", ""),
    withState("pageIsLoading", "setPageIsLoading", true),
    withState("isLoadingSave", "setIsLoadingSave", false),
    withHandlers({
        onAppChanged: (props) => (id) => {
            //props.setSelectedAppId(id)
            //getAppDetails(props, id)
        },
        onShowModal: (props) => async (type, object, id) => {
            // Set configuration for modal
            props.modal.type = type
            props.modal.object = object
            props.modal.id = id
            props.modal.visible = true

            props.setModalFormState({ ...modalFormState })

            // Find the question
            let questionForm = { ...modalForm }
            if (id) {
                const question = props.questionList.filter(function (question) {
                    return question.id === id
                })
                questionForm = { ...question[0] }
            }

            props.setModalForm(questionForm)
            props.setModal(props.modal)
        },
        onHideModal: (props) => async () => {
            hideModal(props)
        },
        onFormChange: (props) => async (field, value) => {
            props.modalForm[field] = value

            props.setModalForm(props.modalForm)

            let isValid = validateProperty(questionSchema, props.modalForm, field).isValid
            if (isValid) {
                props.modalFormState[field] = "success"
            } else {
                props.modalFormState[field] = "error"
            }
            props.setModalFormState(props.modalFormState)
        },
        onConfigSave: (props) => async () => {
            props.setIsLoadingSave(true)

            let validation = validateObject(configurationSchema, {})
            if (validation.isValid) {
                await onConfigSubmit(props)
            }
            props.setIsLoadingSave(false)
        },
        onQuestionEdit: (props) => async () => {
            let validation = validateObject(questionSchema, props.modalForm)
            if (!validation.isValid) {
                let field
                for (field in props.modalForm) {
                    if (validation.errors[field]) {
                        props.modalFormState[field] = "error"
                    }
                }
                props.setModalFormState(props.modalFormState)
                return
            }

            let questionList = props.questionList
            let question
            for (question of questionList) {
                if (question.id === props.modal.id) {
                    let editedQuestion = {
                        id: question.id,
                        addressType: props.modalForm.addressType
                    }
                    // Clear the input
                    switch (props.modalForm.addressType) {
                        case "address":
                            editedQuestion.address = props.modalForm.address
                            break;
                        case "extendedAddress":
                            editedQuestion.extendedAddress = props.modalForm.extendedAddress
                            break;
                        case "generic":
                            editedQuestion.street = props.modalForm.street
                            editedQuestion.street2 = props.modalForm.street2
                            editedQuestion.city = props.modalForm.city
                            editedQuestion.region = props.modalForm.region
                            editedQuestion.country = props.modalForm.extendedAddress
                            editedQuestion.postalCode = props.modalForm.postalCode
                            break;

                        default:
                            break;
                    }

                    // Save the changes in the list
                    question = editedQuestion

                }
            }
            // Save the change
            props.setQuestionList(questionList)

            hideModal(props)
        },
        onQuestionCreate: (props) => async (callback) => {
            let validation = validateObject(questionSchema, props.modalForm)
            if (!validation.isValid) {
                let field
                for (field in props.modalForm) {
                    if (validation.errors[field]) {
                        props.modalFormState[field] = "error"
                    }
                }
                props.setModalFormState(props.modalFormState)
                return
            }

            // Create Id for question
            let newQuestion = {
                id: uuidv4(),
                addressType: props.modalForm.addressType
            }

            switch (props.modalForm.addressType) {
                case "address":
                    newQuestion.address = props.modalForm.address
                    break;
                case "extendedAddress":
                    newQuestion.extendedAddress = props.modalForm.extendedAddress
                    break;
                case "generic":
                    newQuestion.street = props.modalForm.street
                    newQuestion.street2 = props.modalForm.street2
                    newQuestion.city = props.modalForm.city
                    newQuestion.region = props.modalForm.region
                    newQuestion.country = props.modalForm.extendedAddress
                    newQuestion.postalCode = props.modalForm.postalCode
                    break;

                default:
                    break;
            }

            let questionList = props.questionList
            questionList.push(newQuestion)
            console.debug(questionList)

            props.setQuestionList(questionList)

            hideModal(props)
        },
        onQuestionDelete: (props) => async () => {
            const key = props.modal.id

            // Filter the array of questions
            const filteredQuestions = props.questionList.filter(function (question) {
                return question.id !== key
            })

            props.setQuestionList(filteredQuestions)
            hideModal(props)
        },
        onQuestionChange: (props) => (field, value, id) => {
            props.parametersForm.ValidationMessages[id][field] = value
            props.setParametersForm(props.parametersForm)
        }
    }),
    lifecycle({
        componentDidMount() {
            _isMounted = true
            appId = this.props.match.params.id
            formId = `${this.props.match.params.form}`

            this.props.setSelectedAppId(appId)
            this.props.setSelectedFormId(formId)

            getAppDetails(this.props, appId, formId)

            this.props.setPageIsLoading(true)
            this.props.setModal({ ...newModal })
            getAppList(this.props)
        },
        componentWillUnmount() {
            _isMounted = false
        }
    })
)(AddressQuestionnaireView);
