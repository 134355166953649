import { AuthService } from 'utils/Constants'
import axios from 'axios'
import store from 'store'
import { signOut } from 'store/AuthState'
import { createHeaders } from 'utils/functions'

/*
Get the list of tenants
*/
async function getListOfTenants() {
  try {
    let url = `${AuthService.Endpoint}tenants`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getListOfTenants returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Get simple list of tenants (only name and id)
*/
async function getSimpleListOfTenants() {
  try {
    let url = `${AuthService.Endpoint}tenantsSimpleList`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getSimpleListOfTenants returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Get apps by tenant (only name and id)
*/
async function getSimpleListOfApps(tenantId) {
  try {
    let url = `${AuthService.Endpoint}tenants/${tenantId}/appsSimpleList`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`getSimpleListOfApps returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong' }
      }
    } else {
      return { success: false, message: 'Something went wrong' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong' }
  }
}

/*
Create a new tenant with the specified attributes
*/
async function createTenant(tenantBody) {
  try {
    let url = `${AuthService.Endpoint}tenants`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'post',
      url: url,
      headers: requestHeaders,
      data: tenantBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`createTenant returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for createTenant`)

      // If it's a success response
      if (response.status === 201) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.errors[0].msg }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again later' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

/*
Get the Tenant information
*/
async function getTenant(tenant) {
  try {
    let url = `${AuthService.Endpoint}tenants/${tenant}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTenant returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong, try again later' }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again later' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

/*
Edit the information of a tenat
*/
async function editTenant(tenant, tenantBody) {
  try {
    let url = `${AuthService.Endpoint}tenants/${tenant}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'put',
      url: url,
      headers: requestHeaders,
      data: tenantBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          // Throw error if it's not a success response
          throw new Error(`editTenant returned a status code ${error.response.status}`)
        }
      }
    })

    if (response) {
      // Check response status code
      console.info(`statusCode: ${response.status} for editTenant`)

      // If it's a success response
      if (response.status === 204) {
        return { success: true, message: response.data }
      } else {
        return { success: false, message: response.data.errors[0].msg }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

/*
Gets the organization ID from the auth service 
*/
async function getOrganizationIdByTenant(tenant) {
  try {
    let url = `${AuthService.Endpoint}oauth/tenants/${tenant}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTenant returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong, try again later' }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again later' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

/*
Get the Tenant information
*/
async function getTenantForChecking(tenant) {
  try {
    let url = `${AuthService.Endpoint}subscription/tenants/${tenant}`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTenant returned a status code ${error.response.status}`)
        }
      }
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return { success: false, message: 'Something went wrong, try again later' }
      }
    } else {
      return { success: false, message: 'Something went wrong, try again later' }
    }
  } catch (error) {
    console.error(error)
    return { success: false, message: 'Something went wrong, try again later' }
  }
}

/*
Get the Tenant information
*/
async function getHasTenantSubdomain(tenantId) {
  try {
    let url = `${AuthService.Endpoint}tenants/${tenantId}/hasSubdomain`
    let requestHeaders = createHeaders()

    let response = await axios({
      method: 'get',
      url: url,
      headers: requestHeaders
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 401) {
          store.dispatch(signOut())
        } else if (error.response.data) {
          return error.response
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`getTenant returned a status code ${error.response.status}`)
        }
      }
    })
    return response.data.hasSubdomain
  } catch (error) {
    console.error(error)
    return false
  }
}

export {
  getListOfTenants,
  getSimpleListOfTenants,
  getSimpleListOfApps,
  createTenant,
  getTenant,
  editTenant,
  getOrganizationIdByTenant,
  getTenantForChecking,
  getHasTenantSubdomain
}
