import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { validateProperty, validateObject } from 'utils/validator'
import { tenantCreate as tenantCreateSchema } from 'models/tenantModel'
import { createTenant } from 'services/apiTenants'

import CreateView from './CreateView'
import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions'

// Constants
import { enabledApps as enabledAppsConst } from 'utils/Constants'

const newTenant = {
  name: '',
  address: '',
  city: '',
  language: 'en',
  email: '',
  webSiteUrl: '',
  tenantId: '',
  enabledApps: [],
  enabled: true,
  hasSubdomain: false,
  colorCode: '',
  secondColorCode: '',
  imageLogo: ''
}

const newTenantState = {
  name: '',
  address: '',
  city: '',
  language: '',
  email: '',
  webSiteUrl: '',
  tenantId: '',
  enabledApps: {},
  enabled: '',
  hasSubdomain: '',
  colorCode: '',
  secondColorCode: '',
  imageLogo: ''
}

let _isMounted = false

async function onSubmit (props) {
  const tenant = formatIsoDate({ ...props.tenant})
  let result = await createTenant(tenant)
  // Show confirmation message
  if (result.success && _isMounted) {
    props.setCreateSuccess(true)
    setTimeout(function () {
      if (_isMounted) {
        props.history.push('/admin/tenants/index')
        props.setCreateSuccess(false)
      }
    }, 1500)
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
    hideLoading(props.setPageIsLoading)
  }
}

function formatIsoDate(tenant) {
  tenant.enabledApps.forEach(app => {
    app.startDate = new Date(app.startDate).toISOString()
    app.endDate = new Date(app.endDate).toISOString()
  })
  return tenant
}

function enabledApps () {
  let item
  let enabledAppsNew = []
  let enabledAppsNewState = {}
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  let endDate = new Date()
  endDate = endDate.setDate(endDate.getDate() + 15)
  endDate = new Date(endDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  for (item in enabledAppsConst) {
    enabledAppsNew.push(
      {
        freeTrial: enabledAppsConst[item].freeTrial,
        name: enabledAppsConst[item].name,
        suscriptionType: 'paid',
        startDate: currentDate,
        endDate: endDate,
        key: item,
        active: false
      }
    )
    enabledAppsNewState[item] = {
      suscriptionType: '',
      startDate: '',
      endDate: '',
      active: ''
    }
  }
  newTenant.enabledApps = [ ...enabledAppsNew ]
  newTenantState.enabledApps = { ...enabledAppsNewState }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    {}
  ),
  withState('tenant', 'setTenant', { ...newTenant }),
  withState('tenantState', 'setTenantState', { ...newTenantState }),
  withState('createSuccess', 'setCreateSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('isLoadingSave', 'setIsLoadingSave', false),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withHandlers({
    onFieldChange: props => (field, value, enabledApp = null) => {
      if (field === 'startDate' || field === 'endDate') {
        if (typeof value === 'object' && value !== null) {
          value = value._d.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        }
      }

      if (field === 'tenantId') {
        if(value){
          value = value.toLowerCase()
        }
      }

      let isValid = false
      let valid = ''
      if (enabledApp) {
        const i = props.tenant.enabledApps.findIndex(x => x.key === enabledApp);
        props.tenant.enabledApps[i][field] = value
        isValid = validateProperty(
          tenantCreateSchema,
          props.tenant.enabledApps[i],
          field
        ).isValid
        if (isValid) {
          valid = 'success'
        } else {
          valid = 'error'
        }
        props.tenantState.enabledApps[enabledApp][field] = valid
      } else {
        props.tenant[field] = value
        isValid = validateProperty(tenantCreateSchema, props.tenant, field)
          .isValid
        if (isValid) {
          valid = 'success'
        } else {
          valid = 'error'
        }
        props.tenantState[field] = valid
      }
      props.setTenantState(props.tenantState)
      props.setTenant(props.tenant)
    },
    onTenantSave: props => async () => {
      props.setIsLoadingSave(true)

      let validation = validateObject(tenantCreateSchema, props.tenant)
      if (validation.isValid) {
        showLoading();
        await onSubmit(props)
      } else {
        let field
        for (field in props.tenant) {
          if (validation.errors[field]) {
            props.tenantState[field] = 'error'
          }
        }
        props.setTenantState(props.tenantState)
      }
      props.setIsLoadingSave(false)
    },
    onClear: props => () => {
      props.history.push(`/admin/tenants/index`)
    },
  }),
  lifecycle({
    componentDidMount () {
      showLoading(this.props.setPageIsLoading)
      _isMounted = true
      enabledApps()
      this.props.setTenant({ ...newTenant })
      this.props.setTenantState({ ...newTenantState })
      this.props.setCreateSuccess(false)
      this.props.setPageIsLoading(false)
      hideLoading(this.props.setPageIsLoading)
    }
  })
)(CreateView)
