import { ApiPii } from 'utils/Constants'
import axios from 'axios'
import { createHeaders } from 'utils/functions'

async function createAccessToken(
  hashKey,
  validationId,
  email,
  urlUser,
  urlToken,
  isUpdate = false
) {
  console.log('isUpdate', { context: isUpdate })
  const url = `${ApiPii.EndPoint}/accessToken`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  return axios({
    method: 'post',
    url,
    headers,
    data: {
      hashKey,
      validationId,
      receiver: email,
      url: urlUser,
      urlToken,
      isUpdateProcess: isUpdate
    }
  })
}

async function validateAccessToken(token, hashKey, validationId, email, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/accessToken/validate`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }
    const response = await axios({
      method: 'post',
      url,
      headers,
      data: {
        hashKey,
        validationId,
        token,
        receiver: email
      }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 403) {
          throw new Error(error.response.data.message)
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`validateAccessToken returned a status code ${error.response.status}`)
        }
      }
    })

    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function saveSupplierInformation(
  hashKey,
  validationId,
  updatedValidation,
  supplierName,
  ssn,
  accountNumber,
  bankName,
  swiftCode,
  routingNumber,
  banksCountry,
  ibanNumber,
  urlToken
) {
  try {
    let url = `${ApiPii.EndPoint}/pii/client/${hashKey}/task/${validationId}/supplierData`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }
    let dataSend = {
      updatedValidation,
      supplierName,
      ssn,
      accountNumber,
      bankName,
      swiftCode,
      routingNumber,
      banksCountry,
      ibanNumber
    }

    console.log('data to send info', { con: dataSend })
    let response = await axios({
      method: 'post',
      url,
      headers,
      data: {
        updatedValidation,
        supplierName,
        ssn,
        accountNumber,
        bankName,
        swiftCode,
        routingNumber,
        banksCountry,
        ibanNumber
      }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 500 || error.response.status === 404) {
          throw new Error(error.response.data.message)
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`saveSupplierInformation returned a status code ${error.response.status}`)
        }
      }
    })

    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function getSuppliersData(tenantId, status) {
  const url = `${ApiPii.EndPoint}/tenant/${tenantId}/suppliersData`
  const headers = createHeaders()
  return axios({
    method: 'get',
    url,
    headers,
    params: { status: `${status}` }
  })
}

async function updateSupplierEmail(haskKey, validationId, email) {
  const url = `${ApiPii.EndPoint}/pii/client/${haskKey}/task/${validationId}/supplierData`
  const headers = createHeaders()
  return axios({
    method: 'put',
    url,
    headers,
    data: { email }
  })
}
async function savePiiAgreementTerms(hashKey, validationId, agreementVersion, urlToken) {
  const url = `${ApiPii.EndPoint}/pii/client/${hashKey}/task/${validationId}/version/${agreementVersion}/saveAgreement`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  return axios({
    method: 'put',
    url,
    headers
  })
}
async function finalizePiiForm(tenantId, taskId, status, statusDetail, email, urlToken) {
  const url = `${ApiPii.EndPoint}/finalizeForm`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  try {
    let response
    response = await axios({
      method: 'post',
      url,
      headers,
      data: {
        tenantId: tenantId,
        taskId: taskId,
        status: status,
        statusDetail: statusDetail,
        email: email
      }
    })
    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: ''
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}
async function executeValidation(validationName, tenantId, validationId, attempt, data, urlToken) {
  const url = `${ApiPii.EndPoint}/executeValidation`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  try {
    let response
    response = await axios({
      method: 'post',
      url,
      headers,
      data: {
        validationName,
        tenantId,
        validationId,
        attempt,
        singleData: data
      }
    })
    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'An error occurred while validating'
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function blockPIISupplier(hashKey, validationId, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${hashKey}/task/${validationId}/blockSupplier`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }
    const response = await axios({
      method: 'put',
      url,
      headers
    })

    if (response && response.status === 200) {
      return { success: true, message: response.data.message }
    } else {
      return { success: false, message: '' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function rejectSupplier(haskKey, validationId, message) {
  const url = `${ApiPii.EndPoint}/pii/client/${haskKey}/task/${validationId}/supplier/rejectSupplier`
  const headers = createHeaders()
  return axios({
    method: 'post',
    url,
    headers,
    data: { message }
  })
}

async function resendSupplierInvitation(haskKey, validationId) {
  const url = `${ApiPii.EndPoint}/resendPiiFormInvitation`
  const headers = createHeaders()
  return axios({
    method: 'post',
    url,
    headers,
    data: { tenantId: haskKey, taskId: validationId }
  })
}
async function getRelishMessage(appId, typeId, urlToken) {
  try {
    console.log('getting mesg', { appId, typeId })
    //let tenant = getUserTenant()
    const url = `${ApiPii.EndPoint}/pii/apps/${appId}/type/${typeId}/getRelishMessage`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }
    const response = await axios({
      method: 'get',
      url: url,
      headers
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
async function processPIIFile(hashKey, validationId, fileData, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${hashKey}/task/${validationId}/processFile`
    let headers = {
      Authorization: `Bearer ${urlToken}`
    }
    headers['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('file', fileData)
    formData.append('fileName', fileData)

    const response = await axios({
      method: 'post',
      url,
      headers,
      data: formData
    })

    if (response && response.status === 201) {
      return {
        success: true,
        message: response.data.message,
        fileId: response.data.fileId
      }
    } else {
      return { success: false, message: '' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function getFormProcessSatus(fileId, hashKey, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${hashKey}/process/${fileId}`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }

    const response = await axios({
      method: 'get',
      url,
      headers
    })

    if (response && response.status === 200) {
      //order otherValues
      let otherValuesArray = []
      if (response.data.otherValues) {
        otherValuesArray = response.data.otherValues
        otherValuesArray.sort(function(a, b) {
          let key1 = a.key.toUpperCase()
          let key2 = b.key.toUpperCase()

          return key1.localeCompare(key2)
        })
      }
      console.log('otherValues to return', { context: otherValuesArray })
      return {
        success: true,
        message: response.data.message,
        name: response.data.name,
        ssn: response.data.ssn,
        otherValues: otherValuesArray
      }
    } else {
      return { success: false, message: '' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

export {
  createAccessToken,
  validateAccessToken,
  saveSupplierInformation,
  getSuppliersData,
  updateSupplierEmail,
  finalizePiiForm,
  executeValidation,
  blockPIISupplier,
  rejectSupplier,
  resendSupplierInvitation,
  processPIIFile,
  getFormProcessSatus,
  savePiiAgreementTerms,
  getRelishMessage
}