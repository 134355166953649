import React from 'react'
import { NavLink } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import { Select, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { CircularProgress } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'

// react component for creating dynamic tables
import ReactTable from 'react-table'
import SweetAlert from 'react-bootstrap-sweetalert'

// @material-ui/icons
import TouchApp from '@material-ui/icons/TouchApp'
import AddAlert from '@material-ui/icons/AddAlert'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import Info from '@material-ui/icons/Info'
import Map from '@material-ui/icons/Map'
import Edit from '@material-ui/icons/Edit'
import Close from '@material-ui/icons/Close'
import Add from '@material-ui/icons/PlaylistAdd'
import ListAltIcon from '@material-ui/icons/ListAlt'
import Message from '@material-ui/icons/Message'

// components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import NavPills from 'components/NavPills/NavPills.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Button from 'components/CustomButtons/Button.js'
import CardIcon from 'components/Card/CardIcon.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import InputAdornment from '@material-ui/core/InputAdornment'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'

let swipeableActions
const useStyles = makeStyles(styles)

function modal (classes, props) {
  let alertMessage = null
  if (props.modal.visible) {
    switch (props.modal.type) {
      case 'delete':
        alertMessage = (
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title='Are you sure you want to delete the questionnaire?'
            onConfirm={() =>
              props.onQuestionnaireDelete(swipeableActions.updateHeight)
            }
            onCancel={() => props.onHideModal()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText='Yes, I want to delete it'
            cancelBtnText='Cancel'
            showCancel
          >
            {props.modal.name}
          </SweetAlert>
        )
        break
      case 'edit':
        let title = ''
        let label = ''
        let onConfirm
        switch (props.modal.object) {
          case 'questionnaire':
            title = 'Edit questionnaire name'
            label = 'Unique name*'
            onConfirm = () => props.onQuestionnaireEdit()
            break
          case 'message':
            title = 'Edit message'
            label = 'Display message'
            onConfirm = () => props.onMessageEdit()
            break
          default:
            break
        }

        alertMessage = (
          <SweetAlert
            style={{ display: 'block' }}
            title={title}
            onConfirm={onConfirm}
            onCancel={() => props.onHideModal()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText='Confirm'
            cancelBtnText='Cancel'
            showCancel
          >
            {props.modal.name}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <CustomInput
                  success={props.modalFormState.name === 'success'}
                  error={props.modalFormState.name === 'error'}
                  labelText={label}
                  id='inputUniqueName'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      props.onFormChange('name', event.target.value)
                    },
                    type: 'text',
                    value: props.modalForm.name,
                    endAdornment:
                      props.modalFormState['name'] === 'error' ? (
                        <InputAdornment position='end'>
                          <Close className={classes.error} />
                        </InputAdornment>
                      ) : (
                        undefined
                      )
                  }}
                />
              </GridItem>
            </GridContainer>
          </SweetAlert>
        )
        break
      case 'create':
        alertMessage = (
          <SweetAlert
            style={{ display: 'block' }}
            title='Create new questionnaire'
            onConfirm={() =>
              props.onQuestionnaireCreate(swipeableActions.updateHeight)
            }
            onCancel={() => props.onHideModal()}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText='Confirm'
            cancelBtnText='Cancel'
            showCancel
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <CustomInput
                  success={props.modalFormState.name === 'success'}
                  error={props.modalFormState.name === 'error'}
                  labelText='Unique name*'
                  id='inputUniqueName'
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      props.onFormChange('name', event.target.value)
                    },
                    type: 'text',
                    value: props.modalForm.name,
                    endAdornment:
                      props.modalFormState['name'] === 'error' ? (
                        <InputAdornment position='end'>
                          <Close className={classes.error} />
                        </InputAdornment>
                      ) : (
                        undefined
                      )
                  }}
                />
              </GridItem>
            </GridContainer>
          </SweetAlert>
        )
        break

      default:
        break
    }
  }

  return alertMessage
}

export default function NitorConnect (props) {
  const classes = useStyles()

  function returnConfigTab () {
    let dataTableInfo = Object.keys(props.configurationForm.mappings).map(
      (prop, key) => {
        const mapping = props.configurationForm.mappings[prop]
        return {
          id: key,
          name: props.configurationForm.mappings[prop].uniqueName,
          actions: (
            // we've added some custom button actions
            <div className='actions-right'>
              <NavLink
                to={`/admin/dataAssure/addressquestionnaire/${props.configurationForm.env}/${prop}`}
              >
                <Tooltip title={'Questions'}>
                  <Button
                    color={'primary'}
                    simple
                    justIcon
                    className={classes.actionButton}
                  >
                    <ListAltIcon className={classes.icon} />
                  </Button>
                </Tooltip>
              </NavLink>
              <Tooltip title={'Edit questionnaire name'}>
                <Button
                  color={'warning'}
                  simple
                  justIcon
                  className={classes.actionButton}
                  onClick={() => {
                    props.onShowModal(
                      'edit',
                      'questionnaire',
                      prop,
                      mapping.uniqueName
                    )
                  }}
                >
                  <Edit className={classes.icon} />
                </Button>
              </Tooltip>
              <Tooltip title={'Delete questionnaire'}>
                <Button
                  color={'danger'}
                  simple
                  justIcon
                  className={classes.actionButton}
                  onClick={() => {
                    props.onShowModal(
                      'delete',
                      'questionnaire',
                      prop,
                      mapping.uniqueName
                    )
                  }}
                >
                  <Close className={classes.icon} />
                </Button>
              </Tooltip>
            </div>
          )
        }
      }
    )

    return (
      <GridItem xs={12} sm={12}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <h5 className={classes.sectionTitle}>SLP Questionnaire</h5>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer className={classes.row}>
              <GridItem xs={12} sm={12}>
                <FormLabel className={classes.contentLabelHorizontal}>
                  Add all Ariba SLP Questionnaires that require a{' '}
                  <strong>US Address Validation</strong>.
                </FormLabel>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridItem xs={12}>
              <GridContainer className={classes.row}>
                <GridItem xs={12}>
                  <Card>
                    <CardHeader color='info' icon>
                      <CardIcon color='info'>
                        <Map />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Questionnaires</h4>
                      <Tooltip title='Create new questionnaire'>
                        <Button
                          color='primary'
                          className={classes.addButton}
                          onClick={() => {
                            props.onShowModal('create', 'questionnaire', null)
                          }}
                        >
                          <Add className={classes.icons} />
                        </Button>
                      </Tooltip>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={dataTableInfo}
                        filterable
                        columns={[
                          {
                            Header: 'Questionnaire',
                            accessor: 'name'
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            sortable: false,
                            filterable: false
                          }
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        minRows={0}
                        showPaginationBottom={false}
                        className='-striped -highlight'
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <div className={classes.center}>
                {props.isLoadingSave ? (
                  <CircularProgress color='inherit' />
                ) : (
                  <Button color='primary' onClick={props.onConfigSave}>
                    Save
                  </Button>
                )}
              </div>
            </GridItem>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  function returnParamsTab () {
    function returnMessagesTable () {
      let dataTableInfo = Object.keys(
        props.parametersForm.ValidationMessages
      ).map((prop, key) => {
        let message = props.parametersForm.ValidationMessages[prop]
        return {
          id: message.id,
          enabled: (
            <div className='actions-center'>
              <Switch
                checked={message.active}
                onChange={event =>
                  props.onQuestionChange('active', event.target.checked, prop)
                }
                value='Active'
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
              />
            </div>
          ),
          type: message.type.toUpperCase(),
          visible: (
            <div className='actions-center'>
              <Switch
                checked={message.visible}
                onChange={event =>
                  props.onQuestionChange('visible', event.target.checked, prop)
                }
                value='Visible'
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
              />
            </div>
          ),
          description: message.description,
          message: message.message,
          actions: (
            // we've added some custom button actions
            <div className='actions-right'>
              <Tooltip title={'Edit message'}>
                <Button
                  color={'warning'}
                  simple
                  justIcon
                  className={classes.actionButton}
                  onClick={() => {
                    props.onShowModal(
                      'edit',
                      'message',
                      message.id,
                      message.message
                    )
                  }}
                >
                  <Edit className={classes.icon} />
                </Button>
              </Tooltip>
            </div>
          )
        }
      })

      return (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <Message />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Validation Messages</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={dataTableInfo}
                  filterable
                  columns={[
                    {
                      Header: 'Enabled',
                      accessor: 'enabled',
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: 'Type',
                      accessor: 'type'
                    },
                    {
                      Header: 'Visible',
                      accessor: 'visible',
                      sortable: false,
                      filterable: false
                    },
                    {
                      Header: 'Description',
                      accessor: 'description'
                    },
                    {
                      Header: 'Message',
                      accessor: 'message'
                    },
                    {
                      Header: 'Actions',
                      accessor: 'actions',
                      sortable: false,
                      filterable: false
                    }
                  ]}
                  defaultPageSize={5}
                  showPaginationTop
                  minRows={0}
                  showPaginationBottom={false}
                  className='-striped -highlight'
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12}>
            <h5 className={classes.sectionTitle}>Validation</h5>
            <GridItem xs={12} sm={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer className={classes.row}>
                    <GridItem xs={12} sm={8}>
                      <FormLabel className={classes.toggleTitle}>
                        Use House Id in the address validation.
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <GridContainer justify='center'>
                        <div className={classes.block}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={props.parametersForm.ConcatHouseID}
                                onChange={event =>
                                  props.onParamFieldChange(
                                    'ConcatHouseID',
                                    event.target.checked
                                  )
                                }
                                value='ConcatHouseID'
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                          />
                        </div>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer className={classes.row}>
                    <GridItem xs={12} sm={8}>
                      <FormLabel className={classes.toggleTitle}>
                        Use Room Number in the address validation.
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <GridContainer justify='center'>
                        <div className={classes.block}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={props.parametersForm.ConcatRoomID}
                                onChange={event =>
                                  props.onParamFieldChange(
                                    'ConcatRoomID',
                                    event.target.checked
                                  )
                                }
                                value='ConcatRoomID'
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  thumb: classes.switchIcon,
                                  track: classes.switchBar
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                          />
                        </div>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer className={classes.row}>
                    <GridItem xs={12} sm={8}>
                      <FormLabel className={classes.contentLabelHorizontal}>
                        Select MS Teams Visibility.{' '}
                        <strong>Private teams</strong> can only be joined if the
                        team owner adds someone to them.
                        <strong> Public teams</strong> are visible to everyone
                        from the teams gallery and you can join then without
                        getting approval from the team owner.
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor='simple-select'
                          className={classes.selectLabel}
                        >
                          Validation Level
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={props.parametersForm.ValidationLevel}
                          onChange={e =>
                            props.onParamFieldChange(
                              'ValidationLevel',
                              e.target.value
                            )
                          }
                          inputProps={{
                            name: 'ValidationLevel',
                            id: 'ValidationLevel'
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            style={{
                              overflowX: 'auto',
                              textOverflow: 'ellipsis'
                            }}
                            value={'invalid'}
                          >
                            Non strict
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            style={{
                              overflowX: 'auto',
                              textOverflow: 'ellipsis'
                            }}
                            value={'strict'}
                          >
                            Strict
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer className={classes.row}>
                    <GridItem xs={12} sm={8}>
                      <FormLabel className={classes.contentLabelHorizontal}>
                        Time in minutes to Archive the MS Team after it is fully
                        approved. When you archive a team, the team becomes
                        read-only. You and other members can still access it and
                        view all the team activity in channels, files, and
                        chats. You can also add or remove members and update
                        roles. Archive teams can also be reactivated by owners.
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor='simple-select'
                          className={classes.selectLabel}
                        >
                          Postal Code Length
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={props.parametersForm.PostalCodeLength}
                          onChange={e =>
                            props.onParamFieldChange(
                              'PostalCodeLength',
                              e.target.value
                            )
                          }
                          inputProps={{
                            name: 'PostalCodeLength',
                            id: 'PostalCodeLength'
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            style={{
                              overflowX: 'auto',
                              textOverflow: 'ellipsis'
                            }}
                            value={5}
                          >
                            5
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            style={{
                              overflowX: 'auto',
                              textOverflow: 'ellipsis'
                            }}
                            value={9}
                          >
                            9
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer className={classes.row}>
                    <GridItem xs={12} sm={8}>
                      <FormLabel className={classes.contentLabelHorizontal}>
                        Time in minutes to Archive the MS Team after it is fully
                        approved. When you archive a team, the team becomes
                        read-only. You and other members can still access it and
                        view all the team activity in channels, files, and
                        chats. You can also add or remove members and update
                        roles. Archive teams can also be reactivated by owners.
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={4}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor='simple-select'
                          className={classes.selectLabel}
                        >
                          Maximum number of address suggestions
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={props.parametersForm.MaxSuggestions}
                          onChange={e =>
                            props.onParamFieldChange(
                              'MaxSuggestions',
                              e.target.value
                            )
                          }
                          inputProps={{
                            name: 'MaxSuggestions',
                            id: 'MaxSuggestions'
                          }}
                        >
                          {new Array(10).fill().map((_, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                style={{
                                  overflowX: 'auto',
                                  textOverflow: 'ellipsis'
                                }}
                                value={index + 1}
                                key={index + 1}
                              >
                                {index + 1}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer>
              <GridItem xs={12} sm={12}>
                <h5 className={classes.sectionTitle}>
                  API Validation Parameters
                </h5>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer className={classes.row}>
              <GridItem xs={12} sm={12}>
                <FormLabel className={classes.contentLabelHorizontal}>
                  The following parameters control the behavior of the Ariba SLP
                  Address Validation API.
                </FormLabel>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12}>
            {returnMessagesTable()}
          </GridItem>
        </GridContainer>
        <div className={classes.center}>
          {props.isLoadingSave ? (
            <CircularProgress color='inherit' />
          ) : (
            <Button color='primary' onClick={props.onParamsSave}>
              Save
            </Button>
          )}
        </div>
      </GridItem>
    )
  }

  function returnTabsToDisplay () {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <h3 className={classes.formHeader}>
              Nitor Data Assure - US Address Validation
            </h3>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                color='behance'
                simple
                className={classes.documentation}
                href='/admin/dataAssure/documentation'
                target='_blank'
              >
                <Info className={classes.icons} />
                Download Documentation
              </Button>
            </GridItem>
          </CardHeader>
          <CardBody>
            <NavPills
              action={actions => {
                swipeableActions = actions
              }}
              animateHeight
              color='primary'
              tabs={[
                {
                  tabButton: 'Configuration',
                  tabContent: returnConfigTab()
                },
                {
                  tabButton: 'Parameters',
                  tabContent: returnParamsTab()
                }
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    )
  }

  let breadcrumbViews = [
    {
      name: 'Data Assure',
      url: '/admin/dataAssure/index',
      icon: AssignmentTurnedInIcon
    },
    {
      name: 'Address Validation',
      url: `/admin/dataAssure/addressValidation/${props.selectedAppId}`,
      icon: LocationCityIcon
    }
  ]
  return (
    <GridContainer>
      {modal(classes, props)}
      <GridItem xs={12} sm={12} md={12} lg={3}>
        <Card className={classes.appBar}>
          <CardHeader>Application List</CardHeader>
          <CardBody>
            <List>
              {props.appList.map((prop, key) => {
                return (
                  <ListItem
                    className={classes.listItem}
                    button
                    selected={props.selectedAppId === prop.env}
                    key={key}
                    onClick={() => props.onAppChanged(prop.env)}
                  >
                    <ListItemAvatar>
                      {props.selectedAppId === prop.env ? (
                        <Avatar>
                          <TouchApp />
                        </Avatar>
                      ) : (
                        <Avatar className={classes.clearAvatar}></Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={prop.env}
                      secondary={`Type: ${prop.type}`}
                    />
                  </ListItem>
                )
              })}
            </List>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={9}>
        <Breadcrumbs views={breadcrumbViews} />
        {props.formIsLoading ? (
          <Card>
            <GridContainer justify='center'>
              <div className={classes.circularProgress}>
                <CircularProgress color='inherit' />
              </div>
            </GridContainer>
          </Card>
        ) : (
          returnTabsToDisplay()
        )}
      </GridItem>
      <Snackbar
        place='br'
        color='success'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.submitSuccess}
        closeNotification={() => props.setSubmitSuccess(false)}
        close
      />
      <Snackbar
        place='bl'
        color='danger'
        icon={AddAlert}
        message={props.validationMessage}
        open={props.createError}
        closeNotification={() => props.setCreateError(false)}
        close
      />
    </GridContainer>
  )
}
