const configuration = {
}

const parameters = {
}

const questionnaire = {
    uniqueName: {
        type: "string",
        required: true,
        displayName: "Questionnaire Name",
        length: {
            min: 1
        }
    },
    answerType: {
        type: "string",
        displayName: "Answer Type"
    },
    lName: {
        type: "string",
        required: true,
        displayName: "Supplier Name",
        length: {
            min: 1
        }
    },
    supplierType: {
        type: "string",
        required: true,
        displayName: "Supplier Type",
        length: {
            min: 1
        }
    },
    feinValue: {
        type: "string",
        required: true,
        displayName: "Fein Value",
        length: {
            min: 1
        }
    },
    tax: {
        type: "string",
        displayName: "Tax",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if (data.answerType === "tax") {
                        isValid = (data.tax !== null && data.tax !== undefined && data.tax.trim() !== "")
                    }
                    return isValid
                },
                message: "Tax is a mandatory field"
            }
        ]
    },
    country: {
        type: "string",
        displayName: "Country",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if (data.addressType === "generic") {
                        isValid = (data.country !== null && data.country !== undefined && data.country.trim() !== "")
                    }
                    return isValid
                },
                message: "Country is a mandatory field"
            }
        ]
    },
    fein: {
        type: "string",
        displayName: "FEIN",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if (data.addressType === "generic") {
                        isValid = (data.fein !== null && data.fein !== undefined && data.fein.trim() !== "")
                    }
                    return isValid
                },
                message: "FEIN is a mandatory field"
            }
        ]
    },
    ssn: {
        type: "string",
        displayName: "SSN",
        customRules: [
            {
                rule: function (data) {
                    let isValid = true
                    if (data.addressType === "generic") {
                        isValid = (data.ssn !== null && data.ssn !== undefined && data.ssn.trim() !== "")
                    }
                    return isValid
                },
                message: "SSN is a mandatory field"
            }
        ]
    }

}

const message = {
    message: {
        type: "string",
        required: true,
        displayName: "Message",
        length: {
            min: 1
        }
    },
}

export {
    configuration,
    parameters,
    questionnaire,
    message
};
