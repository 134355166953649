import { useQuery, useQueryClient, useMutation } from 'react-query'
import {
  createAccessToken,
  getSuppliersData,
  updateSupplierEmail,
  rejectSupplier,
  resendSupplierInvitation
} from '../services/apiPII'
import { getUserTenant } from 'utils/functions'
import { PIIStatus } from 'utils/Constants'

export function useCreateAccessCode () {
  return useMutation(({ tenantId, env, email, url, urlToken, isUpdate }) =>
    createAccessToken(tenantId, env, email, url, urlToken, isUpdate)
  )
}

export function useGetSuppliersData (status) {
  const client = getUserTenant()
  return useQuery(
    [
      'suppliers',
      client,
      // eslint-disable-next-line
      status.reduce((acc, curr) => ((acc[curr] = curr), acc), {})
    ],
    () =>
      getSuppliersData(client, status).then(result => {
        const rows = result.data.data.map(row => {
          let newRow = { header: {} }
          newRow.header['taskId'] = row.taskId
          newRow.header['supplierId'] = row.supplierId
          newRow.header['status'] = row.status
          newRow.header['lastUpdated'] = row.lastUpdated
          newRow.header['email'] = row.email
          newRow.header['client'] = row.client
          newRow.header['environment'] = row.environment
          newRow.header['hashKey'] = row.hashKey
          newRow.header['validationId'] = row.validationId
          newRow.header['environment'] = row.environment
          newRow.header['creationDate'] = row.creationDate
          newRow.header['piiHistory'] = row.piiHistory
          newRow.header['type'] = row.type
          newRow.header['taskAribaLink'] = row.taskAribaLink
          newRow.header['supplierAribaLink'] = row.supplierAribaLink
          return newRow.header
        })
        return { rows }
      }),
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    }
  )
}

export function useModifySupplier () {
  const queryClient = useQueryClient()
  const client = getUserTenant()
  return useMutation(
    values => {
      return updateSupplierEmail(
        values.hashKey,
        values.validationId,
        values.email
      )
    },
    {
      onMutate: async modifiedInfo => {
        const {
          environment,
          hashKey,
          validationId,
          email,
          status
        } = modifiedInfo
        await queryClient.cancelQueries(['suppliers', client, status])
        const previousValue = queryClient.getQueryData([
          'suppliers',
          client,
          status
        ])
        const newValue = JSON.parse(JSON.stringify(previousValue))
        newValue.rows.forEach(item => {
          if (
            item.hashKey === hashKey &&
            item.validationId === validationId &&
            item.environment === environment
          ) {
            item.email = email
            item.status = PIIStatus.NOT_INVITED
          }
        })
        queryClient.setQueryData(['suppliers', client, status], newValue)

        // Return a rollback function
        return () => {
          queryClient.setQueryData(['suppliers', client, status], previousValue)
        }
      }
    }
  )
}

export function useRejectSupplier () {
  const queryClient = useQueryClient()
  const client = getUserTenant()
  return useMutation(
    values => {
      return rejectSupplier(
        values.hashKey,
        values.validationId,
        values.rejectedMessage
      )
    },
    {
      onMutate: async modifiedInfo => {
        const { hashKey, validationId, status } = modifiedInfo
        await queryClient.cancelQueries(['suppliers', client, status])
        const newValue = queryClient.getQueryData(['suppliers', client, status])
        // const newValue = JSON.parse(JSON.stringify(previousValue))
        const previousValue = {
          ...newValue.rows.find(
            item =>
              item.hashKey === hashKey && item.validationId === validationId
          )
        }

        newValue.rows.forEach(item => {
          if (item.hashKey === hashKey && item.validationId === validationId) {
            item.status = PIIStatus.REJECTED
          }
        })
        queryClient.setQueryData(['suppliers', client, status], newValue)

        // Return a rollback function
        return () => {
          const presentValue = queryClient.getQueryData([
            'suppliers',
            client,
            status
          ])

          presentValue.rows.forEach(item => {
            if (
              item.hashKey === hashKey &&
              item.validationId === validationId
            ) {
              item.status = previousValue.status
            }
          })
          queryClient.setQueryData(['suppliers', client, status], presentValue)
        }
      }
    }
  )
}

export function useResendInvitation () {
  const queryClient = useQueryClient()
  const client = getUserTenant()
  return useMutation(
    values => {
      return resendSupplierInvitation(values.hashKey, values.validationId)
    },
    {
      onMutate: async modifiedInfo => {
        const { hashKey, validationId, status } = modifiedInfo
        await queryClient.cancelQueries(['suppliers', client, status])
        const previousValue = queryClient.getQueryData([
          'suppliers',
          client,
          status
        ])
        const newValue = JSON.parse(JSON.stringify(previousValue))
        newValue.rows.forEach(item => {
          if (item.hashKey === hashKey && item.validationId === validationId) {
            item.status = PIIStatus.INVITED
          }
        })
        queryClient.setQueryData(['suppliers', client, status], newValue)

        // Return a rollback function
        return () => {
          queryClient.setQueryData(['suppliers', client, status], previousValue)
        }
      }
    }
  )
}