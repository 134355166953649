import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'

import { HeaderFieldsStandard } from 'utils/Constants'
import SelectField from './SelectField'
import { getStandardRule, getValidationRule } from './utils'

function getSelectOptions(appConfig) {
  const jsxResult = []
  jsxResult.push(
    <MenuItem value="" key={'none'}>
      <em>None</em>
    </MenuItem>
  )
  // eslint-disable-next-line
  for (const company of appConfig.data.params?.rfc ?? []) {
    jsxResult.push(
      <MenuItem value={company.companyCode} key={company.rfc}>
        {`(${company.companyCode}) ${company.name}`}
      </MenuItem>
    )
  }

  return jsxResult
}

const SelectCompanyCode = props => {
  const { appConfig, headerFieldsActions, getValues, requiredFields, setValueForm } = props
  const { deleteErrorsHeader } = headerFieldsActions
  const options = getSelectOptions(appConfig)

  function onChangeCompanyCode(rfcList = [], code = '') {
    const companyCode = rfcList.find(rfc => rfc.companyCode === code)

    if (!companyCode) {
      return
    }
    const summaryFields = getValues('headerFields')
    const billToRules = getStandardRule({
      requiredFields,
      level: 'header',
      standardField: HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type
    })
    const billToDataType = getValidationRule(billToRules, 'dataType')
    const shipToRules = getStandardRule({
      requiredFields,
      level: 'header',
      standardField: HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type
    })
    const shipToDataType = getValidationRule(shipToRules, 'dataType')

    const deleteErrors: DeleteErrorPayload[] = []

    const indexFieldIdBillTo = summaryFields.findIndex(
      summaryField =>
        HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type === summaryField.type
    )

    if (indexFieldIdBillTo >= 0) {
      deleteErrors.push(
        {
          field: HeaderFieldsStandard['companyProfile.billToAddress.id'].type
        },
        {
          field: HeaderFieldsStandard['companyProfile.billToAddress.fullAddress'].type
        }
      )
      if (billToDataType?.value === 'companyAddress') {
        setValueForm(`summaryFields.${indexFieldIdBillTo}.value`, '', {
          shouldValidate: true,
          shouldDirty: true
        })
        setValueForm(`summaryFields.${indexFieldIdBillTo}.address`, '')
      } else {
        setValueForm(`summaryFields.${indexFieldIdBillTo}.value`, '', {
          shouldValidate: true,
          shouldDirty: true
        })
      }
    }
    const indexFieldIdShip = summaryFields.findIndex(
      summaryField =>
        HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type === summaryField.type
    )
    if (indexFieldIdShip >= 0) {
      deleteErrors.push(
        {
          field: HeaderFieldsStandard['companyProfile.shipToAddress.id'].type
        },
        {
          field: HeaderFieldsStandard['companyProfile.shipToAddress.fullAddress'].type
        }
      )
      if (shipToDataType?.value === 'companyAddress') {
        setValueForm(`summaryFields.${indexFieldIdShip}.value`, '', {
          shouldValidate: true,
          shouldDirty: true
        })
        setValueForm(`summaryFields.${indexFieldIdShip}.address`, '')
      } else {
        setValueForm(`summaryFields.${indexFieldIdShip}.value`, '', {
          shouldValidate: true,
          shouldDirty: true
        })
      }
    }

    if (deleteErrors.length > 0) {
      deleteErrorsHeader(deleteErrors)
    }
  }

  const handleChange = companyCode => {
    onChangeCompanyCode(appConfig.data.params.rfc ?? [], companyCode)
  }

  return (
    <SelectField {...props} onChange={handleChange}>
      {options}
    </SelectField>
  )
}

export default SelectCompanyCode
