import { Dialog, IconButton, Tooltip } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import CustomTreeTable from 'components/CustomTreeTable/CustomTreeTable'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, { useState } from 'react'
import Route from './Route'
import EditIcon from '@material-ui/icons/Edit'
import DialogTitle from '@material-ui/core/DialogTitle'
import SettingsIcon from '@material-ui/icons/Settings'
import ClearIcon from '@material-ui/icons/Clear'
import { makeStyles } from '@material-ui/core/styles'
// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

export default function CustomRoutes (props) {
  const [selectedQuestion, setSelectedQuestion] = useState(undefined)
  const [showDialog, setShowDialog] = useState(false)
  const [isRouteEdit, setIsRouteEdit] = useState(false)
  const [editedFields, setEditedFields] = useState([])
  const [refresh, setRefresh] = useState(false)
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  //Fields: id,type[generic ,extended,others...],typeParameter[ej:extended.street1,...],Variabletype[string,number,boolean],action[skip,validate only],actionCondition[==,!=,Contains,>,<],caseSensitive[true/false],value

  if (!props.element.routes)
    props.element.routes = [
      {
        routeQuestionId: '',
        routeAction: '',
        routeCondition: '',
        routeName: '',
        routeType: '',
        routeValue: '',
        routeExtendedField: ''
      }
    ]

  function getEditedFields (questionId) {
    let fields = []
    let field
    let key
    if (props.element) {
      let index = 0
      for (index = 0; index < props.element.questions.length; index++) {
        field = props.element.questions[index]
        if (field && field.id === questionId) {
          break
        }
      }
      if (field) {
        for (key of Object.keys(field.values)) {
          fields.push(field.values[key].value)
        }
      }
    }
    setEditedFields(fields)
  }
  function getRouteData () {
    let data = []

    data = props.element.routes.map((route, index) => {
      let row = {}
      row.routeQuestionId = route.routeQuestionId
      row.routeName = route.routeName
      row.routeType = route.routeType
      row.routeAction = route.routeAction
      row.routeCondition = route.routeCondition
      row.routeValue = route.routeValue
      row.action = (
        <GridContainer>
          <GridItem xs={6}>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  console.log('To Edit Route', { context: route })
                  setSelectedQuestion(route)
                  setIsRouteEdit(true)
                  setShowDialog(true)
                  getEditedFields(route.routeQuestionId)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </GridItem>
          <GridItem xs={6}>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  console.log('To Delete Route', { context: route })
                  props.element.routes = props.element.routes.filter(function (
                    obj
                  ) {
                    return (
                      obj.routeName !== route.routeName &&
                      obj.routeQuestionId !== route.routeQuestionId
                    )
                  })
                  console.log('after deleted', { con: props.element.routes })
                  props.onSaveTodatabase()
                  setRefresh(true)
                }}
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
          </GridItem>
        </GridContainer>
      )
      return row
    })

    return data
  }
  function returnCustomRoute () {
    return (
      <div>
        <Dialog
          id={'dialogId'}
          keepMounted
          open={showDialog}
          style={{ overflow: 'hidden', maxWidth: '' }}
          fullWidth
          maxWidth={'lg'}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>Router Details</h4>
          </DialogTitle>
          <div>
            <IconButton style={{ float: 'right' }}>
              <Close
                onClick={() => {
                  setShowDialog(false)
                }}
              />
            </IconButton>
            <Route
              route={
                selectedQuestion
                  ? selectedQuestion
                  : {
                      routeQuestionId: '',
                      routeAction: '',
                      routeCondition: '',
                      routeName: '',
                      routeType: '',
                      routeValue: '',
                      routeExtendedField: ''
                    }
              }
              isEdit={isRouteEdit}
              //save new route added
              onSave={route => {
                if (selectedQuestion) {
                  console.log('route edited')
                } else {
                  console.log('to push', { con: route })

                  props.element.routes.push(route)
                }
                props.onSaveTodatabase()
                setShowDialog(false)
              }}
              routerQuestions={props.element.questions}
              routerEditedQuestions={editedFields}
            />
          </div>
        </Dialog>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <CustomTreeTable
                isLoadingSave={''}
                onSave={
                  //props.onConfigSave
                  () => {
                    props.onSaveTodatabase()
                    // console.log('element Routes', {
                    //   context: props.element.routes
                    // })
                  }
                }
                showTablePagination={true}
                tableMaxSize={10}
                hideSaveButton={true}
                hideAddButton={false}
                onClickAdd={() => {
                  setSelectedQuestion(false)
                  setIsRouteEdit(false)
                  setShowDialog(true)
                }}
                //formLabel={'form label'}
                transformedColumnName={''}
                formTitle="ARIBA"
                addTooltipText={'Add new Route'}
                tableTitle="Routes"
                tableIcon={<SettingsIcon />}
                mainAccessor={'routeName'}
                noDataDiv={'nodatadiv'}
                noDataText={'noDataText'}
                //dataVersion={this.state.dataVersion}
                //data={props.element.routes}
                data={getRouteData()}
                columns={[
                  {
                    Header: 'Question',
                    accessor: 'routeQuestionId',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Field',
                    accessor: 'routeName',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Type',
                    accessor: 'routeType',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Action',
                    accessor: 'routeAction',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Condition',
                    accessor: 'routeCondition',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Value',
                    accessor: 'routeValue',
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: 'Actions',
                    accessor: 'action',
                    sortable: false,
                    filterable: false
                  }
                ]}
                refreshData={refreshChild => {
                  //this.refreshTable = refreshChild
                }}
                transformCodes={['xs', 'sm']}
                defaultPageSize={10}
                showPaginationTop
                minRows={0}
                showPaginationBottom={false}
                className="-striped -highlight"
              ></CustomTreeTable>
            </GridContainer>
          </GridItem>
        </GridContainer>

        {/* <CustomTreeTable
          tableMaxSize={10}
          //hide
          hideSaveButton={true}
          hideAddButton={false}
          showTablePagination={false}
          formLabel={''}
          transformedColumnName={'Name'}
          formTitle={'Settings'}
          tableTitle={''}
          //tableIcon={<SettingsIcon />}
          // onClickAdd={() => {
          //   props.showMonitorModal(index, undefined)
          // }}
          mainAccessor={'routeName'}
          noDataDiv={''}
          noDataText=""
          data={props.element.routes}
          columns={[
            {
              Header: 'Name',
              accessor: 'routeName',
              sortable: false,
              filterable: false
            },
            {
              Header: 'Type',
              accessor: 'routeType',
              sortable: false,
              filterable: false
            },
            {
              Header: 'Action',
              accessor: 'routeAction',
              sortable: false,
              filterable: false
            },
            {
              Header: 'Condition',
              accessor: 'routeCondition',
              sortable: false,
              filterable: false
            }
          ]}
          refreshData={refreshChild => {
            // refreshTableFunction.current = () => {
            //   refreshChild(getTableData())
            // }
          }}
          transformCodes={['xs', 'sm']}
          defaultPageSize={10}
          showPaginationTop
          showPaginationBottom={false}
          className="-striped -highlight"
        ></CustomTreeTable> */}

        {/* {props.element.routes.map((route, index) => {
          return (
            <div key={index}>
              <Route
                route={route}
                setRouter={route => {
                  console.log('received route', { con: route })
                }}
              />
            </div>
          )
        })} */}
        {/* <Button
          onClick={() => {
            props.onClick(props.element)
          }}
        >
          Add Router
        </Button> */}
      </div>
    )
  }
  if (refresh) {
    return returnCustomRoute()
  } else {
    return <div>{returnCustomRoute()}</div>
  }
}
