import { combineReducers } from 'redux'

import login from './AuthState'
import app from './AppState'
import qualtrics from './QualtricsState'
import intelligence from './IntelligenceState'

const appReducer = combineReducers({
  login,
  app,
  qualtrics,
  intelligence
})

export default (state, action) => {
  if (action.type === 'Login/SIGN_OUT_SUCCESS') {
    // for all keys defined in your persistConfig(s)
    localStorage.removeItem('persist:root')
    state = undefined
  }

  return appReducer(state, action)
}
