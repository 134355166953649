import React, { useEffect, useState, useRef } from 'react'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import DvrIcon from '@material-ui/icons/Dvr'
import DetailsIcon from '@material-ui/icons/Details'
import DashboardIcon from '@material-ui/icons/Dashboard'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import CustomDownloadButtom from 'components/CustomDownloadButtom/CustomDownloadButtom.js'

// functions
import { formatDate } from 'utils/functions.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'
import SyncLoader from 'components/SyncLoader/SyncLoader.js'

// hooks
import {
  useGetTransactionDataById
  // useDeleteStepHistory
} from 'hooks/useMonitorsConsole'

const useStyles = makeStyles(styles)
export default function MonitorTransactionDetailsView() {
  const history = useHistory()
  const { processId, env, monitorId, description, appId } = useParams()
  const [createError, setCreateError] = useState(false)
  const [userFilters, setUserFilters] = useState({})
  const [stepDetails, setStepDetails] = useState(undefined)
  const [stepNameDetails, setStepNameDetails] = useState(undefined)
  const [expanded, setExpanded] = useState(false)
  const [stepUpdateInfo, setStepUpdateInfo] = useState(undefined)
  const [isStepMonitor, setIsStepMonitor] = useState(undefined)
  const [validationMessage, setValidationMessage] = useState('')
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [dataMapping, setDataMapping] = useState({})
  //const [transactionId, setTransactionId] = useState(undefined)
  //const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [checked, setChecked] = useState(false)
  const classes = useStyles()
  const tableRef = useRef()
  const {
    data: processes,
    isLoading,
    error,
    isError,
    refetch,
    isFetching
  } = useGetTransactionDataById({
    processId,
    env,
    monitorId,
    appId
  })

  // const { mutate: deleteTransaction } = useDeleteStepHistory()
  const breadcrumbViews = [
    {
      name: 'Monitor Console',
      url: `/admin/monitorConsole/index`,
      icon: DesktopWindowsIcon
    },
    {
      name: 'Monitor Details',
      url: `/admin/monitorConsole/transactions/${env}/${appId}/${monitorId}/${description}`,
      icon: DvrIcon
    },
    {
      name: 'Transaction Details',
      url: `/admin/monitorConsole/details/${env}/${appId}/${monitorId}/${description}/${processId}`,
      icon: DetailsIcon
    }
  ]

  function filterColumns(columns) {
    const configCopy = { ...userFilters }
    let column
    for (column of columns) {
      configCopy[column.column.field] = column.value
    }
    setUserFilters(configCopy)
  }

  const actions = [
    {
      icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
      tooltip: 'Details',
      onClick: (event, rowData) => {
        setStepDetails(rowData.history)
        setStepNameDetails(rowData.stepName)
        setExpanded(true)
        scrollToTableDetails()
        setStepUpdateInfo({ id: rowData.tableData.id })
        setIsStepMonitor(rowData.isStep)
        if (rowData.history.length > 0) {
          if (rowData.isStep) {
            let keys = Object.keys(rowData.history[0])
            keys = keys.filter(
              key =>
                key !== 'tableData' && key !== 'isDuplicated' && key !== 'id'
            )
            let mapping = {}
            keys.forEach(key => {
              mapping[key] = key
            })
            setDataMapping(mapping)
          } else {
            let keys = Object.keys(rowData.history[0])
            keys = keys.filter(key => key !== 'tableData')
            let mapping = {}
            keys.forEach(key => {
              mapping[key] = key
            })
            setDataMapping(mapping)
          }
        }
      }
    }
  ]
  const filterValue = value => {
    if (value) {
      const filtered = stepDetails.filter(item => !item.isDuplicated)
      setStepDetails(filtered)
    } else {
      setStepDetails(processes.rows[stepUpdateInfo.id].history)
    }
    setChecked(value)
  }
  const detailsActions = [
    {
      icon: () => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={e => filterValue(e.target.checked)}
              />
            }
            label="Hide Duplicate"
            labelPlacement="end"
          />
        )
      },
      tooltip: 'Hide Duplicated',
      position: 'toolbar'
    }
  ]

  function areArraysEqual(array1, array2) {
    try {
      return (
        array1.length === array2.length &&
        array1.every(function(element, index) {
          return element === array2[index]
        })
      )
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    if (stepDetails && stepUpdateInfo) {
      if (
        !areArraysEqual(stepDetails, processes.rows[stepUpdateInfo.id].history)
      ) {
        setStepDetails(processes.rows[stepUpdateInfo.id].history)
        if (checked) {
          setChecked(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [processes])

  useEffect(() => {
    scrollToTableDetails()
  }, [stepDetails])

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError(true)
  }, [isError])

  let monitorData = {}
  let obj = {}
  let data = {}
  if (processes && processes.rows) {
    let process
    for (process of processes.rows) {
      if (process.lastUpdateDate) {
        obj = {}
        obj[process.lastUpdateDate] = formatDate({
          date: process.lastUpdateDate
        })
        data['lastUpdateDate'] = { ...data['lastUpdateDate'], ...obj }
      }

      if (process.creationDate) {
        obj = {}
        obj[process.creationDate] = formatDate({
          date: process.creationDate
        })
        data['creationDate'] = { ...data['creationDate'], ...obj }
      }

      if (process.stepKey) {
        obj = {}
        obj[process.stepKey] = process.stepKey
        data['stepKey'] = { ...data['stepKey'], ...obj }
      }

      if (process.stepName) {
        obj = {}
        obj[process.stepName] = process.stepName
        data['stepName'] = { ...data['stepName'], ...obj }
      }

      if (process.status) {
        obj = {}
        obj[process.status] =
          process.status.charAt(0).toUpperCase() + process.status.slice(1)
        data['status'] = { ...data['status'], ...obj }
      }

      if (typeof process.stepOrder !== 'undefined') {
        obj = {}
        obj[process.stepOrder] = process.stepOrder
        data['stepOrder'] = { ...data['stepOrder'], ...obj }
      }
    }
    monitorData = data
  }

  const columns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1,
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center'
    },
    {
      field: 'lastUpdateDate',
      title: 'Last Update Date',
      render: rowData =>
        formatDate({
          date: rowData.lastUpdateDate
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['lastUpdateDate'],
      defaultFilter: userFilters['lastUpdateDate']
    },
    {
      field: 'creationDate',
      title: 'Creation Date',
      render: rowData =>
        formatDate({
          date: rowData.creationDate
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['creationDate'],
      defaultFilter: userFilters['creationDate']
    },
    {
      field: 'stepOrder',
      title: 'Step Order',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['stepOrder'],
      defaultFilter: userFilters['stepOrder'],
      defaultSort: 'asc'
    },
    {
      field: 'stepKey',
      title: 'Step Key',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['stepKey'],
      defaultFilter: userFilters['stepKey']
    },
    {
      field: 'stepName',
      title: 'Step Name',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['stepName'],
      defaultFilter: userFilters['stepName']
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData =>
        rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center',
      lookup: monitorData['status'],
      defaultFilter: userFilters['status']
    }
  ]

  const stepDetailsColumns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1,
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center'
    },
    {
      field: 'date',
      title: 'Process Date',
      render: rowData =>
        formatDate({
          date: rowData.date
        })
    },
    {
      field: 'data',
      title: 'Step Data',
      hiddenByColumnsButton: true
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData =>
        rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)
    },
    {
      field: 'message',
      title: 'Message',
      hiddenByColumnsButton: true
    }
  ]

  /* function getConfirmDialog() {
    return (
      <CustomConfirmAlert
        message={'Are you sure you want to delete the register?'}
        title={'Delete Confirm'}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={() => {
          deleteTransaction({ id: transactionId, processId })
          setShowDeleteConfirm(false)
        }}
      ></CustomConfirmAlert>
    )
  } */

  const stepMonitorDetailsColumns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1,
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center'
    },
    {
      field: 'date',
      title: 'Process Date',
      render: rowData =>
        formatDate({
          date: rowData.date
        })
    },
    {
      field: 'stepKey',
      title: 'Step Key',
      hiddenByColumnsButton: true
    },
    {
      field: 'data',
      title: 'Step Data',
      hiddenByColumnsButton: true
    },
    {
      field: 'monitorId',
      title: 'Monitor Id'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData =>
        rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)
    },
    {
      field: 'message',
      title: 'Message',
      hiddenByColumnsButton: true
    }
  ]

  function scrollToTableDetails() {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'auto', block: 'start' })
    }
  }

  function onSuccessDownload() {
    setValidationMessage('File Downloaded successfully')
    setSubmitSuccess(true)
  }

  function onFailedDownload() {
    setValidationMessage('Could not download files')
    setCreateError(true)
  }

  function returnMonitorTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem md={12}>
            <Button
              color="primary"
              size="small"
              className={classes.floatRight}
              onClick={() => {
                history.push(
                  `/admin/monitorConsole/diagram/${env}/${appId}/${monitorId}/${description}/${processId}`
                )
              }}
            >
              <DashboardIcon />
            </Button>
            <Button
              color="primary"
              size="small"
              className={classes.floatRight}
              onClick={() => refetch({ throwOnError: true })}
            >
              <RefreshRoundedIcon />
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <GridContainer justify="center" style={{ marginTop: '5px' }}>
              <SyncLoader showLoader={isFetching && !isLoading} />
              {isLoading ? (
                <div className={classes.reloadSize}>
                  <CircularProgress />
                </div>
              ) : (
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={columns}
                    data={processes?.rows || []}
                    actions={actions}
                    // onChangeColumnHidden={hideColumns}
                    onFilterChange={filterColumns}
                    options={{
                      columnsButton: true,
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: true,
                      showTitle: false
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
            <GridItem xs={12} sm={12} style={{ marginTop: '15px' }}>
              <GridContainer justify="center" style={{ marginTop: '5px' }}>
                {isLoading || !stepDetails ? (
                  ''
                ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <br />
                    <h4>Step History</h4>
                    <Accordion
                      className={classes.accordion}
                      style={{
                        width: '100%'
                      }}
                      expanded={expanded}
                      onChange={() => {
                        setExpanded(!expanded)
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.heading}>
                          {stepNameDetails
                            ? `Current Step: ${stepNameDetails}`
                            : ''}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <GridContainer
                          style={{ paddingBottom: '5%', margin: 'auto' }}
                        >
                          <GridItem md={12}>
                            <div style={{ paddingBottom: '5%' }}>
                              <CustomDownloadButtom
                                data={stepDetails}
                                onFailed={onFailedDownload}
                                onSuccess={onSuccessDownload}
                                className={
                                  classes.floatRight +
                                  ' ' +
                                  classes.clearAvatar +
                                  ' ' +
                                  classes.noPadding
                                }
                                dataMapping={dataMapping}
                                disabled={
                                  !(stepDetails && stepDetails.length > 0)
                                }
                                style={{
                                  maxWidth: '40px',
                                  maxHeight: '30px',
                                  minWidth: '40px',
                                  minHeight: '30px'
                                }}
                              />
                            </div>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <div ref={tableRef}>
                              <MaterialTable
                                columns={
                                  isStepMonitor
                                    ? stepDetailsColumns
                                    : stepMonitorDetailsColumns
                                }
                                actions={
                                  isStepMonitor ? detailsActions : undefined
                                }
                                data={stepDetails}
                                options={{
                                  filtering: true,
                                  sorting: true,
                                  showTitle: false
                                }}
                              />
                            </div>
                          </GridItem>
                        </GridContainer>
                      </AccordionDetails>
                    </Accordion>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridItem>
        </GridContainer>
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Process Details</h3>
              <h4
                className={classes.formHeader}
              >{`Process ID: ${processId}`}</h4>
            </CardHeader>
            <CardBody>{returnMonitorTab()}</CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={validationMessage}
          open={submitSuccess}
          closeNotification={() => {
            setValidationMessage(undefined)
            setSubmitSuccess(false)
          }}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={
            isError
              ? error.response?.data?.message ||
                'Something went wrong, try again later'
              : validationMessage
              ? validationMessage
              : ''
          }
          open={createError}
          closeNotification={() => {
            setCreateError(false)
            if (validationMessage) {
              setValidationMessage(undefined)
            }
          }}
          close
        />
      </GridContainer>
    </Fade>
  )
}
