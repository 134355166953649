import React from 'react'
import MaterialTable from 'material-table'
import Datetime from 'react-datetime'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Select, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { Button as ButonUI } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Fade from '@material-ui/core/Fade'
import InputLabel from '@material-ui/core/InputLabel'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import InfoIcon from '@material-ui/icons/Info'
import SearchIcon from '@material-ui/icons/Search'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import MoreIcon from '@material-ui/icons/More'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Button from 'components/CustomButtons/Button.js'
import CustomDownloadButtom from 'components/CustomDownloadButtom/CustomDownloadButtom.js'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

const useStyles = makeStyles(styles)

export default function DataAssureCountType(props) {
  const classes = useStyles()

  let breadcrumbViews = [
    {
      name: 'Logs',
      url: `/admin/dataAssure/transactions/count`,
      icon: AccountBalanceWalletIcon
    },
    {
      name: 'Logs details',
      url: `/admin/dataAssure/${props.typeValidation}/transactions/count`,
      icon: MoreIcon
    }
  ]

  const columns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'transactionDate',
      title: 'Date',
      render: rowData => new Date(rowData.transactionDate).toLocaleString('en-US')
    },
    {
      field: 'supplierId',
      title: 'Supplier Id',
      render: rowData => (
        <a href={rowData.link.supplierId} target="_blank" rel="noopener noreferrer">
          {rowData.supplierId}
        </a>
      )
    },
    {
      field: 'formId',
      title: 'Form Id',
      render: rowData =>
        rowData.link.formId ? (
          <a href={rowData.link.formId} target="_blank" rel="noopener noreferrer">
            {rowData.formId}
          </a>
        ) : (
          <a href={rowData.link.documentId} target="_blank" rel="noopener noreferrer">
            {rowData.documentId}
          </a>
        )
    },
    {
      field: 'result',
      title: 'Validation Result',
      render: rowData =>
        ['rejected', 'FAILED'].includes(rowData.result) ? (
          <Button style={{ color: 'red', padding: '0' }} simple>
            <CancelIcon fontSize="small" />
            Rejected
          </Button>
        ) : ['approved', 'PASSED WITH CAUTIONS', 'PASSED'].includes(rowData.result) ? (
          <Button style={{ color: 'green', padding: '0' }} simple>
            <CheckCircleIcon fontSize="small" />
            Approved
          </Button>
        ) : (
          <Button style={{ color: 'gray', padding: '0' }} simple>
            <CheckCircleIcon fontSize="small" />
            Unknown
          </Button>
        )
    }
  ]

  function modal() {
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={props.showModal === true}
        keepMounted
        fullWidth={true}
        maxWidth={'md'}
        onClose={() => props.setShowModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ wordWrap: 'break-word' }}
        >
          {props.modalInfo}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => props.setShowModal(false)} color="danger" simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function returnTypeCountTab() {
    if (props.countIsLoading) {
      return (
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer justify="center">
            <div className={classes.reloadSize}>
              <CircularProgress />
            </div>
          </GridContainer>
        </GridItem>
      )
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="center">
          <GridItem xs={12}>
            <GridContainer spacing={0} justify="flex-end">
              {props.rows ? (
                <GridItem spacing={0} className={classes.noPadding}>
                  <ButonUI
                    style={{
                      maxWidth: '40px',
                      maxHeight: '30px',
                      minWidth: '40px',
                      minHeight: '30px'
                    }}
                    className={
                      classes.floatRight + ' ' + classes.clearAvatar + ' ' + classes.noPadding
                    }
                    onClick={() => props.onSearchValues()}
                  >
                    <SearchIcon fontSize="small" />
                  </ButonUI>
                </GridItem>
              ) : null}

              {props.rows && props.rows.length > 0 ? (
                <GridItem spacing={0} className={classes.noPadding}>
                  <CustomDownloadButtom
                    asyncDataFunction={props.getFullDetails}
                    onFailed={props.onFailedDownload}
                    onSuccess={props.onSuccessDownload}
                    className={
                      classes.floatRight + ' ' + classes.clearAvatar + ' ' + classes.noPadding
                    }
                    dataMapping={props.dataMapping}
                    style={{
                      maxWidth: '40px',
                      maxHeight: '30px',
                      minWidth: '40px',
                      minHeight: '30px'
                    }}
                  />
                </GridItem>
              ) : null}
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12}>
            {props.rows ? (
              <GridContainer justify="center" style={{ marginTop: '5px' }}>
                <GridItem xs={12} sm={6} md={3}>
                  <InputLabel className={classes.label}>Type Validation</InputLabel>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={props.filterValues.typeValidation}
                      onChange={e => props.onFieldChange('typeValidation', e.target.value)}
                      inputProps={{
                        name: props.filterValues.typeValidation,
                        id: props.filterValues.typeValidation
                      }}
                    >
                      {props.applicationTypes.map((type, key) => {
                        return (
                          <MenuItem
                            key={type.validationId}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            style={{
                              overflowX: 'auto',
                              textOverflow: 'ellipsis'
                            }}
                            value={type.validationId}
                          >
                            {type.validationLabel}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <InputLabel className={classes.label}>Result</InputLabel>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={props.filterValues.result}
                      onChange={e => props.onFieldChange('result', e.target.value)}
                      inputProps={{
                        name: props.filterValues.result,
                        id: props.filterValues.result
                      }}
                    >
                      <MenuItem
                        key="all"
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{
                          overflowX: 'auto',
                          textOverflow: 'ellipsis'
                        }}
                        value="all"
                      >
                        All
                      </MenuItem>
                      <MenuItem
                        key="rejected"
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{
                          overflowX: 'auto',
                          textOverflow: 'ellipsis'
                        }}
                        value="rejected"
                      >
                        Rejected
                      </MenuItem>
                      <MenuItem
                        key="approved"
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{
                          overflowX: 'auto',
                          textOverflow: 'ellipsis'
                        }}
                        value="approved"
                      >
                        Approved
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <InputLabel className={classes.label}>Start Date</InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      locale="en-US"
                      closeOnSelect={true}
                      dateFormat="MM/DD/YYYY"
                      value={props.filterValues.startDate}
                      timeFormat={false}
                      onChange={event => props.onFieldChange('startDate', event)}
                      inputProps={{
                        placeholder: 'Start Date',
                        value: props.filterValues.startDate
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      locale="en-US"
                      closeOnSelect={true}
                      dateFormat="MM/DD/YYYY"
                      value={props.filterValues.endDate}
                      timeFormat={false}
                      onChange={event => props.onFieldChange('endDate', event)}
                      inputProps={{
                        placeholder: 'End Date',
                        value: props.filterValues.endDate
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <MaterialTable
                    columns={columns}
                    data={props.rows}
                    actions={[
                      {
                        icon: () => <InfoIcon style={{ color: '#081c3e' }} />,
                        tooltip: 'Details Response',
                        onClick: async (_event, _rowData) => {
                          props.setValidationMessage('Data is not available')
                          props.setCreateError(true)
                        }
                      },
                      {
                        icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
                        tooltip: 'Details Parsed Response',
                        onClick: async (event, rowData) => {
                          const dataResponse = await props.onGetParsedResponse(
                            rowData.hashKey,
                            rowData.rangeKey,
                            rowData.transactionDate
                          )
                          props.setShowModal(true)
                          props.setModalInfo(dataResponse)
                        }
                      }
                    ]}
                    options={{
                      actionsColumnIndex: -1,
                      sorting: true,
                      showTitle: false
                    }}
                  />
                </GridItem>
              </GridContainer>
            ) : null}
          </GridItem>
        </GridContainer>
        {modal()}
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={12} lg={3} className={classes.padding10}>
          <Card className={classes.appBar}>
            <CardHeader>Application List</CardHeader>
            <CardBody style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <List>
                {props.appList.map((prop, key) => {
                  return (
                    <ListItem
                      style={{ paddingLeft: '5px', paddingRight: '5px' }}
                      className={classes.listItem}
                      button
                      selected={props.selectedAppId === prop.env}
                      key={key}
                      onClick={() => props.onAppChanged(prop.env)}
                    >
                      <ListItemAvatar>
                        {props.selectedAppId === prop.env ? (
                          <Avatar>
                            <TouchApp />
                          </Avatar>
                        ) : (
                          <Avatar className={classes.clearAvatar}></Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={prop.env}
                        secondary={`Type: ${prop.type}`}
                      />
                      {prop.errors && prop.errors > 0 ? (
                        <ListItemText style={{ marginLeft: '4px' }}>
                          <Button
                            className={classes.textErrors}
                            simple
                            onClick={() => props.onErrors(prop.env)}
                          >
                            <ReportProblemIcon fontSize='small' />
                            {prop.errors}{' '}
                            {prop.errors === 1 ? 'Error' : 'Errors'}
                          </Button>
                        </ListItemText>
                      ) : null}
                    </ListItem>
                  )
                })}
              </List>
            </CardBody>
          </Card>
        </GridItem> */}
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          {props.formIsLoading ? (
            <Card>
              <GridContainer justify="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : (
            <Card>
              <CardHeader>
                <h3 className={classes.formHeader}>Data Assure - Count Details</h3>
              </CardHeader>
              <CardBody>
                {/* <NavPills
                  action={actions => {
                    if (actions) {
                      props.updateHeightFunction(actions.updateHeight)
                    }
                  }}
                  animateHeight={false}
                  color='primary'
                  tabs={[
                    {
                      tabButton: 'Validations',
                      tabContent: returnValidationsTab()
                    }
                  ]}
                /> */}
                {returnTypeCountTab()}
              </CardBody>
            </Card>
          )}
        </GridItem>
        <Snackbar
          place="br"
          color="success"
          icon={AddAlert}
          message={props.validationMessage}
          open={props.submitSuccess}
          closeNotification={() => props.setSubmitSuccess(false)}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={props.validationMessage}
          open={props.createError}
          closeNotification={() => props.setCreateError(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
