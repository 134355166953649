import React, { useState, useEffect } from 'react'
import ResizeObserver from 'react-resize-observer'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/style.css'

// @material-ui/icons
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Fade, Typography } from '@material-ui/core'
import { Select, MenuItem } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Button from '../../components/CustomButtons/Button.js'
import MonitorConsoleSettings from './MonitorConsoleSettings.js'
import CustomConfirmAlert from 'components/CustomConfirmAlert/CustomConfirmAlert.js'
import Card from 'components/Card/Card.js'
import MonitorModal from './MonitorModal.js'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import CustomModal from 'components/CustomModal/CustomModal.js'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'
import wizardStyle from '../../assets/jss/material-dashboard-pro-react/components/wizardStyle.js'
import componentsStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js'

// utils
import { cloneObject } from 'utils/functions.js'

const useStyles = makeStyles(styles)
const usesWizardStyles = makeStyles(wizardStyle)
const useComponentsStyles = makeStyles(componentsStyles)
const periodicityOptions = ['daily', 'weekly', 'monthly', 'yearly']

export default function MonitorSettingsView(props) {
  const classes = useStyles()
  const selectedApp = props.selectedApp
  const wizardClasses = usesWizardStyles()
  const componentsClases = useComponentsStyles()
  const [selectedRow, setSelectedRow] = useState(undefined)
  const [settings, setSettings] = useState(props.monitorSettings)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [settingIndex, setSettingIndex] = useState(undefined)
  const [dialogMessage, setDialogMessage] = useState('')
  const [dialogAction, setDialogAction] = useState('')
  const [enableErrorNotification, setEnableErrorNotfication] = useState(
    props.validationForm[selectedApp].enableErrorNotification ?? false
  )
  const [notificationsEmails, setNotificationsEmails] = useState(
    props.validationForm[selectedApp].notificationsEmails ?? []
  )
  const [onDialogConfirm, setOnDialogConfirm] = useState(undefined)
  const [showModal, setShowModal] = useState(false)
  const [addExistingMonitor, setAddExistingMonitor] = useState(false)
  const [monitorId, setMonitorId] = useState(undefined)
  const [showStepId, setShowStepId] = useState(false)
  const [existingMonitors, setExistingMonitors] = useState(undefined)

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  useEffect(() => {
    const monitorConsoleRegEx = /monitorConsole*[1-9]/
    const newExistingMonitors = []
    let appKeys = Object.keys(props.validationForm)
    appKeys = appKeys.filter(val => {
      return monitorConsoleRegEx.test(val) && val !== props.selectedApp
    })

    appKeys.forEach(key => {
      if (
        props.validationForm[key].configured &&
        props.validationForm[key].enabled
      ) {
        let newMonitor = {
          id: key,
          description: props.validationForm[key].description
        }
        newExistingMonitors.push(newMonitor)
      }
    })

    setExistingMonitors(newExistingMonitors)
    if (newExistingMonitors.length > 0) {
      setMonitorId(newExistingMonitors[0])
    }
  }, [props.validationForm, props.selectedApp])

  function onMappingFieldChange(index, valueId, value) {
    let newSettings = cloneObject(settings)
    if (typeof newSettings[index][valueId] === 'object') {
      if (valueId !== 'timeVerification') {
        newSettings[index][valueId].value = value
      } else {
        newSettings[index][valueId].value = new Date(value._d)
      }

      if (
        valueId === 'enableTimeVerification' &&
        value &&
        !newSettings[index]['notificationsEmails'].value.length
      ) {
        newSettings[index]['notificationsEmails'].success = false
        newSettings[index]['timeVerification'].value = new Date()
      } else if (valueId === 'enableTimeVerification' && !value) {
        newSettings[index]['notificationsEmails'].success = true
      }

      switch (typeof newSettings[index][valueId].value) {
        case 'string':
          value
            ? (newSettings[index][valueId].success = true)
            : (newSettings[index][valueId].success = false)
          break
        default:
          if (Array.isArray(newSettings[index][valueId].value)) {
            newSettings[index][valueId].value.length > 0
              ? (newSettings[index][valueId].success = true)
              : (newSettings[index][valueId].success = false)
          }
          break
      }
    } else {
      newSettings[index][valueId] = value
    }

    setSettings(newSettings)
  }

  function finishButtonClick() {
    let valid = true
    let indexesWithErrors = []
    for (let i = 0; i < settings.length; i++) {
      // eslint-disable-next-line
      for (let key in settings[i]) {
        if (typeof settings[i][key] === 'object') {
          valid = settings[i][key].success
          if (!valid) {
            indexesWithErrors.push(i)
            break
          }
        }
      }
    }

    if (enableErrorNotification && notificationsEmails.length === 0 && valid) {
      valid = false
    }

    if (valid) {
      let settingsCopy = cloneObject(settings)
      settingsCopy.forEach((setting, index) => {
        let keys = Object.keys(setting)
        keys.forEach(key => {
          if (typeof setting[key] === 'object') {
            delete setting[key]
            setting[key] = settings[index][key].value
          }

          if (key === 'timeVerification') {
            setting[key] = new Date(setting[key]).toISOString()
          }
        })
      })

      props.saveToDataBase(
        {
          settings: settingsCopy,
          enableErrorNotification,
          notificationsEmails
        },
        undefined,
        'Configuration saved successfully',
        false,
        !props.configured,
        true
      )
    } else {
      let newSettings = settings
      props.showError('Please fill all the required fields')
      // eslint-disable-next-line
      for (const index of indexesWithErrors) {
        settings[index].expanded = true
      }
      setSettings(newSettings)
    }
  }

  function showDeleteConfirmDialog(index, rowIndex) {
    setDialogAction('Delete Parameter Configuration')
    setDialogMessage('Are you sure you want to delete the configuration?')
    setOnDialogConfirm(() => () =>
      deleteParameterConfiguration(index, rowIndex)
    )
    setShowConfirmDialog(true)
  }

  function deleteParameterConfiguration(index, rowIndex) {
    let newSettings = settings
    newSettings[index].rowsData.value.splice(rowIndex, 1)

    newSettings[index].rowsData.value = newSettings[index].rowsData.value.map(
      (item, index) => {
        return { ...item, rowIndex: index }
      }
    )

    setSettings(newSettings)
    setShowConfirmDialog(false)
  }

  function showMonitorModal(index, rowIndex) {
    setSelectedRow(rowIndex)
    setSettingIndex(index)
    setShowModal(true)
  }

  function getMonitorModal() {
    let modal = (
      <MonitorModal
        paramName={
          typeof selectedRow === 'number'
            ? settings[settingIndex].rowsData.value[selectedRow].paramName
            : ''
        }
        enabled={
          typeof selectedRow === 'number'
            ? settings[settingIndex].rowsData.value[selectedRow].enabled
            : true
        }
        type={
          typeof selectedRow === 'number'
            ? settings[settingIndex].rowsData.value[selectedRow].type
            : 'string'
        }
        title={
          typeof selectedRow === 'number'
            ? 'Edit Parameter Settings'
            : 'Add New Paramater'
        }
        onCancel={() => setShowModal(false)}
        onConfirm={returnValues => {
          let newSettings = settings
          if (typeof selectedRow === 'number') {
            newSettings[settingIndex].rowsData.value[selectedRow] = {
              ...returnValues,
              rowIndex:
                newSettings[settingIndex].rowsData.value[selectedRow].rowIndex
            }
          } else {
            let rowIndex = newSettings[settingIndex].rowsData.value.length
            returnValues.rowIndex = rowIndex
            newSettings[settingIndex].rowsData.value.push(returnValues)
          }

          setSettings(newSettings)
          setShowModal(false)
        }}
      ></MonitorModal>
    )

    return modal
  }

  function deleteMonitorConsole(index) {
    let newSettings = settings
    newSettings.splice(index, 1)

    newSettings = newSettings.map((item, index) => {
      return { ...item, index: index }
    })
    setSettings(newSettings)
    setShowConfirmDialog(false)
  }

  function onDeleteMonitorConsole(index) {
    setDialogAction('Delete Monitor')
    setDialogMessage('Are you sure you want to delete the step?')
    setOnDialogConfirm(() => () => deleteMonitorConsole(index))
    setShowConfirmDialog(true)
  }

  function addNewStep() {
    let newStep
    if (addExistingMonitor) {
      if (!monitorId) {
        props.showError('Please select a valid exsiting step')
      } else {
        let monitorRepetead = false

        settings.forEach(setting => {
          if (!setting.isStep && setting.stepKey === monitorId.id) {
            monitorRepetead = true
            return monitorRepetead
          }
        })

        if (monitorRepetead) {
          props.showError('Monitor already added as step')
          setAddExistingMonitor(false)
          setShowStepId(false)
          return monitorRepetead
        }
        newStep = {
          stepKey: monitorId.id,
          stepName: monitorId.description,
          stepOrder: { value: settings.length, success: true },
          isStep: false
        }

        //newStep.stepKey = uuidv4()
      }
    } else {
      newStep = {
        stepKey: uuidv4(),
        stepName: { value: '', success: false },
        stepOrder: { value: settings.length, success: true },
        readAllParams: { value: true, success: true },
        rowsData: { value: [], success: true },
        errorMessage: { value: '', success: false },
        successMessage: { value: '', success: false },
        isFinishStep: { value: false, success: true },
        enableTimeVerification: { value: false, success: true },
        timeVerification: {
          value: new Date(),
          success: true
        },
        validationPeriodicity: { value: periodicityOptions[0], success: true },
        notificationsEmails: { value: [], success: true },
        expanded: false,
        isStep: true
      }
    }

    if (settings.length > 0) {
      settings.push(newStep)
      setSettings(settings)
    } else {
      let newSettings = cloneObject(settings)
      newSettings.push(newStep)
      setSettings(newSettings)
    }

    setShowStepId(false)
    setAddExistingMonitor(false)
  }

  function getAddStepDialog() {
    return (
      <CustomModal
        title={'Add new Step'}
        //confirmBtnCssClass={classes.button + ' ' + classes.success}
        //cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => {
          setShowStepId(false)
          setAddExistingMonitor(false)
        }}
        onConfirm={() => {
          addNewStep()
        }}
      >
        {existingMonitors.length > 0 ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <CardHeader>Add existing monitor as step</CardHeader>
              <CardBody>
                <Switch
                  checked={addExistingMonitor}
                  onChange={event => {
                    setAddExistingMonitor(event.target.checked)
                  }}
                  value="Active"
                  classes={{
                    switchBase: componentsClases.switchBase,
                    checked: componentsClases.switchChecked,
                    thumb: componentsClases.switchIcon,
                    track: componentsClases.switchBar
                  }}
                />
              </CardBody>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12}>
              <div hidden={!addExistingMonitor}>
                <CardHeader>
                  Select the existing step you want to add
                </CardHeader>
                <CardBody>
                  <Select
                    MenuProps={{
                      style: { zIndex: 5555 }
                    }}
                    style={{ width: '100%' }}
                    value={monitorId}
                    onChange={e => setMonitorId(e.target.value)}
                    inputProps={{
                      name: 'Monitor id',
                      id: 'fieldType'
                    }}
                  >
                    {existingMonitors.map(item => {
                      return (
                        <MenuItem key={item.id} value={item}>
                          {'Monitor Name: ' + item.description}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </CardBody>
              </div>
            </GridItem>
          </GridContainer>
        ) : (
          <div style={{ width: '100%', paddingBottom: '10px' }}>
            <Typography style={{ textAlign: 'center' }}>
              Are you sure, you want to add a new step?
            </Typography>
          </div>
        )}
      </CustomModal>
    )
  }

  function getConfirmDialog() {
    return (
      <CustomConfirmAlert
        message={dialogMessage}
        title={dialogAction}
        //confirmBtnCssClass={classes.button + ' ' + classes.success}
        //cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => setShowConfirmDialog(false)}
        onConfirm={() => {
          onDialogConfirm()
        }}
      ></CustomConfirmAlert>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <div style={{ position: 'relative' }}>
        <ResizeObserver onResize={() => {}} onPosition={() => {}} />
        <Card className={wizardClasses.card}>
          <div
            style={{
              width: '100%'
            }}
          >
            <IconButton
              onClick={() => {
                props.backButtonOnClick()
              }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </div>
          <div className={wizardClasses.wizardHeader}>
            <h3 className={wizardClasses.title}>{props.title}</h3>
          </div>
          <GridContainer className="position:absolute">
            <GridItem xs={12} sm={6}>
              <h5 style={{ paddingLeft: '20px' }}>
                {`Monitor Console Id: `}
                <strong>{props.selectedApp}</strong>
              </h5>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Button
                simple
                onClick={() => {
                  setShowStepId(true)
                }}
                className={classes.documentation}
                style={{ float: 'right' }}
                color={'behance'}
              >
                <AddBoxRoundedIcon className={classes.icons} />
                Add Step
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6}>
              <CardHeader>Enable Error Notification</CardHeader>
              <FormControlLabel
                style={{ padding: '10px' }}
                control={
                  <Switch
                    checked={enableErrorNotification}
                    onChange={event => {
                      setEnableErrorNotfication(event.target.checked)
                    }}
                    value="Active"
                    classes={{
                      switchBase: componentsClases.switchBase,
                      checked: componentsClases.switchChecked,
                      thumb: componentsClases.switchIcon,
                      track: componentsClases.switchBar
                    }}
                  />
                }
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              hidden={!enableErrorNotification}
            >
              <CardHeader>Notification Email</CardHeader>
              <ReactMultiEmail
                placeholder="Add emails to send notifications"
                emails={notificationsEmails}
                onChange={_emails => {
                  setNotificationsEmails(_emails)
                }}
                validateEmail={email => {
                  return isEmail(email)
                }}
                style={{ margin: '1.25rem' }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  )
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <GridContainer>
                {settings.length > 0
                  ? settings.map((setting, index) => {
                      return (
                        <GridItem
                          key={index}
                          xs={12}
                          style={{
                            margin: '10px'
                          }}
                        >
                          <MonitorConsoleSettings
                            isStep={setting.isStep}
                            stepKey={setting.stepKey}
                            rowsData={setting.rowsData}
                            stepName={setting.stepName}
                            stepOrder={setting.stepOrder}
                            errorMessage={setting.errorMessage}
                            successMessage={setting.successMessage}
                            readAllParams={setting.readAllParams}
                            expanded={setting.expanded}
                            isFinishStep={setting.isFinishStep}
                            enableTimeVerification={
                              setting.enableTimeVerification
                            }
                            notificationsEmails={setting.notificationsEmails}
                            timeVerification={setting.timeVerification}
                            validationPeriodicity={
                              setting.validationPeriodicity
                            }
                            index={index}
                            setSettingIndex={setSettingIndex}
                            onMappingFieldChange={onMappingFieldChange}
                            onDeleteMonitorConsole={onDeleteMonitorConsole}
                            showMonitorModal={showMonitorModal}
                            showDeleteConfirmDialog={showDeleteConfirmDialog}
                            periodicityOptions={periodicityOptions}
                          />
                        </GridItem>
                      )
                    })
                  : ''}
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>{showStepId ? getAddStepDialog() : ''}</GridItem>
            <GridItem xs={12}>
              {showConfirmDialog ? getConfirmDialog() : ''}
            </GridItem>
            <GridItem xs={12}>{showModal ? getMonitorModal() : ''}</GridItem>
          </GridContainer>
          <div className={wizardClasses.footer}>
            <div className={wizardClasses.right}>
              <Button
                disabled={
                  (settings.length === 0 && !enableErrorNotification) ||
                  (enableErrorNotification && notificationsEmails.length === 0)
                }
                color="primary"
                onClick={() => {
                  settings.forEach(value => {
                    delete value.expanded
                    delete value.index
                  })

                  finishButtonClick()
                }}
              >
                Save
              </Button>
            </div>
            <div className={wizardClasses.clearfix} />
          </div>
        </Card>
      </div>
    </Fade>
  )
}
