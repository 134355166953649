import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  getAppConfigurationsByApp,
  getAppConfigurationByAppAndId
} from 'services/apiApps'

import TinValidationView from './TinValidationView'
// models
import {
  configuration as configurationSchema,
  questionnaire as questionnaireSchema,
  parameters as parametersSchema,
  message as messageSchema
} from 'models/tinValidationModel'
import { validateProperty, validateObject } from 'utils/validator'

import { v4 as uuidv4 } from 'uuid'

let _isMounted = false
let appId

const newParametersForm = {
  env: '',
  validationMessages: {
    einCode: {},
    tinNameCode: {}
  }
}

const newParametersState = {
  env: ''
}

const newAppConfigurations = {
  env: '',
  type: 'Test',
  mappings: {}
}

const newModal = {
  visible: false,
  type: 'edit',
  object: 'questionnaire',
  name: ''
}

const modalForm = {
  message: '',
  uniqueName: '',
  answerType: 'tax',
  supplierType: '',
  lName: '',
  feinValue: '',
  tax: '',
  fein: '',
  ssn: '',
  country: ''
}

const formState = {
  message: '',
  uniqueName: '',
  answerType: 'tax',
  supplierType: '',
  lName: '',
  feinValue: '',
  tax: '',
  fein: '',
  ssn: '',
  country: ''
}

async function getAppList (props) {
  let response = await getAppConfigurationsByApp('slp')

  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data)
      props.setPageIsLoading(false)
    }
  }
}

async function getAppDetails (props, id) {
  props.setFormIsLoading(true)

  const appDetails = await getAppConfigurationByAppAndId('slp', id)

  if (appDetails.success) {
    const parameters = appDetails.data.tinValidation.parameters

    const parametersDetails = {
      env: appDetails.data.env,
      validationMessages: parameters.validationMessages
    }

    const configurationDetails = {
      env: appDetails.data.env,
      type: appDetails.data.type,
      mappings: appDetails.data.tinValidation.mappings
    }

    if (_isMounted) {
      props.setConfigurationForm(configurationDetails)
      props.setParametersForm(parametersDetails)

      props.setFormIsLoading(false)
    }
  } else {
    props.setFormIsLoading(false)
  }
}

/*async function onConfigSubmit (props) {
  let result = {}
  let successMessage

  let appDetails = await getAppConfigurationByAppAndId(
    'slp',
    props.selectedAppId
  )
  let currentConfiguration = appDetails.data
  // Update with the form values
  let appConfiguration = createConfigRequestBody(
    props.configurationForm,
    currentConfiguration
  )
  let response

  response = await editAppConfigurationByAppAndId(
    'slp',
    props.selectedAppId,
    appConfiguration
  )
  successMessage = 'App updated successfully'

  if (response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

function createConfigRequestBody (configuration, requestBody) {
  let mappings = requestBody.tinValidation.mappings
  requestBody.tinValidation.configured = true
  let mapping
  for (mapping in mappings) {
    if (!configuration.mappings.hasOwnProperty(mapping)) {
      configuration.mappings[mapping] = mappings[mapping]
    }
  }

  requestBody.tinValidation.mappings = configuration.mappings
  return requestBody
}

async function onParamsSubmit (props) {
  let result = {}
  let successMessage

  let appDetails = await getAppConfigurationByAppAndId(
    'slp',
    props.selectedAppId
  )
  let currentConfiguration = appDetails.data

  // Update with the form values
  let appConfiguration = createParamsRequestBody(
    props.parametersForm,
    currentConfiguration
  )
  let response

  response = await editAppConfigurationByAppAndId(
    'slp',
    props.selectedAppId,
    appConfiguration
  )
  successMessage = 'App updated successfully'

  if (response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    setTimeout(function () {
      if (_isMounted) {
        props.setSubmitSuccess(false)
      }
    }, 5000)
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

function createParamsRequestBody (parameters, requestBody) {
  requestBody.tinValidation.configured = true
  // Update parameters
  requestBody.tinValidation.parameters = parameters

  return requestBody
}
*/
function hideModal (props) {
  props.modal.visible = false
  props.setModal(props.modal)
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name
    }),
    {}
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('configurationForm', 'setConfigurationForm', {
    ...newAppConfigurations
  }),
  withState('parametersForm', 'setParametersForm', { ...newParametersForm }),
  withState('parametersFormState', 'setParametersFormState', {
    ...newParametersState
  }),
  withState('modal', 'setModal', { ...newModal }),
  withState('modalForm', 'setModalForm', { ...formState }),
  withState('modalFormState', 'setModalFormState', { ...formState }),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('isLoadingSave', 'setIsLoadingSave', false),
  withState('swipeableActions', 'setSwipeableActions', null),
  withHandlers({
    onAppChanged: props => id => {
      props.setSelectedAppId(id)
      getAppDetails(props, id)
    },
    onShowModal: props => async (type, object, id, name) => {
      // Set configuration for modal
      props.modal.type = type
      props.modal.object = object
      props.modal.id = id
      props.modal.name = name ? name : ''
      props.modal.visible = true

      let form = { ...modalForm }
      // if it's a questionnaire and edit, find the value
      if (id && object === 'questionnaire') {
        const questionnaire = props.configurationForm.mappings[id]
        form = {
          uniqueName: questionnaire.uniqueName,
          ...questionnaire.parameters
        }
      } else if (object === 'message') {
        const message = props.parametersForm.validationMessages.tinNameCode[id]
        form = {
          message: message.message
        }
      }

      props.setModalFormState({ ...formState })
      props.setModalForm(form)
      props.setModal(props.modal)
    },
    onHideModal: props => async () => {
      hideModal(props)
    },
    onFormChange: props => async (field, value) => {
      props.modalForm[field] = value

      props.setModalForm(props.modalForm)

      let isValid
      switch (props.modal.object) {
        case 'questionnaire':
          isValid = validateProperty(
            questionnaireSchema,
            props.modalForm,
            field
          ).isValid

          // remove unused fields
          if (field === 'answerType') {
            switch (value) {
              case 'tax':
                delete props.modalForm.country
                delete props.modalForm.fein
                delete props.modalForm.ssn

                props.modalForm.tax = ''
                break
              case 'generic':
                delete props.modalForm.tax

                props.modalForm.country = ''
                props.modalForm.fein = ''
                props.modalForm.ssn = ''
                break

              default:
                break
            }
          }

          break
        case 'message':
          isValid = validateProperty(messageSchema, props.modalForm, field)
            .isValid
          break
        default:
          break
      }
      if (isValid) {
        props.modalFormState[field] = 'success'
      } else {
        props.modalFormState[field] = 'error'
      }
      props.setModalFormState(props.modalFormState)
    },
    onConfigSave: props => async () => {
      props.setIsLoadingSave(true)

      let validation = validateObject(
        configurationSchema,
        props.configurationForm
      )
      console.log(props.configurationForm)
      if (validation.isValid) {
        //await onConfigSubmit(props)
      }
      props.setIsLoadingSave(false)
    },
    onQuestionnaireEdit: props => async () => {
      const id = props.modal.id

      let validation = validateObject(questionnaireSchema, props.modalForm)
      if (validation.isValid) {
        props.configurationForm.mappings[id].uniqueName =
          props.modalForm.uniqueName
        props.configurationForm.mappings[id].parameters = { ...props.modalForm }
        delete props.configurationForm.mappings[id].parameters.uniqueName

        props.setConfigurationForm(props.configurationForm)

        hideModal(props)
      }
    },
    onQuestionnaireCreate: props => async callback => {
      const name = props.modalForm.uniqueName

      // Create new questionnaire
      props.configurationForm.mappings[uuidv4()] = {
        uniqueName: name,
        SendResponseToQuestionnaire: true,
        questions: []
      }

      props.setConfigurationForm(props.configurationForm, function () {
        callback()
      })

      hideModal(props)
    },
    onQuestionnaireDelete: props => async callback => {
      const key = props.modal.id
      if (props.configurationForm.mappings.hasOwnProperty(key)) {
        // delete it
        delete props.configurationForm.mappings[key]
        props.setConfigurationForm(props.configurationForm, function () {
          callback()
        })
      }
      hideModal(props)
    },
    onParamFieldChange: props => (field, value) => {
      props.parametersForm[field] = value
      props.setParametersForm(props.parametersForm)
      const isValid = validateProperty(
        parametersSchema,
        props.parametersForm,
        field
      ).isValid
      if (isValid) {
        props.parametersFormState[field] = 'success'
      } else {
        props.parametersFormState[field] = 'error'
      }
      props.setParametersFormState(props.parametersFormState)
    },
    onParamsSave: props => async () => {
      props.setIsLoadingSave(true)

      let validation = validateObject(parametersSchema, props.parametersForm)
      console.log(props.parametersForm)
      if (validation.isValid) {
        //await onParamsSubmit(props)
      }
      props.setIsLoadingSave(false)
    },
    onMessageEdit: props => async () => {
      const id = props.modal.id

      let validation = validateObject(messageSchema, props.modalForm)
      if (validation.isValid) {
        props.parametersForm.validationMessages.tinNameCode[id].message =
          props.modalForm.message
        props.setParametersForm(props.parametersForm)

        hideModal(props)
      }
    },
    onQuestionnaireChange: props => (field, value, id) => {
      props.configurationForm.mappings[id][field] = value
      props.setConfigurationForm(props.configurationForm)
    },
    onQuestionChange: props => (field, value, id, messageType) => {
      props.parametersForm.validationMessages[messageType][id][field] = value
      props.setParametersForm(props.parametersForm)
    }
  }),
  lifecycle({
    componentDidMount () {
      _isMounted = true
      appId = this.props.appId
      this.props.setSelectedAppId(appId)

      getAppDetails(this.props, appId)

      this.props.setPageIsLoading(true)
      this.props.setModal({ ...newModal })
      getAppList(this.props)
    },
    componentWillUnmount () {
      _isMounted = false
    }
  })
)(TinValidationView)
