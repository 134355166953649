import coupaLogoWhite from 'assets/img/invoices/CoupaLogoWhite.png'
import logoAribaWhite from 'assets/img/logoAribaWhite.png'
import { fetchInvoiceById, useCopyInvoice, useGetPreviousAndNextInvoice } from 'hooks/useInvoices'
import MaterialTable from 'material-table'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { NavLink, useHistory } from 'react-router-dom'

// @material-ui/core components
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import ArtTrackIcon from '@material-ui/icons/ArtTrack'
import BlockIcon from '@material-ui/icons/Block'
import CachedIcon from '@material-ui/icons/Cached'
import DescriptionIcon from '@material-ui/icons/Description'
import EmailRoundedIcon from '@material-ui/icons/EmailRounded'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import GetAppIcon from '@material-ui/icons/GetApp'
import InboxIcon from '@material-ui/icons/Inbox'
import InfoIcon from '@material-ui/icons/Info'
import LinkIcon from '@material-ui/icons/Link'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { ReactComponent as InvoiceOcrCompare } from 'assets/img/invoices/git-diff.svg'
import { ReactComponent as PaymentReceipt } from 'assets/img/invoices/receipt-outline.svg'

// components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import { useParams } from 'react-router'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
// Constants
import {
  editInvoicesAI,
  InvoiceOrigins,
  InvoiceProcess,
  invoiceStatus,
  ServiceText,
  ServiceType,
  statusHistory
} from 'utils/Constants'

// functions
import { statusColor } from 'utils/functions'

// style
import { Tooltip } from '@material-ui/core'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/addressValidationStyle.js'
import { EmailDialog } from 'components/Invoice/EmailModal'
import { UpdateStatusDialog } from 'components/Invoice/UpdateStatusModal'
import { ValidationsResponseModal } from 'components/Invoice/ValidationsResponseModal'
import SyncLoader from 'components/SyncLoader/SyncLoader'
import { usePermissions } from 'hooks/useAuth.js'
import { useProcessIgnoredDocument } from 'hooks/useIgnoreDocuments.js'
import { useGetInvoiceVersions } from 'hooks/useInvocieSandard.js'
import { useGetInvoice } from 'hooks/useInvoices'
import { useGetVersionsOcr } from 'hooks/useOcrInvoice.js'
import { useGeInvoicesAiTenantConfig } from 'hooks/useTenantConfig'
import { changeInvoiceAiStatus } from 'services/apiInvoices'
import { invoiceProcessType } from 'utils/Constants'
import { formatDate } from 'utils/functions'
import { AttachmentsInvoices } from './AttachmentsInvoices'
import { CopyInvoicesModal } from './CopyInvoiceModal.jsx'
import { IntegratedModal } from './IntegratedModal'

const useStyles = makeStyles(styles)

const filters = ['new', 'processing', 'rejected', 'failed', 'invoiceException', 'completed']

export default function InvoiceDetailsView(props) {
  const { disableBreadcrumbs, pathRoot } = props
  const pathHistory = pathRoot ? pathRoot : 'admin'
  const { invoiceId } = useParams()
  const history = useHistory()
  const [createError, setCreateError] = useState({ isOpen: false, message: '' })
  const [createErrorReprocess, setCreateErrorReprocess] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showEmailModal, setShowEmailModal] = useState({
    isOpen: false,
    data: null
  })

  const [showIntegratedModal, setShowIntegratedModal] = useState(false)
  const [integratedModalInfo, setIntegratedModalInfo] = useState({})
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false)
  const [updateStatus, setUpdateStatus] = useState('')
  const [updateStatusLabel, setUpdateStatusLabel] = useState('')

  const [status] = useState(props.location.state?.status || filters)

  const [copyInvoiceModal, setCopyInvoiceModal] = useState(false)

  const classes = useStyles()
  const queryClient = useQueryClient()

  const { data: invoice, isLoading, error, isError, refetch, isFetching } = useGetInvoice({
    invoiceId,

    options: { refetchOnWindowFocus: false }
  })

  const configTenant = {
    defaultApp: invoice?.emailKey?.appId
  }

  const { data: invoices } = useGetPreviousAndNextInvoice(status, invoiceId, '')
  const nextInvoice = invoices?.next

  const {
    data: appConfig,
    isLoading: appConfigIsLoading,
    isError: appConfigIsError,
    error: appConfigError
  } = useGeInvoicesAiTenantConfig('invoiceAI', invoice?.appId)

  const generateCopyInvoice = useCopyInvoice(invoice?.appId, invoiceId)

  const transactionVersions = useGetVersionsOcr({
    transactionId: invoiceId,
    config: {
      enabled:
        invoice?.subProcess === invoiceProcessType.ocr ||
        invoice?.process === invoiceProcessType.ocr
    }
  })

  const invoiceVersions = useGetInvoiceVersions({
    invoiceId,
    config: {
      enabled: invoice?.subProcess === invoiceProcessType.eInvoice
    }
  })

  const processIgnoreDocument = useProcessIgnoredDocument(invoice?.appId, invoiceId, {
    onSettled: () => {
      refetch()
    }
  })

  const { permissions } = usePermissions()
  const isEditEnabled = permissions.some(x => editInvoicesAI.includes(x))

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError({
      isOpen: true,
      message: error.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [isError, error])

  useEffect(() => {
    if (!appConfigIsError) {
      return
    }
    setCreateError({
      isOpen: true,
      message: appConfigError.response?.data?.message || 'Something went wrong, try again later'
    })
  }, [appConfigIsError, appConfigError])

  useEffect(() => {
    if (nextInvoice) {
      queryClient.prefetchQuery(['invoices', nextInvoice], () =>
        fetchInvoiceById({
          invoiceId: nextInvoice
        })
      )
    }
  }, [nextInvoice, queryClient])

  async function changeInvoiceStatus(status, message) {
    const body = { status: status, message: message }
    const response = await changeInvoiceAiStatus(invoice.appId, invoice.rk, body)
    if (response && response.success) {
      refetch({ throwOnError: true })
      setSubmitSuccess(true)
      setValidationMessage('Invoice changed successfully its status.')
    } else {
      if (response) {
        let message = response.message
        if (!message) {
          message = 'Something went wrong, please try again later'
        }
        setValidationMessage(message)
        setCreateErrorReprocess(true)
      }
    }
  }

  //#region show buttons validations
  function showCopyInvoiceButton() {
    const processOrSubProcessIsOcr =
      invoice.subProcess === invoiceProcessType.ocr || invoice.process === invoiceProcessType.ocr
    const statusAreValid = invoice.status === 'completed' || invoice.status === 'rejected'
    const invoiceIsNotHistorical = !invoice.historicalInvoiceFlag
    return processOrSubProcessIsOcr && statusAreValid && invoiceIsNotHistorical
  }

  //#endregion

  const breadcrumbViews = [
    {
      name: 'Invoices',
      url: `/admin/invoiceAI/index`,
      icon: InboxIcon
    },
    {
      name: 'Invoices Details',
      url: `/admin/invoiceAI/${invoiceId}/details`,
      icon: DescriptionIcon
    }
  ]

  const actions = [
    rowData => ({
      icon: () => <GetAppIcon style={{ color: '#081c3e' }} />,
      onClick: (event, rowData) => {
        window.location.assign(rowData.fileUrl)
      },
      disabled: !rowData.fileUrl
    }),
    rowData => ({
      icon: () => <PaymentReceipt width="25px" height="25px" />,
      tooltip: 'Payment Receipt Details',
      onClick: (event, rowData) => {
        history.push(`/${pathHistory}/invoiceAI/payment/${rowData.paymentId}/details`)
      }
    })
  ]

  const columnsPayments = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'rfc',
      title: 'SupplierVatID'
    },
    {
      field: 'supplierName',
      title: 'Supplier Name'
    },
    {
      field: 'date',
      title: 'Date',
      render: rowData =>
        formatDate({
          date: rowData.date
        })
    },
    {
      field: 'total',
      title: 'Total'
    },
    {
      field: 'integrationStatus',
      title: 'Status Integration',
      render: rowData =>
        typeof rowData.integrationStatus === 'object' ? (
          <Link
            style={{ cursor: 'pointer', color: 'black' }}
            onClick={() => {
              setIntegratedModalInfo(rowData.integrationStatus)
              setShowIntegratedModal(true)
            }}
          >
            {invoiceStatus[(rowData.integrationStatus?.status)]}
          </Link>
        ) : (
          invoiceStatus[rowData.integrationStatus]
        )
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => invoiceStatus[rowData.status]
    }
  ]

  const columnsHistory = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1
    },
    {
      field: 'date',
      title: 'Date',
      render: rowData => formatDate({ date: rowData.date }),
      customSort: (a, b) => {
        return moment(moment(b.date)).isBefore(moment(a.date)) ? 1 : -1
      },
      defaultSort: 'desc'
    },
    {
      field: 'status',
      title: 'Status',
      render: rowData => statusHistory[rowData.status] ?? rowData.status
    },
    {
      field: 'userName',
      title: 'User',
      render: rowData => rowData?.userInfo?.userName ?? ''
    },
    {
      field: 'description',
      title: 'Description',
      render: rowData => {
        const liData = rowData.description.map((data, i) => {
          const isEmail = rowData.emailRecordId && rowData.emailRecordId !== ''
          return (
            <li key={i}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <span>{data}</span>
                {isEmail ? (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '7px'
                    }}
                  >
                    <Button
                      className={classes.colorPrimary}
                      size="small"
                      variant="outlined"
                      color="primary"
                      startIcon={<EmailRoundedIcon />}
                      onClick={() =>
                        setShowEmailModal({
                          isOpen: true,
                          data: rowData.emailRecordId
                        })
                      }
                    >
                      See Details
                    </Button>
                  </div>
                ) : null}
              </div>
            </li>
          )
        })

        return <ul>{liData}</ul>
      }
    }
  ]

  function getLogo() {
    switch (appConfig?.data?.service) {
      case 'ariba':
        return <img src={logoAribaWhite} alt={ServiceText[(appConfig?.data?.service)] ?? ''} />

      case 'coupa':
        return <img src={coupaLogoWhite} alt={ServiceText[(appConfig?.data?.service)] ?? ''} />

      case 'generic':
      case 's4Hana':
      default:
        return <LinkIcon />
    }
  }

  const activeLogo = getLogo()

  function returnPaymentsAddonsTab() {
    const isExceptionReadOnlyEnabled = isEditEnabled
      ? (invoice.subProcess === invoiceProcessType.ocr ||
          invoice.process === invoiceProcessType.ocr ||
          invoice.subProcess === invoiceProcessType.eInvoice) &&
        (invoice.status === 'failed' ||
          invoice.status === 'completed' ||
          invoice.status === 'rejected')
      : (invoice.subProcess === invoiceProcessType.ocr ||
          invoice.process === invoiceProcessType.ocr ||
          invoice.subProcess === invoiceProcessType.eInvoice) &&
        (invoice.status === 'failed' ||
          invoice.status === 'completed' ||
          invoice.status === 'rejected' ||
          invoice.status === 'invoiceException')
    return (
      <GridItem xs={12} sm={12} md={12}>
        {invoice ? (
          <GridContainer justifyContent="center">
            <GridItem xs={12} md={12} style={{ marginBottom: '25px' }}>
              <Button
                color="primary"
                size="small"
                className={classes.floatRight + ' ' + classes.colorPrimary}
                onClick={() => refetch({ throwOnError: true })}
              >
                <RefreshRoundedIcon fontSize="small" />
              </Button>
            </GridItem>
            <GridItem xs={12} md={12}>
              <p style={{ fontSize: '20px', color: 'grey' }}>
                Status:{' '}
                <span
                  style={{
                    color: invoice.status
                      ? statusColor(invoice.status) !== 'black'
                        ? statusColor(invoice.status)
                        : 'grey'
                      : 'grey'
                  }}
                >
                  {invoice.status ? invoiceStatus[invoice.status] : null}
                </span>
              </p>
            </GridItem>
            <GridItem md={12}>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} md={12}>
                      <p style={{ color: 'grey' }}>
                        Substatus:{' '}
                        <span
                          style={{
                            color: invoice.substatus
                              ? statusColor(invoice.substatus) !== 'black'
                                ? statusColor(invoice.substatus)
                                : 'grey'
                              : 'grey'
                          }}
                        >
                          {invoice.substatus ? invoiceStatus[invoice.substatus] : null}
                        </span>
                      </p>
                    </GridItem>
                    <GridItem xs={12} md={12}>
                      <p style={{ color: 'grey' }}>Process: {InvoiceProcess[invoice.process]}</p>
                    </GridItem>
                    <GridItem xs={12} md={12}>
                      <p style={{ color: 'grey' }}>
                        ERP Vendor ID: {invoice.header ? invoice.header.supplierId : null}
                      </p>
                    </GridItem>
                    <GridItem xs={12} md={12}>
                      <p style={{ color: 'grey' }}>
                        Invoice Origin:{' '}
                        {InvoiceOrigins[(invoice.header?.invoiceOrigin)] ??
                          invoice.header?.invoiceOrigin}
                      </p>
                    </GridItem>
                    <GridItem xs={12} md={12}>
                      <AttachmentsInvoices
                        appId={invoice.appId}
                        invoiceId={invoiceId}
                        cantAttachments={invoice.attachments.length}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <GridContainer>
                    <GridItem xs={12} md={12}>
                      <p style={{ color: 'grey' }}>
                        Supplier Name: {invoice.header ? invoice.header.supplierName : null}
                      </p>
                    </GridItem>
                    <GridItem xs={12} md={12}>
                      <p style={{ color: 'grey' }}>
                        {`${ServiceType[(appConfig?.data?.service)] ?? ''} Invoice ID`}:{' '}
                        {invoice.header ? invoice.header.aribaUniqueName : null}
                      </p>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                      <p style={{ color: 'grey' }}>
                        Invoice Date:{' '}
                        {invoice.header && invoice.header?.invoiceDate
                          ? invoice.header.invoiceDate.substring(0, 10)
                          : null}
                      </p>
                    </GridItem>
                    <GridItem xs={12} md={12}>
                      <p style={{ color: 'grey' }}>
                        Purchase Order:{' '}
                        {invoice.header
                          ? invoice.header.orderId && invoice.header.orderId !== ''
                            ? invoice.header.orderId
                            : '-'
                          : null}
                      </p>
                    </GridItem>
                    {invoice.process === invoiceProcessType.mexicanValidation ? (
                      <GridItem xs={12} md={12}>
                        <p style={{ color: 'grey' }}>
                          CFDI Status:{' '}
                          <span
                            style={{
                              color: invoice.header
                                ? statusColor(invoice.header.cfdiStatus) !== 'black'
                                  ? statusColor(invoice.header.cfdiStatus)
                                  : 'grey'
                                : 'grey'
                            }}
                          >
                            {invoice.header ? invoiceStatus[invoice.header.cfdiStatus] : null}
                          </span>
                        </p>
                      </GridItem>
                    ) : null}
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>

            <GridItem xs={12} md={12}>
              {invoice.status === 'failed' && invoice.header.invoiceOrigin === 'email' ? (
                <>
                  <Tooltip title={'Reject Invoice'}>
                    <Fab
                      size="small"
                      color="primary"
                      style={{ margin: '0 5px 0 5px' }}
                      className={classes.floatRight + ' ' + classes.bgColorPrimary}
                      onClick={() => {
                        setUpdateStatus('reject')
                        setUpdateStatusLabel('Reject')
                        setShowUpdateStatusModal(true)
                      }}
                      disabled={!isEditEnabled}
                    >
                      <BlockIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                  {invoice.subProcess === invoiceProcessType.ocr ||
                  invoice.process === invoiceProcessType.ocr ||
                  invoice.subProcess === invoiceProcessType.eInvoice ? (
                    <Tooltip title={'Invoice Exception'}>
                      <Fab
                        size="small"
                        color="primary"
                        style={{ margin: '0 5px 0 5px' }}
                        className={classes.floatRight + ' ' + classes.bgColorPrimary}
                        onClick={() => {
                          setUpdateStatus('invoiceException')
                          setUpdateStatusLabel('Invoice Exception')
                          setShowUpdateStatusModal(true)
                        }}
                        disabled={!isEditEnabled}
                      >
                        <InfoIcon fontSize="small" />
                      </Fab>
                    </Tooltip>
                  ) : null}
                  <Tooltip title={'Reprocess Invoice'}>
                    <Fab
                      size="small"
                      color="primary"
                      style={{ margin: '0 5px 0 5px' }}
                      className={classes.floatRight + ' ' + classes.bgColorPrimary}
                      onClick={() => changeInvoiceStatus('reprocess', '')}
                      disabled={!isEditEnabled}
                    >
                      <CachedIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                </>
              ) : null}

              {isExceptionReadOnlyEnabled ? (
                <NavLink to={`/view/invoiceAI/${invoiceId}/exception-read-only`}>
                  <Tooltip title={'OCR Invoice'}>
                    <Fab
                      size="small"
                      color="primary"
                      style={{ margin: '0 5px 0 5px' }}
                      className={classes.floatRight + ' ' + classes.bgColorPrimary}
                    >
                      <VisibilityIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                </NavLink>
              ) : null}

              {showCopyInvoiceButton() ? (
                <Tooltip title={'Copy Invoice'}>
                  <Fab
                    size="small"
                    color="primary"
                    style={{ margin: '0 5px 0 5px' }}
                    className={classes.floatRight + ' ' + classes.bgColorPrimary}
                    onClick={() => setCopyInvoiceModal(true)}
                    disabled={!isEditEnabled}
                  >
                    <FileCopyIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              ) : null}

              {(invoice.subProcess === invoiceProcessType.ocr ||
                invoice.process === invoiceProcessType.ocr ||
                invoice.subProcess === invoiceProcessType.eInvoice) &&
              (invoice.status === 'completed' || invoice.status === 'invoiceException') ? (
                <NavLink to={`/admin/invoiceAI/${invoiceId}/compare`}>
                  <Tooltip title={'Compare Invoice'}>
                    <Fab
                      size="small"
                      color="primary"
                      style={{ margin: '0 5px 0 5px' }}
                      className={classes.floatRight + ' ' + classes.bgColorPrimary}
                      disabled={
                        !(transactionVersions.data?.length > 1 || invoiceVersions.data?.length > 1)
                      }
                    >
                      <InvoiceOcrCompare width="25px" height="25px" />
                    </Fab>
                  </Tooltip>
                </NavLink>
              ) : null}

              {invoice.status === 'invoiceException' ? (
                <NavLink to={`/view/invoiceAI/${invoiceId}/exception-details`}>
                  <Tooltip title={'Invoice Exception'}>
                    <Fab
                      size="small"
                      color="primary"
                      style={{ margin: '0 5px 0 5px' }}
                      className={classes.floatRight + ' ' + classes.bgColorPrimary}
                      disabled={!isEditEnabled}
                    >
                      <ArtTrackIcon fontSize="small" />
                    </Fab>
                  </Tooltip>
                </NavLink>
              ) : null}
              {['ignored'].includes(invoice.status) && !isFetching ? (
                <Tooltip title={'Process Ignored'}>
                  <Fab
                    size="small"
                    color="primary"
                    style={{ margin: '0 5px 0 5px' }}
                    className={classes.floatRight + ' ' + classes.bgColorPrimary}
                    onClick={() => {
                      processIgnoreDocument.mutate()
                    }}
                    disabled={!isEditEnabled}
                  >
                    <PlayArrowIcon fontSize="small" />
                  </Fab>
                </Tooltip>
              ) : null}
              <Tooltip title={'Validation Details'}>
                <Fab
                  size="small"
                  color="primary"
                  style={{ margin: '0 5px 0 5px', color: 'white' }}
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  onClick={() => {
                    setShowModal(true)
                  }}
                  disabled={
                    (invoice.header
                      ? Object.keys(invoice.header.validationsResponse ?? {}).length === 0
                      : false) ||
                    ['ignored'].includes(invoice.status) ||
                    !isEditEnabled
                  }
                >
                  <PlaylistAddCheckIcon fontSize="small" />
                </Fab>
              </Tooltip>
              <Tooltip title={'Export document'}>
                <Fab
                  size="small"
                  color="primary"
                  style={{ margin: '0 5px 0 5px', color: 'white' }}
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  disabled={!invoice.header.documentDownload ? true : false}
                  onClick={() => window.location.assign(invoice.header.documentDownload)}
                >
                  <GetAppIcon fontSize="small" />
                </Fab>
              </Tooltip>
              <Tooltip title={ServiceText[(appConfig?.data?.service)] ?? ''}>
                <Fab
                  size="small"
                  color="primary"
                  style={{ margin: '0 5px 0 5px', color: 'white' }}
                  className={classes.floatRight + ' ' + classes.bgColorPrimary}
                  onClick={() => window.open(invoice.header.linkAriba, '_blank')}
                  disabled={!invoice.header?.linkAriba}
                >
                  {activeLogo}
                </Fab>
              </Tooltip>
            </GridItem>

            {invoice.subProcess !== 'ocr' && invoice.process !== 'ocr' ? (
              <GridItem xs={12} sm={12}>
                <h4>Payment Receipts</h4>
                <GridContainer justifyContent="center" style={{ marginTop: '5px' }}>
                  {isLoading ? (
                    <div className={classes.reloadSize}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <GridItem xs={12} sm={12} md={12}>
                      <MaterialTable
                        columns={columnsPayments}
                        data={invoice.invoicePayments}
                        actions={actions}
                        options={{
                          actionsColumnIndex: -1,
                          filtering: true,
                          sorting: true,
                          showTitle: false,
                          emptyRowsWhenPaging: false
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </GridItem>
            ) : null}
            <GridItem xs={12} sm={12} style={{ marginTop: '15px' }}>
              <br />
              <h4>Invoice History</h4>
              <GridContainer justifyContent="center" style={{ marginTop: '5px' }}>
                {isLoading ? (
                  <div className={classes.reloadSize}>
                    <CircularProgress />
                  </div>
                ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <MaterialTable
                      columns={columnsHistory}
                      data={invoice.history}
                      options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                        sorting: true,
                        showTitle: false,
                        emptyRowsWhenPaging: false
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        ) : null}
        <ValidationsResponseModal
          showModal={showModal}
          modalInfo={invoice.header.validationsResponse ?? {}}
          onClose={() => setShowModal(false)}
        />
      </GridItem>
    )
  }

  function onChangeInvoice(invoiceId) {
    history.push(`/admin/invoiceAI/${invoiceId}/details`)
  }

  function copyInvoice(status) {
    generateCopyInvoice.mutate(
      {
        status: status
      },
      {
        onError: () =>
          setCreateError({
            isOpen: true,
            message: 'Unable to create the invoice copy'
          }),
        onSuccess: response => history.push(`/admin/invoiceAI/${response.data.invoiceId}/details`),
        onSettled: () => setCopyInvoiceModal(false)
      }
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {disableBreadcrumbs ? null : <Breadcrumbs views={breadcrumbViews} />}
          {isLoading || appConfigIsLoading || processIgnoreDocument.isLoading ? (
            <Card>
              <GridContainer justifyContent="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : (
            <>
              <EmailDialog
                open={showEmailModal.isOpen}
                onClose={() => setShowEmailModal({ ...showEmailModal, isOpen: false })}
                recordId={showEmailModal.data}
                tenantRequest={{ configTenant, isLoadingTenantConfig: false }}
              />
              <UpdateStatusDialog
                open={showUpdateStatusModal}
                onClose={() => setShowUpdateStatusModal(false)}
                changeInvoiceStatus={changeInvoiceStatus}
                status={updateStatus}
                label={updateStatusLabel}
              />
              <CopyInvoicesModal
                open={copyInvoiceModal}
                onClose={() => setCopyInvoiceModal(false)}
                copyInvoice={status => copyInvoice(status)}
                generateCopyInvoice={generateCopyInvoice}
              />
              <IntegratedModal
                open={showIntegratedModal}
                onClose={() => setShowIntegratedModal(false)}
                data={integratedModalInfo}
              />
              <Card>
                <CardHeader>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        className={classes.btnColorPrimary}
                        variant="outlined"
                        color="primary"
                        disabled={!Boolean(invoices?.previous)}
                        onClick={() => onChangeInvoice(invoices.previous)}
                        style={{ marginRight: '1%' }}
                      >
                        Previous
                      </Button>
                      <Button
                        className={classes.btnColorPrimary}
                        color="primary"
                        variant="outlined"
                        disabled={!Boolean(invoices?.next)}
                        onClick={() => onChangeInvoice(invoices.next)}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                  <h3 className={classes.formHeader} style={{ color: 'grey' }}>
                    Invoice Number{' '}
                    {invoice ? (invoice.header ? invoice.header.invoiceId : null) : null}
                  </h3>
                </CardHeader>
                <SyncLoader showLoader={isFetching && !isLoading} />
                <CardBody>{returnPaymentsAddonsTab()}</CardBody>
              </Card>
            </>
          )}
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={createError.message}
          open={createError.isOpen}
          closeNotification={() => setCreateError({ isOpen: false, message: '' })}
          close
        />
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={validationMessage}
          open={createErrorReprocess}
          closeNotification={() => setCreateErrorReprocess(false)}
          close
        />
        <Snackbar
          place="bl"
          color="success"
          icon={AddAlert}
          message={validationMessage}
          open={submitSuccess}
          closeNotification={() => setSubmitSuccess(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
