import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  getTransactionsCountDetailsByAppId,
  getResposeDetails,
  getResposeParsedDetails,
  getTransactionsFullDetailsByAppId
} from '../../services/apiApps'
import DataAssureCountTypeView from './DataAssureCountTypeView'
import queryString from 'query-string'
import { showLoading } from 'utils/functions'
import { hideLoading } from 'utils/functions'
import { updateHeightFunction } from 'store/AppState'

let _isMounted = false
let typeValidation

const filterValues = {
  endDate: new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }),
  startDate: new Date(
    new Date().setMonth(new Date().getMonth() - 1)
  ).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }),
  typeValidation: 'addressValidation',
  result: 'all'
}

// const rows = [
//   {
//     date: '2021-02-16T00:36:27.213Z',
//     supplierId: 1234,
//     formId: 5678,
//     result: 'approve',
//     response: 'response',
//     responseParse: 'response parse',
//     link: {
//       supplierId: 'https://buckeye-test.coupahost.com/supplier_information/56',
//       formId: 'https://buckeye-test.coupahost.com/easy_form_responses/432'
//     }
//   }
// ]

async function getTransactionsCountType (props) {
  props.setCountIsLoading(true)
  let body = {
    startDate: props.filterValues.startDate,
    endDate: props.filterValues.endDate,
    result: props.filterValues.result
  }
  body.startDate = new Date(body.startDate).toISOString()
  body.endDate = new Date(body.endDate).toISOString()
  if (body.result === 'all') {
    delete body.result
  }
  const response = await getTransactionsCountDetailsByAppId(
    'slp',
    props.filterValues.typeValidation,
    body
  )

  if (response && response.success && response.data) {
    const rows = response.data.attempts
    const applicationTypes = response.data.applicationTypes
    if (_isMounted) {
      if (rows) {
        props.setRows([...rows].sort())
      }

      props.setApplicationTypes(applicationTypes)
    }
  } else {
    if (response) {
      let message = response.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
  props.setCountIsLoading(false)
}
async function getTransactionsFullType (props, offset = 0, limit = 100) {
  let returnResult = true
  props.setCountIsLoading(true)
  let body = {
    startDate: props.filterValues.startDate,
    endDate: props.filterValues.endDate,
    result: props.filterValues.result
  }
  body.startDate = new Date(body.startDate).toISOString()
  body.endDate = new Date(body.endDate).toISOString()
  if (body.result === 'all') {
    delete body.result
  }
  let rows = []
  let response
  do {
    response = await getTransactionsFullDetailsByAppId(
      'slp',
      props.filterValues.typeValidation,
      body,
      offset,
      limit
    )

    if (response && response.success && response.data) {
      rows = rows.concat(response.data.attempts)

      if (response.data.attempts.length === 0) returnResult = false
    } else {
      returnResult = false
      if (response) {
        let message = response.message
        if (!message) {
          message = 'Something went wrong, please try again later.'
        }
        props.setValidationMessage(message)
        props.setCreateError(true)
      }
    }
    offset += limit
  } while (returnResult)

  if (_isMounted) {
    if (rows) {
      props.setFullRows([...rows].sort())
    }
    if (rows.length > 0) {
      rows = rows.map(r => {
        return {
          date: r.transactionDate
            ? new Date(r.transactionDate).toISOString()
            : '',
          ...r
        }
      })
    }
    return [...rows].sort()
  }
}

export default compose(
  connect(
    state => ({
      isAuthenticated: state.login.isAuthenticated,
      name: state.login.name,
      updateHeight: state.app.updateHeight
    }),
    { updateHeightFunction }
  ),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('countIsLoading', 'setCountIsLoading', true),
  withState('rows', 'setRows', []),
  withState('applicationTypes', 'setApplicationTypes', []),
  withState('fullRows', 'setFullRows', []),

  withState('showModal', 'setShowModal', false),
  withState('modalInfo', 'setModalInfo', ''),
  withState('filterValues', 'setFilterValues', { ...filterValues }),
  withState('dataMapping', 'setDataMapping', {
    Date: 'date',
    Service: 'service',
    DocumentId: 'documentId',
    Result: 'result',
    SupplierId: 'supplierId',
    SupplierName: 'supplierName',
    Validation: 'validation',
    Message: 'responseParse.message',
    TenantValidation: 'tenantValidation',
    LinkDocument: 'link.documentId',
    LinkSupplier: 'link.supplierId'
  }),
  withHandlers({
    onSearchValues: props => async () => {
      await getTransactionsCountType(props)
    },
    onFieldChange: props => (field, value) => {
      if (field === 'startDate' || field === 'endDate') {
        if (typeof value === 'object' && value !== null) {
          value = value._d.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          })
        }
      }
      props.filterValues[field] = value
      props.setFilterValues(props.filterValues)
    },
    onSuccessDownload: props => () => {
      props.setCountIsLoading(false)
      props.setValidationMessage('Logs exported successfully')
      props.setSubmitSuccess(true)
      setTimeout(() => {
        props.setSubmitSuccess(false)
      }, 5000)
    },
    onFailedDownload: props => () => {
      props.setCountIsLoading(false)
      props.setValidationMessage('Something went wrong, please try again later')
      props.setCreateError(true)
      setTimeout(() => {
        props.setCreateError(false)
      }, 5000)
    },
    onGetResponse: props => async (hashKey, rangeKey, transactionDate) => {
      showLoading()
      const response = await getResposeDetails(
        hashKey,
        rangeKey,
        transactionDate
      )
      hideLoading()
      if (response.success) {
        return response.data.data
      } else {
        return {}
      }
    },
    onGetParsedResponse: props => async (
      hashKey,
      rangeKey,
      transactionDate
    ) => {
      showLoading()
      const response = await getResposeParsedDetails(
        hashKey,
        rangeKey,
        transactionDate
      )
      hideLoading()
      if (response.success) {
        return response.data.data
      } else {
        return ''
      }
    },
    getFullDetails: props => async () => {
      let offset = 0
      return await getTransactionsFullType(props, offset)
    }
  }),
  lifecycle({
    componentDidMount () {
      showLoading(this.props.setPageIsLoading)
      _isMounted = true
      typeValidation = this.props.match.params.typeValidation
      const parsed = queryString.parse(this.props.location.search)
      this.props.filterValues.typeValidation = typeValidation
      this.props.filterValues.startDate = parsed.startDate
      this.props.filterValues.endDate = parsed.endDate
      this.props.setPageIsLoading(true)
      getTransactionsCountType(this.props)
      this.props.setPageIsLoading(false)
      hideLoading(this.props.setPageIsLoading)
    }
  })
)(DataAssureCountTypeView)
