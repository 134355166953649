import React, { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'

// Material UI
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

// Form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

// Inputs
import { TextInput } from '../../../../components/Configuration/Inputs/TextInput'
import { SelectInput } from '../../../../components/Configuration/Inputs/SelectInput'
import { BooleanInput } from '../../../../components/Configuration/Inputs/BooleanInput'

// Components
import InfoModal from '../../../../components/Configuration/InfoModal'

// Style
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/nitorInsightsStyle.js'

// Hooks
import {
  useUpdateTransactionConnection
  // useValidateTransactionsConnection
} from 'hooks/useInvoiceConf'
import { WorkTags } from './WorkTags'

const useStyles = makeStyles(styles)

export default function InvoiceRaasForm(props) {
  const classes = useStyles()
  const {
    app,
    setCreateError,
    setSubmitSuccess,
    setTab,
    connectionObj,
    schema,
    // connected,
    fields,
    modifyData
  } = props
  const [connectionForm] = useState(connectionObj)
  const queryClient = useQueryClient()
  // const [connectionConnected, setConnectionConnected] = useState(connected)
  // const [connectionMessage, setConnectionMessage] = useState('')
  const [showModal, setShowModal] = useState({ open: false, message: '' })
  const [workTags, setWorkTags] = useState([])
  const {
    handleSubmit,
    //  formState: { isDirty },
    control,
    getValues
  } = useForm({
    mode: 'all',
    defaultValues: connectionForm,
    resolver: yupResolver(schema),
    shouldUnregister: false
  })

  /*useEffect(() => {
    if (isDirty) {
      setConnectionConnected(undefined)
      setConnectionMessage('')
    }
  }, [isDirty, setConnectionConnected, setConnectionMessage])*/
  useEffect(() => {
    const data = getValues()
    setWorkTags(data.workTags)
  }, [setWorkTags, getValues])

  const {
    mutate: updateTransactionConnection,
    isLoading: isLoadingUpdateConnection
  } = useUpdateTransactionConnection({
    appId: app.data?.data.appId
  })

  /* const {
    mutate: validateTransactionConnection,
    isLoading: isLoadingValidateConnection
  } = useValidateTransactionsConnection({
    appId: app.data?.data.appId
  })*/

  function onSubmitHook(data) {
    if (
      workTags.length &&
      workTags.find(
        x => !x.workTagName || !x.customReportName || !x.valueTag || !x.descriptionTag || !x.xmlTag
      )
    ) {
      setCreateError({
        message: 'Please fill the required information in the Workdaytags configurations section.',
        isOpen: true,
        color: 'danger'
      })
      return
    }
    data = modifyData({ ...data, workTags })
    updateTransactionConnection(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Apps', app.data?.data.appId])
        },
        onSuccess: response => {
          if (response.status === 200) {
            setSubmitSuccess({ message: 'Saved', isOpen: true })
            app.refetch({ throwOnError: true })
            setTab(0)
          }
        },
        onError: error => {
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }

  function typeForm() {
    return (
      <Grid container justifyContent="center" spacing={3}>
        {fields.filter(x => !x.section)?.map(field => inputs(field))}
      </Grid>
    )
  }

  function accountingSection() {
    const accountFields = fields.filter(x => x.section && x.section === 'accounting') ?? []
    return accountFields.length ? (
      <Grid container justifyContent="center" spacing={3} style={{ marginTop: '10px' }}>
        <Grid item xs={12}>
          <h5 className={classes.sectionTitle}> Accounting information</h5>
        </Grid>
        {accountFields?.map(field => inputs(field))}
      </Grid>
    ) : null
  }

  console.log('getValues', { values: getValues() })

  function inputs(field) {
    let jsxElements = []
    switch (field.type) {
      case 'boolean':
        jsxElements.push(
          <BooleanInput key={field.key} name={field.key} control={control} label={field.label} />
        )
        break
      case 'select':
        jsxElements.push(
          <SelectInput
            key={field.key}
            name={field.key}
            control={control}
            label={field.label}
            values={field.values}
          />
        )
        break
      case 'text':
      case 'password':
      case 'number':
        jsxElements.push(
          <TextInput
            key={field.key}
            name={field.key}
            control={control}
            type={field.type}
            label={field.label}
            multiline={false}
          />
        )
        break
      case 'conditional':
        const conditional = getValues(field.inputCondition)
        if (field.conditional && conditional === field.valueCondition) {
          jsxElements.push(
            <TextInput
              key={field.key}
              name={field.key}
              control={control}
              label={field.label}
              type={field.typeInput}
              multiline={field.multiline}
              rows={field.rows}
            />
          )
        }
        break
      case 'workdayTags':
        jsxElements.push(
          <WorkTags
            key={field.key}
            name={field.key}
            workTags={workTags}
            setWorkTags={setWorkTags}
            label={field.label}
            control={control}
          />
        )
        break
      default:
        break
    }

    return jsxElements.map(jsxElement => jsxElement)
  }

  /*function checkConnection() {
    const data = modifyData(getValues())
    validateTransactionConnection(
      { data },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['Apps', app.data?.data.appId])
        },
        onSuccess: response => {
          if (response.data.valid) {
            setConnectionConnected(true)
            setConnectionMessage('')
          } else {
            let defaultError = {
              error: 'invalid_request',
              error_description: 'Connection Error'
            }
            defaultError = JSON.stringify(defaultError, null, '\t')
            setConnectionConnected(false)
            setConnectionMessage(JSON.stringify(response.data.data) || defaultError)
          }
        },
        onError: error => {
          setConnectionConnected(false)
          setCreateError({
            message: error.response?.data?.message ?? 'Something went wrong, try again later',
            isOpen: true,
            color: 'danger'
          })
        }
      }
    )
  }*/

  return (
    <form onSubmit={handleSubmit(onSubmitHook)}>
      {typeForm()}
      {accountingSection()}
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '15px' }}>
          <Button
            className={classes.bgColorPrimary}
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isLoadingUpdateConnection //|| !connectionConnected || isLoadingValidateConnection
            }
          >
            Save
          </Button>
        </Grid>
        <InfoModal
          showModal={showModal.open}
          onClose={() => setShowModal({ open: false, message: '' })}
          warningMessage={showModal.message}
          title="Error connection details"
        />
      </Grid>
    </form>
  )
}
