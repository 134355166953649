import { compose, withState, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";

//Services
import {
    getAppConfigurationsByApp,
    getAppConfigurationByAppAndId,
    getAppConstantsByApp,
    editAppConfigurationByAppAndType,
    validateAppConnectionByType,
    getAppLoadsByAppId,
    setStartLoad,
    setStopLoad,
    setDeleteLoad,
} from "services/apiApps";

// Constants
import { loadStatus, typeLoad, InputDefault, updateFrequency } from 'utils/Constants'

//Views
import QualtricsAribaView from "./QualtricsAribaView";

import { updateHeightFunction } from "store/AppState";

let _isMounted = false;
let appId;
let typeApp;

const newConfig = {
    environmentId: "",
    realm: "",
    inputs: "",
};

const newConfigState = {
    environmentId: "",
    realm: "",
    inputs: "",
};

async function getAppList(props) {
    let response = await getAppConfigurationsByApp("qualtrics");

    if (response.success) {
        if (_isMounted) {
            props.setAppList(response.data);
            props.setPageIsLoading(false);
        }
    }
}

async function getAppDetails(props, id) {
    props.setFormIsLoading(true);
    props.setSelectedAppId(id);
    const appDetails = await getAppConfigurationByAppAndId("qualtrics", id);
    const constants = await getAppConstantsByApp("qualtrics", 'supplier');
    if (appDetails.success) {
        if (constants.success) {
            let apis = {};
            constants.data.journeys.forEach((journey) => {
                let eventKeys = Object.keys(journey.event);
                eventKeys.forEach((key) => {
                    apis = { ...apis, ...journey.event[key].apis };
                });
            });
            let aribaConfigEdit = {};
            let aribaConfig = { ...setInputs(apis) };
            const params = appDetails.data.params.ariba;
            if (params) {
                aribaConfigEdit = { ...setInputs(params, true) };
            }
            const aribaUnion = { ...aribaConfig, ...aribaConfigEdit }
            const result = {}
            Object.keys(aribaConfig).forEach(item => {
                result[item] = aribaUnion[item]
            })

            const configurations = {
                realm: { value: appDetails.data.params.ariba.realm, state: undefined },
                environmentId: appDetails.data.environmentId,
                apis: { ...result },
            };

            if (_isMounted) {
                props.setConfig(configurations);
            }
        }
    }
    props.setFormIsLoading(false);
}

function setInputs(apis, edit = false) {
    let aribaConfig = {};
    let item
    for (item in apis) {
        if (item !== "remoteConnections") {
            let inputs = {};
            inputs["apiKey_" + [item]] = {
                key: "apiKey_" + [item],
                label: "Ariba Application Key",
                value: apis[item].apiKey ? apis[item].apiKey : "",
                state: undefined,
            };
            inputs["clientId_" + [item]] = {
                key: "clientId_" + [item],
                label: "Client Id",
                value: apis[item].clientId ? apis[item].clientId : "",
                state: undefined,
            };
            inputs["secret_" + [item]] = {
                key: "secret_" + [item],
                label: "Secret",
                value: apis[item].secret ? apis[item].secret : "",
                state: undefined,
            };
            aribaConfig[item] = {
                inputs: inputs,
                aribaConnection: edit ? true : undefined,
                aribaConnectionMessage: undefined,
                aribaConnectionTesting: false,
            };
        }
    }
    return aribaConfig;
}

async function getAppLoads(props, id, type) {
    const appLoads = await getAppLoadsByAppId("qualtrics", id, type);
    if (appLoads.success) {
        let loads = null;
        if (appLoads.data.length > 0) {
            loads = appLoads.data;
            formatLoads(loads, props)
        }
        if (_isMounted) {
            props.setLoads(loads);
        }
    }
}

function formatLoads (loads, props) {
    const rows = loads.map(load => {
      return {
        id: load.idLoad,
        load: load.params.name,
        destination: load.params.destination.nameConnection,
        type: typeLoad[load.params.type],
        startDate: new Date(load.params.startDate).toLocaleDateString(window.navigator.userLanguage || window.navigator.language, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }),
        creationDate: new Date(load.params.creationDate).toLocaleDateString(window.navigator.userLanguage || window.navigator.language, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }),
        updateFrequency: updateFrequency[load.params.updateFrequency],
        status: loadStatus[load.status]
      }
    })
    props.setRows([...rows])
  }

async function onConfigSubmit(props) {
    props.setFormIsLoading(true);
    let successMessage;

    //Update with the form values
    let appConfiguration = createConfigRequestBody(props.config);
    let response;

    response = await editAppConfigurationByAppAndType(
        "qualtrics",
        props.selectedAppId,
        appConfiguration,
        props.typeApp
    );
    successMessage = "App updated successfully";

    if (response && response.success && _isMounted) {
        await getAppDetails(props, props.selectedAppId);
        props.setValidationMessage(successMessage);
        props.setSubmitSuccess(true);

        setTimeout(function() {
            if (_isMounted) {
                props.setSubmitSuccess(false);
                props.setFormIsLoading(false);
            }
        }, 5000);
    } else {
        // Show error message
        if (response) {
            let message = response.message;
            if (!message) {
                message = "Something went wrong, please try again later.";
            }
            props.setValidationMessage(message);
            props.setCreateError(true);
        }
        props.setFormIsLoading(false);
    }
}

function createConfigRequestBody(configuration) {
    let requestBody = { ...configuration };
    delete requestBody.environmentId;

    let object = {
        realm: requestBody.realm.value,
    };
    let item
    for (item in requestBody.apis) {
        if (requestBody.apis[item].aribaConnection === true) {
            object[item] = {
                apiKey: requestBody.apis[item].inputs["apiKey_" + item].value,
                secret: requestBody.apis[item].inputs["secret_" + item].value,
                clientId: requestBody.apis[item].inputs["clientId_" + item].value,
            };
            let checkProps = ["apiKey", "secret", "clientId"];
            let bodyField;
            for (bodyField of checkProps) {
                if (object[item][bodyField] === InputDefault) {
                    delete object[item][bodyField];
                }
            }
        }
    }
    return object;
}

async function checkAribaConnection(item, props) {
    props.config.apis[item].aribaConnection = undefined;
    props.config.apis[item].aribaConnectionTesting = true;
    props.config.apis[item].aribaConnectionMessage = undefined;
    props.setConfig(props.config);

    let connectionData = {
        secret: props.config.apis[item].inputs["secret_" + item].value,
        clientId: props.config.apis[item].inputs["clientId_" + item].value,
        apiKey: props.config.apis[item].inputs["apiKey_" + item].value,
        realm: props.config.realm.value,
    };

    let checkProps = ["apiKey", "secret", "clientId"];
    let bodyField;
    for (bodyField of checkProps) {
        if (connectionData[bodyField] === InputDefault) {
            delete connectionData[bodyField];
        }
    }
    let connection = await validateAppConnectionByType(
        "qualtrics",
        props.config.environmentId,
        connectionData,
        item, 
        "qualtrics"
    );
    let defaultError = {
        error: "invalid_request",
        error_description: "Connection Error",
    };
    defaultError = JSON.stringify(defaultError, null, "\t");

    if (connection.success) {
        props.config.apis[item].aribaConnection = connection.data.valid;
        props.setConfig(props.config);
        if (connection.data.valid) {
            props.config.apis[item].aribaConnectionMessage = undefined;
            props.setConfig(props.config);
        } else {
            props.config.apis[item].aribaConnectionMessage =
                JSON.stringify(connection.data.data) || defaultError;
            props.setConfig(props.config);
        }
    } else {
        props.config.apis[item].aribaConnection = false;
        props.config.apis[item].aribaConnectionMessage = defaultError;
        props.setConfig(props.config);
    }
    props.config.apis[item].aribaConnectionTesting = false;
    props.setConfig(props.config);
}

async function startLoad(props, idLoad) {
    props.setLoadIsLoading(true)
    let successMessage;
    const requestBody = {
        api: props.typeApp,
        enabled: true,
        params: { status: "active" },
    };
    let response = await setStartLoad(
        "qualtrics",
        requestBody,
        props.selectedAppId,
        idLoad
    );
    successMessage = "Load started successfully";

    if (response && response.success && _isMounted) {
        await getAppLoads(props, props.selectedAppId, props.typeApp);
        props.setLoadIsLoading(false)
        props.setSubmitSuccess(true);
        props.setValidationMessage(successMessage);

        setTimeout(function() {
            if (_isMounted) {
                props.setSubmitSuccess(false);
            }
        }, 5000);
    } else {
        // Show error message
        if (response) {
            let message = response.message;
            if (!message) {
                message = "Something went wrong, please try again later.";
            }
            props.setValidationMessage(message);
            props.setCreateError(true);
            props.setFormIsLoading(false);
        }
    }
}

async function stopLoad (props, idLoad) {
    props.setLoadIsLoading(true)
    let successMessage
    const requestBody = {
      api: props.typeApp,
      enabled: false,
      params: { status: 'stopped' }
    }
    let response = await setStopLoad(
      'qualtrics',
      requestBody,
      props.selectedAppId,
      idLoad
    )
    successMessage = 'Load stopped successfully'
  
    if (response && response.success && _isMounted) {
      await getAppLoads(props, props.selectedAppId, props.typeApp)
      props.setLoadIsLoading(false)
      props.setSubmitSuccess(true)
      props.setValidationMessage(successMessage)
  
      setTimeout(function () {
        if (_isMounted) {
          props.setSubmitSuccess(false)
        }
      }, 5000)
    } else {
      // Show error message
      if (response) {
        let message = response.message
        if (!message) {
          message = 'Something went wrong, please try again later.'
        }
        props.setValidationMessage(message)
        props.setCreateError(true)
        props.setFormIsLoading(false)
      }
    }
  }

async function deleteLoad(props, idLoad) {
    props.setLoadIsLoading(true)
    let successMessage;
    let response = await setDeleteLoad(
        "qualtrics",
        props.selectedAppId,
        idLoad
    );
    successMessage = "Load deleted successfully";

    if (response && response.success && _isMounted) {
        await getAppLoads(props, props.selectedAppId, props.typeApp);
        props.setLoadIsLoading(false)
        props.setSubmitSuccess(true);
        props.setValidationMessage(successMessage);

        setTimeout(function() {
            if (_isMounted) {
                props.setSubmitSuccess(false);
            }
        }, 5000);
    } else {
        // Show error message
        if (response) {
            let message = response.message;
            if (!message) {
                message = "Something went wrong, please try again later.";
            }
            props.setValidationMessage(message);
            props.setCreateError(true);
            props.setFormIsLoading(false);
        }
    }
}

export default compose(
    connect(
        (state) => ({
            isAuthenticated: state.login.isAuthenticated,
            name: state.login.name,
            updateHeight: state.app.updateHeight,
        }),
        { updateHeightFunction }
    ),
    withState("appList", "setAppList", []),
    withState("selectedAppId", "setSelectedAppId", null),
    withState("typeApp", "setTypeApp", null),
    withState("config", "setConfig", { ...newConfig }),
    withState("configState", "setConfigState", { ...newConfigState }),
    withState("formIsLoading", "setFormIsLoading", false),
    withState("loadIsLoading", "setLoadIsLoading", false),
    withState("submitSuccess", "setSubmitSuccess", false),
    withState("createError", "setCreateError", false),
    withState("validationMessage", "setValidationMessage", ""),
    withState("pageIsLoading", "setPageIsLoading", true),
    withState("isLoadingSave", "setIsLoadingSave", false),
    withState(
        "aribaConnectionConnected",
        "setAribaConnectionConnected",
        undefined
    ),
    withState("aribaConnectionTesting", "setAribaConnectionTesting", false),
    withState("aribaConnectionMessage", "setAribaConnectionMessage", undefined),
    withState("showModal", "setShowModal", false),
    withState("loads", "setLoads", null),
    withState('rows', 'setRows', null),
    withHandlers({
        onAppChanged: (props) => (id) => {
            props.setSelectedAppId(id);
            props.setLoads(null);
            getAppLoads(props, id, props.typeApp);
            getAppDetails(props, id);
        },
        onConfigSave: (props) => async () => {
            props.setIsLoadingSave(true);
            await onConfigSubmit(props);
            props.setIsLoadingSave(false);
        },
        onFieldChange: (props) => (item, object, field, value) => {
            if (item && object) {
                object.value = value;
                props.config.apis[item].inputs[field] = object;
                props.config.apis[item].aribaConnection = undefined;
                props.config.apis[item].aribaConnectionMessage = undefined;
            } else if (field === "realm") {
                props.config.realm.value = value;
            }
            props.setConfig(props.config);
        },
        onCheckAribaConnection: (props) => (item, input) => {
            checkAribaConnection(item, props);
        },
        onStartLoad: (props) => (idLoad) => {
            startLoad(props, idLoad);
        },
        onStopLoad: props => idLoad => {
            stopLoad(props, idLoad)
          },
        onDeleteLoad: (props) => (idLoad) => {
            deleteLoad(props, idLoad);
        },
        onGoToLoads: (props) => () => {
            props.history.push(
                `/admin/qualtrics/load/${props.selectedAppId}/${props.typeApp}/`
            );
        },
        onRefreshLoads: (props) => async () => {
            props.setLoadIsLoading(true);
            await getAppLoads(props, props.selectedAppId, props.typeApp);
            props.setLoadIsLoading(false);
        },
    }),
    lifecycle({
        componentDidMount() {
            _isMounted = true;
            this.props.setPageIsLoading(true);
            appId = this.props.match.params.id;
            typeApp = this.props.match.params.type;
            this.props.setSelectedAppId(appId);
            this.props.setTypeApp(typeApp);
            getAppLoads(this.props, appId, typeApp);
            getAppDetails(this.props, appId);
            getAppList(this.props);
        },
        componentWillUnmount() {
            _isMounted = false;
        },
    })
)(QualtricsAribaView);
