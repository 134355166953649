import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import CheckIcon from '@material-ui/icons/Check'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import DvrIcon from '@material-ui/icons/Dvr'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import SyncLoader from 'components/SyncLoader/SyncLoader.js'

// functions
import { formatDate } from 'utils/functions.js'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

// hooks
import { useGetTransactionData } from 'hooks/useMonitorsConsole'
import { useLocalStorageState } from 'hooks/useLocalStorage.js'

const useStyles = makeStyles(styles)
const filters = ['processing', 'failed', 'completed']
const monitorDetailsColumns = {
  'Last Update': false,
  'Created Date': false,
  'Process Id': false,
  'Process Description': false,
  'Transaction Id': false,
  Status: false,
  'Last Step Updated': false
}

export default function MonitorDetailsView() {
  const history = useHistory()
  const { env, monitorId, description, appId } = useParams()
  const [createError, setCreateError] = useState(false)
  const [status, setStatus] = useState(filters)
  const [userFilters, setUserFilters] = useState({})
  const [userColumns, setUserColumns] = useLocalStorageState(
    'monitorDetails.config',
    monitorDetailsColumns
  )

  const classes = useStyles()
  const {
    data: transactions,
    isLoading,
    error,
    isError,
    refetch,
    isFetching
  } = useGetTransactionData({
    env,
    monitorId,
    status,
    appId
  })

  const actions = [
    {
      icon: () => <MoreHorizIcon style={{ color: '#081c3e' }} />,
      tooltip: 'Details',
      onClick: (event, rowData) => {
        history.push(
          `/admin/monitorConsole/details/${env}/${appId}/${monitorId}/${description}/${rowData.processId}`
        )
      }
    }
  ]

  function filterColumns(columns) {
    const configCopy = { ...userFilters }
    let column
    for (column of columns) {
      configCopy[column.column.field] = column.value
    }
    setUserFilters(configCopy)
  }

  function hideColumns(column, hidden) {
    const configCopy = { ...userColumns }
    configCopy[column.title] = hidden
    setUserColumns(configCopy)
  }

  async function onSearchTransaction(newStatus) {
    let statusArray = [...status]
    if (newStatus === 'all') {
      if (statusArray.length === 6) {
        statusArray = []
      } else {
        statusArray = filters
      }
    } else {
      let selected = statusArray.findIndex(x => x === newStatus)
      if (selected !== -1) {
        statusArray.splice(selected, 1)
      } else {
        statusArray.push(newStatus)
        statusArray = [...new Set(statusArray)]
      }
    }
    setStatus(statusArray)
  }

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError(true)
  }, [isError])

  const breadcrumbViews = [
    {
      name: 'Monitor Console',
      url: `/admin/monitorConsole/index`,
      icon: DesktopWindowsIcon
    },
    {
      name: 'Monitor Details',
      url: `/admin/monitorConsole/transactions/${env}/${appId}/${monitorId}/${description}`,
      icon: DvrIcon
    }
  ]
  let monitorData = {}
  let obj = {}
  let data = {}
  if (transactions && transactions.rows) {
    let transaction
    for (transaction of transactions.rows) {
      if (transaction.lastUpdate) {
        obj = {}
        obj[transaction.lastUpdate] = transaction.lastUpdate
        data['lastUpdate'] = { ...data['lastUpdate'], ...obj }
      }

      if (transaction.transactionDate) {
        obj = {}
        obj[transaction.transactionDate] = formatDate({
          date: transaction.transactionDate
        })
        data['transactionDate'] = { ...data['transactionDate'], ...obj }
      }

      if (transaction.processId) {
        obj = {}
        obj[transaction.processId] = transaction.processId
        data['processId'] = { ...data['processId'], ...obj }
      }

      if (transaction.transactionId) {
        obj = {}
        obj[transaction.transactionId] = transaction.transactionId
        data['transactionId'] = { ...data['transactionId'], ...obj }
      }

      if (transaction.status) {
        obj = {}
        obj[transaction.status] =
          transaction.status.charAt(0).toUpperCase() +
          transaction.status.slice(1)
        data['status'] = { ...data['status'], ...obj }
      }

      if (transaction.lastStepInfo) {
        obj = {}
        obj[transaction.lastStepInfo] = transaction.lastStepInfo
        data['lastStepInfo'] = { ...data['lastStepInfo'], ...obj }
      }
    }
    monitorData = data
  }
  const columns = [
    {
      title: 'No.',
      render: rowData => rowData.tableData.id + 1,
      hiddenByColumnsButton: true,
      width: 100,
      hidden: false,
      align: 'center'
    },
    {
      field: 'lastUpdate',
      title: 'Last Update',
      render: rowData =>
        formatDate({
          date: rowData.lastUpdate
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Last Update'],
      align: 'center',
      lookup: monitorData['lastUpdate'],
      defaultFilter: userFilters['lastUpdate'],
      defaultSort: 'desc'
    },
    {
      field: 'transactionDate',
      title: 'Created Date',
      render: rowData =>
        formatDate({
          date: rowData.transactionDate
        }),
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Created Date'],
      align: 'center',
      lookup: monitorData['transactionDate'],
      defaultFilter: userFilters['transactionDate']
    },
    {
      field: 'processId',
      title: 'Process Id',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Process Id'],
      align: 'center',
      lookup: monitorData['processId'],
      defaultFilter: userFilters['processId']
    },
    {
      field: 'description',
      title: 'Process Description',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Process Description'],
      align: 'center',
      lookup: monitorData['description'],
      defaultFilter: userFilters['description']
    },
    {
      field: 'transactionId',
      title: 'Transaction Id',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Transaction Id'],
      align: 'center',
      lookup: monitorData['transactionId'],
      defaultFilter: userFilters['transactionId']
    },
    {
      field: 'status',
      render: rowData =>
        rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1),
      title: 'Status',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['Status'],
      align: 'center',
      lookup: monitorData['status'],
      defaultFilter: userFilters['status']
    },
    {
      field: 'lastStepInfo',
      title: 'Last Step Updated',
      hiddenByColumnsButton: true,
      width: 100,
      hidden: userColumns['lastStepInfo'],
      align: 'center',
      lookup: monitorData['lastStepInfo'],
      defaultFilter: userFilters['lastStepInfo']
    }
  ]

  function returnTransactionTab() {
    return (
      <GridItem xs={12} sm={12} md={12}>
        {transactions ? (
          <GridContainer justify="center">
            <GridItem md={12} style={{ marginBottom: '25px' }}>
              <Button
                color="primary"
                size="small"
                className={classes.floatRight}
                onClick={() => refetch({ throwOnError: true })}
              >
                <RefreshRoundedIcon fontSize="small" />
              </Button>
            </GridItem>
            <GridItem md={12}>
              <div
                style={{
                  width: '100%',
                  margin: '0 auto 10px'
                }}
              >
                <div
                  className={classes.statusButton}
                  style={{ width: '145px' }}
                >
                  <div>
                    <Fab
                      size="small"
                      onClick={() => {
                        onSearchTransaction('processing')
                      }}
                      className={
                        status.find(x => x === 'processing')
                          ? classes.primaryColorFilled
                          : classes.primaryColor
                      }
                    >
                      <TimelapseIcon />
                    </Fab>
                    <span className={classes.statusText}>Processing</span>
                  </div>
                </div>
                <div
                  className={classes.statusButton}
                  style={{ width: '115px' }}
                >
                  <div>
                    <Fab
                      size="small"
                      onClick={() => {
                        onSearchTransaction('failed')
                      }}
                      className={
                        status.find(x => x === 'failed') !== undefined
                          ? classes.warningColorFilled
                          : classes.warningColor
                      }
                    >
                      <PriorityHighIcon />
                    </Fab>
                    <span className={classes.statusText}>Failed</span>
                  </div>
                </div>
                <div
                  className={classes.statusButton}
                  style={{ width: '145px' }}
                >
                  <div>
                    <Fab
                      size="small"
                      onClick={() => {
                        onSearchTransaction('completed')
                      }}
                      className={
                        status.find(x => x === 'completed')
                          ? classes.successColorFilled
                          : classes.successColor
                      }
                    >
                      <CheckIcon />
                    </Fab>
                    <span className={classes.statusText}>Completed</span>
                  </div>
                </div>
                <div
                  className={classes.statusButton}
                  style={{ width: '115px', border: 'none' }}
                >
                  <div>
                    <Button
                      size="small"
                      onClick={() => {
                        onSearchTransaction('all')
                      }}
                      style={{ color: '#999' }}
                    >
                      Select All
                    </Button>
                  </div>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <h4>{`Transactions Info`}</h4>
              <GridContainer justify="center" style={{ marginTop: '5px' }}>
                {isLoading ? (
                  <div className={classes.reloadSize}>
                    <CircularProgress />
                  </div>
                ) : (
                  <GridItem xs={12} sm={12} md={12}>
                    <MaterialTable
                      columns={columns}
                      data={transactions.rows}
                      actions={actions}
                      onChangeColumnHidden={hideColumns}
                      onFilterChange={filterColumns}
                      options={{
                        columnsButton: true,
                        actionsColumnIndex: -1,
                        filtering: true,
                        sorting: true,
                        showTitle: false
                      }}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>
          </GridContainer>
        ) : null}
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          {isLoading ? (
            <Card>
              <GridContainer justify="center">
                <div className={classes.circularProgress}>
                  <CircularProgress color="inherit" />
                </div>
              </GridContainer>
            </Card>
          ) : (
            <>
              <Card>
                <CardHeader>
                  <h3 className={classes.formHeader} style={{ color: 'grey' }}>
                    {description}
                  </h3>
                </CardHeader>
                <SyncLoader showLoader={isFetching && !isLoading} />
                <CardBody>{returnTransactionTab()}</CardBody>
              </Card>
            </>
          )}
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={
            isError
              ? error.response?.data?.message ||
                'Something went wrong, try again later'
              : ''
          }
          open={createError}
          closeNotification={() => setCreateError(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
