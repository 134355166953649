import React, { useState, useMemo } from 'react'
import csc from 'countries-states-cities'

// @material-ui/core components
import { Fade } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import {
  makeStyles,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  CircularProgress
} from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { DropzoneArea } from 'material-ui-dropzone'

// componentsG
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CustomInput from 'components/CustomInput/CustomInput'
import { FieldsDetailsModal } from 'components/DataAccess/FieldsDetailsModal'

// @material-ui/icons
import CheckBox from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

// import Close from '@material-ui/icons/Close'
import styles from 'assets/jss/material-dashboard-pro-react/views/loginViewStyle.js'
import stylesButtons from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js'
import wizardStyle from '../../assets/jss/material-dashboard-pro-react/components/wizardStyle.js'
//services
import {
  finalizePiiForm,
  executeValidation,
  saveSupplierInformation,
  blockPIISupplier,
  processPIIFile,
  getFormProcessSatus
} from '../../services/apiPII'

// constants
import { piiValidationTypes, piiValidationUpdateOptions } from 'utils/Constants'

// utils
import { showLoading, hideLoading } from 'utils/functions'

const useStyles = makeStyles(styles)
const usesWizardStyles = makeStyles(wizardStyle)
const useStylesButtons = makeStyles(stylesButtons)
const maxRetries = 3
const timerReviewFileProcess = 5000
const maxRetriesFileProcess = 24

export default function MockupDataAccess (props) {
  const [file, setFile] = useState(undefined)
  const [fileMessage, setFileMessage] = useState('Please select a file')
  const [hideUploadFile, setHideUploadFile] = useState(false)
  const [fileIsLoading, setFileIsLoading] = useState(false)
  const countryListOptions = useMemo(() => csc.getAllCountries(), [])
  const classes = useStyles()
  const urlToken = props.urlToken
    ? props.urlToken
    : localStorage.getItem('pii.urlToken')
  const tenantId = props.tenantId
    ? props.tenantId
    : localStorage.getItem('pii.tenantId')
  const validationId = props.validationId
    ? props.validationId
    : localStorage.getItem('pii.validationId')
  const email = props.email ? props.email : localStorage.getItem('pii.email')
  const isFormUpdate = props.isFormUpdate
    ? props.isFormUpdate
    : localStorage.getItem('pii.isFormUpdate')
  const formUpdateValidation = props.formUpdateValidation
    ? props.formUpdateValidation
    : localStorage.getItem('pii.formUpdateValidation')
  const supplierName = props.supplierName
    ? props.supplierName
    : localStorage.getItem('pii.setSupplierName')
  const supplierId = props.supplierId
    ? props.supplierId
    : localStorage.getItem('pii.supplierId')
  const buttonClases = useStylesButtons()
  const wizardClasses = usesWizardStyles()
  const [name, setName] = useState('')
  const [otherValues, setOtherValues] = useState(undefined)
  const [showOtherValues, setShowOtherValues] = useState(false)
  const [ssn, setSSN] = useState('')
  const [uploadFile, setUploadFile] = useState(false)
  const [country, setCountry] = useState('United States')
  const [accountNumber, setAccountNumber] = useState('')
  const [bankName, setBankName] = useState('')
  const [validate, setValidate] = useState(false)
  const [validate2, setValidate2] = useState(false)
  const [swiftCode, setSwiftCode] = useState('')
  const [ibanNumber, setIbanNumber] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')
  const [validateData, setValidateData] = useState(true)
  const [formStatus, setFormStatus] = useState(false)
  const [formDataErrorValues, setFormDataErrorValues] = useState(false)
  const [formDataErrorMessage, setFormDataErrorMessage] = useState('')
  const [tinRetryCount, setTinRetryCount] = useState(0)
  const [bankRetryCount, setBankRetryCount] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [piiMessage, setPiiMessage] = useState(
    `Thank you for submitting your information.\n 
      \nYou can close this window, no further action is required.`
  )
  const [validationMessage, setValidationMessage] = useState('')
  const [validationMessageTin, setValidationMessageTin] = useState('')
  const [validationsStatus, setValidationStatus] = useState({
    [piiValidationTypes.bank]: false,
    [piiValidationTypes.tin]: false,
    [piiValidationTypes.file]: false
  })
  const [status, setStatus] = useState({
    name: false,
    ssn: false,
    accountNumber: false,
    ibanNumber: false,
    bankName: false,
    swiftCode: false,
    routingNumber: false,
    validateData: false,
    file: false
  })
  // useEffect(() => {
  //   console.log('USE EFFECT')
  //   console.log('useEffect formTin Status', { con: formStatusTin })
  // }, [formStatusTin])
  console.log('props received', { con: props })
  /* console.log('form update local', {
    con: localStorage.getItem('pii.formUpdateValidation')
  })
  console.log('form update props', { con: props.formUpdateValidation })

  console.log('IS form update local', {
    con: localStorage.getItem('pii.isFormUpdate')
  })
  console.log('IS form update props', { con: props.isFormUpdate })*/
  function validateFields (fields, updatedStatus = undefined) {
    let newStatus = { ...status }
    fields.forEach(value => {
      switch (value) {
        case 'ssn':
          if (updatedStatus === undefined) {
            if (ssn !== '') newStatus[value] = true
            else newStatus[value] = false
          } else {
            newStatus[value] = updatedStatus
          }
          break
        case 'accountNumber':
          if (updatedStatus === undefined) {
            if (accountNumber !== '' && accountNumber !== '')
              newStatus[value] = true
            else newStatus[value] = false
          } else {
            newStatus[value] = updatedStatus
          }
          break
        case 'ibanNumber':
          console.log('iban number - validate fields')
          if (updatedStatus === undefined) {
            if (ibanNumber !== '' && accountNumber !== '')
              newStatus[value] = true
            else newStatus[value] = false
          } else {
            newStatus[value] = updatedStatus
          }
          break
        case 'bankName':
          //console.info('bank name new status', { con: updatedStatus })
          if (updatedStatus === undefined) {
            if (bankName !== '') newStatus[value] = true
            else newStatus[value] = false
          } else {
            newStatus[value] = updatedStatus
          }

          break
        case 'swiftCode':
          if (updatedStatus === undefined) {
            if (swiftCode !== '') newStatus[value] = true
            else newStatus[value] = false
          } else {
            newStatus[value] = updatedStatus
          }

          break
        case 'routingNumber':
          if (updatedStatus === undefined) {
            if (routingNumber !== '' && routingNumber.length + 1 === 9)
              newStatus[value] = true
            else newStatus[value] = false
          } else {
            newStatus[value] = updatedStatus
          }

          break
        case 'name':
          if (updatedStatus === undefined) {
            if (name !== '') newStatus[value] = true
            else newStatus[value] = false
          } else {
            newStatus[value] = updatedStatus
          }
          break
        case 'file':
          if (file !== undefined) {
            newStatus[value] = false
          } else {
            newStatus[value] = true
          }
          break
        default:
          break
      }
    })
    // console.log('fields status', { con: newStatus })
    setStatus(newStatus)
  }
  function resetFields (validation) {
    let resetFileStatus = { ...validationsStatus }
    switch (validation) {
      case piiValidationTypes.bank:
        //clear banks section
        setAccountNumber('')
        setBankName('')
        setRoutingNumber('')
        setSwiftCode('')
        setIbanNumber('')
        //form status
        setFormStatus(false)
        //status inputs
        setStatus({
          name: false,
          ssn: false,
          accountNumber: false,
          ibanNumber: false,
          bankName: false,
          swiftCode: false,
          routingNumber: false,
          validateData: false,
          file: false
        })
        setValidate(false)
        setValidate2(false)
        setValidationStatus({
          [piiValidationTypes.bank]: false,
          [piiValidationTypes.tin]: false,
          [piiValidationTypes.file]: false
        })
        break
      case piiValidationTypes.tin:
        //clear tax section
        resetFileStatus[piiValidationTypes.file] = false
        setValidationStatus(resetFileStatus)
        setValidate(false)
        setValidate2(false)
        setUploadFile(false)
        setShowOtherValues(false)
        setOtherValues(undefined)
        hideLoading(setFileIsLoading)
        setHideUploadFile(false)
        setFile(undefined)
        setFileMessage('Please select a file')
        setName('')
        setSSN('')
        setFormDataErrorValues(false)
        setFormDataErrorMessage('')
        //form status
        setFormStatus(false)
        //status inputs
        setStatus({
          name: false,
          ssn: false,
          accountNumber: false,
          ibanNumber: false,
          bankName: false,
          swiftCode: false,
          routingNumber: false,
          validateData: false,
          file: false
        })
        setValidationStatus({
          [piiValidationTypes.bank]: false,
          [piiValidationTypes.tin]: false,
          [piiValidationTypes.file]: false
        })
        break
      default:
        //clear tax section
        setValidationStatus({
          [piiValidationTypes.bank]: false,
          [piiValidationTypes.tin]: false,
          [piiValidationTypes.file]: false
        })
        setValidate(false)
        setValidate2(false)
        setUploadFile(false)
        setShowOtherValues(false)
        setOtherValues(undefined)
        hideLoading(setFileIsLoading)
        setHideUploadFile(false)
        setFile(undefined)
        setFileMessage('Please select a file')
        setName('')
        setSSN('')
        //clear banks section
        setAccountNumber('')
        setBankName('')
        setRoutingNumber('')
        setSwiftCode('')
        setIbanNumber('')
        setFormDataErrorValues(false)
        setFormDataErrorMessage('')
        //form status
        setFormStatus(false)
        //status inputs
        setStatus({
          name: false,
          ssn: false,
          accountNumber: false,
          ibanNumber: false,
          bankName: false,
          swiftCode: false,
          routingNumber: false,
          validateData: false,
          file: false
        })
        break
    }
  }
  function resetCurrentFile () {
    setUploadFile(false)
    setShowOtherValues(false)
    setOtherValues(undefined)
    hideLoading(setFileIsLoading)
    setHideUploadFile(false)
    setFile(undefined)
    setFileMessage('Please select a file')
    setFormDataErrorValues(false)
    setFormDataErrorMessage('')
  }
  function setFileUploaded (files) {
    setFile([...files])
    if (files && files[0]) {
      setFileMessage(files[0].name)
    }
  }
  function showTinFileOtherValues (e) {
    e.preventDefault()
    setShowOtherValues(true)
  }
  function thanksPage () {
    return (
      <div
        className={classes.container}
        style={{ paddingTop: '20%', paddingBottom: '15%' }}
      >
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={8}>
            <Card className={classes.card}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
                style={{ paddingTop: '0%' }}
              >
                <h3 className={classes.cardTitle}>
                  Sensitive Data Entry and Verification Form
                </h3>
              </CardHeader>
              <CardBody>
                <GridContainer className="position:absolute" justify="center">
                  <GridItem xs={12}>
                    <h4 style={{ textAlign: 'center' }}>
                      <strong>{piiMessage}</strong>
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
  async function onSendData () {
    let validated = formStatus
    let response
    // let errorMessage
    if (validated) {
      showLoading()
      setValidationMessage('')
      setValidationMessageTin('')
      setFormDataErrorValues(false)
      setFormDataErrorMessage('')
      //send data to encrypt
      response = await saveSupplierData(tenantId, validationId)
      if (response.success) {
        //local storage
        localStorage.removeItem('pii.tenantId')
        localStorage.removeItem('pii.validationId')
        localStorage.removeItem('pii.email')
        localStorage.removeItem('pii.supplierId')
        localStorage.removeItem('pii.setSupplierName')
        localStorage.removeItem('pii.isFormUpdate')
        localStorage.removeItem('pii.urlToken')
        localStorage.removeItem('pii.formUpdateValidation')
        response = await finalizePiiForm(
          tenantId, //props.tenantId,
          validationId, //props.validationId,
          'Completed',
          'confirmed and sent',
          email, //props.email,
          urlToken
        )
        // console.log('response updated forms', { con: response })
        if (response.success) {
          // setValidationMessage(
          //   'Information successfully encrypted and confirmed.'
          // )
          setValidateData(false)
        }
      } else {
        // errorMessage = validationMessage + response.message
        setValidationMessage(response.message)
      }
      hideLoading()
    }
  }

  async function processFile (tenantId, validationId) {
    if (file && file.length !== 0) {
      let newValidationStatus = { ...validationsStatus }
      newValidationStatus[piiValidationTypes.file] = true
      let newStatus = { ...status }
      newStatus.file = true

      showLoading()
      const response = await processPIIFile(
        tenantId,
        validationId,
        file[0],
        urlToken
      )
      if (response.success) {
        setHideUploadFile(true)
        showLoading(setFileIsLoading)
        setValidationStatus(newValidationStatus)
        setStatus(newStatus)
        setTimeout(() => {
          getFileProcessStatus(response.fileId, 1)
        }, timerReviewFileProcess)
      } else {
        setFileMessage('An error ocurrs, please try again')
      }

      hideLoading()
    }
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  async function getFileProcessStatus (fileId, fileRetryCount) {
    const response = await getFormProcessSatus(fileId, tenantId, urlToken)
    if (response.success) {
      //console.log('status completed')
      setSSN(response.ssn)
      setName(response.name)
      setUploadFile(false)
      hideLoading(setFileIsLoading)
      setOtherValues(response.otherValues)
      if (response.ssn.length === 0 && response.name.length === 0) {
        setFileIsLoading(false)
        setFormDataErrorValues(true)
        setFormDataErrorMessage(
          'No information was found for the processed file.'
        )
      }
    } else {
      fileRetryCount += 1
      if (fileRetryCount <= maxRetriesFileProcess) {
        setTimeout(() => {
          getFileProcessStatus(fileId, fileRetryCount)
        }, timerReviewFileProcess)
      } else {
        console.log('process could not be verified')
        setSSN('')
        setName('')
        setUploadFile(false)
        setFormDataErrorValues(true)
        setFormDataErrorMessage(
          'No information was found for the processed file.'
        )
        setFileIsLoading(false)
      }
    }
  }

  async function saveSupplierData (tenantId, validationId) {
    let response
    let updatedValidation = ''
    /*console.log('save supplier data', {
      isFormUpdate: isFormUpdate,
      updatedValidationName: formUpdateValidation
    })*/
    if (isFormUpdate && isFormUpdate === 'true')
      updatedValidation = formUpdateValidation
    response = await saveSupplierInformation(
      tenantId,
      validationId,
      updatedValidation,
      name,
      ssn,
      accountNumber,
      bankName,
      swiftCode,
      routingNumber,
      country,
      ibanNumber,
      urlToken
    )
    return response
  }
  function updateValidationStatus (validation, newStat) {
    //update validations status
    let newValidationStatus = { ...validationsStatus }
    newValidationStatus[validation] = newStat

    // console.log('validation status result', { con: newValidationStatus })
    setValidationStatus(newValidationStatus)
    //update form status
    let validated = newStat
    let validationTypes = [piiValidationTypes.bank, piiValidationTypes.tin]
    validationTypes.forEach(val => {
      validated = validated && newValidationStatus[val]
    })
    if (isFormUpdate && isFormUpdate === 'true' && newStat) {
      // console.log(`validation ${validation} is update`, { validated: newStat })
      validated = true
    }
    setFormStatus(validated)
    //update validation fields
    let fields = []
    switch (validation) {
      case piiValidationTypes.bank:
        let bankFields = []
        bankFields.push('bankName')
        bankFields.push('routingNumber')
        if (swiftCode.length > 0) bankFields.push('swiftCode')
        if (accountNumber.length > 0) bankFields.push('accountNumber')
        if (ibanNumber.length > 0) bankFields.push('ibanNumber')
        fields = bankFields
        break
      case piiValidationTypes.tin:
        fields = ['ssn', 'name']
        break
      default:
        break
    }
    // console.log('fields used new status', { con: newStat })

    validateFields(fields, newStat)
  }
  //block supplier after max retries
  async function blockSupplier () {
    // console.log('block user')

    let message = ''
    //request to block Pii Supplier
    showLoading()
    await blockPIISupplier(tenantId, validationId, urlToken)
    //test
    //response = await blockPIISupplier('nitorDevTest-PIITest', 'TSK3160419884')
    hideLoading()
    //console.log('block user response', { context: response })
    message = 'You’ve reached the maximum attempts.'
    //setValidationMessage(message)
    setPiiMessage(message)
    //setValidationMessageTin(message)
    setValidateData(false)
  }
  async function validatePiiData (validationName) {
    let response
    let dataAnswer = []
    let answer
    let validated = false
    let validData = false
    let message = ''
    let bankCount = bankRetryCount
    let tinCount = tinRetryCount
    let attemptCount = 0
    //console.log('Validate Pii Data')
    if (bankCount >= maxRetries || tinCount >= maxRetries) {
      await blockSupplier()
      return
    } else {
      //build data
      switch (validationName) {
        case piiValidationTypes.bank:
          attemptCount = bankCount
          if (bankName.length === 0) {
            validateFields(['bankName'])
            //   console.log('invalid bank name')
            return
          }
          if (routingNumber.length !== 9 || routingNumber.length < 9) {
            validateFields(['routingNumber'])
          } else validData = true
          if (accountNumber.length === 0 && ibanNumber.length === 0) {
            validateFields(['accountNumber', 'ibanNumber'])
            return
          }
          if (swiftCode.length === 0) {
            validateFields(['swiftCode'])
            if (!validData) {
              //    console.log('missing data')
              return
            }
          }
          //bank country
          if (country !== '') {
            answer = {
              id: 'bankCountry',
              answer: country
            }
            dataAnswer.push(answer)
          }
          //bank name
          if (bankName !== '') {
            answer = {
              id: 'bankName',
              answer: bankName
            }
            dataAnswer.push(answer)
          }
          //bank routing number
          if (routingNumber !== '') {
            answer = {
              id: 'routingNumber',
              answer: routingNumber
            }
            dataAnswer.push(answer)
          }
          //bank swift code
          if (swiftCode !== '') {
            answer = {
              id: 'swiftCode',
              answer: swiftCode
            }
            dataAnswer.push(answer)
          }
          break
        case piiValidationTypes.tin:
          setHideUploadFile(true)
          attemptCount = tinCount
          if (name.length === 0) {
            validateFields(['name'])
            return
          }
          if (ssn.length === 0) {
            validateFields(['ssn'])
            return
          }
          //country
          if (country !== '' && country === 'United States') {
            answer = {
              id: 'country',
              answer: 'US'
            }
            dataAnswer.push(answer)
          }
          //legal name
          if (name !== '') {
            answer = {
              id: 'supplierName',
              answer: name
            }
            dataAnswer.push(answer)
          }
          //tax
          if (ssn !== '') {
            answer = {
              id: 'tax',
              answer: ssn
            }
            dataAnswer.push(answer)
          }
          break
        default:
          break
      }
      showLoading()
      setValidationMessage('')
      setValidationMessageTin('')
      setFormDataErrorValues(false)
      setFormDataErrorMessage('')
      response = await executeValidation(
        validationName,
        tenantId,
        validationId,
        attemptCount,
        dataAnswer,
        urlToken
      )
      //test
      // response = await executeValidation(
      //   validationName,
      //   'nitorDevTest-PIITest',
      //   'TSK3160419884',
      //   dataAnswer
      // )
      hideLoading()
      //console.log('response res', { con: response })
      if (response && response.data) {
        if (response.data.result.errorsCount > 0) {
          if (validationName === piiValidationTypes.bank) {
            bankCount++
            setBankRetryCount(bankCount)
            if (response.data.result.message)
              message = response.data.result.message
            else message = `Invalid banking data`
            setValidationMessage(message)
          } else {
            if (validationName === piiValidationTypes.tin) {
              tinCount++
              setTinRetryCount(tinCount)
              message =
                'Error message: - No IRS Match found. TIN and Name combination does not match IRS records  IRS EIN/Name'
              setValidationMessageTin(message)
            }
          }
        } else {
          //  console.log(`success validation ${validationName}`)
          validated = true
        }
        //console.log('sending new validation result', { con: validated })
        updateValidationStatus(validationName, validated)
      }
    }
  }
  function returnForm () {
    // console.log(props)
    return (
      <div className={classes.container} style={{ paddingTop: '0%' }}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={8}>
            {!isFormUpdate ||
            isFormUpdate === 'false' ||
            (isFormUpdate &&
              isFormUpdate === 'true' &&
              formUpdateValidation === piiValidationUpdateOptions.all) ? (
              <Card className={classes.card}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                  style={{ paddingTop: '0%' }}
                >
                  <h3 className={classes.cardTitle}>
                    Sensitive Data Entry and Verification Form
                  </h3>
                </CardHeader>
                <CardBody>
                  <GridContainer className="position:absolute" justify="center">
                    <GridItem xs={12}>
                      <h5 style={{ textAlign: 'center' }}>
                        <strong>
                          For Supplier: {supplierName} ({supplierId})
                        </strong>
                      </h5>
                    </GridItem>
                    <GridItem xs={12}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Reset section data.'}>
                          <Button
                            color={'primary'}
                            onClick={() => {
                              resetFields('')
                            }}
                            className={classes.documentation}
                          >
                            Reset
                          </Button>
                        </Tooltip>
                      </div>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Upload new form.'}>
                          <Button
                            color={'primary'}
                            disabled={fileIsLoading}
                            onClick={() => {
                              resetCurrentFile()
                            }}
                            className={classes.documentation}
                          >
                            UPLOAD FORM
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                    <GridItem xs={12}>
                      <h6>
                        <strong>Personal Tax ID Info</strong>
                      </h6>
                    </GridItem>
                    <GridItem xs={12} className="padding-bottom:5px">
                      <h7
                        style={{
                          color: 'red',
                          hidden: validationsStatus[piiValidationTypes.tin]
                        }}
                      >
                        <strong>{validationMessageTin}</strong>
                      </h7>
                      <h7
                        style={{
                          color: 'red',
                          hidden: !formDataErrorValues
                        }}
                      >
                        <strong>{formDataErrorMessage}</strong>
                      </h7>
                    </GridItem>
                    <GridItem xs={12} hidden={hideUploadFile}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={uploadFile}
                            onChange={event =>
                              setUploadFile(event.target.checked)
                            }
                            classes={{
                              switchBase: buttonClases.switchBase,
                              checked: buttonClases.switchChecked,
                              thumb: buttonClases.switchIcon,
                              track: buttonClases.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: buttonClases.label
                        }}
                        label="Upload W8/W9 Form"
                        labelPlacement="start"
                      />
                    </GridItem>
                    <GridItem xs={12} hidden={uploadFile}>
                      <CustomInput
                        labelText={'Name*'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        required
                        inputProps={{
                          type: 'text',
                          value: name,
                          onChange: event => {
                            setName(event.target.value)
                            validateFields(['ssn', 'name'])
                          },
                          endAdornment: !validate2 ? (
                            ''
                          ) : status.name ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem></GridItem>
                    <GridItem xs={12} hidden={uploadFile}>
                      <CustomInput
                        labelText={'Social Security Number*'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="ssn"
                        formControlProps={{
                          fullWidth: true
                        }}
                        required
                        inputProps={{
                          type: showPassword ? 'text' : 'password',
                          value: ssn,
                          onChange: event => {
                            setSSN(event.target.value)
                            validateFields(['ssn', 'name'])
                          },
                          endAdornment: !validate2 ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ) : status.ssn ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              <CheckCircleOutlinedIcon
                                style={{ color: 'green' }}
                              />
                            </InputAdornment>
                          ) : (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              <ClearOutlinedIcon style={{ color: 'red' }} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} hidden={!uploadFile}>
                      {!validate2 && fileMessage !== 'Please select a file' ? (
                        <div
                          style={{
                            display: 'inline-flex'
                          }}
                        >
                          <AttachFileOutlinedIcon
                            style={{ color: 'primary' }}
                          />
                          {fileMessage}
                        </div>
                      ) : status.file ? (
                        ''
                      ) : (
                        <div
                          style={{
                            display: 'inline-flex'
                          }}
                        >
                          <ClearOutlinedIcon style={{ color: 'red' }} />
                          {fileMessage}
                        </div>
                      )}
                    </GridItem>
                    <GridItem xs={12} hidden={!uploadFile}>
                      <GridContainer justify="center">
                        {fileIsLoading ? (
                          <div
                            className={classes.reloadSize}
                            style={{ alignItems: 'center' }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <div style={{ width: '50%' }}>
                            <DropzoneArea
                              acceptedFiles={['.pdf']}
                              dropzoneText={
                                'Drag and drop your form here or click.'
                              }
                              onChange={files => {
                                setFileUploaded(files)
                                setValidate2(false)
                              }}
                              onDelete={deleted => {
                                setValidate2(false)
                                let newStatus = { ...status }
                                newStatus.file = false
                                setStatus(newStatus)
                                setFileMessage('Please select a file')
                              }}
                              filesLimit={1}
                              showPreviews={false}
                              showPreviewsInDropzone={true}
                              showFileNamesInPreview={true}
                            />
                          </div>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} hidden={!uploadFile}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Click to process file '}>
                          <Button
                            color={
                              !validate2
                                ? //? formStatusTin

                                  'primary'
                                : validationsStatus[piiValidationTypes.file]
                                ? 'info'
                                : 'danger'
                            }
                            onClick={() => {
                              validateFields(['file'])
                              processFile(tenantId, validationId)
                              setValidate2(true)
                            }}
                            round
                            className={classes.documentation}
                            disabled={
                              validate2 &&
                              validationsStatus[piiValidationTypes.file]
                            }
                          >
                            {!validate2 ? (
                              //formStatusTin ? (

                              'Click To Process File'
                            ) : validate2 ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.file] ? (
                                <div>
                                  <CheckBox className={classes.icons} /> File
                                  Processing
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Process File
                                </div>
                              )
                            ) : (
                              <div>...</div>
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                    <GridItem xs={6} hidden={!otherValues}>
                      <div className={wizardClasses.left}>
                        <Tooltip title={'Click to show other fields found'}>
                          {/* <Button
                            color={'primary'}
                            onClick={() => {
                              setShowOtherValues(true)
                            }}
                            round
                            className={classes.documentation}
                          >
                            Click To show other fields found
                          </Button> */}
                          <a href="# " onClick={showTinFileOtherValues}>
                            Click To show other fields found
                          </a>
                        </Tooltip>
                      </div>
                    </GridItem>
                    <GridItem xs={otherValues ? 6 : 12} hidden={uploadFile}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Click to Validate '}>
                          <Button
                            color={
                              !validate2
                                ? //? formStatusTin
                                  validationsStatus[piiValidationTypes.tin]
                                  ? 'info'
                                  : 'danger'
                                : validationsStatus[piiValidationTypes.tin]
                                ? 'info'
                                : 'danger'
                            }
                            onClick={() => {
                              validateFields(['ssn', 'name'])
                              validatePiiData(piiValidationTypes.tin)
                              setValidate2(true)
                            }}
                            round
                            className={classes.documentation}
                          >
                            {!validate2 ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.tin] ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Validated
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Validate
                                </div>
                              )
                            ) : validate2 ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.tin] ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Validated
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Validate
                                </div>
                              )
                            ) : (
                              <div>...</div>
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                    <GridItem xs={12} className="padding-bottom:5px">
                      <h6>
                        <strong>Banking Details</strong>
                      </h6>
                    </GridItem>
                    <GridItem xs={12} className="padding-bottom:5px">
                      <h7
                        style={{
                          color: 'red',
                          hidden: validationsStatus[piiValidationTypes.bank]
                        }}
                      >
                        <strong>{validationMessage}</strong>
                      </h7>
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={'Account Number'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="accountNumber"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          value: accountNumber,
                          onChange: event => {
                            setAccountNumber(event.target.value)
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['accountNumber'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (accountNumber.length === 0 &&
                              ibanNumber.length === 0) ||
                            (!status['accountNumber'] &&
                              accountNumber.length > 0) ? (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={'IBAN Number'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="ibanNumber"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          value: ibanNumber,
                          onChange: event => {
                            setIbanNumber(event.target.value)
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['ibanNumber'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (accountNumber.length === 0 &&
                              ibanNumber.length === 0) ||
                            (!status['ibanNumber'] && ibanNumber.length > 0) ? (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={'Bank Name*'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="bankName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        required
                        inputProps={{
                          type: 'text',
                          value: bankName,
                          onChange: event => {
                            setBankName(event.target.value)
                            validateFields(['bankName'])
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['bankName'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={'Routing Number*'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="routingNumber"
                        formControlProps={{
                          fullWidth: true
                        }}
                        required
                        inputProps={{
                          type: 'text',
                          value: routingNumber,
                          onChange: event => {
                            setRoutingNumber(event.target.value)
                            validateFields([
                              'routingNumber',
                              'bankName',
                              'accountNumber',
                              'ibanNumber',
                              'swiftCode'
                            ])
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['routingNumber'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'SWIFT code'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="swiftCode"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          value: swiftCode,
                          onChange: event => {
                            setSwiftCode(event.target.value)
                            validateFields([
                              'routingNumber',
                              'bankName',
                              'accountNumber',
                              'ibanNumber',
                              'swiftCode'
                            ])
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['swiftCode'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : !status['swiftCode'] && swiftCode.length > 0 ? (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <InputLabel
                        className={classes.labelRoot}
                        htmlFor="fieldType"
                      >
                        Bank's Country
                      </InputLabel>
                      <Select
                        MenuProps={{
                          style: { zIndex: 5555 }
                        }}
                        style={{ width: '100%' }}
                        value={country}
                        onChange={e => setCountry(e.target.value)}
                        inputProps={{
                          name: 'stepId',
                          id: 'fieldType'
                        }}
                      >
                        {countryListOptions.map(value => {
                          return (
                            <MenuItem value={value.name} key={value.name}>
                              {value.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </GridItem>
                    <GridItem xs={12}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Click to Validate '}>
                          <Button
                            color={
                              !validate
                                ? //? formStatusTin
                                  validationsStatus[piiValidationTypes.bank]
                                  ? 'info'
                                  : 'danger'
                                : validationsStatus[piiValidationTypes.bank]
                                ? 'info'
                                : 'danger'
                            }
                            onClick={() => {
                              validateFields([
                                'routingNumber',
                                'bankName',
                                'accountNumber',
                                'ibanNumber',
                                'swiftCode'
                              ])
                              validatePiiData(piiValidationTypes.bank)
                              setValidate(true)
                            }}
                            round
                            className={classes.documentation}
                          >
                            {!validate ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.bank] ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Validated
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Validate
                                </div>
                              )
                            ) : validate ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.bank] ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Validated
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Validate
                                </div>
                              )
                            ) : (
                              <div>...</div>
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <div className={wizardClasses.footer}>
                  <div className={wizardClasses.right}>
                    <Tooltip title={'Click to Validate '}>
                      <Button
                        color="primary"
                        disabled={!formStatus}
                        onClick={() => {
                          onSendData()
                        }}
                        round
                        className={classes.documentation}
                      >
                        Click to encrypt and send data
                      </Button>
                    </Tooltip>
                  </div>
                  <div className={wizardClasses.clearfix} />
                </div>
              </Card>
            ) : isFormUpdate &&
              isFormUpdate === 'true' &&
              formUpdateValidation === piiValidationUpdateOptions.bank ? (
              <Card className={classes.card}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                  style={{ paddingTop: '0%' }}
                >
                  <h3 className={classes.cardTitle}>
                    Sensitive Data Entry and Verification Form
                  </h3>
                </CardHeader>
                <CardBody>
                  <GridContainer className="position:absolute" justify="center">
                    <GridItem xs={12}>
                      <h5 style={{ textAlign: 'center' }}>
                        <strong>
                          For Supplier: {supplierName} ({supplierId})
                        </strong>
                      </h5>
                    </GridItem>
                    <GridItem xs={12}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Reset section data.'}>
                          <Button
                            color={'primary'}
                            onClick={() => {
                              resetFields(piiValidationTypes.bank)
                            }}
                            className={classes.documentation}
                          >
                            Reset
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                    <GridItem xs={12} className="padding-bottom:5px">
                      <h6>
                        <strong>Banking Details</strong>
                      </h6>
                    </GridItem>
                    <GridItem xs={12} className="padding-bottom:5px">
                      <h7
                        style={{
                          color: 'red',
                          hidden: validationsStatus[piiValidationTypes.bank]
                        }}
                      >
                        <strong>{validationMessage}</strong>
                      </h7>
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={'Account Number'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="accountNumber"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          value: accountNumber,
                          onChange: event => {
                            setAccountNumber(event.target.value)
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['accountNumber'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (accountNumber.length === 0 &&
                              ibanNumber.length === 0) ||
                            (!status['accountNumber'] &&
                              accountNumber.length > 0) ? (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={'IBAN Number'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="ibanNumber"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          value: ibanNumber,
                          onChange: event => {
                            setIbanNumber(event.target.value)
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['ibanNumber'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (accountNumber.length === 0 &&
                              ibanNumber.length === 0) ||
                            (!status['ibanNumber'] && ibanNumber.length > 0) ? (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={'Bank Name*'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="bankName"
                        formControlProps={{
                          fullWidth: true
                        }}
                        required
                        inputProps={{
                          type: 'text',
                          value: bankName,
                          onChange: event => {
                            setBankName(event.target.value)
                            validateFields(['bankName'])
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['bankName'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={'Routing Number*'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="routingNumber"
                        formControlProps={{
                          fullWidth: true
                        }}
                        required
                        inputProps={{
                          type: 'text',
                          value: routingNumber,
                          onChange: event => {
                            setRoutingNumber(event.target.value)
                            validateFields([
                              'routingNumber',
                              'bankName',
                              'accountNumber',
                              'swiftCode'
                            ])
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['routingNumber'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={'SWIFT code'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="swiftCode"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: 'text',
                          value: swiftCode,
                          onChange: event => {
                            setSwiftCode(event.target.value)
                            validateFields([
                              'routingNumber',
                              'bankName',
                              'accountNumber',
                              'swiftCode'
                            ])
                          },
                          endAdornment: !validate ? (
                            ''
                          ) : status['swiftCode'] ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : !status['swiftCode'] && swiftCode.length > 0 ? (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          ) : (
                            ''
                          )
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12}>
                      <InputLabel
                        className={classes.labelRoot}
                        htmlFor="fieldType"
                      >
                        Bank's Country
                      </InputLabel>
                      <Select
                        MenuProps={{
                          style: { zIndex: 5555 }
                        }}
                        style={{ width: '100%' }}
                        value={country}
                        onChange={e => setCountry(e.target.value)}
                        inputProps={{
                          name: 'stepId',
                          id: 'fieldType'
                        }}
                      >
                        {countryListOptions.map(value => {
                          return (
                            <MenuItem value={value.name} key={value.name}>
                              {value.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </GridItem>
                    <GridItem xs={12}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Click to Validate '}>
                          <Button
                            color={
                              !validate
                                ? //? formStatusTin
                                  validationsStatus[piiValidationTypes.bank]
                                  ? 'info'
                                  : 'danger'
                                : validationsStatus[piiValidationTypes.bank]
                                ? 'info'
                                : 'danger'
                            }
                            onClick={() => {
                              validateFields([
                                'routingNumber',
                                'bankName',
                                'accountNumber',
                                'ibanNumber',
                                'swiftCode'
                              ])
                              validatePiiData(piiValidationTypes.bank)
                              setValidate(true)
                            }}
                            round
                            className={classes.documentation}
                          >
                            {!validate ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.bank] ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Validated
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Validate
                                </div>
                              )
                            ) : validate ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.bank] ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Validated
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Validate
                                </div>
                              )
                            ) : (
                              <div>...</div>
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <div className={wizardClasses.footer}>
                  <div className={wizardClasses.right}>
                    <Tooltip title={'Click to Validate '}>
                      <Button
                        color="primary"
                        disabled={!formStatus}
                        onClick={() => {
                          onSendData()
                        }}
                        round
                        className={classes.documentation}
                      >
                        Click to encrypt and send data
                      </Button>
                    </Tooltip>
                  </div>
                  <div className={wizardClasses.clearfix} />
                </div>
              </Card>
            ) : (
              <Card className={classes.card}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="primary"
                  style={{ paddingTop: '0%' }}
                >
                  <h3 className={classes.cardTitle}>
                    Sensitive Data Entry and Verification Form
                  </h3>
                </CardHeader>
                <CardBody>
                  <GridContainer className="position:absolute" justify="center">
                    <GridItem xs={12}>
                      <h5 style={{ textAlign: 'center' }}>
                        <strong>
                          For Supplier: {supplierName} ({supplierId})
                        </strong>
                      </h5>
                    </GridItem>
                    <GridItem xs={12}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Reset section data.'}>
                          <Button
                            color={'primary'}
                            onClick={() => {
                              resetFields(piiValidationTypes.tin)
                            }}
                            className={classes.documentation}
                          >
                            Reset
                          </Button>
                        </Tooltip>
                      </div>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Upload new form.'}>
                          <Button
                            color={'primary'}
                            disabled={fileIsLoading}
                            onClick={() => {
                              resetCurrentFile()
                            }}
                            className={classes.documentation}
                          >
                            UPLOAD FORM
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                    <GridItem xs={12}>
                      <h6>
                        <strong>Personal Tax ID Info</strong>
                      </h6>
                    </GridItem>
                    <GridItem xs={12} className="padding-bottom:5px">
                      <h7
                        style={{
                          color: 'red',
                          hidden: validationsStatus[piiValidationTypes.tin]
                        }}
                      >
                        <strong>{validationMessageTin}</strong>
                      </h7>
                      <h7
                        style={{
                          color: 'red',
                          hidden: !formDataErrorValues
                        }}
                      >
                        <strong>{formDataErrorMessage}</strong>
                      </h7>
                    </GridItem>
                    <GridItem xs={12} hidden={hideUploadFile}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={uploadFile}
                            onChange={event =>
                              setUploadFile(event.target.checked)
                            }
                            classes={{
                              switchBase: buttonClases.switchBase,
                              checked: buttonClases.switchChecked,
                              thumb: buttonClases.switchIcon,
                              track: buttonClases.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: buttonClases.label
                        }}
                        label="Upload W8/W9 Form"
                        labelPlacement="start"
                      />
                    </GridItem>
                    <GridItem xs={12} hidden={uploadFile}>
                      <CustomInput
                        labelText={'Name*'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        required
                        inputProps={{
                          type: 'text',
                          value: name,
                          onChange: event => {
                            setName(event.target.value)
                            validateFields(['ssn', 'name'])
                          },
                          endAdornment: !validate2 ? (
                            ''
                          ) : status.name ? (
                            <CheckCircleOutlinedIcon
                              style={{ color: 'green' }}
                            />
                          ) : (
                            <ClearOutlinedIcon style={{ color: 'red' }} />
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem></GridItem>
                    <GridItem xs={12} hidden={uploadFile}>
                      <CustomInput
                        labelText={'Social Security Number*'}
                        labelProps={{
                          style: { width: '100%', float: 'left' }
                        }}
                        id="ssn"
                        formControlProps={{
                          fullWidth: true
                        }}
                        required
                        inputProps={{
                          type: showPassword ? 'text' : 'password',
                          value: ssn,
                          onChange: event => {
                            setSSN(event.target.value)
                            validateFields(['ssn', 'name'])
                          },
                          endAdornment: !validate2 ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ) : status.ssn ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              <CheckCircleOutlinedIcon
                                style={{ color: 'green' }}
                              />
                            </InputAdornment>
                          ) : (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                              <ClearOutlinedIcon style={{ color: 'red' }} />
                            </InputAdornment>
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} hidden={!uploadFile}>
                      {!validate2 && fileMessage !== 'Please select a file' ? (
                        <div
                          style={{
                            display: 'inline-flex'
                          }}
                        >
                          <AttachFileOutlinedIcon
                            style={{ color: 'primary' }}
                          />
                          {fileMessage}
                        </div>
                      ) : status.file ? (
                        ''
                      ) : (
                        <div
                          style={{
                            display: 'inline-flex'
                          }}
                        >
                          <ClearOutlinedIcon style={{ color: 'red' }} />
                          {fileMessage}
                        </div>
                      )}
                    </GridItem>
                    <GridItem xs={12} hidden={!uploadFile}>
                      <GridContainer justify="center">
                        {fileIsLoading ? (
                          <div
                            className={classes.reloadSize}
                            style={{ alignItems: 'center' }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                          <div style={{ width: '50%' }}>
                            <DropzoneArea
                              acceptedFiles={['.pdf']}
                              dropzoneText={
                                'Drag and drop your form here or click.'
                              }
                              onChange={files => {
                                setFileUploaded(files)
                                setValidate2(false)
                              }}
                              onDelete={deleted => {
                                setValidate2(false)
                                let newStatus = { ...status }
                                newStatus.file = false
                                setStatus(newStatus)
                                setFileMessage('Please select a file')
                              }}
                              filesLimit={1}
                              showPreviews={false}
                              showPreviewsInDropzone={true}
                              showFileNamesInPreview={true}
                            />
                          </div>
                        )}
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} hidden={!uploadFile}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Click to process file '}>
                          <Button
                            color={
                              !validate2
                                ? //? formStatusTin

                                  'primary'
                                : validationsStatus[piiValidationTypes.file]
                                ? 'info'
                                : 'danger'
                            }
                            onClick={() => {
                              validateFields(['file'])
                              processFile(tenantId, validationId)
                              setValidate2(true)
                            }}
                            round
                            className={classes.documentation}
                            disabled={
                              validate2 &&
                              validationsStatus[piiValidationTypes.file]
                            }
                          >
                            {!validate2 ? (
                              //formStatusTin ? (

                              'Click To Process File'
                            ) : validate2 ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.file] ? (
                                <div>
                                  <CheckBox className={classes.icons} /> File
                                  Processing
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Process File
                                </div>
                              )
                            ) : (
                              <div>...</div>
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                    <GridItem xs={6} hidden={!otherValues}>
                      <div className={wizardClasses.left}>
                        <Tooltip title={'Click to show other fields found'}>
                          {/* <Button
                            color={'primary'}
                            onClick={() => {
                              setShowOtherValues(true)
                            }}
                            round
                            className={classes.documentation}
                          >
                            Click To show other fields found
                          </Button> */}
                          <a href="# " onClick={showTinFileOtherValues}>
                            Click To show other fields found
                          </a>
                        </Tooltip>
                      </div>
                    </GridItem>
                    <GridItem xs={otherValues ? 6 : 12} hidden={uploadFile}>
                      <div className={wizardClasses.right}>
                        <Tooltip title={'Click to Validate '}>
                          <Button
                            color={
                              !validate2
                                ? //? formStatusTin
                                  validationsStatus[piiValidationTypes.tin]
                                  ? 'info'
                                  : 'danger'
                                : validationsStatus[piiValidationTypes.tin]
                                ? 'info'
                                : 'danger'
                            }
                            onClick={() => {
                              validateFields(['ssn', 'name'])
                              validatePiiData(piiValidationTypes.tin)
                              setValidate2(true)
                            }}
                            round
                            className={classes.documentation}
                          >
                            {!validate2 ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.tin] ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Validated
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Validate
                                </div>
                              )
                            ) : validate2 ? (
                              //formStatusTin ? (
                              validationsStatus[piiValidationTypes.tin] ? (
                                <div>
                                  <CheckBox className={classes.icons} />{' '}
                                  Validated
                                </div>
                              ) : (
                                <div>
                                  <Cancel className={classes.icons} />
                                  Click To Validate
                                </div>
                              )
                            ) : (
                              <div>...</div>
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <div className={wizardClasses.footer}>
                  <div className={wizardClasses.right}>
                    <Tooltip title={'Click to Validate '}>
                      <Button
                        color="primary"
                        disabled={!formStatus}
                        onClick={() => {
                          onSendData()
                        }}
                        round
                        className={classes.documentation}
                      >
                        Click to encrypt and send data
                      </Button>
                    </Tooltip>
                  </div>
                  <div className={wizardClasses.clearfix} />
                </div>
              </Card>
            )}
          </GridItem>
          <FieldsDetailsModal
            showModal={showOtherValues}
            onClose={() => setShowOtherValues(false)}
            modalInfo={otherValues}
          />
        </GridContainer>
      </div>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      {validateData ? returnForm() : thanksPage()}
    </Fade>
  )
}
