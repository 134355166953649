import { Button } from '@material-ui/core'
import React, { useState } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { Select, MenuItem } from '@material-ui/core'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import CustomInput from '../../components/CustomInput/CustomInput.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

const useStyles = makeStyles(styles)

export default function Route(props) {
  const [type, setType] = useState(props.route.routeType)
  const [, setShowExtendedFields] = useState(false)
  const [, setQuestionId] = useState('')
  const [, setFieldName] = useState('')
  const [, setRouteAction] = useState(props.route.routeAction)
  const [, setRouteCondition] = useState(props.route.routeCondition)
  const [, setRouteExtendedField] = useState(props.route.routeExtendedField)
  const [, setRouteValue] = useState(props.route.routeValue)
  const [questionChanged, setQuestionChanged] = useState(false)
  const classes = useStyles()
  //Fields
  const [fieldNamesArray, setFieldNamesArray] = useState([])
  //Fields: id,type[generic,extended,others...],typeParameter[ej:extended.street1,...],Variabletype[string,number,boolean],action[skip,validate only],actionCondition[==,!=,Contains,>,<],caseSensitive[true/false],value
  console.log('Route prop', { con: props })
  console.log('route type', { context: type })
  //const typesArray = [{ label: 'generic', value: 'generic' }]
  // const actionsArray = [
  //   { label: 'Skip', value: 'skip' },
  //   { label: 'Validate Only', value: 'validate only' }
  // ]
  /* const conditionsArray = [
    { label: '=', value: '=' },
    { label: '!=', value: '!=' },
    { label: 'Contains', value: 'contains' },
    { label: 'Not Contains', value: 'not contains' },
    { label: '<', value: '<' },
    { label: '>', value: '>' }
  ]
  */
  const validationFields = {
    addressValidation: {
      addressExtendedFields: [
        { label: 'Country', value: 'countryCode' },
        { label: 'Postal code', value: 'streetPostalCode' },
        { label: 'City', value: 'cityName' },
        { label: 'Region', value: 'regionCode' },
        { label: 'Street', value: 'streetName' }
      ],
      addressFields: [
        { label: 'Country', value: 'countryCode' },
        { label: 'Postal code', value: 'postalCode' },
        { label: 'City', value: 'city' },
        { label: 'Region', value: 'state' },
        { label: 'Street', value: 'street' }
      ]
    }
  }
  function getFieldArray(questionId) {
    let selectedField = {}
    let index = 0
    let field
    let kiArray = []
    let key
    for (index = 0; index < props.routerQuestions.length; index++) {
      field = props.routerQuestions[index]
      if (field && field.id === questionId) {
        break
      }
    }

    if (field) {
      selectedField.type = field.type
      for (key of Object.keys(field.values)) {
        kiArray.push(field.values[key].value)
      }
      selectedField.kiArray = kiArray
    }

    console.log('field array', { context: selectedField })
    return selectedField
  }
  return (
    <div style={{ width: '90%', margin: 'auto' }}>
      <GridContainer container spacing={12} style={{ paddingBottom: '5%' }}>
        {/* question: Select the question of which the field name is going to be chosen*/}
        <GridItem xs={12} sm={6}>
          <CardHeader>Question</CardHeader>
          <CardBody>
            <Select
              MenuProps={{
                style: { zIndex: 5555 }
              }}
              style={{ width: '100%' }}
              value={props.route.routeQuestionId}
              onChange={e => {
                setQuestionChanged(true)
                setQuestionId(e.target.value)
                props.route.routeQuestionId = e.target.value
                console.log('selected question', { context: e.target })
                //set the field-names array of the selected question
                let selectedField = getFieldArray(e.target.value)
                setFieldNamesArray(selectedField.kiArray)
                //setFieldNamesArray(Object.keys(e.target.value.values))
                //set type
                setType(selectedField.type)
                props.route.routeType = selectedField.type
                if (selectedField.type.includes('extended')) {
                  setShowExtendedFields(true)
                }
              }}
              inputProps={{
                name: props.route.routeQuestionId,
                id: props.route.routeQuestionId
              }}
            >
              <MenuItem disabled>Select a question</MenuItem>
              {props.routerQuestions.map(question => (
                <MenuItem value={question.id} key={question.id}>
                  {question.id}
                </MenuItem>
              ))}
            </Select>
          </CardBody>
        </GridItem>
        {/* Field-Name : field on which the router is going to be applied. */}
        <GridItem xs={12} sm={6}>
          <CardHeader>Field Name</CardHeader>
          <CardBody>
            <Select
              MenuProps={{
                style: { zIndex: 5555 }
              }}
              style={{ width: '100%' }}
              value={props.route.routeName}
              onChange={e => {
                setFieldName(e.target.value)
                props.route.routeName = e.target.value
                console.log('selected field', { context: e.target.value })
              }}
              inputProps={{
                name: props.route.routeName,
                id: props.route.routeName
              }}
              f
            >
              <MenuItem disabled>Select a field to apply router</MenuItem>
              {props.isEdit && !questionChanged
                ? props.routerEditedQuestions.map(fieldName => (
                    <MenuItem value={fieldName} key={fieldName}>
                      {fieldName}
                    </MenuItem>
                  ))
                : fieldNamesArray.map(fieldName => (
                    <MenuItem value={fieldName} key={fieldName}>
                      {fieldName}
                    </MenuItem>
                  ))}
            </Select>
          </CardBody>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CardHeader>Type</CardHeader>
          <CardBody>
            <Select
              MenuProps={{
                style: { zIndex: 5555 }
              }}
              style={{ width: '100%' }}
              value={props.route.routeType}
              onChange={e => {
                setType(e.target.value)
                props.route.routeType = e.target.value
                if (e.target.value.includes('extended')) {
                  setShowExtendedFields(true)
                }
              }}
              inputProps={{
                name: props.route.routeType,
                id: props.route.routeType
              }}
            >
              <MenuItem
                key="generic"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="generic"
              >
                Generic
              </MenuItem>
              <MenuItem
                key="extendedAddress"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="extendedAddress"
              >
                Extended Address
              </MenuItem>
              <MenuItem
                key="address"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="address"
              >
                Address
              </MenuItem>
            </Select>
          </CardBody>
        </GridItem>
        {type.includes('extended') ||
        props.route.routeType.includes('extended') ? (
          <GridItem xs={12} sm={6}>
            <CardHeader>Extended field</CardHeader>
            <CardBody>
              <Select
                MenuProps={{
                  style: { zIndex: 5555 }
                }}
                style={{ width: '100%' }}
                value={props.route.routeExtendedField}
                onChange={e => {
                  setRouteExtendedField(e.target.value)
                  props.route.routeExtendedField = e.target.value
                }}
                inputProps={{
                  name: props.route.routeExtendedField,
                  id: props.route.routeExtendedField
                }}
              >
                <MenuItem disabled>
                  Select a extended field to apply router
                </MenuItem>
                {validationFields.addressValidation.addressExtendedFields.map(
                  fieldName => (
                    <MenuItem value={fieldName.value} key={fieldName.value}>
                      {fieldName.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </CardBody>
          </GridItem>
        ) : (
          ''
        )}
        {type.includes('address') ||
        props.route.routeType.includes('address') ? (
          <GridItem xs={12} sm={6}>
            <CardHeader>Address field</CardHeader>
            <CardBody>
              <Select
                MenuProps={{
                  style: { zIndex: 5555 }
                }}
                style={{ width: '100%' }}
                value={props.route.routeExtendedField}
                onChange={e => {
                  setRouteExtendedField(e.target.value)
                  props.route.routeExtendedField = e.target.value
                }}
                inputProps={{
                  name: props.route.routeExtendedField,
                  id: props.route.routeExtendedField
                }}
              >
                <MenuItem disabled>Select a field to apply router</MenuItem>
                {validationFields.addressValidation.addressFields.map(
                  fieldName => (
                    <MenuItem value={fieldName.value} key={fieldName.value}>
                      {fieldName.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </CardBody>
          </GridItem>
        ) : (
          ''
        )}
        <GridItem xs={12} sm={6}>
          <CardHeader>Actions</CardHeader>
          <CardBody>
            <Select
              MenuProps={{
                style: { zIndex: 5555 }
              }}
              style={{ width: '100%' }}
              value={props.route.routeAction}
              onChange={e => {
                setRouteAction(e.target.value)
                props.route.routeAction = e.target.value
              }}
              inputProps={{
                name: props.route.routeAction,
                id: props.route.routeAction
              }}
            >
              <MenuItem
                key="skip"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="skip"
              >
                Skip
              </MenuItem>
              <MenuItem
                key="validate only"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="validate only"
              >
                Validate Only
              </MenuItem>
            </Select>
          </CardBody>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CardHeader>Conditions</CardHeader>
          <CardBody>
            <Select
              MenuProps={{
                style: { zIndex: 5555 }
              }}
              style={{ width: '100%' }}
              value={props.route.routeCondition}
              onChange={e => {
                setRouteCondition(e.target.value)
                props.route.routeCondition = e.target.value
              }}
              inputProps={{
                name: props.route.routeCondition,
                id: props.route.routeCondition
              }}
            >
              <MenuItem
                key="="
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="="
              >
                =
              </MenuItem>
              <MenuItem
                key="!="
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="!="
              >
                !=
              </MenuItem>
              <MenuItem
                key="contains"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="contains"
              >
                Contains
              </MenuItem>
              <MenuItem
                key="not contains"
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                style={{
                  overflowX: 'auto',
                  textOverflow: 'ellipsis'
                }}
                value="not contains"
              >
                Not Contains
              </MenuItem>
            </Select>
          </CardBody>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CardHeader>Value</CardHeader>
          <CardBody>
            <CustomInput
              labelProps={{ style: { width: '100%' } }}
              id="Value"
              // formControlProps={{
              //   fullWidth: true
              // }}
              // required
              inputProps={{
                type: 'text',
                value: props.route.routeValue,
                style: { padding: 0 },
                onChange: event => {
                  setRouteValue(event.target.value)
                  props.route.routeValue = event.target.value
                }
              }}
              inputInternalProps={{
                style: { padding: 0 }
              }}
              formControlProps={{ style: { paddingTop: '15px' } }}
            />
          </CardBody>
        </GridItem>
        <GridItem xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log('Route to push', { con: props.route })
              props.onSave(props.route)
            }}
            style={{
              marginTop: '-25px',
              float: 'right',
              marginRight: '1em'
            }}
          >
            {props.isEdit === true ? 'Edit Route' : 'Add Route'}
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  )
}
