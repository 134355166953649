import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import moment from 'moment'

//services
import {
  getInvoiceConfigurationsByApp,
  createInvoiceAppLoad,
  getInvoiceAppLoadByAppAndId,
  editInvoiceAppLoadByAppAndId,
  getInvoiceAppConfigurationByAppAndId
} from 'services/apiApps'
import { checkExpiration } from 'utils/functions'

//Views
import InvoiceLoadView from './InvoiceLoadView'

import { v4 as uuidv4 } from 'uuid'
import { getMasterDataConfigurations } from 'services/apiInvoices'

let _isMounted = false
let appId
let typeApp
let idLoad
moment.locale(window.navigator.userLanguage || window.navigator.language)

const configuration = {
  name: '',
  type: 'historical',
  preferredUsageType: 'sync',
  updateFrequency: 'daily',
  executionTime: moment(new Date()).format('hh:mm a'),
  executionHour: '',
  executionMinute: '',
  executionDay: '',
  startDate: moment(new Date()).format('MM/DD/YYYY'),
  typeApp: '',
  displayMode: 'create',
  enabled: true
}

const connection = {
  remoteConnection: '',
  remoteConnections: '',
  unzip: false,
  fileExtension: '.json',
  path: '',
  typeApp: '',
  displayMode: 'create',
  enabled: true
}

const views = {
  views: [],
  viewsApi: [],
  typeApp: 'default',
  enabled: true
}

async function getAppList(props) {
  let response = await getInvoiceConfigurationsByApp()

  if (response.success) {
    if (_isMounted) {
      props.setAppList(response.data)
    }
  }
}

async function getAppDetails(props, id) {
  const appDetails = await getInvoiceAppConfigurationByAppAndId(id)
  if (appDetails.success) {
    props.setSelectedAppId(id)
    const insightsAppId = appDetails.data.data.insightsAppId
    props.setInsightsAppId(insightsAppId)
    props.setConnections(
      appDetails.data.data.params.remoteConnections
        ? appDetails.data.data.params.remoteConnections
        : []
    )
    const details = {
      connections: appDetails.data.data.params.remoteConnections
        ? appDetails.data.data.params.remoteConnections
        : [],
      insightsAppId: insightsAppId
    }
    return details
  }
}

async function getAppLoad(props, id, idLoad, typeApp, connections, data) {
  props.setFormIsLoading(true)
  const appLoad = await getInvoiceAppLoadByAppAndId('invoiceAI', id, idLoad)
  if (appLoad.success) {
    if (_isMounted) {
      const load = appLoad.data.params
      const destination = load.destination
      const viewsInit = load.views.map(view => view.view)
      let views = {
        views: viewsInit,
        viewsApi: load.views,
        typeApp: typeApp,
        enabled: data.enabled
      }
      let config = {
        name: load.name,
        type: load.type,
        updateFrequency: load.updateFrequency,
        preferredUsageType: load.preferredUsageType || 'sync',
        startDate: moment(load.startDate).format('MM/DD/YYYY'),
        executionTime: moment(load.executionTime).format('hh:mm a'),
        executionDay: load.executionDay,
        executionHour: load.executionHour,
        executionMinute: load.executionMinute,
        displayMode: 'edit',
        typeApp: typeApp,
        creationDate: load.creationDate,
        enabled: data.enabled
      }
      let connect = {
        remoteConnection: destination.remoteConnection,
        unzip: destination.unzip,
        fileExtension: destination.fileExtension,
        path: destination.path,
        displayMode: 'edit',
        remoteConnections: connections,
        typeApp: typeApp,
        enabled: data.enabled
      }
      props.setDisplayMode('edit')
      props.setIdLoad(idLoad)
      props.setLoad(appLoad.data)
      props.setConfig({ ...config })
      props.setConnection({ ...connect })
      props.setViews({ ...views })
      props.setFormIsLoading(false)
    }
  } else {
    props.setFormIsLoading(false)
  }
  props.setPageIsLoading(false)
}

async function setAppLoad(props, typeApp, connections, data, appId) {
  props.setFormIsLoading(true)
  configuration.typeApp = typeApp
  configuration.enabled = data.enabled
  configuration.executionTime = moment(new Date()).format('hh:mm a')
  //   new Date().toLocaleTimeString(
  //     window.navigator.userLanguage || window.navigator.language,
  //     {
  //         hour: '2-digit',
  //         minute: '2-digit'
  //     }
  // )
  connection.typeApp = typeApp
  connection.enabled = data.enabled
  let views = {
    views: [],
    viewsApi: [],
    typeApp: typeApp,
    enabled: data.enabled
  }
  if (Object.keys(connections).length > 0) {
    connection.remoteConnections = { ...connections }
    const defaultConnect =
      connection.remoteConnections[Object.keys(connection.remoteConnections)[0]]
    connection.fileExtension = defaultConnect.fileExtension
    connection.unzip = defaultConnect.unzip
    connection.path = defaultConnect.path
    connection.remoteConnection = Object.keys(connection.remoteConnections)[0]
  } else {
    props.history.push(`/admin/invoiceAI/${typeApp}/${appId}`)
  }
  props.setConfig({ ...configuration })
  props.setConnection({ ...connection })
  props.setViews({ ...views })
  props.setFormIsLoading(false)
  props.setPageIsLoading(false)
}

async function onSubmit(props, event) {
  let result = {}
  let successMessage

  let appConfiguration = createConfigRequestBody(props, event)
  let response

  if (props.displayMode === 'edit') {
    response = await editInvoiceAppLoadByAppAndId(
      'invoiceAI',
      props.insightsAppId,
      appConfiguration,
      props.idLoad
    )
    successMessage = 'Load updated successfully'
  } else {
    response = await createInvoiceAppLoad(
      'invoiceAI',
      props.insightsAppId,
      appConfiguration,
      uuidv4()
    )
    successMessage = 'Load created successfully'
  }

  if (response && response.success && _isMounted) {
    props.setValidationMessage(successMessage)
    props.setSubmitSuccess(true)

    setTimeout(function() {
      if (_isMounted) {
        props.setSubmitSuccess(false)
        props.setFormIsLoading(false)
        props.history.push(`/admin/invoiceAI/${props.typeApp}/${props.selectedAppId}`)
      }
    }, 5000)
  } else {
    // Show error message
    if (result) {
      let message = result.message
      if (!message) {
        message = 'Something went wrong, please try again later.'
      }
      props.setValidationMessage(message)
      props.setCreateError(true)
    }
  }
}

async function initFunction(props, appId, idLoad, typeApp) {
  await setCustomViews(props, { appId })

  const details = await getAppDetails(props, appId)
  const data = checkExpiration(props.enabledApps['invoiceAI'])
  props.setExpirationData(data)
  if (idLoad) {
    getAppLoad(props, details.insightsAppId, idLoad, typeApp, details.connections, data)
  } else {
    setAppLoad(props, typeApp, details.connections, data, appId)
  }
}

function createConfigRequestBody(props, formValues) {
  if (formValues.config.type === 'historical') {
    formValues.config.updateFrequency = 'oneTime'
    formValues.config.preferredUsageType = undefined
  }
  const todayDate = moment(new Date()).format('MM/DD/YYYY')
  const params = {
    destination: {
      fileExtension: props.connection.fileExtension,
      path: props.connection.path ? props.connection.path : '',
      remoteConnection: props.connection.remoteConnection,
      nameConnection: props.connection.remoteConnections[props.connection.remoteConnection].name,
      unzip: props.connection.unzip
    },
    name: formValues.config.name,
    startDate: new Date(formValues.config.startDate).toISOString(),
    executionDay: formValues.config.executionDay,
    executionHour: formValues.config.executionHour,
    executionMinute: formValues.config.executionMinute,
    executionTime: new Date(todayDate + ' ' + formValues.config.executionTime).toISOString(),
    type: formValues.config.type,
    updateFrequency: formValues.config.updateFrequency,
    preferredUsageType: formValues.config.preferredUsageType,
    views: formValues.views.viewsApi,
    creationDate: formValues.config.creationDate,
    filters: {}
  }
  if (props.displayMode === 'create') {
    params.creationDate = new Date().toISOString()
  }
  if (props.typeApp === 'sourcingMasterData') {
    params.filters = {
      organizations: {
        expand: 'OrganizationID',
        filter: "OrganizationID.Domain in ('internalsupplierid','sap')"
      }
    }
  }
  if (props.typeApp === 'procurementMasterData') {
    params.filters = {
      suppliers: {
        expand: 'Locations,PaymentTerms'
      },
      addresses: {
        expand: 'PostalAddress'
      }
    }
  }
  const requestBody = {
    api: props.typeApp,
    params: params,
    appId: props.selectedAppId
  }

  return requestBody
}

async function setCustomViews(props, { appId }) {
  const customMasterDataViews = await getCustomMasterDataViews(props, { appId })
  props.setCustomViews([...customMasterDataViews])
}

async function getCustomMasterDataViews(props, { appId }) {
  const config = await getMasterDataConfigurations(appId)
  let viewsParsed = []
  if (config.data && config.data !== '') {
    const entityGroup = props.match.params.type
    const entityGroupConfig = config.data?.customEntitiesParams[entityGroup]
    const views = Object.keys(entityGroupConfig || []).filter(
      x => entityGroupConfig[x].insightsDisplay
    )
    viewsParsed = views.map(x => ({
      id: x,
      view: entityGroupConfig[x].insightsDisplayName + ': ' + x,
      api: entityGroup
    }))
  }

  return viewsParsed
}

export default compose(
  connect(state => ({
    isAuthenticated: state.login.isAuthenticated,
    name: state.login.name,
    enabledApps: state.app.enabledApps
  })),
  withState('appList', 'setAppList', []),
  withState('selectedAppId', 'setSelectedAppId', null),
  withState('selectedApp', 'setSelectedApp', null),
  withState('insightsAppId', 'setInsightsAppId', null),
  withState('config', 'setConfig', { ...configuration }),
  withState('connection', 'setConnection', { ...connection }),
  withState('views', 'setViews', { ...views }),
  withState('connections', 'setConnections', null),
  withState('formIsLoading', 'setFormIsLoading', false),
  withState('submitSuccess', 'setSubmitSuccess', false),
  withState('createError', 'setCreateError', false),
  withState('validationMessage', 'setValidationMessage', ''),
  withState('pageIsLoading', 'setPageIsLoading', true),
  withState('typeApp', 'setTypeApp', null),
  withState('idLoad', 'setIdLoad', null),
  withState('load', 'setLoad', null),
  withState('displayMode', 'setDisplayMode', 'create'),
  withState('expirationData', 'setExpirationData', {}),
  withState('customViews', 'setCustomViews', []),
  withHandlers({
    onAppChanged: props => id => {
      props.history.push('/admin/invoiceAI/configurations')
    },
    onAppSaved: props => async event => {
      props.setFormIsLoading(true)
      await onSubmit(props, event)
    },
    onClear: props => () => {
      props.history.push(`/admin/invoiceAI/${props.typeApp}/${props.selectedAppId}`)
    }
  }),
  lifecycle({
    componentDidMount() {
      _isMounted = true
      appId = this.props.match.params.id
      typeApp = this.props.match.params.type
      idLoad = this.props.match.params.idLoad
      this.props.setPageIsLoading(true)
      this.props.setTypeApp(typeApp)
      getAppList(this.props)
      initFunction(this.props, appId, idLoad, typeApp)
    }
  })
)(InvoiceLoadView)
