import React, { useEffect, useState, useRef } from 'react'
import ReactFlow, { Controls, Background } from 'react-flow-renderer'
import { useReactToPrint } from 'react-to-print'
import { useParams } from 'react-router'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { Button as ButonUI } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Fade from '@material-ui/core/Fade'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'

// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import GetAppIcon from '@material-ui/icons/GetApp'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import DvrIcon from '@material-ui/icons/Dvr'
import DashboardIcon from '@material-ui/icons/Dashboard'
import DetailsIcon from '@material-ui/icons/Details'

// components
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import Card from 'components/Card/Card.js'
import CardHeader from 'components/Card/CardHeader.js'
import CardBody from 'components/Card/CardBody.js'
import Snackbar from 'components/Snackbar/Snackbar.js'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Button from 'components/CustomButtons/Button.js'
import SyncLoader from 'components/SyncLoader/SyncLoader.js'

// functions
import { formatDate } from 'utils/functions.js'

// hooks
import { useGetDiagramData } from 'hooks/useMonitorsConsole'

// style
import styles from 'assets/jss/material-dashboard-pro-react/views/Apps/invoiceStyle.js'

const useStyles = makeStyles(styles)

export default function MonitorDiagramView() {
  const { processId, env, monitorId, description, appId } = useParams()
  const classes = useStyles()
  const diagramRef = useRef()
  const [createError, setCreateError] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalInfo, setModalInfo] = useState([])

  const {
    data: diagramData,
    isLoading,
    error,
    isError,
    refetch,
    isFetching
  } = useGetDiagramData({
    processId,
    env,
    monitorId,
    appId
  })

  const breadcrumbViews = [
    {
      name: 'Monitor Console',
      url: `/admin/monitorConsole/index`,
      icon: DesktopWindowsIcon
    },
    {
      name: 'Monitor Details',
      url: `/admin/monitorConsole/transactions/${env}/${appId}/${monitorId}/${description}`,
      icon: DvrIcon
    },
    {
      name: 'Transaction Details',
      url: `/admin/monitorConsole/details/${env}/${appId}/${monitorId}/${description}/${processId}`,
      icon: DetailsIcon
    },
    {
      name: 'Diagram View',
      url: `/admin/monitorConsole/diagram/${env}/${appId}/${monitorId}/${description}/${processId}`,
      icon: DashboardIcon
    }
  ]

  useEffect(() => {
    if (!isError) {
      return
    }
    setCreateError(true)
  }, [isError])

  const handlePrint = useReactToPrint({
    content: () => diagramRef.current,
    removeAfterPrint: true
  })

  /* function scrolltoTop() {
    mountedRef.current.scrollIntoView({ behavior: 'auto', block: 'start' })
  } */

  function getModal() {
    return (
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        open={showModal}
        keepMounted
        fullWidth={true}
        maxWidth={'md'}
        onClose={() => setShowModal(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
          style={{ wordWrap: 'break-word' }}
        >
          {modalInfo.map((value, index) => {
            let keys = Object.keys(value)
            return (
              <Accordion
                key={index}
                className={classes.accordion}
                style={{
                  width: '100%'
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.heading}>
                    {value['time']
                      ? new Date(value['time']).toLocaleTimeString(
                          window.navigator.userLanguage ||
                            window.navigator.language
                        )
                      : 'Warning'}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <GridContainer
                    style={{ paddingBottom: '5%', margin: 'auto' }}
                  >
                    {keys.map((item, index2) => {
                      if (item === 'time') {
                        return (
                          <GridItem key={`${index}-${index2}`} xs={12}>
                            <div>
                              <h5>{item.toUpperCase()}</h5>
                              <p>{formatDate({ date: value[item] })}</p>
                            </div>
                          </GridItem>
                        )
                      } else {
                        return (
                          <GridItem key={`${index}-${index2}`} xs={12}>
                            <div>
                              <h5>{item.toUpperCase()}</h5>
                              <p>{value[item]}</p>
                            </div>
                          </GridItem>
                        )
                      }
                    })}
                  </GridContainer>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setShowModal(false)} color="danger" simple>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  function returnDiagram() {
    if (isLoading) {
      return (
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer justify="center">
            <div className={classes.reloadSize}>
              <CircularProgress />
            </div>
          </GridContainer>
        </GridItem>
      )
    } else {
      return (
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer justify="center">
            <GridItem xs={12}>
              <GridContainer spacing={0} justify="flex-end">
                <GridItem md={12}>
                  {diagramData.data && diagramData.data.length > 0 ? (
                    <ButonUI
                      color="primary"
                      size="small"
                      className={classes.floatRight}
                      onClick={() => {
                        handlePrint()
                      }}
                    >
                      <GetAppIcon fontSize="small" />
                    </ButonUI>
                  ) : null}
                  {diagramData ? (
                    <ButonUI
                      color="primary"
                      size="small"
                      className={classes.floatRight}
                      onClick={() => refetch()}
                    >
                      <RefreshRoundedIcon fontSize="small" />
                    </ButonUI>
                  ) : null}
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12}>
              {diagramData ? (
                <GridContainer justify="center" style={{ marginTop: '5px' }}>
                  <GridItem xs={12} sm={6} md={3}>
                    <InputLabel className={classes.label}>
                      Environment
                    </InputLabel>
                    <FormControl fullWidth>{env}</FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <InputLabel className={classes.label}>App</InputLabel>
                    <FormControl fullWidth>{appId}</FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <InputLabel className={classes.label}>
                      Monitor Name
                    </InputLabel>
                    <FormControl fullWidth>{description}</FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={6} md={3}>
                    <InputLabel className={classes.label}>
                      Process Id
                    </InputLabel>
                    <FormControl fullWidth>{processId}</FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <p></p>
                  </GridItem>
                  <Grid container innerRef={diagramRef}>
                    {Object.keys(diagramData.colors).length > 0 ? (
                      <GridItem xs={12} sm={12} md={12}>
                        <div
                          style={{
                            display: 'inline-block',
                            paddingRight: '10px'
                          }}
                        >
                          Success
                          <div
                            style={{
                              display: 'inline-block',
                              paddingLeft: '10px'
                            }}
                          >
                            <input
                              className="nodrag"
                              type="color"
                              defaultValue={diagramData.colors.successColor}
                              disabled
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            paddingRight: '10px'
                          }}
                        >
                          Error
                          <div
                            style={{
                              display: 'inline-block',
                              paddingLeft: '10px'
                            }}
                          >
                            <input
                              className="nodrag"
                              type="color"
                              defaultValue={diagramData.colors.failureColor}
                              disabled
                            />
                          </div>
                        </div>
                        <div style={{ display: 'inline-block' }}>
                          Warning
                          <div
                            style={{
                              display: 'inline-block',
                              paddingLeft: '10px'
                            }}
                          >
                            <input
                              className="nodrag"
                              type="color"
                              defaultValue={diagramData.colors.warningColor}
                              disabled
                            />
                          </div>
                        </div>
                      </GridItem>
                    ) : (
                      ''
                    )}
                    <GridItem xs={12} sm={12} md={12}>
                      {diagramData.data.length > 0 ? (
                        <div style={{ height: 450 }}>
                          <ReactFlow
                            ref={diagramRef}
                            elements={diagramData.data}
                            snapToGrid={true}
                            snapGrid={[15, 15]}
                            onElementClick={(event, element) => {
                              if (element.data.message) {
                                setModalInfo(element.data.message)
                                setShowModal(true)
                              }
                            }}
                          >
                            <Controls />
                            <Background color="#aaa" gap={16} />
                          </ReactFlow>
                        </div>
                      ) : (
                        <h5
                          style={{
                            paddingTop: '25px',
                            paddingBottom: '25px',
                            textAlign: 'center'
                          }}
                        >
                          Diagram not available
                        </h5>
                      )}
                    </GridItem>
                  </Grid>
                </GridContainer>
              ) : null}
            </GridItem>
          </GridContainer>
          {getModal()}
        </GridItem>
      )
    }
  }

  return (
    <Fade in={true} timeout={250}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Breadcrumbs views={breadcrumbViews} />
          <Card>
            <CardHeader>
              <h3 className={classes.formHeader}>Diagram View</h3>
            </CardHeader>
            <CardBody>
              <SyncLoader showLoader={isFetching && !isLoading} />
              {returnDiagram()}
            </CardBody>
          </Card>
        </GridItem>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={
            isError
              ? error.response?.data?.message ||
                'Something went wrong, try again later'
              : ''
          }
          open={createError}
          closeNotification={() => setCreateError(false)}
          close
        />
      </GridContainer>
    </Fade>
  )
}
